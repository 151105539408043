import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Time: string;
};

export type AcademicSession = {
  __typename?: "AcademicSession";
  id: Scalars["ID"];
  session_end_date?: Maybe<Scalars["Time"]>;
  session_start_date: Scalars["Time"];
};

export type ActiveGoals = {
  __typename?: "ActiveGoals";
  speaking: SpeakingActiveGoals;
  writing: WritingActiveGoals;
};

export type AdditionalFeedback = {
  __typename?: "AdditionalFeedback";
  audio: Array<Maybe<File>>;
  feedback_notes?: Maybe<Scalars["String"]>;
};

export type AdditionalFeedbackInput = {
  additional_feedback?: InputMaybe<Scalars["String"]>;
  file_name?: InputMaybe<Scalars["String"]>;
  file_type?: InputMaybe<Scalars["String"]>;
  submission_id: Scalars["String"];
  url?: InputMaybe<Scalars["String"]>;
};

export type Admin = {
  __typename?: "Admin";
  admin_id: Scalars["String"];
  created_at?: Maybe<Scalars["Time"]>;
  created_by_first_name?: Maybe<Scalars["String"]>;
  created_by_last_name?: Maybe<Scalars["String"]>;
  district: Scalars["String"];
  districtData?: Maybe<DistrictData>;
  district_id: Scalars["String"];
  email: Scalars["String"];
  first_name: Scalars["String"];
  id: Scalars["ID"];
  job_title: Scalars["String"];
  last_name: Scalars["String"];
  middle_name?: Maybe<Scalars["String"]>;
  phone_number?: Maybe<Scalars["String"]>;
  school?: Maybe<Scalars["String"]>;
  schoolData: Array<Maybe<School>>;
  school_id?: Maybe<Scalars["String"]>;
  source: Scalars["String"];
  title: Scalars["String"];
  updated_at?: Maybe<Scalars["Time"]>;
  updated_by_first_name?: Maybe<Scalars["String"]>;
  updated_by_last_name?: Maybe<Scalars["String"]>;
  user_id: Scalars["ID"];
};

export type AdminBenchmarkGradeComparisonResult = {
  __typename?: "AdminBenchmarkGradeComparisonResult";
  benchmark_data_points: Array<AdminBenchmarkScoresDataPoint>;
  id: Scalars["String"];
  name: Scalars["String"];
  position: Scalars["Int"];
};

export type AdminBenchmarkGradeComparisonResultNode = {
  __typename?: "AdminBenchmarkGradeComparisonResultNode";
  nodes: Array<Maybe<AdminBenchmarkGradeComparisonResult>>;
  total_count: Scalars["Int"];
};

export type AdminBenchmarkIndicatorRubricLineItem = {
  __typename?: "AdminBenchmarkIndicatorRubricLineItem";
  rubric_icon_base_64: Scalars["String"];
  rubric_id: Scalars["String"];
  rubric_position: Scalars["Int"];
  rubric_scores_for_benchmark_type: Array<AdminBenchmarkIndicatorRubricLineItemScoreForBenchmarkType>;
  rubric_title: Scalars["String"];
  ytd: Scalars["Float"];
  ytd_formatted: Scalars["String"];
};

export type AdminBenchmarkIndicatorRubricLineItemScoreForBenchmarkType = {
  __typename?: "AdminBenchmarkIndicatorRubricLineItemScoreForBenchmarkType";
  benchmark_type: BenchmarkType;
  rubric_score_average: Scalars["Float"];
  rubric_score_color: Scalars["String"];
  rubric_score_progress: Scalars["String"];
  submission_count: Scalars["Int"];
};

export type AdminBenchmarkIndicatorScoresResult = {
  __typename?: "AdminBenchmarkIndicatorScoresResult";
  rubric_line_items: Array<AdminBenchmarkIndicatorRubricLineItem>;
  submission_type: SubmissionType;
};

export type AdminBenchmarkInput = {
  district_id: Scalars["String"];
  grade_ids?: InputMaybe<Array<Scalars["String"]>>;
  school_ids?: InputMaybe<Array<Scalars["String"]>>;
  teacher_ids?: InputMaybe<Array<Scalars["String"]>>;
};

export enum AdminBenchmarkReportingWidgetSortType {
  GradePosition = "grade_position",
  SchoolName = "school_name",
  SpBoyScore = "sp_boy_score",
  SpEoyScore = "sp_eoy_score",
  SpMoyScore = "sp_moy_score",
  SpYtdChange = "sp_ytd_change",
  TeacherName = "teacher_name",
  WrBoyScore = "wr_boy_score",
  WrEoyScore = "wr_eoy_score",
  WrMoyScore = "wr_moy_score",
  WrYtdChange = "wr_ytd_change",
}

export type AdminBenchmarkSchoolsComparisonResult = {
  __typename?: "AdminBenchmarkSchoolsComparisonResult";
  benchmark_data_points: Array<AdminBenchmarkScoresDataPoint>;
  id: Scalars["String"];
  name: Scalars["String"];
};

export type AdminBenchmarkSchoolsComparisonResultNode = {
  __typename?: "AdminBenchmarkSchoolsComparisonResultNode";
  nodes: Array<Maybe<AdminBenchmarkSchoolsComparisonResult>>;
  total_count: Scalars["Int"];
};

export type AdminBenchmarkSchoolsTeacherAndGradeComparisonResult = {
  __typename?: "AdminBenchmarkSchoolsTeacherAndGradeComparisonResult";
  benchmark_data_points: Array<AdminBenchmarkScoresDataPoint>;
  grade_id: Scalars["String"];
  grade_name: Scalars["String"];
  grade_position: Scalars["Int"];
  id: Scalars["String"];
  name: Scalars["String"];
};

export type AdminBenchmarkSchoolsTeacherAndGradeComparisonResultNode = {
  __typename?: "AdminBenchmarkSchoolsTeacherAndGradeComparisonResultNode";
  nodes: Array<Maybe<AdminBenchmarkSchoolsTeacherAndGradeComparisonResult>>;
  total_count: Scalars["Int"];
};

export type AdminBenchmarkSchoolsTeacherComparisonResult = {
  __typename?: "AdminBenchmarkSchoolsTeacherComparisonResult";
  benchmark_data_points: Array<AdminBenchmarkScoresDataPoint>;
  id: Scalars["String"];
  name: Scalars["String"];
};

export type AdminBenchmarkSchoolsTeacherComparisonResultNode = {
  __typename?: "AdminBenchmarkSchoolsTeacherComparisonResultNode";
  nodes: Array<Maybe<AdminBenchmarkSchoolsTeacherComparisonResult>>;
  total_count: Scalars["Int"];
};

export type AdminBenchmarkScoresDataPoint = {
  __typename?: "AdminBenchmarkScoresDataPoint";
  boy_color: Scalars["String"];
  boy_score: Scalars["Float"];
  boy_submission_count: Scalars["Int"];
  boy_to_moy_progress: Scalars["String"];
  eoy_color: Scalars["String"];
  eoy_score: Scalars["Float"];
  eoy_submission_count: Scalars["Int"];
  moy_color: Scalars["String"];
  moy_score: Scalars["Float"];
  moy_submission_count: Scalars["Int"];
  moy_to_eoy_progress: Scalars["String"];
  submission_type: Scalars["String"];
  ytd_change: Scalars["String"];
  ytd_change_float: Scalars["Float"];
};

export type AdminBenchmarkSubmissionStatusGrowthResult = {
  __typename?: "AdminBenchmarkSubmissionStatusGrowthResult";
  benchmark_type: BenchmarkType;
  speaking_score: Scalars["Float"];
  speaking_score_color: Scalars["String"];
  submission_line_items: Array<SubmissionLineItem>;
  total_submission: Scalars["Int"];
  writing_score: Scalars["Float"];
  writing_score_color: Scalars["String"];
};

export type AdminConnection = {
  __typename?: "AdminConnection";
  nodes: Array<Admin>;
  totalCount: Scalars["Int"];
};

export type AdminDashboardData = {
  __typename?: "AdminDashboardData";
  classes: Scalars["Int"];
  district_admins: Scalars["Int"];
  districts: Scalars["Int"];
  role?: Maybe<Scalars["String"]>;
  school_admins: Scalars["Int"];
  schools: Scalars["Int"];
  states: Scalars["Int"];
  students: Scalars["Int"];
  teachers: Scalars["Int"];
};

export type AdminDashboardFilterInput = {
  end_timestamp?: InputMaybe<Scalars["Int"]>;
  grade_ids?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  school_ids?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  section_ids?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  start_timestamp?: InputMaybe<Scalars["Int"]>;
  submission_score_type?: InputMaybe<SubmissionType>;
  teacher_ids?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type AdminDistrict = {
  __typename?: "AdminDistrict";
  id: Scalars["ID"];
  name: Scalars["String"];
  state: AdminState;
};

export type AdminFile = {
  __typename?: "AdminFile";
  file_key: Scalars["String"];
  file_type: Scalars["String"];
  id: Scalars["String"];
  thumbnail_file_key?: Maybe<Scalars["String"]>;
  thumbnail_file_type?: Maybe<Scalars["String"]>;
  thumbnail_url?: Maybe<Scalars["String"]>;
  url: Scalars["String"];
};

export type AdminInput = {
  admin_id: Scalars["String"];
  district_id: Scalars["String"];
  email: Scalars["String"];
  first_name: Scalars["String"];
  id?: InputMaybe<Scalars["String"]>;
  job_title: Scalars["String"];
  last_name: Scalars["String"];
  middle_name?: InputMaybe<Scalars["String"]>;
  password?: InputMaybe<Scalars["String"]>;
  phone_number?: InputMaybe<Scalars["String"]>;
  role?: InputMaybe<UserRole>;
  school_id?: InputMaybe<Scalars["String"]>;
  title: Scalars["String"];
};

export enum AdminListSortType {
  AdminId = "admin_id",
  CreatedAt = "created_at",
  CreatedBy = "created_by",
  District = "district",
  Email = "email",
  FirstName = "first_name",
  JobTitle = "job_title",
  School = "school",
  Source = "source",
  UpdatedAt = "updated_at",
  UpdatedBy = "updated_by",
}

export type AdminPerformanceScoreLineItem = {
  __typename?: "AdminPerformanceScoreLineItem";
  color: Scalars["String"];
  id: Scalars["ID"];
  skill_description: Scalars["String"];
  skill_key: Scalars["String"];
  skill_label: Scalars["String"];
  skill_level: Scalars["Int"];
  total_count: Scalars["Int"];
};

export type AdminResource = {
  __typename?: "AdminResource";
  file: AdminFile;
  grade: Array<Scalars["String"]>;
  id: Scalars["String"];
  image_height: Scalars["Int"];
  image_width: Scalars["Int"];
  keywords: Array<Scalars["ID"]>;
  subject_areas: Array<Scalars["ID"]>;
  task_types: Array<Scalars["ID"]>;
  thumbnail_height: Scalars["Int"];
  thumbnail_width: Scalars["Int"];
  title: Scalars["String"];
};

export enum AdminRole {
  DistrictAdmin = "DistrictAdmin",
  SchoolAdmin = "SchoolAdmin",
  SuperAdmin = "SuperAdmin",
  ZoneAdmin = "ZoneAdmin",
}

export type AdminSchool = {
  __typename?: "AdminSchool";
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type AdminState = {
  __typename?: "AdminState";
  id: Scalars["ID"];
  name: Scalars["String"];
};

export enum AdminStudentsListSortType {
  District = "district",
  Grades = "grades",
  Name = "name",
  School = "school",
  Source = "source",
  StudentId = "student_id",
}

export type Agency = {
  __typename?: "Agency";
  agency_id: Scalars["String"];
  agency_name: Scalars["String"];
  id: Scalars["ID"];
  state_id: Scalars["String"];
};

export type AllStudentConnection = {
  __typename?: "AllStudentConnection";
  nodes: Array<StudentListDto>;
  totalCount: Scalars["Int"];
};

export type AnalyticsData = {
  __typename?: "AnalyticsData";
  assigned_date?: Maybe<Scalars["Time"]>;
  current_goal?: Maybe<Scalars["String"]>;
  current_goal_skill?: Maybe<Scalars["String"]>;
  icon_base64?: Maybe<Scalars["String"]>;
  student_id: Scalars["ID"];
  student_name: Scalars["String"];
};

export type Annotation = {
  __typename?: "Annotation";
  id: Scalars["ID"];
  submission_id: Scalars["String"];
  title: Scalars["String"];
  x_position: Scalars["Float"];
  y_position: Scalars["Float"];
};

export type Assignment = {
  __typename?: "Assignment";
  IsSubmissionsGoalsShared?: Maybe<Scalars["Boolean"]>;
  back_navigation_allowed: Scalars["Boolean"];
  close_at: Scalars["Time"];
  id: Scalars["ID"];
  is_common_prompt: Scalars["Boolean"];
  is_submissions_started: Scalars["Boolean"];
  resource?: Maybe<StaticResource>;
  resource_id: Scalars["String"];
  section?: Maybe<Section>;
  speaking_prompt: Scalars["String"];
  speaking_prompt_file?: Maybe<PromptFile>;
  start_at: Scalars["Time"];
  status_aggregation?: Maybe<AssignmentSubmissionAggregation>;
  submissionForStudent?: Maybe<Submission>;
  submissionsForTeacher: SubmissionConnection;
  teacher?: Maybe<User>;
  title: Scalars["String"];
  writing_prompt: Scalars["String"];
  writing_prompt_file?: Maybe<PromptFile>;
};

export type AssignmentSubmissionForStudentArgs = {
  studentID?: InputMaybe<Scalars["String"]>;
};

export type AssignmentSubmissionsForTeacherArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<OrderBy>;
  page?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Array<InputMaybe<SubmissionStatus>>>;
};

export type AssignmentConnection = {
  __typename?: "AssignmentConnection";
  nodes: Array<Assignment>;
  score_details: Array<ScoreDetail>;
  totalCount: Scalars["Int"];
};

export type AssignmentSubmissionAggregation = {
  __typename?: "AssignmentSubmissionAggregation";
  InProgress: Scalars["Int"];
  NotStarted: Scalars["Int"];
  Submitted: Scalars["Int"];
};

export enum AssignmentSubmissionsForTeacherSortType {
  SubmissionSubmittedAt = "submission_submitted_at",
}

export type BenchmarkAssignToInput = {
  district_ids: Array<Scalars["String"]>;
  grade_ids?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  limit?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<OrderBy>;
  page?: InputMaybe<Scalars["Int"]>;
  school_ids?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sort?: InputMaybe<BenchmarkAssignToSortType>;
};

export enum BenchmarkAssignToSortType {
  DistrictName = "district_name",
  GradeName = "grade_name",
  SchoolName = "school_name",
  StudentCount = "student_count",
}

export type BenchmarkAssignedToCountResult = {
  __typename?: "BenchmarkAssignedToCountResult";
  node_count?: Maybe<Scalars["Int"]>;
  nodes?: Maybe<Array<Maybe<BenchmarkAssignedToNode>>>;
};

export type BenchmarkAssignedToNode = {
  __typename?: "BenchmarkAssignedToNode";
  district_id: Scalars["String"];
  district_name: Scalars["String"];
  grade_id?: Maybe<Scalars["String"]>;
  grade_name?: Maybe<Scalars["String"]>;
  school_id?: Maybe<Scalars["String"]>;
  school_name?: Maybe<Scalars["String"]>;
  student_count?: Maybe<Scalars["Int"]>;
};

export enum BenchmarkGradedBy {
  Flashlight = "flashlight",
  Teacher = "teacher",
}

export type BenchmarkIndividualVsGradeLevelInfoResult = {
  __typename?: "BenchmarkIndividualVsGradeLevelInfoResult";
  rubric_line_items?: Maybe<Array<Maybe<BenchmarkIndividualVsGradeLevelRubricLineItem>>>;
  submission_type: Scalars["String"];
};

export type BenchmarkIndividualVsGradeLevelInput = {
  benchmark_type?: InputMaybe<BenchmarkType>;
  student_user_id?: InputMaybe<Scalars["String"]>;
  submission_type?: InputMaybe<SubmissionType>;
};

export type BenchmarkIndividualVsGradeLevelRubricLineItem = {
  __typename?: "BenchmarkIndividualVsGradeLevelRubricLineItem";
  rubric_district_grade_score_average: Scalars["Float"];
  rubric_icon: Scalars["ID"];
  rubric_position: Scalars["Float"];
  rubric_score_average: Scalars["Float"];
  rubric_score_color: Scalars["String"];
  rubric_title: Scalars["ID"];
};

export type BenchmarkPt = {
  __typename?: "BenchmarkPT";
  back_navigation_allowed: Scalars["Boolean"];
  benchmark_type: BenchmarkType;
  close_at: Scalars["Time"];
  district_names: Array<Scalars["String"]>;
  grade_titles?: Maybe<Array<Scalars["String"]>>;
  graded_by: BenchmarkGradedBy;
  id: Scalars["ID"];
  is_common_prompt: Scalars["Boolean"];
  is_submissions_started: Scalars["Boolean"];
  resource: StaticResource;
  school_names?: Maybe<Array<Scalars["String"]>>;
  speaking_prompt: Scalars["String"];
  speaking_prompt_file?: Maybe<PromptFile>;
  start_at: Scalars["Time"];
  status_aggregation?: Maybe<AssignmentSubmissionAggregation>;
  title: Scalars["String"];
  writing_prompt: Scalars["String"];
  writing_prompt_file?: Maybe<PromptFile>;
};

export type BenchmarkProgressScoreInput = {
  grade_ids?: InputMaybe<Array<Scalars["ID"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<OrderBy>;
  page?: InputMaybe<Scalars["Int"]>;
  school_ids?: InputMaybe<Array<Scalars["ID"]>>;
  search_by_name?: InputMaybe<Scalars["String"]>;
  section_ids?: InputMaybe<Array<Scalars["ID"]>>;
  sort_by?: InputMaybe<BenchmarkProgressScoreSortType>;
};

export type BenchmarkProgressScoreNode = {
  __typename?: "BenchmarkProgressScoreNode";
  grade: Scalars["String"];
  has_reviewed_submissions: Scalars["Boolean"];
  score_by_submission_type: Array<BenchmarkProgressScoreSubmissionType>;
  student_first_name: Scalars["String"];
  student_id: Scalars["ID"];
  student_last_name: Scalars["String"];
  student_user_id: Scalars["ID"];
};

export type BenchmarkProgressScoreResult = {
  __typename?: "BenchmarkProgressScoreResult";
  nodes: Array<BenchmarkProgressScoreNode>;
  total_count: Scalars["Int"];
};

export enum BenchmarkProgressScoreSortType {
  SpBoy = "sp_boy",
  SpEoy = "sp_eoy",
  SpMoy = "sp_moy",
  SpYtd = "sp_ytd",
  StudentGrade = "student_grade",
  StudentName = "student_name",
  WrBoy = "wr_boy",
  WrEoy = "wr_eoy",
  WrMoy = "wr_moy",
  WrYtd = "wr_ytd",
}

export type BenchmarkProgressScoreSubmissionType = {
  __typename?: "BenchmarkProgressScoreSubmissionType";
  boy_color?: Maybe<Scalars["String"]>;
  boy_score?: Maybe<Scalars["Float"]>;
  boy_to_moy_progress?: Maybe<Scalars["String"]>;
  eoy_color?: Maybe<Scalars["String"]>;
  eoy_score?: Maybe<Scalars["Float"]>;
  moy_color?: Maybe<Scalars["String"]>;
  moy_score?: Maybe<Scalars["Float"]>;
  moy_to_eoy_progress?: Maybe<Scalars["String"]>;
  submission_type: SubmissionType;
  ytd_change?: Maybe<Scalars["String"]>;
};

export type BenchmarkScoresInput = {
  limit?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<OrderBy>;
  page?: InputMaybe<Scalars["Int"]>;
  sort_by?: InputMaybe<BenchmarkScoresSortType>;
  student_user_id: Scalars["String"];
};

export type BenchmarkScoresLineItem = {
  __typename?: "BenchmarkScoresLineItem";
  benchmark_title: Scalars["String"];
  benchmark_type: BenchmarkType;
  speaking_score_average?: Maybe<Scalars["Float"]>;
  speaking_score_color?: Maybe<Scalars["String"]>;
  submission_id?: Maybe<Scalars["String"]>;
  submission_status?: Maybe<SubmissionStatus>;
  writing_score_average?: Maybe<Scalars["Float"]>;
  writing_score_color?: Maybe<Scalars["String"]>;
};

export type BenchmarkScoresResult = {
  __typename?: "BenchmarkScoresResult";
  current_page: Scalars["Int"];
  submission_line_items: Array<BenchmarkScoresLineItem>;
  total_count: Scalars["Int"];
};

export enum BenchmarkScoresSortType {
  SubmittedAt = "submitted_at",
}

export type BenchmarkSlideOutInput = {
  benchmark_id: Scalars["String"];
  status_list?: InputMaybe<Array<SubmissionStatus>>;
};

export type BenchmarkSlideOutResult = {
  __typename?: "BenchmarkSlideOutResult";
  nodes: Array<SlideOutStudents>;
  total_count: Scalars["Int"];
};

export type BenchmarkStudentGrowthReportInput = {
  student_user_id?: InputMaybe<Scalars["String"]>;
};

export type BenchmarkStudentGrowthReportResult = {
  __typename?: "BenchmarkStudentGrowthReportResult";
  benchmark_type: Scalars["String"];
  submission_speaking_score_average?: Maybe<Scalars["Float"]>;
  submission_submitted_at_timestamp: Scalars["Time"];
  submission_writing_score_average?: Maybe<Scalars["Float"]>;
};

export type BenchmarkSubmission = {
  __typename?: "BenchmarkSubmission";
  additional_feedbackBM: AdditionalFeedback;
  annotationsBM: Array<Annotation>;
  benchmarkPT: BenchmarkPt;
  benchmark_id: Scalars["String"];
  feedback_notes?: Maybe<Scalars["String"]>;
  gradesBM: Array<Grade>;
  grading_statusBM: GradingStatus;
  id: Scalars["ID"];
  reviewed_at?: Maybe<Scalars["Time"]>;
  scoring_details?: Maybe<Array<Maybe<ScoreDetail>>>;
  speakingsBM: Array<File>;
  status: SubmissionStatus;
  studentBM: User;
  submission_scoreBM?: Maybe<Array<Maybe<SubmissionScore>>>;
  submission_type_scores?: Maybe<Array<SubmissionTypeGroup>>;
  submitted_at?: Maybe<Scalars["Time"]>;
  teacher_id: Scalars["String"];
  writing?: Maybe<Scalars["String"]>;
};

export type BenchmarkSubmissionListInput = {
  benchmark_types?: InputMaybe<Array<BenchmarkType>>;
  district_ids?: InputMaybe<Array<Scalars["String"]>>;
  grade_statuses?: InputMaybe<Array<SubmissionStatus>>;
  graded_by?: InputMaybe<Array<BenchmarkGradedBy>>;
  limit?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<OrderBy>;
  page?: InputMaybe<Scalars["Int"]>;
  predefined_grade_ids?: InputMaybe<Array<Scalars["String"]>>;
  school_ids?: InputMaybe<Array<Scalars["String"]>>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<BenchmarkSubmissionListSortType>;
  state_ids?: InputMaybe<Array<Scalars["String"]>>;
};

export type BenchmarkSubmissionListNode = {
  __typename?: "BenchmarkSubmissionListNode";
  benchmark_id: Scalars["String"];
  benchmark_image_title: Scalars["String"];
  benchmark_image_url: Scalars["String"];
  benchmark_thumbnail_image_url?: Maybe<Scalars["String"]>;
  benchmark_title: Scalars["String"];
  graded_by: BenchmarkGradedBy;
  id: Scalars["String"];
  status: Scalars["String"];
  student_district_id: Scalars["String"];
  student_district_title: Scalars["String"];
  student_grade_id: Scalars["String"];
  student_grade_title: Scalars["String"];
  student_id: Scalars["String"];
  submitted_at?: Maybe<Scalars["Time"]>;
  user_first_name?: Maybe<Scalars["String"]>;
  user_last_name?: Maybe<Scalars["String"]>;
};

export type BenchmarkSubmissionListResult = {
  __typename?: "BenchmarkSubmissionListResult";
  node_count: Scalars["Int"];
  nodes: Array<BenchmarkSubmissionListNode>;
};

export enum BenchmarkSubmissionListSortType {
  BenchmarkTitle = "benchmark_title",
  DistrictName = "district_name",
  GradeTitle = "grade_title",
  StudentName = "student_name",
  SubmittedAt = "submitted_at",
}

export type BenchmarkSuperAdminDtoConnection = {
  __typename?: "BenchmarkSuperAdminDTOConnection";
  nodes: Array<BenchmarkSuperAdminDto>;
  totalCount: Scalars["Int"];
};

export enum BenchmarkSuperAdminDtoSortType {
  BenchmarkTitle = "benchmark_title",
  DistrictName = "district_name",
  GradeTitle = "grade_title",
  SchoolName = "school_name",
  StartDate = "start_date",
}

export type BenchmarkSuperAdminDto = {
  __typename?: "BenchmarkSuperAdminDto";
  benchmark_id: Scalars["String"];
  benchmark_title: Scalars["String"];
  close_date: Scalars["Time"];
  district_id: Scalars["String"];
  district_name: Scalars["String"];
  grade_title: Scalars["String"];
  resource: StaticResource;
  school_id: Scalars["String"];
  school_name: Scalars["String"];
  start_date: Scalars["Time"];
  status_aggregation: AssignmentSubmissionAggregation;
};

export type BenchmarkTeacherDto = {
  __typename?: "BenchmarkTeacherDTO";
  benchmark_id: Scalars["String"];
  benchmark_title: Scalars["String"];
  benchmark_type: BenchmarkType;
  close_date: Scalars["Time"];
  graded_by: BenchmarkGradedBy;
  resource: StaticResource;
  school_id: Scalars["String"];
  start_date: Scalars["Time"];
  status_aggregation: AssignmentSubmissionAggregation;
};

export type BenchmarkTeacherDtoConnection = {
  __typename?: "BenchmarkTeacherDTOConnection";
  nodes?: Maybe<Array<BenchmarkTeacherDto>>;
  total_count: Scalars["Int"];
};

export enum BenchmarkTeacherDtoSortType {
  BenchmarkTitle = "benchmark_title",
  BenchmarkType = "benchmark_type",
  GradedBy = "graded_by",
  StartDate = "start_date",
}

export enum BenchmarkType {
  Boy = "boy",
  Eoy = "eoy",
  Moy = "moy",
}

export type CategorizedGoal = {
  __typename?: "CategorizedGoal";
  category: Scalars["String"];
  goal_list: Array<PredefinedGoal>;
};

export type ClassResourcesCount = {
  __typename?: "ClassResourcesCount";
  group_count: Scalars["Int"];
  student_count: Scalars["Int"];
};

export type ClassesWithStudentsInput = {
  sectionIDs?: InputMaybe<Array<Scalars["ID"]>>;
  studentID: Scalars["ID"];
};

export type CleverDistrict = {
  __typename?: "CleverDistrict";
  clever_id: Scalars["String"];
  name: Scalars["String"];
  source: Scalars["String"];
};

export type CleverDistrictImportInput = {
  id: Scalars["String"];
  score_scheme_id: Scalars["String"];
  source: Scalars["String"];
};

export type CleverSyncDetails = {
  __typename?: "CleverSyncDetails";
  admin?: Maybe<UserAdmin>;
  created_at?: Maybe<Scalars["Time"]>;
  district_ids?: Maybe<Array<Maybe<Scalars["String"]>>>;
  districts?: Maybe<Array<Maybe<DistrictDto>>>;
  id: Scalars["String"];
  message?: Maybe<Scalars["String"]>;
  owner_type: OwnerType;
  run_duration?: Maybe<Scalars["Int"]>;
  status: SyncStatus;
  sync_type: SyncType;
};

export type CleverSyncListConnection = {
  __typename?: "CleverSyncListConnection";
  nodes: Array<CleverSyncListLineItem>;
  syncEnabled: Scalars["Boolean"];
  totalCount: Scalars["Int"];
};

export type CleverSyncListInput = {
  districtID?: InputMaybe<Scalars["ID"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<OrderBy>;
  page?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Array<InputMaybe<SyncStatus>>>;
};

export type CleverSyncListLineItem = {
  __typename?: "CleverSyncListLineItem";
  admin?: Maybe<UserAdmin>;
  created_at?: Maybe<Scalars["Time"]>;
  district_ids?: Maybe<Array<Maybe<Scalars["String"]>>>;
  id: Scalars["String"];
  message?: Maybe<Scalars["String"]>;
  owner_type: OwnerType;
  run_duration?: Maybe<Scalars["Int"]>;
  status: SyncStatus;
  sync_type: SyncType;
};

export type CognitoPendingUserDto = {
  __typename?: "CognitoPendingUserDTO";
  cognito_id?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  external_id?: Maybe<Scalars["String"]>;
  first_name?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  last_name?: Maybe<Scalars["String"]>;
  role: UserRole;
  source: Scalars["String"];
};

export type CreateAdminFileInput = {
  file_key: Scalars["String"];
  file_type: Scalars["String"];
  thumbnail_file_key?: InputMaybe<Scalars["String"]>;
  thumbnail_file_type?: InputMaybe<Scalars["String"]>;
  thumbnail_url?: InputMaybe<Scalars["String"]>;
  url: Scalars["String"];
};

export type CreateAdminResourceInput = {
  file: CreateAdminFileInput;
  grades: Array<Scalars["String"]>;
  image_height: Scalars["Int"];
  image_width: Scalars["Int"];
  keywords: Array<Scalars["ID"]>;
  resourceType: StaticResourceType;
  subject_areas: Array<Scalars["ID"]>;
  task_types: Array<Scalars["ID"]>;
  thumbnail_height?: InputMaybe<Scalars["Int"]>;
  thumbnail_width?: InputMaybe<Scalars["Int"]>;
  title: Scalars["String"];
};

export type CreateAnnotationsInput = {
  title: Scalars["String"];
  x_position: Scalars["Float"];
  y_position: Scalars["Float"];
};

export type CreateAssignmentInput = {
  back_navigation_allowed: Scalars["Boolean"];
  close_at: Scalars["Time"];
  is_common_prompt: Scalars["Boolean"];
  resource_id: Scalars["String"];
  section_id: Scalars["String"];
  speaking_prompt: Scalars["String"];
  speaking_prompt_file_name?: InputMaybe<Scalars["String"]>;
  speaking_prompt_file_type?: InputMaybe<Scalars["String"]>;
  speaking_prompt_url?: InputMaybe<Scalars["String"]>;
  start_at: Scalars["Time"];
  title: Scalars["String"];
  writing_prompt: Scalars["String"];
  writing_prompt_file_name?: InputMaybe<Scalars["String"]>;
  writing_prompt_file_type?: InputMaybe<Scalars["String"]>;
  writing_prompt_url?: InputMaybe<Scalars["String"]>;
};

export type CreateBenchmarkInput = {
  back_navigation_allowed: Scalars["Boolean"];
  benchmark_type: BenchmarkType;
  close_at: Scalars["Time"];
  district_ids: Array<Scalars["String"]>;
  grade_ids?: InputMaybe<Array<Scalars["String"]>>;
  graded_by: BenchmarkGradedBy;
  is_common_prompt: Scalars["Boolean"];
  resource_id: Scalars["String"];
  school_ids?: InputMaybe<Array<Scalars["String"]>>;
  speaking_prompt: Scalars["String"];
  speaking_prompt_file_name?: InputMaybe<Scalars["String"]>;
  speaking_prompt_file_type?: InputMaybe<Scalars["String"]>;
  speaking_prompt_url?: InputMaybe<Scalars["String"]>;
  start_at: Scalars["Time"];
  title: Scalars["String"];
  writing_prompt: Scalars["String"];
  writing_prompt_file_name?: InputMaybe<Scalars["String"]>;
  writing_prompt_file_type?: InputMaybe<Scalars["String"]>;
  writing_prompt_url?: InputMaybe<Scalars["String"]>;
};

export type CreateDemoUsersInput = {
  name: Scalars["String"];
  score_scheme_id: Scalars["ID"];
  state_id: Scalars["ID"];
};

export type CreateDemoUsersOutput = {
  __typename?: "CreateDemoUsersOutput";
  admins: Array<DemoAdminDetails>;
  district: DistrictData;
  students: Array<DemoStudentDetails>;
  teachers: Array<DemoTeacherDetails>;
};

export type CreateGoalInput = {
  description: Scalars["String"];
  example?: InputMaybe<Scalars["String"]>;
  existing_goal_id?: InputMaybe<Scalars["ID"]>;
  rubric_id: Scalars["ID"];
  submission_id?: InputMaybe<Scalars["ID"]>;
};

export type CreateStudentInput = {
  demographic_info?: InputMaybe<Scalars["String"]>;
  district_id: Scalars["String"];
  email?: InputMaybe<Scalars["String"]>;
  first_name: Scalars["String"];
  heritage_language?: InputMaybe<Scalars["String"]>;
  language_class?: InputMaybe<Scalars["String"]>;
  language_level?: InputMaybe<Scalars["String"]>;
  last_name: Scalars["String"];
  middle_name?: InputMaybe<Scalars["String"]>;
  password: Scalars["String"];
  predefined_grade_id: Scalars["String"];
  school_id: Scalars["String"];
  section_ids: Array<Scalars["String"]>;
  student_id: Scalars["String"];
};

export type CreateTeacherInput = {
  class_types?: InputMaybe<Array<SectionCategory>>;
  district_id: Scalars["String"];
  email: Scalars["String"];
  first_name: Scalars["String"];
  job_title: Scalars["String"];
  last_name: Scalars["String"];
  middle_name?: InputMaybe<Scalars["String"]>;
  password: Scalars["String"];
  phone_number?: InputMaybe<Scalars["String"]>;
  schools: Array<SchoolSectionListInput>;
  state_id?: InputMaybe<Scalars["String"]>;
  teacher_id: Scalars["String"];
  title?: InputMaybe<Scalars["String"]>;
};

export type CreateUpdateStudentGoalInput = {
  id?: InputMaybe<Scalars["ID"]>;
  predefined_goal_id: Scalars["String"];
  submission_id: Scalars["String"];
};

export type CustomClassAllocationInput = {
  allocated_limit?: InputMaybe<Scalars["Int"]>;
  allocated_orderBy?: InputMaybe<OrderBy>;
  allocated_page?: InputMaybe<Scalars["Int"]>;
  allocated_sort?: InputMaybe<StudentCustomClassGroupSortType>;
  available_limit?: InputMaybe<Scalars["Int"]>;
  available_orderBy?: InputMaybe<OrderBy>;
  available_page?: InputMaybe<Scalars["Int"]>;
  available_sort?: InputMaybe<StudentCustomClassGroupSortType>;
  grade_id?: InputMaybe<Scalars["String"]>;
  language_level_id?: InputMaybe<Scalars["String"]>;
  search?: InputMaybe<Scalars["String"]>;
  section_id?: InputMaybe<Scalars["String"]>;
  selected_section_id: Scalars["String"];
  teacher_id?: InputMaybe<Scalars["String"]>;
  temporary_allocated_ids?: InputMaybe<Array<Scalars["ID"]>>;
};

export type DateQuery = {
  eq?: InputMaybe<Scalars["Time"]>;
  gt?: InputMaybe<Scalars["Time"]>;
  gte?: InputMaybe<Scalars["Time"]>;
  lt?: InputMaybe<Scalars["Time"]>;
  lte?: InputMaybe<Scalars["Time"]>;
  orIsNull?: InputMaybe<Scalars["Boolean"]>;
};

export enum DecoupleLogStatusType {
  CognitoInProgress = "CognitoInProgress",
  DbInProgress = "DBInProgress",
  Failed = "Failed",
  LoginRestoreInProgress = "LoginRestoreInProgress",
  Success = "Success",
}

export type DemoAdminDetails = {
  __typename?: "DemoAdminDetails";
  admin_id: Scalars["String"];
  email: Scalars["String"];
  first_name: Scalars["String"];
  id: Scalars["String"];
  last_name: Scalars["String"];
  middle_name?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  user_id: Scalars["String"];
};

export type DemoStudentDetails = {
  __typename?: "DemoStudentDetails";
  email?: Maybe<Scalars["String"]>;
  first_name: Scalars["String"];
  id: Scalars["String"];
  last_name: Scalars["String"];
  middle_name?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  student_id: Scalars["String"];
  user_id: Scalars["String"];
};

export type DemoTeacherDetails = {
  __typename?: "DemoTeacherDetails";
  email: Scalars["String"];
  first_name: Scalars["String"];
  id: Scalars["String"];
  last_name: Scalars["String"];
  middle_name?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  teacher_id: Scalars["String"];
  user_id: Scalars["String"];
};

export type DistrictConnection = {
  __typename?: "DistrictConnection";
  nodes: Array<DistrictDetails>;
  totalCount: Scalars["Int"];
};

export type DistrictDto = {
  __typename?: "DistrictDTO";
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type DistrictData = {
  __typename?: "DistrictData";
  agency_id?: Maybe<Scalars["String"]>;
  cognito_pending_users?: Maybe<Array<Maybe<CognitoPendingUserDto>>>;
  decouple_log?: Maybe<DistrictDecoupleLog>;
  delete_district_status?: Maybe<Scalars["String"]>;
  district_id: Scalars["String"];
  has_active_assignments?: Maybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
  initialized_at?: Maybe<Scalars["Time"]>;
  is_disabled?: Maybe<Scalars["Boolean"]>;
  is_district_deleted?: Maybe<Scalars["Boolean"]>;
  last_synced_at?: Maybe<Scalars["Time"]>;
  mdr_number?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  nces_id?: Maybe<Scalars["String"]>;
  score_scheme_id: Scalars["String"];
  source?: Maybe<Scalars["String"]>;
  source_id?: Maybe<Scalars["String"]>;
  state?: Maybe<State>;
  state_id?: Maybe<Scalars["String"]>;
  sync_status?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["Time"]>;
};

export type DistrictDecoupleLog = {
  __typename?: "DistrictDecoupleLog";
  created_at?: Maybe<Scalars["Time"]>;
  created_by?: Maybe<User>;
  district_id: Scalars["String"];
  id: Scalars["String"];
  message?: Maybe<Scalars["String"]>;
  pending_cognito_user_update_count: Scalars["Int"];
  status: Scalars["String"];
  updated_at?: Maybe<Scalars["Time"]>;
};

export type DistrictDetails = {
  __typename?: "DistrictDetails";
  agency_id?: Maybe<Scalars["String"]>;
  district_id: Scalars["String"];
  id: Scalars["ID"];
  name: Scalars["String"];
  school_count: Scalars["Int"];
  score_scheme_id: Scalars["String"];
  source?: Maybe<Scalars["String"]>;
  state?: Maybe<State>;
  state_id?: Maybe<Scalars["String"]>;
  student_count: Scalars["Int"];
};

export type DistrictInput = {
  agency_id?: InputMaybe<Scalars["String"]>;
  district_id?: InputMaybe<Scalars["String"]>;
  mdr_number?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  nces_id?: InputMaybe<Scalars["String"]>;
  score_scheme_id: Scalars["String"];
  state_id?: InputMaybe<Scalars["String"]>;
};

export type DistrictItem = {
  __typename?: "DistrictItem";
  id: Scalars["ID"];
  name: Scalars["String"];
  sync_status?: Maybe<Scalars["String"]>;
};

export enum DistrictListSortType {
  DistrictId = "district_id",
  Name = "name",
  SchoolCount = "school_count",
  Source = "source",
  StateId = "state_id",
  StudentCount = "student_count",
}

export type DistrictResourcesCount = {
  __typename?: "DistrictResourcesCount";
  class_count: Scalars["Int"];
  district_admin_count: Scalars["Int"];
  school_admin_count: Scalars["Int"];
  school_count: Scalars["Int"];
  student_count: Scalars["Int"];
  sync_count: Scalars["Int"];
  teacher_count: Scalars["Int"];
};

export type DistrictWideGradeDetail = {
  __typename?: "DistrictWideGradeDetail";
  grade_id: Scalars["String"];
  grade_position: Scalars["Int"];
  grade_title: Scalars["String"];
  speaking_overall_score: Scalars["Float"];
  student_count: Scalars["Int"];
  teacher_count: Scalars["Int"];
  total_goals_assigned: Scalars["Int"];
  total_submissions_completed_count: Scalars["Int"];
  total_submissions_count: Scalars["Int"];
  writing_overall_score: Scalars["Float"];
};

export type DistrictWideGradeInput = {
  district_id?: InputMaybe<Scalars["String"]>;
  orderBy?: InputMaybe<OrderBy>;
  school_id?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<DistrictWideGradeSortType>;
};

export enum DistrictWideGradeSortType {
  GradePosition = "GradePosition",
  GradeTitle = "GradeTitle",
  SpeakingOverallScore = "SpeakingOverallScore",
  StudentCount = "StudentCount",
  TeacherCount = "TeacherCount",
  TotalGoalsAssigned = "TotalGoalsAssigned",
  TotalSubmissionsCompletedCount = "TotalSubmissionsCompletedCount",
  TotalSubmissionsCount = "TotalSubmissionsCount",
  WritingOverallScore = "WritingOverallScore",
}

export type DistrictWideGradeSpecificDetails = {
  __typename?: "DistrictWideGradeSpecificDetails";
  avg_speaking_score: Scalars["Float"];
  avg_writing_score: Scalars["Float"];
  school: Scalars["String"];
  school_id: Scalars["String"];
  section: Scalars["String"];
  section_id: Scalars["String"];
  teacher: Scalars["String"];
  teacher_id: Scalars["String"];
};

export type DistrictWideGradeSpecificDetailsNode = {
  __typename?: "DistrictWideGradeSpecificDetailsNode";
  districtWideGradeSpecificDetails: Array<DistrictWideGradeSpecificDetails>;
  districtWideGradeSpecificDetailsCount: Scalars["Int"];
};

export enum DistrictWideGradeSpecificDetailsSortType {
  AvgSpeakingScore = "avg_speaking_score",
  AvgWritingScore = "avg_writing_score",
  School = "school",
  Section = "section",
  Teacher = "teacher",
}

export type DistrictWideSchoolDetails = {
  __typename?: "DistrictWideSchoolDetails";
  avg_speaking_score: Scalars["Float"];
  avg_writing_score: Scalars["Float"];
  goals_assigned: Scalars["Int"];
  pts_assigned: Scalars["Int"];
  pts_completed: Scalars["Int"];
  school_id: Scalars["String"];
  school_name: Scalars["String"];
  students: Scalars["Int"];
  teachers: Scalars["Int"];
};

export enum DistrictWideSchoolDetailsSortType {
  AvgSpeakingScore = "avg_speaking_score",
  AvgWritingScore = "avg_writing_score",
  GoalsAssigned = "goals_assigned",
  PtsCompleted = "pts_completed",
  SchoolName = "school_name",
  Students = "students",
  Teachers = "teachers",
}

export type DistrictWideSchoolNode = {
  __typename?: "DistrictWideSchoolNode";
  districtWideSchoolDetails: Array<DistrictWideSchoolDetails>;
  districtWideSchoolDetailsCount: Scalars["Int"];
};

export type DistrictWideSchoolSpecificDetails = {
  __typename?: "DistrictWideSchoolSpecificDetails";
  avg_speaking_score: Scalars["Float"];
  avg_writing_score: Scalars["Float"];
  grade: Scalars["String"];
  grade_position: Scalars["Int"];
  section: Scalars["String"];
  section_id: Scalars["String"];
  teacher: Scalars["String"];
  teacher_id: Scalars["String"];
};

export type DistrictWideSchoolSpecificDetailsNode = {
  __typename?: "DistrictWideSchoolSpecificDetailsNode";
  DistrictWideSchoolSpecificDetailsCount: Scalars["Int"];
  districtWideSchoolSpecificDetails: Array<DistrictWideSchoolSpecificDetails>;
};

export enum DistrictWideSchoolSpecificDetailsSortType {
  AvgSpeakingScore = "avg_speaking_score",
  AvgWritingScore = "avg_writing_score",
  Grade = "grade",
  GradePosition = "grade_position",
  Section = "section",
  Teacher = "teacher",
}

export type EditAssignmentInput = {
  back_navigation_allowed: Scalars["Boolean"];
  close_at: Scalars["Time"];
  id: Scalars["String"];
  is_common_prompt: Scalars["Boolean"];
  resource_id: Scalars["String"];
  section_id: Scalars["String"];
  speaking_prompt: Scalars["String"];
  speaking_prompt_file_name?: InputMaybe<Scalars["String"]>;
  speaking_prompt_file_type?: InputMaybe<Scalars["String"]>;
  speaking_prompt_url?: InputMaybe<Scalars["String"]>;
  start_at: Scalars["Time"];
  title: Scalars["String"];
  writing_prompt: Scalars["String"];
  writing_prompt_file_name?: InputMaybe<Scalars["String"]>;
  writing_prompt_file_type?: InputMaybe<Scalars["String"]>;
  writing_prompt_url?: InputMaybe<Scalars["String"]>;
};

export type EditBenchmarkInput = {
  close_at: Scalars["Time"];
  id: Scalars["ID"];
  start_at: Scalars["Time"];
};

export type EnrollmentInput = {
  selected_section_id: Scalars["ID"];
  student_ids_to_add?: InputMaybe<Array<Scalars["ID"]>>;
  student_ids_to_remove?: InputMaybe<Array<Scalars["ID"]>>;
};

export enum Features {
  TeacherManageClass = "TeacherManageClass",
}

export type File = {
  __typename?: "File";
  bucket: Scalars["String"];
  file_type: Scalars["String"];
  id: Scalars["ID"];
  key: Scalars["String"];
  thumbnail_file_key?: Maybe<Scalars["String"]>;
  thumbnail_file_type?: Maybe<Scalars["String"]>;
  thumbnail_url?: Maybe<Scalars["String"]>;
  url: Scalars["String"];
};

export type Goal = {
  __typename?: "Goal";
  assigned_date: Scalars["Time"];
  goal_details: GoalsData;
  goal_status?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  submission_id: Scalars["String"];
  target_submission_id?: Maybe<Scalars["String"]>;
  teacher?: Maybe<User>;
};

export type GoalAchievementByCategory = {
  __typename?: "GoalAchievementByCategory";
  data: Array<Maybe<GoalAchievementGraphLineItem>>;
  label: Scalars["String"];
};

export type GoalAchievementGraphInput = {
  district_id?: InputMaybe<Scalars["String"]>;
  filters?: InputMaybe<AdminDashboardFilterInput>;
  school_id?: InputMaybe<Scalars["String"]>;
};

export type GoalAchievementGraphLineItem = {
  __typename?: "GoalAchievementGraphLineItem";
  timestamp: Scalars["Int"];
  value: Scalars["Int"];
};

export type GoalAchievementGraphResult = {
  __typename?: "GoalAchievementGraphResult";
  goal_achievement_by_category: Array<Maybe<GoalAchievementByCategory>>;
  max_count?: Maybe<Scalars["Int"]>;
};

export type GoalAnalyticsData = {
  __typename?: "GoalAnalyticsData";
  assigned_date: Scalars["Time"];
  current_goal: Scalars["String"];
  student_id: Scalars["ID"];
  student_name: Scalars["String"];
};

export type GoalData = {
  __typename?: "GoalData";
  analytic_data: Array<Maybe<GoalAnalyticsData>>;
  count: Scalars["Int"];
};

export enum GoalStatus {
  Emerging = "Emerging",
  GoalMet = "GoalMet",
  NoAttempt = "NoAttempt",
  Progressing = "Progressing",
}

export enum GoalType {
  Active = "Active",
  Attached = "Attached",
}

export type GoalsData = {
  __typename?: "GoalsData";
  predefined_goals: Array<CategorizedGoal>;
  rubric: Rubric;
};

export enum GoalsScoreWidgetSortType {
  AverageScore = "average_score",
  GoalsCount = "goals_count",
  RubricTitle = "rubric_title",
}

export type Grade = {
  __typename?: "Grade";
  assignment_id?: Maybe<Scalars["String"]>;
  benchmark_id?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  rubric: Rubric;
  score?: Maybe<Scalars["Int"]>;
  submission_id: Scalars["String"];
};

export type GradeDto = {
  __typename?: "GradeDTO";
  grade: Scalars["String"];
  id: Scalars["ID"];
  position: Scalars["Int"];
};

export enum GradeSortType {
  Position = "position",
}

export type GradingStatus = {
  __typename?: "GradingStatus";
  IsReviewed: Scalars["Boolean"];
  IsSpeakingGoalSet: Scalars["Boolean"];
  IsSpeakingScored: Scalars["Boolean"];
  IsWritingGoalSet: Scalars["Boolean"];
  IsWritingScored: Scalars["Boolean"];
};

export type GroupAllocationInput = {
  allocated_limit?: InputMaybe<Scalars["Int"]>;
  allocated_orderBy?: InputMaybe<OrderBy>;
  allocated_page?: InputMaybe<Scalars["Int"]>;
  allocated_sort?: InputMaybe<StudentCustomClassGroupSortType>;
  available_limit?: InputMaybe<Scalars["Int"]>;
  available_orderBy?: InputMaybe<OrderBy>;
  available_page?: InputMaybe<Scalars["Int"]>;
  available_sort?: InputMaybe<StudentCustomClassGroupSortType>;
  group_id: Scalars["String"];
  search?: InputMaybe<Scalars["String"]>;
};

export type GroupConnection = {
  __typename?: "GroupConnection";
  nodes: Array<GroupDetails>;
  totalCount: Scalars["Int"];
};

export type GroupDetails = {
  __typename?: "GroupDetails";
  id: Scalars["ID"];
  name: Scalars["String"];
  parent_section_id: Scalars["ID"];
  parent_section_name: Scalars["String"];
  school?: Maybe<School>;
  student_count: Scalars["Int"];
};

export type GroupInput = {
  id?: InputMaybe<Scalars["ID"]>;
  name: Scalars["String"];
  parent_section_id: Scalars["ID"];
};

export enum GroupsListSortType {
  Name = "name",
  StudentCount = "student_count",
}

export type KeyValueType = {
  __typename?: "KeyValueType";
  key: Scalars["String"];
  value: Scalars["String"];
};

export type Keyword = {
  __typename?: "Keyword";
  id?: Maybe<Scalars["ID"]>;
  keyword?: Maybe<Scalars["String"]>;
};

export enum LearningGoalSlideOutSortType {
  LearningGoalSlideOutSortByStudentGrade = "learning_goal_slide_out_sort_by_student_grade",
  LearningGoalSlideOutSortByStudentName = "learning_goal_slide_out_sort_by_student_name",
  LearningGoalSlideOutSortByStudentSchool = "learning_goal_slide_out_sort_by_student_school",
}

export type LearningGoalWidgetSlideOutInput = {
  district_id?: InputMaybe<Scalars["String"]>;
  filters?: InputMaybe<AdminDashboardFilterInput>;
  goal_status: GoalStatus;
  limit?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<OrderBy>;
  page?: InputMaybe<Scalars["Int"]>;
  school_id?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<LearningGoalSlideOutSortType>;
};

export type LearningGoalWidgetSlideOutResult = {
  __typename?: "LearningGoalWidgetSlideOutResult";
  nodes: Array<Maybe<PerformanceAndLearningGoalWidgetSlideOutLineItem>>;
  total_count?: Maybe<Scalars["Int"]>;
};

export type LearningGoalsByIndicator = {
  __typename?: "LearningGoalsByIndicator";
  color: Scalars["String"];
  goals_count: Scalars["Int"];
  id: Scalars["ID"];
  skill_description: Scalars["String"];
  skill_key: Scalars["String"];
  skill_label: Scalars["String"];
  skill_level: Scalars["Int"];
};

export type LearningGoalsCompletedPTsMostRecentGoalsPerMonth = {
  __typename?: "LearningGoalsCompletedPTsMostRecentGoalsPerMonth";
  average_no_of_most_recent_goals_count: Scalars["Float"];
  month: Scalars["Int"];
  most_recent_goals_count: Scalars["Int"];
  unique_student_completed_pts_count: Scalars["Int"];
  year: Scalars["String"];
};

export type LearningGoalsIndicatorNode = {
  __typename?: "LearningGoalsIndicatorNode";
  learningGoalsbyIndicator: Array<LearningGoalsByIndicator>;
  total_goals: Scalars["Int"];
};

export type LearningGoalsPerformance = {
  __typename?: "LearningGoalsPerformance";
  color: Scalars["String"];
  percentage_of_students: Scalars["Float"];
  title: Scalars["String"];
  total_students: Scalars["Int"];
};

export type LearningGoalsScoresByIndicatorInput = {
  district_id?: InputMaybe<Scalars["String"]>;
  filters?: InputMaybe<AdminDashboardFilterInput>;
  order_by?: InputMaybe<OrderBy>;
  school_id?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<GoalsScoreWidgetSortType>;
};

export type LearningGoalsScoresByIndicatorLineItem = {
  __typename?: "LearningGoalsScoresByIndicatorLineItem";
  average_score: Scalars["Float"];
  goals_count: Scalars["Int"];
  rubric_title?: Maybe<Scalars["String"]>;
};

export type LoginDistrictDetails = {
  __typename?: "LoginDistrictDetails";
  district_id: Scalars["String"];
  id: Scalars["String"];
  name: Scalars["String"];
  source: Scalars["String"];
  source_id: Scalars["String"];
};

export type Mutation = {
  __typename?: "Mutation";
  AddTypeformUserMapping?: Maybe<Scalars["ID"]>;
  addAdminDistrictUserMapping: Array<Scalars["ID"]>;
  addKeyword: Array<Scalars["String"]>;
  addSuperAdmin: Scalars["String"];
  anchorToggle: Scalars["ID"];
  archiveStaticResource?: Maybe<Scalars["String"]>;
  benchmarkDeleteFeedbackAudio: Scalars["Boolean"];
  cleanupTables: Scalars["Boolean"];
  createAcademicSession: AcademicSession;
  createAdminImageResource: Array<Scalars["ID"]>;
  createAssignment?: Maybe<Assignment>;
  createBenchmark: Scalars["ID"];
  createBenchmarkSubmission: Scalars["ID"];
  createDemoDataForDistrict: CreateDemoUsersOutput;
  createDistrict?: Maybe<Scalars["String"]>;
  createOrSkipAnnotations?: Maybe<Array<Annotation>>;
  createPredefinedGoal: PredefinedGoalsList;
  createStudent?: Maybe<Scalars["String"]>;
  createSubmission: Scalars["ID"];
  createTeacher?: Maybe<Scalars["String"]>;
  createUpdateStudentGoal: StudentGoal;
  decoupleDistrict: Scalars["String"];
  deleteAssignment?: Maybe<Scalars["String"]>;
  deleteBenchmark?: Maybe<Scalars["String"]>;
  deleteDistrictRequest: Scalars["String"];
  deleteFeedbackAudio: Scalars["Boolean"];
  deleteGroup: Scalars["ID"];
  deletePredefinedGoal: PredefinedGoalsList;
  deleteSuperAdmin: Scalars["String"];
  editAssignment?: Maybe<Assignment>;
  editBenchmark: Scalars["ID"];
  enrollStudentsInCustomSection: Scalars["ID"];
  enrollStudentsInGroup: Scalars["ID"];
  health: Scalars["Boolean"];
  importCleverDistrict?: Maybe<Scalars["String"]>;
  markBenchmarkReviewComplete: Submission;
  markFeatureViewed: Scalars["Boolean"];
  markFeedbackViewed?: Maybe<Array<Scalars["ID"]>>;
  markHelpVideoSeen?: Maybe<User>;
  markReviewComplete: Submission;
  removeInProgressSubmissionsOfSection: Scalars["ID"];
  removeStudentsFromClasses?: Maybe<Array<Scalars["String"]>>;
  sendResetPasswordLink?: Maybe<Scalars["String"]>;
  setPasswordByToken?: Maybe<Scalars["String"]>;
  syncCleverDistricts: Scalars["String"];
  updateAdditionalFeedBack: Submission;
  updateAdminImageResource: Scalars["ID"];
  updateBenchmarkAdditionalFeedBack: Submission;
  updateBenchmarkFeedbackNotes: Submission;
  updateBenchmarkScore: Scalars["String"];
  updateDistrict: Scalars["String"];
  updateDistrictDisabledFlag: Scalars["String"];
  updateFeedbackNotes: Submission;
  updateGoalStatus: Goal;
  updateScore: Scalars["String"];
  updateSpeaking: Submission;
  updateSpeakingComplete: Submission;
  updateStudent?: Maybe<Scalars["String"]>;
  updateSubmission?: Maybe<Submission>;
  updateTeacher?: Maybe<Scalars["String"]>;
  updateWriting: Submission;
  upsertAgency: Agency;
  upsertDistrictAdmin: Scalars["ID"];
  upsertGroup: SectionDetails;
  upsertSchool?: Maybe<Scalars["String"]>;
  upsertSchoolAdmin: Scalars["ID"];
  upsertSection: SectionDetails;
  upsertSuperAdmin: Scalars["ID"];
  upsertZone: Zone;
};

export type MutationAddTypeformUserMappingArgs = {
  input: TypeformUserMapping;
};

export type MutationAddKeywordArgs = {
  input: Array<Scalars["String"]>;
};

export type MutationAddSuperAdminArgs = {
  input?: InputMaybe<SuperAdminInput>;
};

export type MutationAnchorToggleArgs = {
  show_toggle: Scalars["Boolean"];
};

export type MutationArchiveStaticResourceArgs = {
  id: Scalars["String"];
};

export type MutationBenchmarkDeleteFeedbackAudioArgs = {
  id: Scalars["ID"];
  submission_id: Scalars["String"];
};

export type MutationCreateAcademicSessionArgs = {
  end_date?: InputMaybe<Scalars["Time"]>;
  start_date: Scalars["Time"];
};

export type MutationCreateAdminImageResourceArgs = {
  input: CreateAdminResourceInput;
};

export type MutationCreateAssignmentArgs = {
  input: CreateAssignmentInput;
};

export type MutationCreateBenchmarkArgs = {
  input: CreateBenchmarkInput;
};

export type MutationCreateBenchmarkSubmissionArgs = {
  benchmark_id: Scalars["String"];
};

export type MutationCreateDemoDataForDistrictArgs = {
  input: CreateDemoUsersInput;
};

export type MutationCreateDistrictArgs = {
  input: DistrictInput;
};

export type MutationCreateOrSkipAnnotationsArgs = {
  annotations?: InputMaybe<Array<CreateAnnotationsInput>>;
  submission_id: Scalars["String"];
};

export type MutationCreatePredefinedGoalArgs = {
  input?: InputMaybe<CreateGoalInput>;
};

export type MutationCreateStudentArgs = {
  input: CreateStudentInput;
};

export type MutationCreateSubmissionArgs = {
  assignment_id: Scalars["String"];
};

export type MutationCreateTeacherArgs = {
  input: CreateTeacherInput;
};

export type MutationCreateUpdateStudentGoalArgs = {
  input: CreateUpdateStudentGoalInput;
};

export type MutationDecoupleDistrictArgs = {
  districtID: Scalars["String"];
};

export type MutationDeleteAssignmentArgs = {
  id: Scalars["String"];
};

export type MutationDeleteBenchmarkArgs = {
  id: Scalars["String"];
};

export type MutationDeleteDistrictRequestArgs = {
  district_id: Scalars["String"];
};

export type MutationDeleteFeedbackAudioArgs = {
  id: Scalars["ID"];
  submission_id: Scalars["String"];
};

export type MutationDeleteGroupArgs = {
  group_id: Scalars["ID"];
};

export type MutationDeletePredefinedGoalArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteSuperAdminArgs = {
  id: Scalars["String"];
};

export type MutationEditAssignmentArgs = {
  input: EditAssignmentInput;
};

export type MutationEditBenchmarkArgs = {
  input: EditBenchmarkInput;
};

export type MutationEnrollStudentsInCustomSectionArgs = {
  input: EnrollmentInput;
};

export type MutationEnrollStudentsInGroupArgs = {
  input: EnrollmentInput;
};

export type MutationImportCleverDistrictArgs = {
  input: CleverDistrictImportInput;
};

export type MutationMarkBenchmarkReviewCompleteArgs = {
  id: Scalars["ID"];
};

export type MutationMarkFeatureViewedArgs = {
  key: Features;
};

export type MutationMarkFeedbackViewedArgs = {
  submission_ids: Array<Scalars["String"]>;
};

export type MutationMarkReviewCompleteArgs = {
  submission_id: Scalars["String"];
};

export type MutationRemoveInProgressSubmissionsOfSectionArgs = {
  section_id: Scalars["ID"];
  student_ids?: InputMaybe<Array<Scalars["ID"]>>;
};

export type MutationRemoveStudentsFromClassesArgs = {
  input: RemoveStudentsFromClassInput;
};

export type MutationSendResetPasswordLinkArgs = {
  email: Scalars["String"];
  host: Scalars["String"];
};

export type MutationSetPasswordByTokenArgs = {
  password: Scalars["String"];
  token: Scalars["String"];
};

export type MutationSyncCleverDistrictsArgs = {
  input: Array<Scalars["String"]>;
};

export type MutationUpdateAdditionalFeedBackArgs = {
  input: AdditionalFeedbackInput;
};

export type MutationUpdateAdminImageResourceArgs = {
  input?: InputMaybe<UpdateAdminResourceInput>;
};

export type MutationUpdateBenchmarkAdditionalFeedBackArgs = {
  input: AdditionalFeedbackInput;
};

export type MutationUpdateBenchmarkFeedbackNotesArgs = {
  id: Scalars["ID"];
  notes?: InputMaybe<Scalars["String"]>;
};

export type MutationUpdateBenchmarkScoreArgs = {
  input: UpdateScoreInput;
};

export type MutationUpdateDistrictArgs = {
  id: Scalars["String"];
  input: DistrictInput;
};

export type MutationUpdateDistrictDisabledFlagArgs = {
  flag: Scalars["Boolean"];
  id: Scalars["String"];
};

export type MutationUpdateFeedbackNotesArgs = {
  id: Scalars["ID"];
  notes?: InputMaybe<Scalars["String"]>;
};

export type MutationUpdateGoalStatusArgs = {
  input: UpdateGoalStatusInput;
};

export type MutationUpdateScoreArgs = {
  input?: InputMaybe<UpdateScoreInput>;
};

export type MutationUpdateSpeakingArgs = {
  input: UpdateSpeakingInput;
};

export type MutationUpdateSpeakingCompleteArgs = {
  id: Scalars["ID"];
};

export type MutationUpdateStudentArgs = {
  input: UpdateStudentInput;
};

export type MutationUpdateSubmissionArgs = {
  id: Scalars["ID"];
  input: SubmissionUpdateInput;
};

export type MutationUpdateTeacherArgs = {
  input: UpdateTeacherInput;
};

export type MutationUpdateWritingArgs = {
  id: Scalars["String"];
  writing?: InputMaybe<Scalars["String"]>;
};

export type MutationUpsertAgencyArgs = {
  agency_id: Scalars["String"];
  agency_name: Scalars["String"];
  id?: InputMaybe<Scalars["String"]>;
  state_id: Scalars["String"];
};

export type MutationUpsertDistrictAdminArgs = {
  input: AdminInput;
};

export type MutationUpsertGroupArgs = {
  input?: InputMaybe<GroupInput>;
};

export type MutationUpsertSchoolArgs = {
  input: SchoolInput;
};

export type MutationUpsertSchoolAdminArgs = {
  input: AdminInput;
};

export type MutationUpsertSectionArgs = {
  input?: InputMaybe<SectionInput>;
};

export type MutationUpsertSuperAdminArgs = {
  input?: InputMaybe<SuperAdminInput>;
};

export type MutationUpsertZoneArgs = {
  district_id: Scalars["String"];
  id?: InputMaybe<Scalars["String"]>;
  zone: Scalars["String"];
  zone_id: Scalars["String"];
};

export type NewSubmission = {
  __typename?: "NewSubmission";
  annotations: Array<Annotation>;
  assignment?: Maybe<Assignment>;
  assignment_id: Scalars["String"];
  benchmark_id: Scalars["String"];
  feedback_notes?: Maybe<Scalars["String"]>;
  getAdditionalFeedback: AdditionalFeedback;
  getBenchmark?: Maybe<BenchmarkPt>;
  getGoalsForSubmission: UserGoal;
  id: Scalars["ID"];
  reviewed_at?: Maybe<Scalars["Time"]>;
  scoring_details: Array<ScoreDetail>;
  speakings: Array<File>;
  status: SubmissionStatus;
  student?: Maybe<User>;
  submission_type_scores?: Maybe<Array<SubmissionTypeGroup>>;
  submitted_at?: Maybe<Scalars["Time"]>;
  teacher_id: Scalars["String"];
  writing?: Maybe<Scalars["String"]>;
};

export enum OrderBy {
  Asc = "Asc",
  Desc = "Desc",
}

export type OverallBenchmarkIndicatorInput = {
  benchmark_type: BenchmarkType;
  grade_ids?: InputMaybe<Array<Scalars["ID"]>>;
  order_by?: InputMaybe<OrderBy>;
  school_ids?: InputMaybe<Array<Scalars["ID"]>>;
  section_ids?: InputMaybe<Array<Scalars["ID"]>>;
  sort_by?: InputMaybe<OverallBenchmarkIndicatorSortType>;
};

export type OverallBenchmarkIndicatorResult = {
  __typename?: "OverallBenchmarkIndicatorResult";
  rubric_line_items: Array<OverallBenchmarkIndicatorRubricLineItem>;
  submission_type: SubmissionType;
};

export type OverallBenchmarkIndicatorRubricLineItem = {
  __typename?: "OverallBenchmarkIndicatorRubricLineItem";
  rubric_icon: Scalars["String"];
  rubric_id: Scalars["String"];
  rubric_score_average: Scalars["Float"];
  rubric_score_color: Scalars["String"];
  rubric_title: Scalars["String"];
};

export enum OverallBenchmarkIndicatorSortType {
  ScoreValue = "score_value",
}

export type OverallGradeDetail = {
  __typename?: "OverallGradeDetail";
  type?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["Int"]>;
};

export type OverallPerformanceLevelInput = {
  benchmark_type: BenchmarkType;
  grade_ids?: InputMaybe<Array<Scalars["ID"]>>;
  order_by?: InputMaybe<OrderBy>;
  school_ids?: InputMaybe<Array<Scalars["ID"]>>;
  section_ids?: InputMaybe<Array<Scalars["ID"]>>;
  sort_by?: InputMaybe<OverallPerformanceLevelSortType>;
};

export type OverallPerformanceLevelResult = {
  __typename?: "OverallPerformanceLevelResult";
  average_score: Scalars["Float"];
  score_line_items: Array<OverallPerformanceLevelScoreLineItem>;
  submission_type: SubmissionType;
};

export type OverallPerformanceLevelScoreLineItem = {
  __typename?: "OverallPerformanceLevelScoreLineItem";
  score_level_abbr: Scalars["String"];
  score_level_color: Scalars["String"];
  score_level_id: Scalars["String"];
  score_level_title: Scalars["String"];
  student_count: Scalars["Int"];
};

export type OverallPerformanceLevelSlideOutInput = {
  benchmark_type: BenchmarkType;
  grade_ids?: InputMaybe<Array<Scalars["ID"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<OrderBy>;
  page?: InputMaybe<Scalars["Int"]>;
  school_ids?: InputMaybe<Array<Scalars["ID"]>>;
  score_level_id?: InputMaybe<Scalars["String"]>;
  section_ids?: InputMaybe<Array<Scalars["ID"]>>;
  sort_by?: InputMaybe<OverallPerformanceLevelSlideOutSortType>;
  submission_type?: InputMaybe<SubmissionType>;
};

export type OverallPerformanceLevelSlideOutLineItem = {
  __typename?: "OverallPerformanceLevelSlideOutLineItem";
  score_level_color: Scalars["String"];
  student_average_score: Scalars["Float"];
  student_first_name: Scalars["String"];
  student_id: Scalars["ID"];
  student_last_name?: Maybe<Scalars["String"]>;
  student_user_id: Scalars["ID"];
};

export type OverallPerformanceLevelSlideOutResult = {
  __typename?: "OverallPerformanceLevelSlideOutResult";
  slideout_line_items: Array<OverallPerformanceLevelSlideOutLineItem>;
  total_count: Scalars["Int"];
};

export enum OverallPerformanceLevelSlideOutSortType {
  StudentAverageScore = "student_average_score",
  StudentFullName = "student_full_name",
}

export enum OverallPerformanceLevelSortType {
  ScoreValue = "score_value",
}

export type OverallPerformanceWidgetInput = {
  districtID?: InputMaybe<Scalars["String"]>;
  filters?: InputMaybe<AdminDashboardFilterInput>;
  orderBy?: InputMaybe<OrderBy>;
  schoolID?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<PerformanceWidgetSortType>;
};

export type OverallStats = {
  __typename?: "OverallStats";
  speaking: ScoreDistribution;
  writing: ScoreDistribution;
};

export enum OwnerType {
  SuperAdmin = "SuperAdmin",
  System = "System",
}

export type PtSlideOutInput = {
  assignment_id: Scalars["String"];
  status_list?: InputMaybe<Array<SubmissionStatus>>;
};

export type PtSlideOutResult = {
  __typename?: "PTSlideOutResult";
  nodes: Array<SlideOutStudents>;
  total_count: Scalars["Int"];
};

export type PerformanceAndLearningGoalWidgetSlideOutLineItem = {
  __typename?: "PerformanceAndLearningGoalWidgetSlideOutLineItem";
  first_name: Scalars["String"];
  grade: Scalars["String"];
  grade_position: Scalars["Int"];
  last_name: Scalars["String"];
  school_id?: Maybe<Scalars["String"]>;
  school_name?: Maybe<Scalars["String"]>;
  section_id: Scalars["String"];
  section_name: Scalars["String"];
  student_id: Scalars["String"];
  teacher_first_name: Scalars["String"];
  teacher_id: Scalars["String"];
  teacher_last_name: Scalars["String"];
};

export enum PerformanceSlideOutSortType {
  PerformanceSlideOutSortByStudentGrade = "performance_slide_out_sort_by_student_grade",
  PerformanceSlideOutSortByStudentName = "performance_slide_out_sort_by_student_name",
  PerformanceSlideOutSortByStudentSchool = "performance_slide_out_sort_by_student_school",
}

export type PerformanceWideStudentCompletionRateBar = {
  __typename?: "PerformanceWideStudentCompletionRateBar";
  month?: Maybe<Scalars["Int"]>;
  pts_assigned: Scalars["Int"];
  pts_submitted_and_graded: Scalars["Int"];
  year?: Maybe<Scalars["String"]>;
};

export type PerformanceWideStudentCompletionRateNode = {
  __typename?: "PerformanceWideStudentCompletionRateNode";
  academic_session_end_date?: Maybe<Scalars["Time"]>;
  academic_session_start_date?: Maybe<Scalars["Time"]>;
  percentage_of_pts_submitted_and_graded_for_ytd: Scalars["Float"];
  performanceWideStudentCompletionRateBar: Array<PerformanceWideStudentCompletionRateBar>;
};

export type PerformanceWideStudentSubmissionAndReviewedDbv2 = {
  __typename?: "PerformanceWideStudentSubmissionAndReviewedDBV2";
  assignment_id: Scalars["String"];
  assignment_teacher_id: Scalars["String"];
  predefined_grade_id: Scalars["String"];
  school_district_id: Scalars["String"];
  section_id: Scalars["String"];
  section_school_id: Scalars["String"];
  student_id: Scalars["String"];
  submission_id: Scalars["String"];
  submitted_month: Scalars["Int"];
  submitted_year: Scalars["String"];
};

export type PerformanceWideTaskAssignedAndCompleted = {
  __typename?: "PerformanceWideTaskAssignedAndCompleted";
  average_tasks_assigned_per_teacher: Scalars["Float"];
  count_of_students: Scalars["Int"];
  count_of_tasks_assigned: Scalars["Int"];
  tasks_completed_per_student: Scalars["Float"];
};

export type PerformanceWidgetData = {
  __typename?: "PerformanceWidgetData";
  current_day_average_score?: Maybe<Scalars["Float"]>;
  historical_score?: Maybe<Array<Maybe<PerformanceWidgetScoreLineItem>>>;
};

export type PerformanceWidgetInput = {
  district_id?: InputMaybe<Scalars["String"]>;
  filters?: InputMaybe<AdminDashboardFilterInput>;
  school_id?: InputMaybe<Scalars["String"]>;
};

export type PerformanceWidgetScoreLineItem = {
  __typename?: "PerformanceWidgetScoreLineItem";
  average_score?: Maybe<Scalars["Float"]>;
  date_timestamp?: Maybe<Scalars["Int"]>;
  scored_pt_count?: Maybe<Scalars["Int"]>;
  unique_student_count?: Maybe<Scalars["Int"]>;
};

export type PerformanceWidgetSlideOutInput = {
  district_id?: InputMaybe<Scalars["String"]>;
  filters?: InputMaybe<AdminDashboardFilterInput>;
  limit?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<OrderBy>;
  page?: InputMaybe<Scalars["Int"]>;
  school_id?: InputMaybe<Scalars["String"]>;
  scoring_detail_id: Scalars["ID"];
  sort?: InputMaybe<PerformanceSlideOutSortType>;
};

export type PerformanceWidgetSlideOutResult = {
  __typename?: "PerformanceWidgetSlideOutResult";
  nodes: Array<Maybe<PerformanceAndLearningGoalWidgetSlideOutLineItem>>;
  total_count?: Maybe<Scalars["Int"]>;
};

export enum PerformanceWidgetSortType {
  AverageScore = "average_score",
  DateTimestamp = "date_timestamp",
}

export type Permission = {
  __typename?: "Permission";
  id: Scalars["ID"];
  permission: Scalars["String"];
};

export type PredefinedGoal = {
  __typename?: "PredefinedGoal";
  description: Scalars["String"];
  example?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type PredefinedGoalsList = {
  __typename?: "PredefinedGoalsList";
  speaking: Array<GoalsData>;
  writing: Array<GoalsData>;
};

export type PredefinedGrades = {
  __typename?: "PredefinedGrades";
  grade: Scalars["String"];
  id: Scalars["ID"];
  position: Scalars["Int"];
};

export type PromptFile = {
  __typename?: "PromptFile";
  file_name: Scalars["String"];
  file_type: Scalars["String"];
  id: Scalars["ID"];
  url: Scalars["String"];
};

export type Query = {
  __typename?: "Query";
  activeGoalsSummary: Array<Summary>;
  admin: Admin;
  adminBenchmarkGradeComparison: AdminBenchmarkGradeComparisonResultNode;
  adminBenchmarkGradingQueue?: Maybe<Array<Scalars["String"]>>;
  adminBenchmarkIndicatorScores: Array<AdminBenchmarkIndicatorScoresResult>;
  adminBenchmarkSchoolsComparison: AdminBenchmarkSchoolsComparisonResultNode;
  adminBenchmarkSchoolsSlideOutComparison: AdminBenchmarkSchoolsTeacherAndGradeComparisonResultNode;
  adminBenchmarkSlideOut: BenchmarkSlideOutResult;
  adminBenchmarkSubmissionList: BenchmarkSubmissionListResult;
  adminBenchmarkSubmissionStatusGrowth: Array<AdminBenchmarkSubmissionStatusGrowthResult>;
  adminBenchmarkSubmissionsCount: Scalars["Int"];
  adminBenchmarkTeacherComparison: AdminBenchmarkSchoolsTeacherComparisonResultNode;
  adminDashboardSectionFilterList?: Maybe<Array<SectionForAdminDashboardFilterNode>>;
  adminList: AdminConnection;
  adminTeacherSchools: Array<Maybe<School>>;
  agencyUnderState: Array<Agency>;
  allPredefinedGrades: Array<PredefinedGrades>;
  allStudentsList: AllStudentConnection;
  allSubmissionsOfStudent?: Maybe<Array<SubmissionFilterData>>;
  anchorToggle: Scalars["Boolean"];
  assignment: Assignment;
  benchmark: BenchmarkPt;
  benchmarkIndividualVsGradeLevelInfo?: Maybe<BenchmarkIndividualVsGradeLevelInfoResult>;
  benchmarkProgressScore: BenchmarkProgressScoreResult;
  benchmarkScores: BenchmarkScoresResult;
  benchmarkStudentCountByFilters?: Maybe<BenchmarkAssignedToCountResult>;
  benchmarkStudentGrowthReport?: Maybe<Array<BenchmarkStudentGrowthReportResult>>;
  benchmarkSubmission: BenchmarkSubmission;
  benchmarkTypes: Array<Maybe<KeyValueType>>;
  cleverSyncDetails?: Maybe<CleverSyncDetails>;
  customClassStudentList: StudentAllocationData;
  dashboardData: AdminDashboardData;
  district: Array<LoginDistrictDetails>;
  districtAdmin: Admin;
  districtAdminList: AdminConnection;
  districtDetails: DistrictData;
  districtList: DistrictConnection;
  districtWideGradeInfo?: Maybe<Array<Maybe<DistrictWideGradeDetail>>>;
  districtWideGradeSpecificData: DistrictWideGradeSpecificDetailsNode;
  districtWideSchoolData: DistrictWideSchoolNode;
  districtWideSchoolSpecificData: DistrictWideSchoolSpecificDetailsNode;
  getAcademicSession: AcademicSession;
  getAdminDashboardSurveyTypeform: Typeform;
  getAdminDashboardSurveyTypeformPhase2: Typeform;
  getAdminDashboardTourTypeform: Typeform;
  getAdminDashboardTourTypeformPhase2: Typeform;
  getAllAssignmentSubmissionsForTeacher: TeacherAssignmentSubmissionsResult;
  getAllAssignmentsAndBenchmarksForStudent: StudentBenchmarkAndAssignmentResult;
  getAllAssignmentsForStudentByID: AssignmentConnection;
  getAllAssignmentsForTeacher: AssignmentConnection;
  getAllBenchmarksForSuperAdmin: BenchmarkSuperAdminDtoConnection;
  getAllStudentsOfTeacher: Array<Student>;
  getBTSSideTabTypeform: Typeform;
  getBTSTypeform: Typeform;
  getBenchmarkV2Typeform: Typeform;
  getCSVDownloadSurveyTypeform: Typeform;
  getCSVSurveyTypeform: Typeform;
  getCreateClassesSurveyTypeform: Typeform;
  getDistrictById: LoginDistrictDetails;
  getGoalsByStudentID?: Maybe<StudentGoalListResult>;
  getGoalsForSubmission: UserGoal;
  getLastSnowflakeUpdateTime: Scalars["Time"];
  getPTDetailsForStudent: StudentDashboardPtResult;
  getPredefinedGoals: PredefinedGoalsList;
  getRubrics?: Maybe<Array<Rubric>>;
  getStudentGoals?: Maybe<StudentGoalListResult>;
  getStudentsWithClasses: Array<Student>;
  getSubmissionGoalInfo: SubmissionTypeGoalInfo;
  getTeacherBenchmarkDashboardSurvey: Typeform;
  getTeacherBenchmarkDashboardWalkthrough: Typeform;
  getTeacherDashboardIndividualStudentSurveyTypeform: Typeform;
  getTeacherDashboardIndividualStudentTourTypeform: Typeform;
  getTeacherDashboardSurveyTypeform: Typeform;
  getUnImportedCleverDistricts?: Maybe<Array<Maybe<CleverDistrict>>>;
  goalAchievementGraph?: Maybe<GoalAchievementGraphResult>;
  groupDetails: GroupDetails;
  groupList: GroupConnection;
  groupsBySection: GroupConnection;
  health: Scalars["Boolean"];
  keyword?: Maybe<Array<Keyword>>;
  learningGoalWidgetSlideOut: LearningGoalWidgetSlideOutResult;
  learningGoalsCompletedPTsMostRecentGoalsData: Array<LearningGoalsCompletedPTsMostRecentGoalsPerMonth>;
  learningGoalsIndicatorData: LearningGoalsIndicatorNode;
  learningGoalsPerformanceData: Array<LearningGoalsPerformance>;
  learningScoresAndGoalsWidget: Array<Maybe<LearningGoalsScoresByIndicatorLineItem>>;
  listCleverSyncs?: Maybe<CleverSyncListConnection>;
  listViewedFeatures?: Maybe<Array<Scalars["String"]>>;
  me?: Maybe<User>;
  myDistrict: DistrictData;
  mySchool: School;
  newSubmission: NewSubmission;
  overallBenchmarkIndicatorScores?: Maybe<Array<OverallBenchmarkIndicatorResult>>;
  overallPerformanceInfo?: Maybe<PerformanceWidgetData>;
  overallPerformanceLevel?: Maybe<Array<OverallPerformanceLevelResult>>;
  overallPerformanceLevelSlideOut: OverallPerformanceLevelSlideOutResult;
  performanceWideStudentCompletionRateData: PerformanceWideStudentCompletionRateNode;
  performanceWideTaskAssignedAndCompletedData: PerformanceWideTaskAssignedAndCompleted;
  performanceWidget?: Maybe<Array<AdminPerformanceScoreLineItem>>;
  performanceWidgetSlideOut: PerformanceWidgetSlideOutResult;
  primarySectionsOfTeacher: Array<SectionDetails>;
  resourceCountByClass?: Maybe<ClassResourcesCount>;
  resourceCountByDistrict?: Maybe<DistrictResourcesCount>;
  resourceCountBySchool?: Maybe<SchoolResourcesCount>;
  resourceCountByStudent?: Maybe<StudentResourcesCount>;
  resourceCountByTeacher?: Maybe<TeacherResourcesCount>;
  schoolAdmin: Admin;
  schoolAdminList: AdminConnection;
  schoolDetails: School;
  schoolPredefinedGrades?: Maybe<Array<Maybe<SchoolPredefinedGradeMappings>>>;
  schoolsFilterList: Array<SchoolDetails>;
  schoolsList: SchoolsConnection;
  scoreSchemeList: Array<ScoreScheme>;
  scoreSummary: Array<Summary>;
  scoreSummaryForStudent: StudentViewScoreSummary;
  sectionCount: Scalars["Int"];
  sectionDetails: SectionDetails;
  sectionTeachers: Array<TeacherInfo>;
  sections: Array<Section>;
  sectionsFilterList?: Maybe<Array<SectionWSchoolDto>>;
  sectionsList: SectionConnection;
  sectionsOfStudent: Array<Section>;
  sectionsToCreatePT: Array<SectionWithGroups>;
  speakingScoreSummary: Array<ScoreDistribution>;
  states: Array<State>;
  staticResource: AdminResource;
  staticResources: Array<StaticResource>;
  student: Student;
  studentGroupStudentsList: StudentAllocationData;
  studentScoreSummary: StudentViewScoreSummary;
  students: StudentConnection;
  studentsWithInProgressSubmission: Array<User>;
  subjectArea?: Maybe<Array<SubjectArea>>;
  submission?: Maybe<Submission>;
  submissionQueue?: Maybe<SubmissionQueueResponse>;
  submissionStatus: SubmissionStatusResult;
  submissionStatusSlideout: SubmissionStatusSlideoutResult;
  submissions: SubmissionConnection;
  submissionsByStudent?: Maybe<Array<Maybe<Submission>>>;
  submissionsCount: Scalars["Int"];
  superAdminDetails: SuperAdmin;
  superAdminList: AdminConnection;
  syncDistrictsList: Array<DistrictItem>;
  taskType?: Maybe<Array<TaskType>>;
  teacher: Teacher;
  teacherBenchmarkGradingQueue?: Maybe<Array<Scalars["String"]>>;
  teacherBenchmarkSlideOut: BenchmarkSlideOutResult;
  teacherBenchmarkStudentList?: Maybe<Array<TeacherBenchmarkStudentLineItem>>;
  teacherBenchmarkSubmissionList?: Maybe<TeacherBenchmarkSubmissionListResult>;
  teacherBenchmarks: BenchmarkTeacherDtoConnection;
  teacherByUser: Teacher;
  teacherList: TeacherConnection;
  teacherListForAdminBenchmark: TeacherConnection;
  teacherListForTeacherSchool: TeacherConnection;
  teacherPTSlideOut: PtSlideOutResult;
  teacherSchools: Array<Maybe<School>>;
  teacherStudentGradesFilter: Array<Maybe<GradeDto>>;
  teachersOfSection?: Maybe<Array<SectionDto>>;
  teachersOfStudent: Array<TeachersFilter>;
  test?: Maybe<Array<Maybe<PerformanceWideStudentSubmissionAndReviewedDbv2>>>;
  totalBenchmarkStudentCountByFilters: Scalars["Int"];
  unreadFeedbackOfStudent?: Maybe<Array<Submission>>;
  viewStudent: StudentData;
  writingScoreSummary: Array<ScoreDistribution>;
  zoneUnderDistrict?: Maybe<Array<Zone>>;
};

export type QueryActiveGoalsSummaryArgs = {
  assignmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
  schoolID?: InputMaybe<Scalars["ID"]>;
  sectionIDs?: InputMaybe<Array<Scalars["ID"]>>;
};

export type QueryAdminArgs = {
  id: Scalars["ID"];
};

export type QueryAdminBenchmarkGradeComparisonArgs = {
  district_id?: InputMaybe<Scalars["String"]>;
  orderBy?: InputMaybe<OrderBy>;
  school_id?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<AdminBenchmarkReportingWidgetSortType>;
};

export type QueryAdminBenchmarkGradingQueueArgs = {
  input?: InputMaybe<BenchmarkSubmissionListInput>;
};

export type QueryAdminBenchmarkIndicatorScoresArgs = {
  input: AdminBenchmarkInput;
};

export type QueryAdminBenchmarkSchoolsComparisonArgs = {
  district_id: Scalars["String"];
  limit?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<OrderBy>;
  page?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<AdminBenchmarkReportingWidgetSortType>;
};

export type QueryAdminBenchmarkSchoolsSlideOutComparisonArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<OrderBy>;
  page?: InputMaybe<Scalars["Int"]>;
  school_id: Scalars["String"];
  sort?: InputMaybe<AdminBenchmarkReportingWidgetSortType>;
};

export type QueryAdminBenchmarkSlideOutArgs = {
  input: BenchmarkSlideOutInput;
};

export type QueryAdminBenchmarkSubmissionListArgs = {
  input?: InputMaybe<BenchmarkSubmissionListInput>;
};

export type QueryAdminBenchmarkSubmissionStatusGrowthArgs = {
  input: AdminBenchmarkInput;
};

export type QueryAdminBenchmarkSubmissionsCountArgs = {
  grade_statuses?: InputMaybe<Array<SubmissionStatus>>;
};

export type QueryAdminBenchmarkTeacherComparisonArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<OrderBy>;
  page?: InputMaybe<Scalars["Int"]>;
  school_id: Scalars["String"];
  sort?: InputMaybe<AdminBenchmarkReportingWidgetSortType>;
};

export type QueryAdminDashboardSectionFilterListArgs = {
  class_types?: InputMaybe<Array<SectionCategory>>;
  district_id?: InputMaybe<Scalars["String"]>;
  grade_ids?: InputMaybe<Array<Scalars["String"]>>;
  school_ids?: InputMaybe<Array<Scalars["String"]>>;
  state_id?: InputMaybe<Scalars["String"]>;
  teacher_ids?: InputMaybe<Array<Scalars["String"]>>;
};

export type QueryAdminListArgs = {
  district_id?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<OrderBy>;
  page?: InputMaybe<Scalars["Int"]>;
  school_id?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<AdminListSortType>;
  state_id?: InputMaybe<Scalars["String"]>;
};

export type QueryAdminTeacherSchoolsArgs = {
  teacherUserID?: InputMaybe<Scalars["String"]>;
};

export type QueryAgencyUnderStateArgs = {
  state_id: Scalars["ID"];
};

export type QueryAllPredefinedGradesArgs = {
  districtIDs?: InputMaybe<Array<Scalars["String"]>>;
  order_by?: InputMaybe<OrderBy>;
  schoolIDs?: InputMaybe<Array<Scalars["String"]>>;
  sort?: InputMaybe<GradeSortType>;
  stateIDs?: InputMaybe<Array<Scalars["String"]>>;
};

export type QueryAllStudentsListArgs = {
  agency_id?: InputMaybe<Scalars["String"]>;
  district_id?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<OrderBy>;
  page?: InputMaybe<Scalars["Int"]>;
  school_id?: InputMaybe<Scalars["String"]>;
  searchByName?: InputMaybe<Scalars["String"]>;
  section_id?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<AdminStudentsListSortType>;
  state_id?: InputMaybe<Scalars["String"]>;
  teacher_id?: InputMaybe<Scalars["String"]>;
  zone_id?: InputMaybe<Scalars["String"]>;
};

export type QueryAllSubmissionsOfStudentArgs = {
  status?: InputMaybe<Array<InputMaybe<SubmissionStatus>>>;
};

export type QueryAssignmentArgs = {
  id: Scalars["ID"];
};

export type QueryBenchmarkArgs = {
  id: Scalars["ID"];
};

export type QueryBenchmarkIndividualVsGradeLevelInfoArgs = {
  input?: InputMaybe<BenchmarkIndividualVsGradeLevelInput>;
};

export type QueryBenchmarkProgressScoreArgs = {
  input?: InputMaybe<BenchmarkProgressScoreInput>;
};

export type QueryBenchmarkScoresArgs = {
  input?: InputMaybe<BenchmarkScoresInput>;
};

export type QueryBenchmarkStudentCountByFiltersArgs = {
  input: BenchmarkAssignToInput;
};

export type QueryBenchmarkStudentGrowthReportArgs = {
  input?: InputMaybe<BenchmarkStudentGrowthReportInput>;
};

export type QueryBenchmarkSubmissionArgs = {
  id: Scalars["ID"];
};

export type QueryCleverSyncDetailsArgs = {
  id: Scalars["String"];
};

export type QueryCustomClassStudentListArgs = {
  input: CustomClassAllocationInput;
};

export type QueryDistrictAdminArgs = {
  id: Scalars["ID"];
};

export type QueryDistrictAdminListArgs = {
  district_id?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<OrderBy>;
  page?: InputMaybe<Scalars["Int"]>;
  school_id?: InputMaybe<Scalars["String"]>;
  searchByName?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<AdminListSortType>;
  state_id?: InputMaybe<Scalars["String"]>;
};

export type QueryDistrictDetailsArgs = {
  id: Scalars["ID"];
};

export type QueryDistrictListArgs = {
  agency_id?: InputMaybe<Scalars["String"]>;
  excludeDeleted?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<OrderBy>;
  page?: InputMaybe<Scalars["Int"]>;
  searchByName?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<DistrictListSortType>;
  source?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  state_ids?: InputMaybe<Array<Scalars["String"]>>;
};

export type QueryDistrictWideGradeInfoArgs = {
  input?: InputMaybe<DistrictWideGradeInput>;
};

export type QueryDistrictWideGradeSpecificDataArgs = {
  district_id?: InputMaybe<Scalars["String"]>;
  grade_id: Scalars["String"];
  limit?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<OrderBy>;
  page?: InputMaybe<Scalars["Int"]>;
  school_id?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<DistrictWideGradeSpecificDetailsSortType>;
};

export type QueryDistrictWideSchoolDataArgs = {
  district_id?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<OrderBy>;
  page?: InputMaybe<Scalars["Int"]>;
  school_id?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<DistrictWideSchoolDetailsSortType>;
};

export type QueryDistrictWideSchoolSpecificDataArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<OrderBy>;
  page?: InputMaybe<Scalars["Int"]>;
  school_id: Scalars["String"];
  sort?: InputMaybe<DistrictWideSchoolSpecificDetailsSortType>;
};

export type QueryGetAllAssignmentSubmissionsForTeacherArgs = {
  input?: InputMaybe<TeacherAssignmentSubmissionsInput>;
};

export type QueryGetAllAssignmentsAndBenchmarksForStudentArgs = {
  input?: InputMaybe<StudentAssignmentAndBenchmarkInput>;
};

export type QueryGetAllAssignmentsForStudentByIdArgs = {
  assignmentID?: InputMaybe<Scalars["String"]>;
  closeAt?: InputMaybe<DateQuery>;
  includeNotStarted?: InputMaybe<Scalars["Boolean"]>;
  orderBy?: InputMaybe<OrderBy>;
  sectionID?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<Scalars["String"]>;
  startAt?: InputMaybe<DateQuery>;
  status?: InputMaybe<Array<InputMaybe<SubmissionStatus>>>;
  studentID: Scalars["String"];
};

export type QueryGetAllAssignmentsForTeacherArgs = {
  closeAt?: InputMaybe<DateQuery>;
  limit?: InputMaybe<Scalars["Int"]>;
  loadAggregatedStatus?: InputMaybe<Scalars["Boolean"]>;
  orderBy?: InputMaybe<OrderBy>;
  page?: InputMaybe<Scalars["Int"]>;
  schoolId?: InputMaybe<Scalars["String"]>;
  search?: InputMaybe<Scalars["String"]>;
  sectionId?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<Scalars["String"]>;
  startAt?: InputMaybe<DateQuery>;
};

export type QueryGetAllBenchmarksForSuperAdminArgs = {
  closeAt?: InputMaybe<DateQuery>;
  limit?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<OrderBy>;
  page?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<BenchmarkSuperAdminDtoSortType>;
  startAt?: InputMaybe<DateQuery>;
};

export type QueryGetAllStudentsOfTeacherArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<OrderBy>;
  schoolID?: InputMaybe<Scalars["String"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<StudentListOfTeacherSortType>;
};

export type QueryGetDistrictByIdArgs = {
  id: Scalars["String"];
};

export type QueryGetGoalsByStudentIdArgs = {
  assignment_id?: InputMaybe<Scalars["ID"]>;
  section_id?: InputMaybe<Scalars["ID"]>;
  student_id?: InputMaybe<Scalars["ID"]>;
  submission_id?: InputMaybe<Scalars["ID"]>;
  target_submission_id?: InputMaybe<GoalType>;
};

export type QueryGetGoalsForSubmissionArgs = {
  submissionID: Scalars["ID"];
};

export type QueryGetPtDetailsForStudentArgs = {
  input?: InputMaybe<StudentAssignmentAndBenchmarkInput>;
};

export type QueryGetStudentGoalsArgs = {
  target_submission_id?: InputMaybe<GoalType>;
};

export type QueryGetStudentsWithClassesArgs = {
  studentIDs?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
};

export type QueryGetSubmissionGoalInfoArgs = {
  submissionID: Scalars["ID"];
};

export type QueryGoalAchievementGraphArgs = {
  input?: InputMaybe<GoalAchievementGraphInput>;
};

export type QueryGroupDetailsArgs = {
  id: Scalars["String"];
};

export type QueryGroupListArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  page?: InputMaybe<Scalars["Int"]>;
  school_id?: InputMaybe<Scalars["String"]>;
  search?: InputMaybe<Scalars["String"]>;
  section_id?: InputMaybe<Scalars["String"]>;
};

export type QueryGroupsBySectionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<OrderBy>;
  page?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  section_id?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<GroupsListSortType>;
};

export type QueryKeywordArgs = {
  input?: InputMaybe<Scalars["String"]>;
};

export type QueryLearningGoalWidgetSlideOutArgs = {
  input: LearningGoalWidgetSlideOutInput;
};

export type QueryLearningGoalsCompletedPTsMostRecentGoalsDataArgs = {
  district_id?: InputMaybe<Scalars["String"]>;
  input?: InputMaybe<AdminDashboardFilterInput>;
  school_id?: InputMaybe<Scalars["String"]>;
};

export type QueryLearningGoalsIndicatorDataArgs = {
  district_id?: InputMaybe<Scalars["String"]>;
  input?: InputMaybe<AdminDashboardFilterInput>;
  school_id?: InputMaybe<Scalars["String"]>;
};

export type QueryLearningGoalsPerformanceDataArgs = {
  district_id?: InputMaybe<Scalars["String"]>;
  input?: InputMaybe<AdminDashboardFilterInput>;
  school_id?: InputMaybe<Scalars["String"]>;
};

export type QueryLearningScoresAndGoalsWidgetArgs = {
  input?: InputMaybe<LearningGoalsScoresByIndicatorInput>;
};

export type QueryListCleverSyncsArgs = {
  input?: InputMaybe<CleverSyncListInput>;
};

export type QueryNewSubmissionArgs = {
  id: Scalars["ID"];
};

export type QueryOverallBenchmarkIndicatorScoresArgs = {
  input: OverallBenchmarkIndicatorInput;
};

export type QueryOverallPerformanceInfoArgs = {
  input?: InputMaybe<OverallPerformanceWidgetInput>;
};

export type QueryOverallPerformanceLevelArgs = {
  input: OverallPerformanceLevelInput;
};

export type QueryOverallPerformanceLevelSlideOutArgs = {
  input: OverallPerformanceLevelSlideOutInput;
};

export type QueryPerformanceWideStudentCompletionRateDataArgs = {
  district_id?: InputMaybe<Scalars["String"]>;
  input?: InputMaybe<AdminDashboardFilterInput>;
  school_id?: InputMaybe<Scalars["String"]>;
};

export type QueryPerformanceWideTaskAssignedAndCompletedDataArgs = {
  district_id?: InputMaybe<Scalars["String"]>;
  input?: InputMaybe<AdminDashboardFilterInput>;
  school_id?: InputMaybe<Scalars["String"]>;
};

export type QueryPerformanceWidgetArgs = {
  input: PerformanceWidgetInput;
};

export type QueryPerformanceWidgetSlideOutArgs = {
  input: PerformanceWidgetSlideOutInput;
};

export type QueryPrimarySectionsOfTeacherArgs = {
  class_types?: InputMaybe<Array<SectionCategory>>;
  school_id?: InputMaybe<Scalars["String"]>;
};

export type QueryResourceCountByClassArgs = {
  sectionID: Scalars["ID"];
};

export type QueryResourceCountByDistrictArgs = {
  districtID: Scalars["ID"];
};

export type QueryResourceCountBySchoolArgs = {
  schoolID: Scalars["ID"];
};

export type QueryResourceCountByStudentArgs = {
  studentID: Scalars["ID"];
};

export type QueryResourceCountByTeacherArgs = {
  schoolID?: InputMaybe<Scalars["ID"]>;
  teacherID: Scalars["ID"];
};

export type QuerySchoolAdminArgs = {
  id: Scalars["ID"];
};

export type QuerySchoolAdminListArgs = {
  district_id?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<OrderBy>;
  page?: InputMaybe<Scalars["Int"]>;
  school_id?: InputMaybe<Scalars["String"]>;
  searchByName?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<AdminListSortType>;
  state_id?: InputMaybe<Scalars["String"]>;
};

export type QuerySchoolDetailsArgs = {
  id: Scalars["ID"];
};

export type QuerySchoolPredefinedGradesArgs = {
  school_id?: InputMaybe<Scalars["String"]>;
};

export type QuerySchoolsFilterListArgs = {
  district_ids?: InputMaybe<Array<Scalars["String"]>>;
  grade_ids?: InputMaybe<Array<Scalars["String"]>>;
  state_ids?: InputMaybe<Array<Scalars["String"]>>;
};

export type QuerySchoolsListArgs = {
  agency_id?: InputMaybe<Scalars["String"]>;
  district_id?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<OrderBy>;
  page?: InputMaybe<Scalars["Int"]>;
  searchByName?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<SchoolsListSortType>;
  state_id?: InputMaybe<Scalars["String"]>;
  zone_id?: InputMaybe<Scalars["String"]>;
};

export type QueryScoreSummaryArgs = {
  input?: InputMaybe<ScoreSummaryInput>;
};

export type QueryScoreSummaryForStudentArgs = {
  assignmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
  sectionIDs?: InputMaybe<Array<Scalars["ID"]>>;
  submissionID: Scalars["ID"];
  teacherID?: InputMaybe<Scalars["ID"]>;
};

export type QuerySectionCountArgs = {
  class_types?: InputMaybe<Array<SectionCategory>>;
  district_id?: InputMaybe<Scalars["String"]>;
  school_id?: InputMaybe<Scalars["String"]>;
  state_id?: InputMaybe<Scalars["String"]>;
  student_id?: InputMaybe<Scalars["String"]>;
  teacher_id?: InputMaybe<Scalars["String"]>;
};

export type QuerySectionDetailsArgs = {
  id: Scalars["String"];
};

export type QuerySectionTeachersArgs = {
  id: Scalars["ID"];
};

export type QuerySectionsArgs = {
  hideGroups?: InputMaybe<Scalars["Boolean"]>;
  schoolIDs?: InputMaybe<Array<Scalars["String"]>>;
  studentID?: InputMaybe<Scalars["String"]>;
};

export type QuerySectionsFilterListArgs = {
  class_types?: InputMaybe<Array<SectionCategory>>;
  district_id?: InputMaybe<Scalars["String"]>;
  school_ids?: InputMaybe<Array<Scalars["String"]>>;
  state_id?: InputMaybe<Scalars["String"]>;
  teacher_ids?: InputMaybe<Array<Scalars["String"]>>;
};

export type QuerySectionsListArgs = {
  agency_id?: InputMaybe<Scalars["String"]>;
  class_types?: InputMaybe<Array<SectionCategory>>;
  district_id?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<OrderBy>;
  page?: InputMaybe<Scalars["Int"]>;
  school_id?: InputMaybe<Scalars["String"]>;
  searchByName?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<SectionsListSortType>;
  state_id?: InputMaybe<Scalars["String"]>;
  student_id?: InputMaybe<Scalars["String"]>;
  teacher_id?: InputMaybe<Scalars["String"]>;
  zone_id?: InputMaybe<Scalars["String"]>;
};

export type QuerySpeakingScoreSummaryArgs = {
  input?: InputMaybe<ScoreSummaryInput>;
};

export type QueryStaticResourceArgs = {
  id: Scalars["ID"];
};

export type QueryStaticResourcesArgs = {
  input?: InputMaybe<StaticResourcesInput>;
};

export type QueryStudentArgs = {
  id: Scalars["ID"];
};

export type QueryStudentGroupStudentsListArgs = {
  input: GroupAllocationInput;
};

export type QueryStudentScoreSummaryArgs = {
  assignmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
  sectionIDs?: InputMaybe<Array<Scalars["ID"]>>;
  studentID: Scalars["ID"];
};

export type QueryStudentsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<OrderBy>;
  page?: InputMaybe<Scalars["Int"]>;
  school_id?: InputMaybe<Scalars["ID"]>;
  search?: InputMaybe<Scalars["String"]>;
  section_id?: InputMaybe<Scalars["ID"]>;
  sort?: InputMaybe<Scalars["String"]>;
};

export type QueryStudentsWithInProgressSubmissionArgs = {
  section_id: Scalars["ID"];
  student_ids?: InputMaybe<Array<Scalars["ID"]>>;
};

export type QuerySubmissionArgs = {
  id: Scalars["ID"];
};

export type QuerySubmissionQueueArgs = {
  orderBy?: InputMaybe<OrderBy>;
  schoolID?: InputMaybe<Scalars["String"]>;
  search?: InputMaybe<Scalars["String"]>;
  sectionID?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Array<InputMaybe<SubmissionStatus>>>;
};

export type QuerySubmissionStatusArgs = {
  input?: InputMaybe<SubmissionStatusDataInput>;
};

export type QuerySubmissionStatusSlideoutArgs = {
  input: SubmissionStatusSlideoutDataInput;
};

export type QuerySubmissionsArgs = {
  assignment_id?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<OrderBy>;
  page?: InputMaybe<Scalars["Int"]>;
  school_id?: InputMaybe<Scalars["String"]>;
  search?: InputMaybe<Scalars["String"]>;
  section_id?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Array<InputMaybe<SubmissionStatus>>>;
  student_id?: InputMaybe<Scalars["String"]>;
};

export type QuerySubmissionsByStudentArgs = {
  student_id?: InputMaybe<Scalars["String"]>;
};

export type QuerySubmissionsCountArgs = {
  assignment_id?: InputMaybe<Scalars["String"]>;
  school_id?: InputMaybe<Scalars["String"]>;
  search?: InputMaybe<Scalars["String"]>;
  section_id?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Array<InputMaybe<SubmissionStatus>>>;
  student_id?: InputMaybe<Scalars["String"]>;
};

export type QuerySuperAdminDetailsArgs = {
  id: Scalars["ID"];
};

export type QuerySuperAdminListArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<OrderBy>;
  page?: InputMaybe<Scalars["Int"]>;
  searchByName?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<AdminListSortType>;
};

export type QueryTaskTypeArgs = {
  input?: InputMaybe<Scalars["String"]>;
};

export type QueryTeacherArgs = {
  class_types?: InputMaybe<Array<SectionCategory>>;
  id: Scalars["String"];
};

export type QueryTeacherBenchmarkGradingQueueArgs = {
  input?: InputMaybe<TeacherSubmissionListInput>;
};

export type QueryTeacherBenchmarkSlideOutArgs = {
  input: BenchmarkSlideOutInput;
};

export type QueryTeacherBenchmarkStudentListArgs = {
  input?: InputMaybe<TeacherBenchmarkStudentListInput>;
};

export type QueryTeacherBenchmarkSubmissionListArgs = {
  input?: InputMaybe<TeacherSubmissionListInput>;
};

export type QueryTeacherBenchmarksArgs = {
  close_at?: InputMaybe<DateQuery>;
  limit?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<OrderBy>;
  page?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<BenchmarkTeacherDtoSortType>;
  start_at?: InputMaybe<DateQuery>;
};

export type QueryTeacherByUserArgs = {
  userID: Scalars["ID"];
};

export type QueryTeacherListArgs = {
  agency_id?: InputMaybe<Scalars["String"]>;
  district_id?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<OrderBy>;
  page?: InputMaybe<Scalars["Int"]>;
  school_id?: InputMaybe<Scalars["String"]>;
  searchByName?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<TeachersListSortType>;
  state_id?: InputMaybe<Scalars["String"]>;
  zone_id?: InputMaybe<Scalars["String"]>;
};

export type QueryTeacherListForAdminBenchmarkArgs = {
  input: TeacherListForAdminBenchmarkInput;
};

export type QueryTeacherListForTeacherSchoolArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<OrderBy>;
  page?: InputMaybe<Scalars["Int"]>;
  school_id?: InputMaybe<Scalars["String"]>;
  searchByName?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<TeachersListSortType>;
};

export type QueryTeacherPtSlideOutArgs = {
  input: PtSlideOutInput;
};

export type QueryTeacherStudentGradesFilterArgs = {
  order_by?: InputMaybe<OrderBy>;
  sort_by?: InputMaybe<TeacherStudentGradesSortType>;
};

export type QueryTeachersOfSectionArgs = {
  section_ids?: InputMaybe<Array<Scalars["ID"]>>;
};

export type QueryTotalBenchmarkStudentCountByFiltersArgs = {
  input: TotalBenchmarkAssignToInput;
};

export type QueryUnreadFeedbackOfStudentArgs = {
  section_id?: InputMaybe<Scalars["ID"]>;
  teacher_id?: InputMaybe<Scalars["ID"]>;
};

export type QueryViewStudentArgs = {
  id: Scalars["ID"];
};

export type QueryWritingScoreSummaryArgs = {
  input?: InputMaybe<ScoreSummaryInput>;
};

export type QueryZoneUnderDistrictArgs = {
  district_id: Scalars["ID"];
};

export type RemoveStudentsFromClassInput = {
  students?: InputMaybe<Array<ClassesWithStudentsInput>>;
};

export type Rubric = {
  __typename?: "Rubric";
  content: Scalars["String"];
  id: Scalars["ID"];
  submission_type: Scalars["String"];
  title: Scalars["String"];
};

export type School = {
  __typename?: "School";
  district?: Maybe<DistrictData>;
  district_id: Scalars["String"];
  id: Scalars["ID"];
  mdr_number?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  nces_id?: Maybe<Scalars["String"]>;
  school_id: Scalars["String"];
  school_predefined_grade_mappings?: Maybe<Array<Maybe<SchoolPredefinedGradeMappings>>>;
  updated_at?: Maybe<Scalars["Time"]>;
  zone_id?: Maybe<Scalars["String"]>;
};

export type SchoolDetails = {
  __typename?: "SchoolDetails";
  district_id: Scalars["String"];
  district_name: Scalars["String"];
  district_source?: Maybe<Scalars["String"]>;
  district_state_id?: Maybe<Scalars["String"]>;
  grade?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  name: Scalars["String"];
  school_id: Scalars["String"];
  school_predefined_grade_mappings?: Maybe<Array<Maybe<SchoolPredefinedGradeMappings>>>;
  student_count: Scalars["Int"];
};

export type SchoolInfo = {
  __typename?: "SchoolInfo";
  id: Scalars["String"];
  name: Scalars["String"];
};

export type SchoolInput = {
  district_id?: InputMaybe<Scalars["String"]>;
  grades: Array<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  mdr_number?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  nces_id?: InputMaybe<Scalars["String"]>;
  school_id: Scalars["String"];
  zone_id?: InputMaybe<Scalars["String"]>;
};

export type SchoolPredefinedGradeMappings = {
  __typename?: "SchoolPredefinedGradeMappings";
  id: Scalars["ID"];
  predefined_grade_id: Scalars["String"];
  predefined_grades?: Maybe<PredefinedGrades>;
  school_id: Scalars["String"];
};

export type SchoolResourcesCount = {
  __typename?: "SchoolResourcesCount";
  class_count: Scalars["Int"];
  school_admin_count: Scalars["Int"];
  student_count: Scalars["Int"];
  teacher_count: Scalars["Int"];
};

export type SchoolSection = {
  __typename?: "SchoolSection";
  id: Scalars["String"];
  primary_teacher: Scalars["Boolean"];
  school_id: Scalars["String"];
  school_name: Scalars["String"];
  section_id: Scalars["String"];
  section_name: Scalars["String"];
  source: Scalars["String"];
};

export type SchoolSectionListInput = {
  school_id: Scalars["String"];
  sections_ids?: InputMaybe<Array<Scalars["String"]>>;
};

export type SchoolSectionMapping = {
  school_id: Scalars["String"];
  section_id: Array<Scalars["String"]>;
};

export type SchoolsConnection = {
  __typename?: "SchoolsConnection";
  nodes: Array<SchoolDetails>;
  totalCount: Scalars["Int"];
};

export enum SchoolsListSortType {
  DistrictName = "district_name",
  DistrictSource = "district_source",
  Name = "name",
  SchoolId = "school_id",
  Source = "source",
  StudentCount = "student_count",
}

export type ScoreDetail = {
  __typename?: "ScoreDetail";
  color_code: Scalars["String"];
  description: Scalars["String"];
  id: Scalars["ID"];
  range_end: Scalars["Float"];
  range_start: Scalars["Float"];
  score_value: Scalars["Int"];
  title: Scalars["String"];
  title_key: Scalars["String"];
};

export type ScoreDistribution = {
  __typename?: "ScoreDistribution";
  color: Scalars["String"];
  icon_base64: Scalars["String"];
  id: Scalars["ID"];
  scores: Array<Maybe<Scores>>;
  skill_description: Scalars["String"];
  skill_key: Scalars["String"];
  skill_label: Scalars["String"];
  skill_level: Scalars["Int"];
};

export type ScoreScheme = {
  __typename?: "ScoreScheme";
  id: Scalars["ID"];
  scoreDetail: Array<ScoreDetail>;
  title: Scalars["String"];
  title_key: Scalars["String"];
};

export type ScoreSummaryInput = {
  assignment_ids?: InputMaybe<Array<Scalars["ID"]>>;
  order_by?: InputMaybe<OrderBy>;
  school_ids?: InputMaybe<Array<Scalars["ID"]>>;
  section_ids?: InputMaybe<Array<Scalars["ID"]>>;
  sort_by?: InputMaybe<ScoreSummarySortType>;
  student_ids?: InputMaybe<Array<Scalars["ID"]>>;
};

export enum ScoreSummarySortType {
  SkillLevel = "skill_level",
}

export type Scores = {
  __typename?: "Scores";
  color: Scalars["String"];
  id: Scalars["ID"];
  skill_description: Scalars["String"];
  skill_key: Scalars["String"];
  skill_label: Scalars["String"];
  skill_level: Scalars["Int"];
  students: Array<AnalyticsData>;
  total_count: Scalars["Int"];
};

export type Section = {
  __typename?: "Section";
  category: SectionCategory;
  course: Scalars["String"];
  id: Scalars["ID"];
  name: Scalars["String"];
  parent_section_id: Scalars["ID"];
  school?: Maybe<School>;
  students?: Maybe<Array<Student>>;
  subject: Scalars["String"];
  teachers?: Maybe<Array<TeacherInfo>>;
};

export enum SectionCategory {
  Class = "Class",
  CustomClass = "CustomClass",
  Group = "Group",
}

export type SectionConnection = {
  __typename?: "SectionConnection";
  nodes: Array<SectionDetails>;
  totalCount: Scalars["Int"];
};

export type SectionDto = {
  __typename?: "SectionDTO";
  id: Scalars["ID"];
  name: Scalars["String"];
  teachers: Array<Maybe<TeacherInfoV2>>;
};

export type SectionDetails = {
  __typename?: "SectionDetails";
  active_pt?: Maybe<Scalars["Boolean"]>;
  category: SectionCategory;
  course_name?: Maybe<Scalars["String"]>;
  district: Scalars["String"];
  districtData?: Maybe<DistrictData>;
  district_id: Scalars["String"];
  grades: Array<Scalars["String"]>;
  id: Scalars["ID"];
  name: Scalars["String"];
  period?: Maybe<Scalars["Int"]>;
  school: Scalars["String"];
  school_id: Scalars["String"];
  section_number?: Maybe<Scalars["String"]>;
  sis_id?: Maybe<Scalars["String"]>;
  source?: Maybe<Scalars["String"]>;
  student_ids: Array<Scalars["String"]>;
  subject?: Maybe<Scalars["String"]>;
  teachers: Array<Maybe<TeacherInfo>>;
  term_end?: Maybe<Scalars["Time"]>;
  term_start?: Maybe<Scalars["Time"]>;
};

export type SectionForAdminDashboardFilterNode = {
  __typename?: "SectionForAdminDashboardFilterNode";
  gradesInfo?: Maybe<Array<GradeDto>>;
  id: Scalars["ID"];
  name: Scalars["String"];
  school_id: Scalars["String"];
  school_name: Scalars["String"];
  teachers?: Maybe<Array<UserDto>>;
};

export type SectionInput = {
  district_id: Scalars["String"];
  grade?: InputMaybe<Array<Scalars["String"]>>;
  id?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  period?: InputMaybe<Scalars["Int"]>;
  school_id: Scalars["String"];
  secondary_teacher_ids: Array<InputMaybe<Scalars["ID"]>>;
  section_number?: InputMaybe<Scalars["String"]>;
  sis_id?: InputMaybe<Scalars["String"]>;
  student_ids_to_add?: InputMaybe<Array<Scalars["ID"]>>;
  student_ids_to_remove?: InputMaybe<Array<Scalars["ID"]>>;
  subject?: InputMaybe<Scalars["String"]>;
  teacher_id: Scalars["String"];
  term_end?: InputMaybe<Scalars["Time"]>;
  term_start?: InputMaybe<Scalars["Time"]>;
  zone_id?: InputMaybe<Scalars["String"]>;
};

export type SectionSchoolPredefinedGradeMappings = {
  __typename?: "SectionSchoolPredefinedGradeMappings";
  id: Scalars["ID"];
  school_predefined_grade?: Maybe<SchoolPredefinedGradeMappings>;
  school_predefined_grade_id: Scalars["String"];
  section_id: Scalars["String"];
};

export type SectionTeacher = {
  __typename?: "SectionTeacher";
  first_name: Scalars["String"];
  last_name: Scalars["String"];
  middle_name: Scalars["String"];
  teacher_id: Scalars["String"];
};

export type SectionWSchoolDto = {
  __typename?: "SectionWSchoolDTO";
  id: Scalars["ID"];
  name: Scalars["String"];
  school_id: Scalars["String"];
  school_name: Scalars["String"];
};

export type SectionWithGroups = {
  __typename?: "SectionWithGroups";
  course: Scalars["String"];
  groups?: Maybe<Array<Section>>;
  id: Scalars["ID"];
  name: Scalars["String"];
  school?: Maybe<School>;
  students?: Maybe<Array<Student>>;
  subject: Scalars["String"];
};

export enum SectionsListSortType {
  District = "district",
  Grade = "grade",
  Name = "name",
  Period = "period",
  School = "school",
}

export type SlideOutStudents = {
  __typename?: "SlideOutStudents";
  first_name: Scalars["String"];
  last_name: Scalars["String"];
  student_number: Scalars["String"];
  submission_id?: Maybe<Scalars["String"]>;
  submission_status: SubmissionStatus;
};

export type SpeakingActiveGoals = {
  __typename?: "SpeakingActiveGoals";
  fluency: GoalData;
  grammar: GoalData;
  interpretation: GoalData;
  pronunciation: GoalData;
  vocabulary: GoalData;
};

export type State = {
  __typename?: "State";
  id: Scalars["ID"];
  name: Scalars["String"];
  state_id: Scalars["String"];
};

export type StaticResource = {
  __typename?: "StaticResource";
  file: File;
  grade: Scalars["String"];
  id: Scalars["ID"];
  image_height: Scalars["Int"];
  image_width: Scalars["Int"];
  last_used?: Maybe<Scalars["Time"]>;
  thumbnail_height?: Maybe<Scalars["Int"]>;
  thumbnail_width?: Maybe<Scalars["Int"]>;
  title: Scalars["String"];
};

export enum StaticResourceType {
  Benchmark = "benchmark",
  Regular = "regular",
}

export type StaticResourcesInput = {
  grades?: InputMaybe<Array<Scalars["ID"]>>;
  keywords?: InputMaybe<Array<Scalars["String"]>>;
  resourceType: StaticResourceType;
  subject_areas?: InputMaybe<Array<Scalars["ID"]>>;
  task_types?: InputMaybe<Array<Scalars["ID"]>>;
};

export type Student = {
  __typename?: "Student";
  demographic_info?: Maybe<Scalars["String"]>;
  ell_status: Scalars["String"];
  grade?: Maybe<Scalars["String"]>;
  heritage_language?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  language_class?: Maybe<Scalars["String"]>;
  language_level?: Maybe<Scalars["String"]>;
  school?: Maybe<School>;
  section?: Maybe<Array<Section>>;
  student_number: Scalars["String"];
  user?: Maybe<User>;
};

export type StudentAllocationData = {
  __typename?: "StudentAllocationData";
  allocated_student_ids: Array<Scalars["ID"]>;
  allocated_students: StudentUserConnection;
  available_student_ids: Array<Scalars["ID"]>;
  available_students: StudentUserConnection;
  temporary_allocated_students: StudentUserConnection;
};

export type StudentAssignment = {
  __typename?: "StudentAssignment";
  back_navigation_allowed: Scalars["Boolean"];
  close_at: Scalars["Time"];
  id: Scalars["ID"];
  is_common_prompt: Scalars["Boolean"];
  is_submissions_started: Scalars["Boolean"];
  resource_id: Scalars["String"];
  resource_url: Scalars["String"];
  section_id?: Maybe<Scalars["String"]>;
  section_name?: Maybe<Scalars["String"]>;
  speaking_prompt_title: Scalars["String"];
  speaking_prompt_url: Scalars["String"];
  start_at: Scalars["Time"];
  submission_id?: Maybe<Scalars["String"]>;
  submission_speaking_average_score?: Maybe<Scalars["Float"]>;
  submission_status?: Maybe<Scalars["String"]>;
  submission_writing_average_score?: Maybe<Scalars["Float"]>;
  submitted_at?: Maybe<Scalars["Time"]>;
  teacher_user_id?: Maybe<Scalars["String"]>;
  teachers?: Maybe<Array<Scalars["String"]>>;
  thumbnail_url?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
  writing_prompt_title: Scalars["String"];
  writing_prompt_url: Scalars["String"];
};

export type StudentAssignmentAndBenchmarkInput = {
  close_at?: InputMaybe<DateQuery>;
  include_not_started?: InputMaybe<Scalars["Boolean"]>;
  order_by?: InputMaybe<OrderBy>;
  sort?: InputMaybe<SubmissionsForStudentSortType>;
  start_at?: InputMaybe<DateQuery>;
  status?: InputMaybe<Array<SubmissionStatus>>;
};

export type StudentAssignmentData = {
  __typename?: "StudentAssignmentData";
  close_at: Scalars["Time"];
  id: Scalars["ID"];
  resource_id: Scalars["String"];
  resource_url: Scalars["String"];
  section_id?: Maybe<Scalars["String"]>;
  start_at: Scalars["Time"];
  submission_id?: Maybe<Scalars["String"]>;
  submission_status?: Maybe<Scalars["String"]>;
  teacher_user_id?: Maybe<Scalars["String"]>;
  teachers?: Maybe<Array<Scalars["String"]>>;
  thumbnail_url?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
};

export type StudentBenchmark = {
  __typename?: "StudentBenchmark";
  back_navigation_allowed: Scalars["Boolean"];
  close_at: Scalars["Time"];
  id: Scalars["ID"];
  is_common_prompt: Scalars["Boolean"];
  resource_id: Scalars["String"];
  resource_url: Scalars["String"];
  speaking_prompt_title?: Maybe<Scalars["String"]>;
  speaking_prompt_url?: Maybe<Scalars["String"]>;
  start_at: Scalars["Time"];
  submission_id?: Maybe<Scalars["String"]>;
  submission_speaking_average_score?: Maybe<Scalars["Float"]>;
  submission_status?: Maybe<Scalars["String"]>;
  submission_writing_average_score?: Maybe<Scalars["Float"]>;
  submitted_at?: Maybe<Scalars["Time"]>;
  thumbnail_url?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
  writing_prompt_title?: Maybe<Scalars["String"]>;
  writing_prompt_url?: Maybe<Scalars["String"]>;
};

export type StudentBenchmarkAndAssignmentResult = {
  __typename?: "StudentBenchmarkAndAssignmentResult";
  assignments: Array<StudentAssignment>;
  benchmarks: Array<StudentBenchmark>;
  score_details: Array<ScoreDetail>;
  total_count: Scalars["Int"];
};

export type StudentBenchmarkData = {
  __typename?: "StudentBenchmarkData";
  close_at: Scalars["Time"];
  id: Scalars["ID"];
  resource_id: Scalars["String"];
  resource_url: Scalars["String"];
  start_at: Scalars["Time"];
  submission_id?: Maybe<Scalars["String"]>;
  submission_status?: Maybe<Scalars["String"]>;
  thumbnail_url?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
};

export type StudentConnection = {
  __typename?: "StudentConnection";
  nodes: Array<Student>;
  totalCount: Scalars["Int"];
};

export enum StudentCustomClassGroupSortType {
  Grades = "grades",
  Name = "name",
}

export type StudentDashboardPtResult = {
  __typename?: "StudentDashboardPTResult";
  assignments: Array<StudentAssignmentData>;
  benchmarks: Array<StudentBenchmarkData>;
  score_details: Array<ScoreDetail>;
  total_count: Scalars["Int"];
};

export type StudentData = {
  __typename?: "StudentData";
  demographic_info?: Maybe<Scalars["String"]>;
  district_id: Scalars["String"];
  email?: Maybe<Scalars["String"]>;
  first_name: Scalars["String"];
  grade?: Maybe<Scalars["String"]>;
  heritage_language?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  language_class?: Maybe<Scalars["String"]>;
  language_level?: Maybe<Scalars["String"]>;
  last_name: Scalars["String"];
  middle_name: Scalars["String"];
  predefined_grade_id?: Maybe<Scalars["String"]>;
  school_id: Scalars["String"];
  school_name: Scalars["String"];
  sections: Array<StudentSectionGrade>;
  source: Scalars["String"];
  state_id?: Maybe<Scalars["String"]>;
  student_id: Scalars["String"];
  user_id: Scalars["ID"];
};

export type StudentGoal = {
  __typename?: "StudentGoal";
  id: Scalars["String"];
  predefined_goal_id: Scalars["String"];
  submission_id: Scalars["String"];
};

export type StudentGoalListNode = {
  __typename?: "StudentGoalListNode";
  assigned_date: Scalars["Time"];
  goal_status?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  predefined_goal_description?: Maybe<Scalars["String"]>;
  predefined_goal_example?: Maybe<Scalars["String"]>;
  predefined_goal_id?: Maybe<Scalars["String"]>;
  predefined_goal_rubric_icon_base_64?: Maybe<Scalars["String"]>;
  predefined_goal_rubric_id?: Maybe<Scalars["String"]>;
  predefined_goal_rubric_submission_type?: Maybe<Scalars["String"]>;
  predefined_goal_rubric_title?: Maybe<Scalars["String"]>;
  submission_id: Scalars["String"];
  target_submission_id?: Maybe<Scalars["String"]>;
  teacher_user_first_name?: Maybe<Scalars["String"]>;
  teacher_user_id?: Maybe<Scalars["String"]>;
  teacher_user_last_name?: Maybe<Scalars["String"]>;
};

export type StudentGoalListResult = {
  __typename?: "StudentGoalListResult";
  speaking: Array<StudentGoalListNode>;
  writing: Array<StudentGoalListNode>;
};

export type StudentGrade = {
  __typename?: "StudentGrade";
  id: Scalars["String"];
  name: Scalars["String"];
};

export type StudentListDto = {
  __typename?: "StudentListDTO";
  district_id: Scalars["String"];
  district_name: Scalars["String"];
  first_name: Scalars["String"];
  grade?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  is_active_submission: Scalars["Boolean"];
  last_name: Scalars["String"];
  middle_name: Scalars["String"];
  school_id: Scalars["String"];
  school_name: Scalars["String"];
  source: Scalars["String"];
  source_id: Scalars["String"];
  student_id: Scalars["String"];
  teachers: Array<TeacherInfo>;
};

export enum StudentListOfTeacherSortType {
  Grades = "grades",
  Name = "name",
  StudentId = "student_id",
}

export type StudentResourcesCount = {
  __typename?: "StudentResourcesCount";
  class_count: Scalars["Int"];
};

export type StudentScoreSummary = {
  __typename?: "StudentScoreSummary";
  speaking: StudentSpeakingScore;
  writing: StudentWritingScore;
};

export type StudentSectionGrade = {
  __typename?: "StudentSectionGrade";
  grade: Array<StudentGrade>;
  id: Scalars["String"];
  is_active_submission: Scalars["Boolean"];
  name: Scalars["String"];
  period: Scalars["Int"];
  school_id: Scalars["String"];
  school_name: Scalars["String"];
  source: Scalars["String"];
  student_count: Scalars["Int"];
};

export type StudentSections = {
  __typename?: "StudentSections";
  name: Scalars["String"];
  section_id: Scalars["String"];
  teachers: Array<SectionTeacher>;
};

export type StudentSpeakingScore = {
  __typename?: "StudentSpeakingScore";
  fluency: Scalars["Int"];
  grammar: Scalars["Int"];
  interpretation: Scalars["Int"];
  pronunciation: Scalars["Int"];
  vocabulary: Scalars["Int"];
};

export type StudentUserConnection = {
  __typename?: "StudentUserConnection";
  nodes: Array<User>;
  totalCount: Scalars["Int"];
};

export type StudentViewScoreLineItem = {
  __typename?: "StudentViewScoreLineItem";
  count: Scalars["Int"];
  rubric_icon_base_64: Scalars["String"];
  rubric_id: Scalars["String"];
  rubric_title: Scalars["String"];
  score_detail_color_code: Scalars["String"];
  score_detail_description: Scalars["String"];
  score_detail_id: Scalars["String"];
  score_detail_title: Scalars["String"];
};

export type StudentViewScoreSummary = {
  __typename?: "StudentViewScoreSummary";
  max_score_count: Scalars["Int"];
  rubric_groups: Array<StudentViewScoreSummaryRubricGroup>;
  score_details: Array<ScoreDetail>;
};

export type StudentViewScoreSummaryRubricGroup = {
  __typename?: "StudentViewScoreSummaryRubricGroup";
  score_line_items: Array<StudentViewScoreLineItem>;
  submission_type: Scalars["String"];
};

export type StudentWritingScore = {
  __typename?: "StudentWritingScore";
  description_and_explanation: Scalars["Int"];
  points_and_reasons: Scalars["Int"];
  vocab_and_grammar: Scalars["Int"];
};

export type SubjectArea = {
  __typename?: "SubjectArea";
  id?: Maybe<Scalars["ID"]>;
  title?: Maybe<Scalars["String"]>;
};

export type Submission = {
  __typename?: "Submission";
  annotations: Array<Annotation>;
  assignment?: Maybe<Assignment>;
  assignment_id: Scalars["String"];
  benchmark_id: Scalars["String"];
  feedback_notes?: Maybe<Scalars["String"]>;
  getAdditionalFeedback: AdditionalFeedback;
  getBenchmark?: Maybe<BenchmarkPt>;
  getGoalsForSubmission: UserGoal;
  grades: Array<Grade>;
  gradingStatus: GradingStatus;
  id: Scalars["ID"];
  reviewed_at?: Maybe<Scalars["Time"]>;
  speakings: Array<File>;
  status: SubmissionStatus;
  student?: Maybe<User>;
  submission_score?: Maybe<Array<Maybe<SubmissionScore>>>;
  submitted_at?: Maybe<Scalars["Time"]>;
  teacher_id: Scalars["String"];
  writing?: Maybe<Scalars["String"]>;
};

export type SubmissionConnection = {
  __typename?: "SubmissionConnection";
  nodes: Array<Submission>;
  totalCount: Scalars["Int"];
};

export type SubmissionFilterData = {
  __typename?: "SubmissionFilterData";
  assignment_id?: Maybe<Scalars["ID"]>;
  benchmark_id?: Maybe<Scalars["ID"]>;
  submission_id: Scalars["ID"];
  title: Scalars["String"];
};

export type SubmissionGoalInfo = {
  __typename?: "SubmissionGoalInfo";
  inactive_goal_count?: Maybe<Scalars["Int"]>;
};

export type SubmissionLineItem = {
  __typename?: "SubmissionLineItem";
  submission_count: Scalars["Int"];
  submission_status: SubmissionStatus;
};

export type SubmissionQueueResponse = {
  __typename?: "SubmissionQueueResponse";
  IDs?: Maybe<Array<Scalars["String"]>>;
};

export type SubmissionScore = {
  __typename?: "SubmissionScore";
  score?: Maybe<Scalars["Float"]>;
  type?: Maybe<Scalars["String"]>;
};

export enum SubmissionStatus {
  AnnotationCompleted = "AnnotationCompleted",
  NotStarted = "NotStarted",
  Reviewed = "Reviewed",
  SpeakingCompleted = "SpeakingCompleted",
  Started = "Started",
  Submitted = "Submitted",
}

export type SubmissionStatusDataInput = {
  benchmark_type: BenchmarkType;
  grade_ids?: InputMaybe<Array<Scalars["String"]>>;
  school_ids?: InputMaybe<Array<Scalars["String"]>>;
  section_ids?: InputMaybe<Array<Scalars["String"]>>;
};

export type SubmissionStatusLineItem = {
  __typename?: "SubmissionStatusLineItem";
  student_count: Scalars["Int"];
  submission_status: TeacherBenchmarkSubmissionStatus;
};

export type SubmissionStatusResult = {
  __typename?: "SubmissionStatusResult";
  submission_line_items: Array<SubmissionStatusLineItem>;
  total_submissions: Scalars["Int"];
};

export type SubmissionStatusSlideoutDataInput = {
  benchmark_type: BenchmarkType;
  grade_ids?: InputMaybe<Array<Scalars["ID"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<OrderBy>;
  page?: InputMaybe<Scalars["Int"]>;
  school_ids?: InputMaybe<Array<Scalars["ID"]>>;
  section_ids?: InputMaybe<Array<Scalars["ID"]>>;
  sort_by?: InputMaybe<SubmissionStatusSlideoutSortType>;
  submission_status?: InputMaybe<TeacherBenchmarkSubmissionStatus>;
};

export type SubmissionStatusSlideoutLineItem = {
  __typename?: "SubmissionStatusSlideoutLineItem";
  student_first_name: Scalars["String"];
  student_id: Scalars["ID"];
  student_last_name: Scalars["String"];
  student_user_id: Scalars["ID"];
  submission_status: TeacherBenchmarkSubmissionStatus;
};

export type SubmissionStatusSlideoutResult = {
  __typename?: "SubmissionStatusSlideoutResult";
  slideout_line_items: Array<SubmissionStatusSlideoutLineItem>;
  total_count: Scalars["Int"];
};

export enum SubmissionStatusSlideoutSortType {
  StudentFullName = "student_full_name",
}

export enum SubmissionType {
  Speaking = "Speaking",
  Writing = "Writing",
}

export type SubmissionTypeGoalInfo = {
  __typename?: "SubmissionTypeGoalInfo";
  speaking?: Maybe<SubmissionGoalInfo>;
  writing?: Maybe<SubmissionGoalInfo>;
};

export type SubmissionTypeGroup = {
  __typename?: "SubmissionTypeGroup";
  rubric_line_items: Array<SubmissionTypeRubricScoreLineItem>;
  submission_type: Scalars["String"];
};

export type SubmissionTypeRubricScoreLineItem = {
  __typename?: "SubmissionTypeRubricScoreLineItem";
  grade_id: Scalars["String"];
  rubric_content: Scalars["String"];
  rubric_id: Scalars["ID"];
  rubric_title: Scalars["String"];
  rubric_title_key: Scalars["String"];
  score_detail_id?: Maybe<Scalars["String"]>;
};

export type SubmissionUpdateInput = {
  status: SubmissionStatus;
  writing?: InputMaybe<Scalars["String"]>;
};

export enum SubmissionsForStudentSortType {
  SubmittedAt = "submitted_at",
}

export type Summary = {
  __typename?: "Summary";
  score_distribution: Array<Scores>;
  submission_type: Scalars["String"];
};

export type SuperAdmin = {
  __typename?: "SuperAdmin";
  admin_id: Scalars["String"];
  created_at?: Maybe<Scalars["Time"]>;
  created_by_first_name?: Maybe<Scalars["String"]>;
  created_by_last_name?: Maybe<Scalars["String"]>;
  email: Scalars["String"];
  first_name: Scalars["String"];
  id: Scalars["ID"];
  last_name: Scalars["String"];
  middle_name?: Maybe<Scalars["String"]>;
  phone_number?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
  user_id: Scalars["ID"];
};

export type SuperAdminInput = {
  admin_id: Scalars["String"];
  email: Scalars["String"];
  first_name: Scalars["String"];
  id?: InputMaybe<Scalars["String"]>;
  last_name: Scalars["String"];
  middle_name: Scalars["String"];
  password?: InputMaybe<Scalars["String"]>;
  phone_number: Scalars["String"];
  title: Scalars["String"];
};

export enum SyncStatus {
  Failed = "Failed",
  InProgress = "InProgress",
  Success = "Success",
  SuccessWithWarning = "SuccessWithWarning",
}

export enum SyncType {
  AllDistricts = "AllDistricts",
  District = "District",
}

export type TaskType = {
  __typename?: "TaskType";
  category?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["ID"]>;
  title?: Maybe<Scalars["String"]>;
};

export type Teacher = {
  __typename?: "Teacher";
  district: Scalars["String"];
  district_id: Scalars["String"];
  email: Scalars["String"];
  first_name: Scalars["String"];
  id: Scalars["String"];
  job_title: Scalars["String"];
  last_name: Scalars["String"];
  middle_name: Scalars["String"];
  phone_number?: Maybe<Scalars["String"]>;
  school_sections: Array<SchoolSection>;
  schools: Array<SchoolInfo>;
  source: Scalars["String"];
  state_code?: Maybe<Scalars["String"]>;
  state_id: Scalars["String"];
  teacher_id: Scalars["String"];
  title: Scalars["String"];
  user_id: Scalars["String"];
  zone_id?: Maybe<Scalars["String"]>;
};

export type TeacherAssignmentSubmissionNode = {
  __typename?: "TeacherAssignmentSubmissionNode";
  assignment_assigned_at: Scalars["Time"];
  assignment_id: Scalars["String"];
  assignment_title: Scalars["String"];
  submission_id: Scalars["String"];
  submission_speaking_average_score: Scalars["Float"];
  submission_status: Scalars["String"];
  submission_submitted_at: Scalars["Time"];
  submission_writing_average_score: Scalars["Float"];
};

export type TeacherAssignmentSubmissionsInput = {
  assignment_id?: InputMaybe<Scalars["String"]>;
  order_by?: InputMaybe<OrderBy>;
  section_id?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<AssignmentSubmissionsForTeacherSortType>;
  student_id?: InputMaybe<Scalars["String"]>;
};

export type TeacherAssignmentSubmissionsResult = {
  __typename?: "TeacherAssignmentSubmissionsResult";
  assignments: Array<TeacherAssignmentSubmissionNode>;
  total_count: Scalars["Int"];
};

export type TeacherBenchmarkStudentLineItem = {
  __typename?: "TeacherBenchmarkStudentLineItem";
  student_first_name?: Maybe<Scalars["String"]>;
  student_last_name?: Maybe<Scalars["String"]>;
  student_user_id: Scalars["ID"];
};

export type TeacherBenchmarkStudentListInput = {
  order_by?: InputMaybe<OrderBy>;
  sort_by?: InputMaybe<TeacherBenchmarkStudentListSortType>;
};

export enum TeacherBenchmarkStudentListSortType {
  StudentFullName = "student_full_name",
}

export type TeacherBenchmarkSubmissionListNode = {
  __typename?: "TeacherBenchmarkSubmissionListNode";
  benchmark_id: Scalars["String"];
  benchmark_image_title: Scalars["String"];
  benchmark_image_url: Scalars["String"];
  benchmark_thumbnail_image_url?: Maybe<Scalars["String"]>;
  benchmark_title: Scalars["String"];
  benchmark_type: Scalars["String"];
  graded_by: BenchmarkGradedBy;
  id: Scalars["String"];
  status: Scalars["String"];
  student_school_id: Scalars["String"];
  student_school_title: Scalars["String"];
  submitted_at: Scalars["Time"];
  user_first_name?: Maybe<Scalars["String"]>;
  user_last_name?: Maybe<Scalars["String"]>;
};

export type TeacherBenchmarkSubmissionListResult = {
  __typename?: "TeacherBenchmarkSubmissionListResult";
  node_count?: Maybe<Scalars["Int"]>;
  nodes?: Maybe<Array<TeacherBenchmarkSubmissionListNode>>;
};

export enum TeacherBenchmarkSubmissionListSortType {
  BenchmarkTitle = "benchmark_title",
  BenchmarkType = "benchmark_type",
  DistrictTitle = "district_title",
  SchoolTitle = "school_title",
  StudentTitle = "student_title",
  SubmissionStatus = "submission_status",
  SubmittedAt = "submitted_at",
}

export enum TeacherBenchmarkSubmissionStatus {
  AnnotationCompleted = "AnnotationCompleted",
  Graded = "Graded",
  InProgress = "InProgress",
  NotStarted = "NotStarted",
  Reviewed = "Reviewed",
  SpeakingCompleted = "SpeakingCompleted",
  Started = "Started",
  Submitted = "Submitted",
}

export type TeacherBenchmarksCsvInput = {
  __typename?: "TeacherBenchmarksCSVInput";
  benchmark_types: Array<BenchmarkType>;
  school_ids: Array<Scalars["String"]>;
};

export type TeacherConnection = {
  __typename?: "TeacherConnection";
  nodes: Array<TeacherList>;
  totalCount: Scalars["Int"];
};

export type TeacherInfo = {
  __typename?: "TeacherInfo";
  email?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  primary_teacher?: Maybe<Scalars["Boolean"]>;
  source: Scalars["String"];
  teacher_name?: Maybe<Scalars["String"]>;
};

export type TeacherInfoV2 = {
  __typename?: "TeacherInfoV2";
  email: Scalars["String"];
  first_name: Scalars["String"];
  id: Scalars["ID"];
  last_name: Scalars["String"];
  primary_teacher: Scalars["Boolean"];
};

export type TeacherList = {
  __typename?: "TeacherList";
  district: Scalars["String"];
  district_id: Scalars["String"];
  first_name: Scalars["String"];
  id: Scalars["String"];
  job_title: Scalars["String"];
  last_name: Scalars["String"];
  middle_name: Scalars["String"];
  school: Array<Maybe<SchoolInfo>>;
  source: Scalars["String"];
  teacher_id: Scalars["String"];
  title: Scalars["String"];
};

export type TeacherListForAdminBenchmarkInput = {
  district_id: Scalars["String"];
  grade_ids: Array<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  page?: InputMaybe<Scalars["Int"]>;
  school_ids: Array<Scalars["String"]>;
};

export type TeacherResourcesCount = {
  __typename?: "TeacherResourcesCount";
  class_count: Scalars["Int"];
  student_count: Scalars["Int"];
};

export enum TeacherStudentGradesSortType {
  GradePosition = "GradePosition",
}

export type TeacherSubmissionListInput = {
  benchmark_types?: InputMaybe<Array<BenchmarkType>>;
  graded_by?: InputMaybe<Array<BenchmarkGradedBy>>;
  limit?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<OrderBy>;
  page?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<TeacherBenchmarkSubmissionListSortType>;
  submission_statuses?: InputMaybe<Array<SubmissionStatus>>;
};

export type TeachersFilter = {
  __typename?: "TeachersFilter";
  first_name: Scalars["String"];
  id: Scalars["String"];
  last_name: Scalars["String"];
  middle_name: Scalars["String"];
};

export enum TeachersListSortType {
  District = "district",
  JobTitle = "job_title",
  Name = "name",
  School = "school",
  Source = "source",
  TeacherId = "teacher_id",
}

export type TotalBenchmarkAssignToInput = {
  district_ids: Array<Scalars["String"]>;
  grade_ids?: InputMaybe<Array<Scalars["String"]>>;
  school_ids?: InputMaybe<Array<Scalars["String"]>>;
};

export type Typeform = {
  __typename?: "Typeform";
  id: Scalars["ID"];
  show_typeform: Scalars["Boolean"];
  typeform_form_id: Scalars["String"];
  typeform_name: Scalars["String"];
};

export type TypeformUserMapping = {
  submitted?: InputMaybe<Scalars["Boolean"]>;
  typeform_id: Scalars["String"];
};

export type UpdateAdminFileInput = {
  thumbnail_file_key?: InputMaybe<Scalars["String"]>;
  thumbnail_file_type?: InputMaybe<Scalars["String"]>;
  thumbnail_url?: InputMaybe<Scalars["String"]>;
};

export type UpdateAdminResourceInput = {
  file?: InputMaybe<UpdateAdminFileInput>;
  grades: Array<Scalars["String"]>;
  id: Scalars["ID"];
  keywords: Array<Scalars["ID"]>;
  resourceType: StaticResourceType;
  subject_areas: Array<Scalars["ID"]>;
  task_types: Array<Scalars["ID"]>;
  thumbnail_height?: InputMaybe<Scalars["Int"]>;
  thumbnail_width?: InputMaybe<Scalars["Int"]>;
  title: Scalars["String"];
};

export type UpdateGoalStatusInput = {
  goal_id: Scalars["ID"];
  status: GoalStatus;
};

export type UpdateScoreInput = {
  grade_id: Scalars["String"];
  score_detail_id: Scalars["String"];
  submission_id?: InputMaybe<Scalars["ID"]>;
};

export type UpdateSpeakingInput = {
  fileName: Scalars["String"];
  fileType: Scalars["String"];
  submission_id: Scalars["String"];
  url: Scalars["String"];
};

export type UpdateStudentInput = {
  demographic_info?: InputMaybe<Scalars["String"]>;
  district_id: Scalars["String"];
  email?: InputMaybe<Scalars["String"]>;
  first_name: Scalars["String"];
  heritage_language?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  language_class?: InputMaybe<Scalars["String"]>;
  language_level?: InputMaybe<Scalars["String"]>;
  last_name: Scalars["String"];
  middle_name?: InputMaybe<Scalars["String"]>;
  password?: InputMaybe<Scalars["String"]>;
  predefined_grade_id: Scalars["String"];
  school_id: Scalars["String"];
  section_ids: Array<Scalars["String"]>;
  student_id: Scalars["String"];
};

export type UpdateTeacherInput = {
  class_types?: InputMaybe<Array<SectionCategory>>;
  district_id: Scalars["String"];
  email: Scalars["String"];
  first_name: Scalars["String"];
  id?: InputMaybe<Scalars["String"]>;
  job_title: Scalars["String"];
  last_name: Scalars["String"];
  middle_name?: InputMaybe<Scalars["String"]>;
  phone_number?: InputMaybe<Scalars["String"]>;
  schools: Array<SchoolSectionListInput>;
  state_id?: InputMaybe<Scalars["String"]>;
  teacher_id: Scalars["String"];
  title?: InputMaybe<Scalars["String"]>;
  zone_id?: InputMaybe<Scalars["String"]>;
};

export type User = {
  __typename?: "User";
  admin?: Maybe<UserAdmin>;
  email: Scalars["String"];
  first_name: Scalars["String"];
  id: Scalars["ID"];
  last_name: Scalars["String"];
  middle_name: Scalars["String"];
  permissions: Array<Permission>;
  role: UserRole;
  score_scheme?: Maybe<ScoreScheme>;
  showHelpVideo: Scalars["Boolean"];
  source: Scalars["String"];
  student?: Maybe<Student>;
};

export type UserAdmin = {
  __typename?: "UserAdmin";
  admin_id: Scalars["String"];
  district?: Maybe<AdminDistrict>;
  id: Scalars["ID"];
  job_title: Scalars["String"];
  school?: Maybe<AdminSchool>;
  user?: Maybe<User>;
};

export type UserDto = {
  __typename?: "UserDTO";
  first_name?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  last_name?: Maybe<Scalars["String"]>;
};

export type UserGoal = {
  __typename?: "UserGoal";
  speaking: Array<Goal>;
  writing: Array<Goal>;
};

export enum UserPermissions {
  AddAssignments = "add_assignments",
  AddClasses = "add_classes",
  AddDeleteJobs = "add_delete_jobs",
  AddDistrictAdmins = "add_district_admins",
  AddDistricts = "add_districts",
  AddGoals = "add_goals",
  AddKeyword = "add_keyword",
  AddPredefinedGoals = "add_predefined_goals",
  AddSchoolAdmins = "add_school_admins",
  AddSchools = "add_schools",
  AddStaticResource = "add_static_resource",
  AddStudents = "add_students",
  AddSuperAdmin = "add_super_admin",
  AddTeachers = "add_teachers",
  AnchorToggle = "anchor_toggle",
  ArchiveStaticResource = "archive_static_resource",
  CreateBenchmark = "create_benchmark",
  CreateBenchmarkStaticResources = "create_benchmark_static_resources",
  CreateSubmissions = "create_submissions",
  CustomClassAllocation = "custom_class_allocation",
  CustomClassDeleteSubmissions = "custom_class_delete_submissions",
  DeleteAssignments = "delete_assignments",
  DeleteBenchmark = "delete_benchmark",
  DeleteBenchmarkStaticResources = "delete_benchmark_static_resources",
  DeleteDeleteJobs = "delete_delete_jobs",
  DeleteGoals = "delete_goals",
  DeletePredefinedGoals = "delete_predefined_goals",
  DeletePurgeDistrict = "delete_purge_district",
  DeleteStaticResource = "delete_static_resource",
  DeleteSuperAdmin = "delete_super_admin",
  DistrictAdminDashboard = "district_admin_dashboard",
  DistrictWideDashboard = "district_wide_dashboard",
  EditAssignments = "edit_assignments",
  EditBenchmark = "edit_benchmark",
  EditBenchmarkStaticResources = "edit_benchmark_static_resources",
  EditClasses = "edit_classes",
  EditDistrictAdmins = "edit_district_admins",
  EditDistricts = "edit_districts",
  EditSchoolAdmins = "edit_school_admins",
  EditSchools = "edit_schools",
  EditStaticResource = "edit_static_resource",
  EditStudents = "edit_students",
  EditTeachers = "edit_teachers",
  EnrollStudentsInCustomClass = "enroll_students_in_custom_class",
  GetBenchmarkStaticResources = "get_benchmark_static_resources",
  GetBenchmarkTypes = "get_benchmark_types",
  GetBenchmarks = "get_benchmarks",
  GetStaticResource = "get_static_resource",
  GetSubjectArea = "get_subject_area",
  GradeBenchmark = "grade_benchmark",
  GroupDeleteSubmissions = "group_delete_submissions",
  ImitateUser = "imitate_user",
  LearningGoalsDashboard = "learning_goals_dashboard",
  ListAssignments = "list_assignments",
  ListAssignmentsStudent = "list_assignments_student",
  ListBenchmarkSubmissions = "list_benchmark_submissions",
  ListClasses = "list_classes",
  ListDeleteJobs = "list_delete_jobs",
  ListDistrictAdmins = "list_district_admins",
  ListDistricts = "list_districts",
  ListMySections = "list_my_sections",
  ListMyTeachers = "list_my_teachers",
  ListSchoolAdmins = "list_school_admins",
  ListSchools = "list_schools",
  ListStudents = "list_students",
  ListSuperAdmins = "list_super_admins",
  ListTeacherBenchmark = "list_teacher_benchmark",
  ListTeachers = "list_teachers",
  MyDistrict = "my_district",
  MySchool = "my_school",
  PerformanceDashboard = "performance_dashboard",
  RemoveDistrictData = "remove_district_data",
  SchoolAdminDashboard = "school_admin_dashboard",
  ScoreGoals = "score_goals",
  ScoreSubmission = "score_submission",
  SearchKeyword = "search_keyword",
  SearchTaskTypes = "search_task_types",
  StartSync = "start_sync",
  StudentDashboard = "student_dashboard",
  StudentGroupAllocation = "student_group_allocation",
  StudentGroups = "student_groups",
  SuperAdminDashboard = "super_admin_dashboard",
  SyncSchoolStudents = "sync_school_students",
  TeacherBenchmarkCsvDownload = "teacher_benchmark_csv_download",
  TeacherBenchmarkDashboardData = "teacher_benchmark_dashboard_data",
  TeacherBenchmarkGradeList = "teacher_benchmark_grade_list",
  TeacherBenchmarkIndicatorScores = "teacher_benchmark_indicator_scores",
  TeacherBenchmarkOverallPerformanceLevel = "teacher_benchmark_overall_performance_level",
  TeacherBenchmarkProgressScore = "teacher_benchmark_progress_score",
  TeacherBenchmarkProgressScores = "teacher_benchmark_progress_scores",
  TeacherBenchmarkStudentList = "teacher_benchmark_student_list",
  TeacherDashboard = "teacher_dashboard",
  UploadImage = "upload_image",
  ViewAllSubmission = "view_all_submission",
  ViewAllSubmissionStudent = "view_all_submission_student",
  ViewAnalytics = "view_analytics",
  ViewAssignments = "view_assignments",
  ViewAssignmentsStudent = "view_assignments_student",
  ViewClasses = "view_classes",
  ViewDeleteJobs = "view_delete_jobs",
  ViewDistrictAdmins = "view_district_admins",
  ViewDistricts = "view_districts",
  ViewGoals = "view_goals",
  ViewGoalsStudent = "view_goals_student",
  ViewPredefinedGoals = "view_predefined_goals",
  ViewSchoolAdmins = "view_school_admins",
  ViewSchools = "view_schools",
  ViewStudents = "view_students",
  ViewSubmission = "view_submission",
  ViewSuperAdmin = "view_super_admin",
  ViewTeachers = "view_teachers",
}

export enum UserRole {
  DistrictAdmin = "district_admin",
  SchoolAdmin = "school_admin",
  Student = "student",
  SuperAdmin = "super_admin",
  Teacher = "teacher",
}

export type WritingActiveGoals = {
  __typename?: "WritingActiveGoals";
  description_and_explanation: GoalData;
  points_and_reasons: GoalData;
  vocab_and_grammar: GoalData;
};

export type Zone = {
  __typename?: "Zone";
  district_id: Scalars["String"];
  id: Scalars["ID"];
  zone: Scalars["String"];
  zone_id: Scalars["String"];
};

export type AddKeywordsMutationVariables = Exact<{
  search: Array<Scalars["String"]> | Scalars["String"];
}>;

export type AddKeywordsMutation = { __typename?: "Mutation"; addKeyword: Array<string> };

export type GetKeywordsQueryVariables = Exact<{ [key: string]: never }>;

export type GetKeywordsQuery = {
  __typename?: "Query";
  keyword?: Array<{ __typename?: "Keyword"; id?: string | null; keyword?: string | null }> | null;
};

export type AssignmentFilterFragmentFragment = {
  __typename?: "Assignment";
  id: string;
  title: string;
  section?: { __typename?: "Section"; id: string; name: string } | null;
};

export type AssignmentsFilterQueryVariables = Exact<{
  sectionId?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<Scalars["String"]>;
  orderBy?: InputMaybe<OrderBy>;
  schoolId?: InputMaybe<Scalars["String"]>;
}>;

export type AssignmentsFilterQuery = {
  __typename?: "Query";
  getAllAssignmentsForTeacher: {
    __typename?: "AssignmentConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "Assignment";
      id: string;
      title: string;
      section?: { __typename?: "Section"; id: string; name: string } | null;
    }>;
  };
};

export type StudentAssignmentsFilterQueryVariables = Exact<{
  studentId: Scalars["String"];
  sectionId?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<Scalars["String"]>;
  orderBy?: InputMaybe<OrderBy>;
}>;

export type StudentAssignmentsFilterQuery = {
  __typename?: "Query";
  getAllAssignmentsForStudentByID: {
    __typename?: "AssignmentConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "Assignment";
      id: string;
      title: string;
      section?: { __typename?: "Section"; id: string; name: string } | null;
    }>;
  };
};

export type ClassFilterFragmentFragment = {
  __typename?: "SectionWSchoolDTO";
  id: string;
  name: string;
  school_id: string;
  school_name: string;
};

export type ClassesFilterQueryVariables = Exact<{
  stateID?: InputMaybe<Scalars["String"]>;
  districtID?: InputMaybe<Scalars["String"]>;
  schoolIDs?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  teacherID?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  classTypes?: InputMaybe<Array<SectionCategory> | SectionCategory>;
}>;

export type ClassesFilterQuery = {
  __typename?: "Query";
  sectionsFilterList?: Array<{
    __typename?: "SectionWSchoolDTO";
    id: string;
    name: string;
    school_id: string;
    school_name: string;
  }> | null;
};

export type CleanupTablesMutationVariables = Exact<{ [key: string]: never }>;

export type CleanupTablesMutation = { __typename?: "Mutation"; cleanupTables: boolean };

export type DistrictFilterFragmentFragment = {
  __typename?: "DistrictDetails";
  id: string;
  name: string;
  district_id: string;
  state_id?: string | null;
  source?: string | null;
};

export type DistrictsFilterQueryVariables = Exact<{
  stateIDs?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  source?: InputMaybe<Array<InputMaybe<Scalars["String"]>> | InputMaybe<Scalars["String"]>>;
  excludeDeleted?: InputMaybe<Scalars["Boolean"]>;
}>;

export type DistrictsFilterQuery = {
  __typename?: "Query";
  districtList: {
    __typename?: "DistrictConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "DistrictDetails";
      id: string;
      name: string;
      district_id: string;
      state_id?: string | null;
      source?: string | null;
    }>;
  };
};

export type TeacherListForAdminBenchmarkQueryVariables = Exact<{
  input: TeacherListForAdminBenchmarkInput;
}>;

export type TeacherListForAdminBenchmarkQuery = {
  __typename?: "Query";
  teacherListForAdminBenchmark: {
    __typename?: "TeacherConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "TeacherList";
      id: string;
      first_name: string;
      middle_name: string;
      last_name: string;
    }>;
  };
};

export type TeacherStudentGradesFilterQueryVariables = Exact<{
  sortBy?: InputMaybe<TeacherStudentGradesSortType>;
  orderBy?: InputMaybe<OrderBy>;
}>;

export type TeacherStudentGradesFilterQuery = {
  __typename?: "Query";
  teacherStudentGradesFilter: Array<{ __typename?: "GradeDTO"; id: string; grade: string } | null>;
};

export type MarkHelpVideoSeenMutationVariables = Exact<{ [key: string]: never }>;

export type MarkHelpVideoSeenMutation = {
  __typename?: "Mutation";
  markHelpVideoSeen?: {
    __typename?: "User";
    id: string;
    first_name: string;
    last_name: string;
    email: string;
    role: UserRole;
    showHelpVideo: boolean;
  } | null;
};

export type DistrictFragmentDataFragment = { __typename?: "DistrictData"; id: string; state_id?: string | null };

export type SchoolFilterFragmentFragment = {
  __typename?: "SchoolDetails";
  id: string;
  name: string;
  district_id: string;
  district_state_id?: string | null;
  school_predefined_grade_mappings?: Array<{
    __typename?: "SchoolPredefinedGradeMappings";
    id: string;
    predefined_grade_id: string;
  } | null> | null;
};

export type SchoolsFilterQueryVariables = Exact<{
  stateIDs?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  districtIDs?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  gradeIDs?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
}>;

export type SchoolsFilterQuery = {
  __typename?: "Query";
  schoolsFilterList: Array<{
    __typename?: "SchoolDetails";
    id: string;
    name: string;
    district_id: string;
    district_state_id?: string | null;
    school_predefined_grade_mappings?: Array<{
      __typename?: "SchoolPredefinedGradeMappings";
      id: string;
      predefined_grade_id: string;
    } | null> | null;
  }>;
};

export type TeacherSchoolFilterQueryVariables = Exact<{ [key: string]: never }>;

export type TeacherSchoolFilterQuery = {
  __typename?: "Query";
  teacherSchools: Array<{ __typename?: "School"; id: string; name: string; district_id: string } | null>;
};

export type SectionFilterFragmentFragment = {
  __typename?: "Section";
  id: string;
  course: string;
  name: string;
  subject: string;
};

export type TeacherInfoFragmentFragment = {
  __typename?: "TeacherInfo";
  teacher_name?: string | null;
  email?: string | null;
  primary_teacher?: boolean | null;
  id: string;
};

export type SectionsFilterQueryVariables = Exact<{
  studentID?: InputMaybe<Scalars["String"]>;
  hideGroups?: InputMaybe<Scalars["Boolean"]>;
  schoolIDs?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
}>;

export type SectionsFilterQuery = {
  __typename?: "Query";
  sections: Array<{ __typename?: "Section"; id: string; course: string; name: string; subject: string }>;
};

export type SectionTeachersFilterQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type SectionTeachersFilterQuery = {
  __typename?: "Query";
  sectionTeachers: Array<{
    __typename?: "TeacherInfo";
    teacher_name?: string | null;
    email?: string | null;
    primary_teacher?: boolean | null;
    id: string;
  }>;
};

export type AdminDashboardSectionFragmentFragment = {
  __typename?: "SectionForAdminDashboardFilterNode";
  id: string;
  name: string;
  school_id: string;
  teachers?: Array<{ __typename?: "UserDTO"; id: string }> | null;
  gradesInfo?: Array<{ __typename?: "GradeDTO"; id: string; grade: string; position: number }> | null;
};

export type AdminDashboardSectionFilterListQueryVariables = Exact<{
  stateID?: InputMaybe<Scalars["String"]>;
  districtID?: InputMaybe<Scalars["String"]>;
  schoolID?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  teacherID?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  classTypes?: InputMaybe<Array<SectionCategory> | SectionCategory>;
}>;

export type AdminDashboardSectionFilterListQuery = {
  __typename?: "Query";
  adminDashboardSectionFilterList?: Array<{
    __typename?: "SectionForAdminDashboardFilterNode";
    id: string;
    name: string;
    school_id: string;
    teachers?: Array<{ __typename?: "UserDTO"; id: string }> | null;
    gradesInfo?: Array<{ __typename?: "GradeDTO"; id: string; grade: string; position: number }> | null;
  }> | null;
};

export type SectionsOfStudentFilterQueryVariables = Exact<{ [key: string]: never }>;

export type SectionsOfStudentFilterQuery = {
  __typename?: "Query";
  sectionsOfStudent: Array<{ __typename?: "Section"; id: string; course: string; name: string; subject: string }>;
};

export type GetAllPredefinedGradesQueryVariables = Exact<{
  districtID?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  schoolIDs?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  sort?: InputMaybe<GradeSortType>;
  order?: InputMaybe<OrderBy>;
}>;

export type GetAllPredefinedGradesQuery = {
  __typename?: "Query";
  allPredefinedGrades: Array<{ __typename?: "PredefinedGrades"; id: string; grade: string; position: number }>;
};

export type SubmissionCountQueryVariables = Exact<{
  search?: InputMaybe<Scalars["String"]>;
  studentID?: InputMaybe<Scalars["String"]>;
  sectionID?: InputMaybe<Scalars["String"]>;
  assignmentID?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Array<InputMaybe<SubmissionStatus>> | InputMaybe<SubmissionStatus>>;
}>;

export type SubmissionCountQuery = { __typename?: "Query"; submissionsCount: number };

export type BenchmarkGradesCountQueryVariables = Exact<{
  gradeStatus?: InputMaybe<Array<SubmissionStatus> | SubmissionStatus>;
}>;

export type BenchmarkGradesCountQuery = { __typename?: "Query"; adminBenchmarkSubmissionsCount: number };

export type SectionCountQueryVariables = Exact<{
  stateId?: InputMaybe<Scalars["String"]>;
  districtId?: InputMaybe<Scalars["String"]>;
  schoolId?: InputMaybe<Scalars["String"]>;
  teacherId?: InputMaybe<Scalars["String"]>;
  studentId?: InputMaybe<Scalars["String"]>;
  classTypes?: InputMaybe<Array<SectionCategory> | SectionCategory>;
}>;

export type SectionCountQuery = { __typename?: "Query"; sectionCount: number };

export type StateFilterFragmentFragment = { __typename?: "State"; id: string; state_id: string; name: string };

export type StatesFilterQueryVariables = Exact<{ [key: string]: never }>;

export type StatesFilterQuery = {
  __typename?: "Query";
  states: Array<{ __typename?: "State"; id: string; state_id: string; name: string }>;
};

export type StudentFilterFragmentFragment = {
  __typename?: "Student";
  id: string;
  student_number: string;
  user?: { __typename?: "User"; first_name: string; last_name: string } | null;
};

export type StudentsFilterQueryVariables = Exact<{ [key: string]: never }>;

export type StudentsFilterQuery = {
  __typename?: "Query";
  students: {
    __typename?: "StudentConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "Student";
      id: string;
      student_number: string;
      user?: { __typename?: "User"; first_name: string; last_name: string } | null;
    }>;
  };
};

export type SubmissionFilterFragment = {
  __typename?: "SubmissionFilterData";
  submission_id: string;
  title: string;
  benchmark_id?: string | null;
  assignment_id?: string | null;
};

export type MySubmissionsFilterQueryVariables = Exact<{
  status?: InputMaybe<Array<InputMaybe<SubmissionStatus>> | InputMaybe<SubmissionStatus>>;
}>;

export type MySubmissionsFilterQuery = {
  __typename?: "Query";
  allSubmissionsOfStudent?: Array<{
    __typename?: "SubmissionFilterData";
    submission_id: string;
    title: string;
    benchmark_id?: string | null;
    assignment_id?: string | null;
  }> | null;
};

export type TaskTypesFilterQueryVariables = Exact<{ [key: string]: never }>;

export type TaskTypesFilterQuery = {
  __typename?: "Query";
  taskType?: Array<{
    __typename?: "TaskType";
    id?: string | null;
    category?: string | null;
    title?: string | null;
  }> | null;
};

export type TeacherFilterFragmentFragment = {
  __typename?: "TeacherList";
  id: string;
  first_name: string;
  last_name: string;
  school: Array<{ __typename?: "SchoolInfo"; id: string } | null>;
};

export type TeachersFilterQueryVariables = Exact<{
  stateID?: InputMaybe<Scalars["String"]>;
  districtID?: InputMaybe<Scalars["String"]>;
  schoolID?: InputMaybe<Scalars["String"]>;
}>;

export type TeachersFilterQuery = {
  __typename?: "Query";
  teacherList: {
    __typename?: "TeacherConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "TeacherList";
      id: string;
      first_name: string;
      last_name: string;
      school: Array<{ __typename?: "SchoolInfo"; id: string } | null>;
    }>;
  };
};

export type TeacherListForTeacherSchoolQueryVariables = Exact<{
  schoolID?: InputMaybe<Scalars["String"]>;
}>;

export type TeacherListForTeacherSchoolQuery = {
  __typename?: "Query";
  teacherListForTeacherSchool: {
    __typename?: "TeacherConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "TeacherList";
      id: string;
      first_name: string;
      last_name: string;
      school: Array<{ __typename?: "SchoolInfo"; id: string } | null>;
    }>;
  };
};

export type TeacherOfStudentFilterFragmentFragment = {
  __typename?: "TeachersFilter";
  id: string;
  first_name: string;
  last_name: string;
  middle_name: string;
};

export type TeachersOfstudentFilterQueryVariables = Exact<{ [key: string]: never }>;

export type TeachersOfstudentFilterQuery = {
  __typename?: "Query";
  teachersOfStudent: Array<{
    __typename?: "TeachersFilter";
    id: string;
    first_name: string;
    last_name: string;
    middle_name: string;
  }>;
};

export type AnchorToggleStatusQueryVariables = Exact<{ [key: string]: never }>;

export type AnchorToggleStatusQuery = { __typename?: "Query"; anchorToggle: boolean };

export type AnchorToggleUpdateMutationVariables = Exact<{
  showToggle: Scalars["Boolean"];
}>;

export type AnchorToggleUpdateMutation = { __typename?: "Mutation"; anchorToggle: string };

export type RubricsQueryVariables = Exact<{ [key: string]: never }>;

export type RubricsQuery = {
  __typename?: "Query";
  getRubrics?: Array<{
    __typename?: "Rubric";
    id: string;
    title: string;
    content: string;
    submission_type: string;
  }> | null;
};

export type AdminFragmentFragment = {
  __typename?: "Admin";
  id: string;
  district_id: string;
  school_id?: string | null;
  first_name: string;
  middle_name?: string | null;
  last_name: string;
  admin_id: string;
  title: string;
  job_title: string;
  email: string;
  phone_number?: string | null;
  schoolData: Array<{ __typename?: "School"; id: string } | null>;
  districtData?: { __typename?: "DistrictData"; id: string; state_id?: string | null } | null;
};

export type SchoolAdminEditDetailsQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type SchoolAdminEditDetailsQuery = {
  __typename?: "Query";
  schoolAdmin: {
    __typename?: "Admin";
    id: string;
    district_id: string;
    school_id?: string | null;
    first_name: string;
    middle_name?: string | null;
    last_name: string;
    admin_id: string;
    title: string;
    job_title: string;
    email: string;
    phone_number?: string | null;
    schoolData: Array<{ __typename?: "School"; id: string } | null>;
    districtData?: { __typename?: "DistrictData"; id: string; state_id?: string | null } | null;
  };
};

export type DistrictAdminEditDetailsQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DistrictAdminEditDetailsQuery = {
  __typename?: "Query";
  districtAdmin: {
    __typename?: "Admin";
    id: string;
    district_id: string;
    school_id?: string | null;
    first_name: string;
    middle_name?: string | null;
    last_name: string;
    admin_id: string;
    title: string;
    job_title: string;
    email: string;
    phone_number?: string | null;
    schoolData: Array<{ __typename?: "School"; id: string } | null>;
    districtData?: { __typename?: "DistrictData"; id: string; state_id?: string | null } | null;
  };
};

export type DistrictAdminFormMutationVariables = Exact<{
  input: AdminInput;
}>;

export type DistrictAdminFormMutation = { __typename?: "Mutation"; upsertDistrictAdmin: string };

export type SchoolAdminFormMutationVariables = Exact<{
  input: AdminInput;
}>;

export type SchoolAdminFormMutation = { __typename?: "Mutation"; upsertSchoolAdmin: string };

export type GetAcademicSessionQueryVariables = Exact<{ [key: string]: never }>;

export type GetAcademicSessionQuery = {
  __typename?: "Query";
  getAcademicSession: {
    __typename?: "AcademicSession";
    id: string;
    session_start_date: string;
    session_end_date?: string | null;
  };
};

export type DistrictWideSchoolDataQueryVariables = Exact<{
  district_id?: InputMaybe<Scalars["String"]>;
  school_id?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  page?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<DistrictWideSchoolDetailsSortType>;
  orderBy?: InputMaybe<OrderBy>;
}>;

export type DistrictWideSchoolDataQuery = {
  __typename?: "Query";
  districtWideSchoolData: {
    __typename?: "DistrictWideSchoolNode";
    districtWideSchoolDetailsCount: number;
    districtWideSchoolDetails: Array<{
      __typename?: "DistrictWideSchoolDetails";
      school_id: string;
      school_name: string;
      teachers: number;
      students: number;
      goals_assigned: number;
      pts_assigned: number;
      pts_completed: number;
      avg_speaking_score: number;
      avg_writing_score: number;
    }>;
  };
};

export type DistrictWideSchoolSpecificDataQueryVariables = Exact<{
  school_id: Scalars["String"];
  limit?: InputMaybe<Scalars["Int"]>;
  page?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<DistrictWideSchoolSpecificDetailsSortType>;
  orderBy?: InputMaybe<OrderBy>;
}>;

export type DistrictWideSchoolSpecificDataQuery = {
  __typename?: "Query";
  districtWideSchoolSpecificData: {
    __typename?: "DistrictWideSchoolSpecificDetailsNode";
    DistrictWideSchoolSpecificDetailsCount: number;
    districtWideSchoolSpecificDetails: Array<{
      __typename?: "DistrictWideSchoolSpecificDetails";
      teacher: string;
      grade: string;
      grade_position: number;
      section: string;
      avg_speaking_score: number;
      avg_writing_score: number;
    }>;
  };
};

export type DistrictWideGradeDataQueryVariables = Exact<{
  input?: InputMaybe<DistrictWideGradeInput>;
}>;

export type DistrictWideGradeDataQuery = {
  __typename?: "Query";
  districtWideGradeInfo?: Array<{
    __typename?: "DistrictWideGradeDetail";
    grade_id: string;
    grade_title: string;
    teacher_count: number;
    student_count: number;
    total_goals_assigned: number;
    total_submissions_count: number;
    total_submissions_completed_count: number;
    speaking_overall_score: number;
    writing_overall_score: number;
  } | null> | null;
};

export type DistrictWideGradeSpecificDataQueryVariables = Exact<{
  district_id?: InputMaybe<Scalars["String"]>;
  school_id?: InputMaybe<Scalars["String"]>;
  grade_id: Scalars["String"];
  limit?: InputMaybe<Scalars["Int"]>;
  page?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<DistrictWideGradeSpecificDetailsSortType>;
  orderBy?: InputMaybe<OrderBy>;
}>;

export type DistrictWideGradeSpecificDataQuery = {
  __typename?: "Query";
  districtWideGradeSpecificData: {
    __typename?: "DistrictWideGradeSpecificDetailsNode";
    districtWideGradeSpecificDetailsCount: number;
    districtWideGradeSpecificDetails: Array<{
      __typename?: "DistrictWideGradeSpecificDetails";
      teacher: string;
      teacher_id: string;
      school: string;
      school_id: string;
      section: string;
      section_id: string;
      avg_speaking_score: number;
      avg_writing_score: number;
    }>;
  };
};

export type CompletedAssignmentsLearningGoalsWidgetQueryVariables = Exact<{
  district_id?: InputMaybe<Scalars["String"]>;
  school_id?: InputMaybe<Scalars["String"]>;
  input?: InputMaybe<AdminDashboardFilterInput>;
}>;

export type CompletedAssignmentsLearningGoalsWidgetQuery = {
  __typename?: "Query";
  learningGoalsCompletedPTsMostRecentGoalsData: Array<{
    __typename?: "LearningGoalsCompletedPTsMostRecentGoalsPerMonth";
    month: number;
    year: string;
    unique_student_completed_pts_count: number;
    most_recent_goals_count: number;
    average_no_of_most_recent_goals_count: number;
  }>;
};

export type GoalsAchievementGraphWidgetQueryVariables = Exact<{
  input: GoalAchievementGraphInput;
}>;

export type GoalsAchievementGraphWidgetQuery = {
  __typename?: "Query";
  goalAchievementGraph?: {
    __typename?: "GoalAchievementGraphResult";
    max_count?: number | null;
    goal_achievement_by_category: Array<{
      __typename?: "GoalAchievementByCategory";
      label: string;
      data: Array<{ __typename?: "GoalAchievementGraphLineItem"; timestamp: number; value: number } | null>;
    } | null>;
  } | null;
};

export type LearningScoresAndGoalsWidgetQueryVariables = Exact<{
  input?: InputMaybe<LearningGoalsScoresByIndicatorInput>;
}>;

export type LearningScoresAndGoalsWidgetQuery = {
  __typename?: "Query";
  learningScoresAndGoalsWidget: Array<{
    __typename?: "LearningGoalsScoresByIndicatorLineItem";
    rubric_title?: string | null;
    goals_count: number;
    average_score: number;
  } | null>;
};

export type LearningGoalsPerformanceWidgetQueryVariables = Exact<{
  district_id?: InputMaybe<Scalars["String"]>;
  school_id?: InputMaybe<Scalars["String"]>;
  input?: InputMaybe<AdminDashboardFilterInput>;
}>;

export type LearningGoalsPerformanceWidgetQuery = {
  __typename?: "Query";
  learningGoalsPerformanceData: Array<{
    __typename?: "LearningGoalsPerformance";
    color: string;
    title: string;
    total_students: number;
    percentage_of_students: number;
  }>;
};

export type LearningGoalsByIndicatorWidgetQueryVariables = Exact<{
  district_id?: InputMaybe<Scalars["String"]>;
  school_id?: InputMaybe<Scalars["String"]>;
  input?: InputMaybe<AdminDashboardFilterInput>;
}>;

export type LearningGoalsByIndicatorWidgetQuery = {
  __typename?: "Query";
  learningGoalsIndicatorData: {
    __typename?: "LearningGoalsIndicatorNode";
    total_goals: number;
    learningGoalsbyIndicator: Array<{
      __typename?: "LearningGoalsByIndicator";
      skill_label: string;
      skill_level: number;
      skill_key: string;
      skill_description: string;
      color: string;
      goals_count: number;
      id: string;
    }>;
  };
};

export type LearningGoalsPerformanceWidgetSlideoutQueryVariables = Exact<{
  input: LearningGoalWidgetSlideOutInput;
}>;

export type LearningGoalsPerformanceWidgetSlideoutQuery = {
  __typename?: "Query";
  learningGoalWidgetSlideOut: {
    __typename?: "LearningGoalWidgetSlideOutResult";
    total_count?: number | null;
    nodes: Array<{
      __typename?: "PerformanceAndLearningGoalWidgetSlideOutLineItem";
      first_name: string;
      last_name: string;
      student_id: string;
      grade: string;
      school_id?: string | null;
      school_name?: string | null;
      section_id: string;
      section_name: string;
      teacher_id: string;
      teacher_first_name: string;
      teacher_last_name: string;
    } | null>;
  };
};

export type OverallPerformanceWidgetQueryVariables = Exact<{
  input?: InputMaybe<OverallPerformanceWidgetInput>;
}>;

export type OverallPerformanceWidgetQuery = {
  __typename?: "Query";
  overallPerformanceInfo?: {
    __typename?: "PerformanceWidgetData";
    current_day_average_score?: number | null;
    historical_score?: Array<{
      __typename?: "PerformanceWidgetScoreLineItem";
      date_timestamp?: number | null;
      average_score?: number | null;
      scored_pt_count?: number | null;
      unique_student_count?: number | null;
    } | null> | null;
  } | null;
};

export type PerformanceWidgetQueryVariables = Exact<{
  input: PerformanceWidgetInput;
}>;

export type PerformanceWidgetQuery = {
  __typename?: "Query";
  performanceWidget?: Array<{
    __typename?: "AdminPerformanceScoreLineItem";
    id: string;
    skill_key: string;
    skill_label: string;
    skill_description: string;
    skill_level: number;
    color: string;
    total_count: number;
  }> | null;
};

export type StudentCompletionRatesWidgetQueryVariables = Exact<{
  district_id?: InputMaybe<Scalars["String"]>;
  school_id?: InputMaybe<Scalars["String"]>;
  input?: InputMaybe<AdminDashboardFilterInput>;
}>;

export type StudentCompletionRatesWidgetQuery = {
  __typename?: "Query";
  performanceWideStudentCompletionRateData: {
    __typename?: "PerformanceWideStudentCompletionRateNode";
    percentage_of_pts_submitted_and_graded_for_ytd: number;
    academic_session_end_date?: string | null;
    academic_session_start_date?: string | null;
    performanceWideStudentCompletionRateBar: Array<{
      __typename?: "PerformanceWideStudentCompletionRateBar";
      month?: number | null;
      pts_assigned: number;
      pts_submitted_and_graded: number;
      year?: string | null;
    }>;
  };
};

export type PerformanceWidgetSlideOutQueryVariables = Exact<{
  input: PerformanceWidgetSlideOutInput;
}>;

export type PerformanceWidgetSlideOutQuery = {
  __typename?: "Query";
  performanceWidgetSlideOut: {
    __typename?: "PerformanceWidgetSlideOutResult";
    total_count?: number | null;
    nodes: Array<{
      __typename?: "PerformanceAndLearningGoalWidgetSlideOutLineItem";
      first_name: string;
      last_name: string;
      student_id: string;
      grade: string;
      school_id?: string | null;
      school_name?: string | null;
      section_id: string;
      section_name: string;
      teacher_id: string;
      teacher_first_name: string;
      teacher_last_name: string;
    } | null>;
  };
};

export type TasksAssignedAndCompletedWidgetQueryVariables = Exact<{
  district_id?: InputMaybe<Scalars["String"]>;
  school_id?: InputMaybe<Scalars["String"]>;
  input?: InputMaybe<AdminDashboardFilterInput>;
}>;

export type TasksAssignedAndCompletedWidgetQuery = {
  __typename?: "Query";
  performanceWideTaskAssignedAndCompletedData: {
    __typename?: "PerformanceWideTaskAssignedAndCompleted";
    count_of_tasks_assigned: number;
    count_of_students: number;
    average_tasks_assigned_per_teacher: number;
    tasks_completed_per_student: number;
  };
};

export type GetLastSnowflakeUpdateTimeQueryVariables = Exact<{ [key: string]: never }>;

export type GetLastSnowflakeUpdateTimeQuery = { __typename?: "Query"; getLastSnowflakeUpdateTime: string };

export type GetTotalBenchmarkStudentsQueryVariables = Exact<{
  districtIds: Array<Scalars["String"]> | Scalars["String"];
  gradeIds?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  schoolIds?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
}>;

export type GetTotalBenchmarkStudentsQuery = { __typename?: "Query"; totalBenchmarkStudentCountByFilters: number };

export type GetBenchmarkStudentsQueryVariables = Exact<{
  districtIds: Array<Scalars["String"]> | Scalars["String"];
  gradeIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>> | InputMaybe<Scalars["String"]>>;
  schoolIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>> | InputMaybe<Scalars["String"]>>;
  page?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<BenchmarkAssignToSortType>;
  orderBy?: InputMaybe<OrderBy>;
}>;

export type GetBenchmarkStudentsQuery = {
  __typename?: "Query";
  benchmarkStudentCountByFilters?: {
    __typename?: "BenchmarkAssignedToCountResult";
    node_count?: number | null;
    nodes?: Array<{
      __typename?: "BenchmarkAssignedToNode";
      district_id: string;
      district_name: string;
      grade_id?: string | null;
      grade_name?: string | null;
      school_id?: string | null;
      school_name?: string | null;
      student_count?: number | null;
    } | null> | null;
  } | null;
};

export type CreateBenchmarkMutationVariables = Exact<{
  input: CreateBenchmarkInput;
}>;

export type CreateBenchmarkMutation = { __typename?: "Mutation"; createBenchmark: string };

export type BenchmarkDetailQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type BenchmarkDetailQuery = {
  __typename?: "Query";
  benchmark: {
    __typename?: "BenchmarkPT";
    id: string;
    title: string;
    start_at: string;
    close_at: string;
    graded_by: BenchmarkGradedBy;
    benchmark_type: BenchmarkType;
    back_navigation_allowed: boolean;
    district_names: Array<string>;
    grade_titles?: Array<string> | null;
    school_names?: Array<string> | null;
    is_submissions_started: boolean;
  };
};

export type BenchmarkEditFormMutationVariables = Exact<{
  input: EditBenchmarkInput;
}>;

export type BenchmarkEditFormMutation = { __typename?: "Mutation"; editBenchmark: string };

export type BenchmarkGradesListQueryVariables = Exact<{
  state_ids?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  district_ids?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  school_ids?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  predefined_grade_ids?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  grade_statuses?: InputMaybe<Array<SubmissionStatus> | SubmissionStatus>;
  benchmark_types?: InputMaybe<Array<BenchmarkType> | BenchmarkType>;
  graded_by?: InputMaybe<Array<BenchmarkGradedBy> | BenchmarkGradedBy>;
  search?: InputMaybe<Scalars["String"]>;
  page?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<BenchmarkSubmissionListSortType>;
  order_by?: InputMaybe<OrderBy>;
}>;

export type BenchmarkGradesListQuery = {
  __typename?: "Query";
  adminBenchmarkSubmissionList: {
    __typename?: "BenchmarkSubmissionListResult";
    node_count: number;
    nodes: Array<{
      __typename?: "BenchmarkSubmissionListNode";
      id: string;
      status: string;
      student_id: string;
      submitted_at?: string | null;
      user_first_name?: string | null;
      user_last_name?: string | null;
      student_grade_id: string;
      student_grade_title: string;
      student_district_id: string;
      student_district_title: string;
      graded_by: BenchmarkGradedBy;
      benchmark_id: string;
      benchmark_title: string;
      benchmark_image_url: string;
      benchmark_image_title: string;
      benchmark_thumbnail_image_url?: string | null;
    }>;
  };
};

export type AdminBenchmarkGradingQueueQueryVariables = Exact<{
  state_ids?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  district_ids?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  school_ids?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  predefined_grade_ids?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  grade_statuses?: InputMaybe<Array<SubmissionStatus> | SubmissionStatus>;
  benchmark_types?: InputMaybe<Array<BenchmarkType> | BenchmarkType>;
  graded_by?: InputMaybe<Array<BenchmarkGradedBy> | BenchmarkGradedBy>;
  search?: InputMaybe<Scalars["String"]>;
  page?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<BenchmarkSubmissionListSortType>;
  order_by?: InputMaybe<OrderBy>;
}>;

export type AdminBenchmarkGradingQueueQuery = {
  __typename?: "Query";
  adminBenchmarkGradingQueue?: Array<string> | null;
};

export type AdminBmStudentsSlideoutQueryVariables = Exact<{
  benchmarkId: Scalars["String"];
  status?: InputMaybe<Array<SubmissionStatus> | SubmissionStatus>;
}>;

export type AdminBmStudentsSlideoutQuery = {
  __typename?: "Query";
  adminBenchmarkSlideOut: {
    __typename?: "BenchmarkSlideOutResult";
    total_count: number;
    nodes: Array<{
      __typename?: "SlideOutStudents";
      first_name: string;
      last_name: string;
      submission_id?: string | null;
      submission_status: SubmissionStatus;
      student_number: string;
    }>;
  };
};

export type BenchmarksListQueryVariables = Exact<{
  startAt?: InputMaybe<DateQuery>;
  closeAt?: InputMaybe<DateQuery>;
  page?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<BenchmarkSuperAdminDtoSortType>;
  orderBy?: InputMaybe<OrderBy>;
  search?: InputMaybe<Scalars["String"]>;
}>;

export type BenchmarksListQuery = {
  __typename?: "Query";
  getAllBenchmarksForSuperAdmin: {
    __typename?: "BenchmarkSuperAdminDTOConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "BenchmarkSuperAdminDto";
      benchmark_id: string;
      benchmark_title: string;
      start_date: string;
      close_date: string;
      district_id: string;
      district_name: string;
      school_id: string;
      school_name: string;
      grade_title: string;
      status_aggregation: {
        __typename?: "AssignmentSubmissionAggregation";
        NotStarted: number;
        InProgress: number;
        Submitted: number;
      };
      resource: {
        __typename?: "StaticResource";
        id: string;
        file: { __typename?: "File"; url: string; thumbnail_url?: string | null };
      };
    }>;
  };
};

export type DeleteBenchmarkMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type DeleteBenchmarkMutation = { __typename?: "Mutation"; deleteBenchmark?: string | null };

export type DashboardDataQueryVariables = Exact<{ [key: string]: never }>;

export type DashboardDataQuery = {
  __typename?: "Query";
  dashboardData: {
    __typename?: "AdminDashboardData";
    role?: string | null;
    states: number;
    districts: number;
    school_admins: number;
    district_admins: number;
    schools: number;
    students: number;
    teachers: number;
    classes: number;
  };
};

export type AdminStudentDetailsQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type AdminStudentDetailsQuery = {
  __typename?: "Query";
  viewStudent: {
    __typename?: "StudentData";
    id: string;
    user_id: string;
    first_name: string;
    last_name: string;
    email?: string | null;
    student_id: string;
    grade?: string | null;
    school_id: string;
    district_id: string;
    state_id?: string | null;
    school_name: string;
    language_level?: string | null;
    heritage_language?: string | null;
    demographic_info?: string | null;
    source: string;
  };
};

export type StudentEditFormQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type StudentEditFormQuery = {
  __typename?: "Query";
  viewStudent: {
    __typename?: "StudentData";
    id: string;
    first_name: string;
    middle_name: string;
    last_name: string;
    student_id: string;
    email?: string | null;
    grade?: string | null;
    school_id: string;
    school_name: string;
    heritage_language?: string | null;
    language_class?: string | null;
    language_level?: string | null;
    demographic_info?: string | null;
    state_id?: string | null;
    district_id: string;
    source: string;
    sections: Array<{ __typename?: "StudentSectionGrade"; id: string }>;
  };
};

export type CreateStudentFormMutationVariables = Exact<{
  input: CreateStudentInput;
}>;

export type CreateStudentFormMutation = { __typename?: "Mutation"; createStudent?: string | null };

export type UpdateStudentFormMutationVariables = Exact<{
  input: UpdateStudentInput;
}>;

export type UpdateStudentFormMutation = { __typename?: "Mutation"; updateStudent?: string | null };

export type StudentDetailsFragmentFragment = {
  __typename?: "StudentListDTO";
  id: string;
  first_name: string;
  last_name: string;
  student_id: string;
  is_active_submission: boolean;
  grade?: string | null;
  school_id: string;
  school_name: string;
  district_id: string;
  district_name: string;
  source: string;
  source_id: string;
  teachers: Array<{
    __typename?: "TeacherInfo";
    id: string;
    teacher_name?: string | null;
    email?: string | null;
    primary_teacher?: boolean | null;
  }>;
};

export type StudentsListQueryVariables = Exact<{
  stateId?: InputMaybe<Scalars["String"]>;
  districtId?: InputMaybe<Scalars["String"]>;
  schoolId?: InputMaybe<Scalars["String"]>;
  agencyId?: InputMaybe<Scalars["String"]>;
  sectionId?: InputMaybe<Scalars["String"]>;
  teacherId?: InputMaybe<Scalars["String"]>;
  page?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<AdminStudentsListSortType>;
  searchByName?: InputMaybe<Scalars["String"]>;
  orderBy?: InputMaybe<OrderBy>;
}>;

export type StudentsListQuery = {
  __typename?: "Query";
  allStudentsList: {
    __typename?: "AllStudentConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "StudentListDTO";
      id: string;
      first_name: string;
      last_name: string;
      student_id: string;
      is_active_submission: boolean;
      grade?: string | null;
      school_id: string;
      school_name: string;
      district_id: string;
      district_name: string;
      source: string;
      source_id: string;
      teachers: Array<{
        __typename?: "TeacherInfo";
        id: string;
        teacher_name?: string | null;
        email?: string | null;
        primary_teacher?: boolean | null;
      }>;
    }>;
  };
};

export type AdminDetailsFragmentFragment = {
  __typename?: "Admin";
  id: string;
  first_name: string;
  last_name: string;
  admin_id: string;
  email: string;
  user_id: string;
  source: string;
  districtData?: {
    __typename?: "DistrictData";
    source?: string | null;
    name: string;
    id: string;
    state?: { __typename?: "State"; id: string; state_id: string } | null;
  } | null;
  schoolData: Array<{ __typename?: "School"; id: string; name: string } | null>;
};

export type DistrictAdminQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DistrictAdminQuery = {
  __typename?: "Query";
  districtAdmin: {
    __typename?: "Admin";
    id: string;
    first_name: string;
    last_name: string;
    admin_id: string;
    email: string;
    user_id: string;
    source: string;
    districtData?: {
      __typename?: "DistrictData";
      source?: string | null;
      name: string;
      id: string;
      state?: { __typename?: "State"; id: string; state_id: string } | null;
    } | null;
    schoolData: Array<{ __typename?: "School"; id: string; name: string } | null>;
  };
};

export type SchoolAdminQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type SchoolAdminQuery = {
  __typename?: "Query";
  schoolAdmin: {
    __typename?: "Admin";
    id: string;
    first_name: string;
    last_name: string;
    admin_id: string;
    email: string;
    user_id: string;
    source: string;
    districtData?: {
      __typename?: "DistrictData";
      source?: string | null;
      name: string;
      id: string;
      state?: { __typename?: "State"; id: string; state_id: string } | null;
    } | null;
    schoolData: Array<{ __typename?: "School"; id: string; name: string } | null>;
  };
};

export type AdminListFragmentFragment = {
  __typename?: "Admin";
  id: string;
  admin_id: string;
  first_name: string;
  last_name: string;
  job_title: string;
  source: string;
  school?: string | null;
  school_id?: string | null;
  district: string;
  district_id: string;
  created_by_first_name?: string | null;
  created_by_last_name?: string | null;
  districtData?: { __typename?: "DistrictData"; source?: string | null } | null;
};

export type SchoolAdminListQueryVariables = Exact<{
  stateId?: InputMaybe<Scalars["String"]>;
  districtId?: InputMaybe<Scalars["String"]>;
  schoolId?: InputMaybe<Scalars["String"]>;
  page?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<AdminListSortType>;
  searchByName?: InputMaybe<Scalars["String"]>;
  orderBy?: InputMaybe<OrderBy>;
}>;

export type SchoolAdminListQuery = {
  __typename?: "Query";
  schoolAdminList: {
    __typename?: "AdminConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "Admin";
      id: string;
      admin_id: string;
      first_name: string;
      last_name: string;
      job_title: string;
      source: string;
      school?: string | null;
      school_id?: string | null;
      district: string;
      district_id: string;
      created_by_first_name?: string | null;
      created_by_last_name?: string | null;
      districtData?: { __typename?: "DistrictData"; source?: string | null } | null;
    }>;
  };
};

export type DistrictAdminListQueryVariables = Exact<{
  stateId?: InputMaybe<Scalars["String"]>;
  districtId?: InputMaybe<Scalars["String"]>;
  schoolId?: InputMaybe<Scalars["String"]>;
  page?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<AdminListSortType>;
  searchByName?: InputMaybe<Scalars["String"]>;
  orderBy?: InputMaybe<OrderBy>;
}>;

export type DistrictAdminListQuery = {
  __typename?: "Query";
  districtAdminList: {
    __typename?: "AdminConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "Admin";
      id: string;
      admin_id: string;
      first_name: string;
      last_name: string;
      job_title: string;
      source: string;
      school?: string | null;
      school_id?: string | null;
      district: string;
      district_id: string;
      created_by_first_name?: string | null;
      created_by_last_name?: string | null;
      districtData?: { __typename?: "DistrictData"; source?: string | null } | null;
    }>;
  };
};

export type ScoresFragmentFragment = {
  __typename?: "Scores";
  skill_label: string;
  id: string;
  skill_level: number;
  color: string;
  skill_description: string;
  total_count: number;
  skill_key: string;
  students: Array<{
    __typename?: "AnalyticsData";
    student_id: string;
    student_name: string;
    current_goal?: string | null;
    current_goal_skill?: string | null;
    assigned_date?: string | null;
    icon_base64?: string | null;
  }>;
};

export type ScoreSummaryQueryVariables = Exact<{
  assignmentIDs?: InputMaybe<Array<Scalars["ID"]> | Scalars["ID"]>;
  sectionIDs?: InputMaybe<Array<Scalars["ID"]> | Scalars["ID"]>;
  studentIDs?: InputMaybe<Array<Scalars["ID"]> | Scalars["ID"]>;
  schoolIDs?: InputMaybe<Array<Scalars["ID"]> | Scalars["ID"]>;
}>;

export type ScoreSummaryQuery = {
  __typename?: "Query";
  scoreSummary: Array<{
    __typename?: "Summary";
    submission_type: string;
    score_distribution: Array<{
      __typename?: "Scores";
      skill_label: string;
      id: string;
      skill_level: number;
      color: string;
      skill_description: string;
      total_count: number;
      skill_key: string;
      students: Array<{
        __typename?: "AnalyticsData";
        student_id: string;
        student_name: string;
        current_goal?: string | null;
        current_goal_skill?: string | null;
        assigned_date?: string | null;
        icon_base64?: string | null;
      }>;
    }>;
  }>;
};

export type ActiveGoalSummaryQueryVariables = Exact<{
  assignmentIDs?: InputMaybe<Array<Scalars["ID"]> | Scalars["ID"]>;
  sectionIDs?: InputMaybe<Array<Scalars["ID"]> | Scalars["ID"]>;
  schoolID?: InputMaybe<Scalars["ID"]>;
}>;

export type ActiveGoalSummaryQuery = {
  __typename?: "Query";
  activeGoalsSummary: Array<{
    __typename?: "Summary";
    submission_type: string;
    score_distribution: Array<{
      __typename?: "Scores";
      skill_label: string;
      id: string;
      skill_level: number;
      color: string;
      skill_description: string;
      total_count: number;
      skill_key: string;
      students: Array<{
        __typename?: "AnalyticsData";
        student_id: string;
        student_name: string;
        current_goal?: string | null;
        current_goal_skill?: string | null;
        assigned_date?: string | null;
        icon_base64?: string | null;
      }>;
    }>;
  }>;
};

export type SpeakingScoreSummaryQueryVariables = Exact<{
  assignmentIDs?: InputMaybe<Array<Scalars["ID"]> | Scalars["ID"]>;
  sectionIDs?: InputMaybe<Array<Scalars["ID"]> | Scalars["ID"]>;
  schoolIDs?: InputMaybe<Array<Scalars["ID"]> | Scalars["ID"]>;
}>;

export type SpeakingScoreSummaryQuery = {
  __typename?: "Query";
  speakingScoreSummary: Array<{
    __typename?: "ScoreDistribution";
    skill_label: string;
    skill_level: number;
    id: string;
    color: string;
    skill_key: string;
    skill_description: string;
    icon_base64: string;
    scores: Array<{
      __typename?: "Scores";
      skill_label: string;
      id: string;
      skill_level: number;
      color: string;
      skill_description: string;
      total_count: number;
      skill_key: string;
      students: Array<{
        __typename?: "AnalyticsData";
        student_id: string;
        student_name: string;
        current_goal?: string | null;
        current_goal_skill?: string | null;
        assigned_date?: string | null;
        icon_base64?: string | null;
      }>;
    } | null>;
  }>;
};

export type ScoreDistributionFragment = {
  __typename?: "ScoreDistribution";
  skill_label: string;
  skill_level: number;
  id: string;
  color: string;
  skill_key: string;
  skill_description: string;
  icon_base64: string;
  scores: Array<{
    __typename?: "Scores";
    skill_label: string;
    id: string;
    skill_level: number;
    color: string;
    skill_description: string;
    total_count: number;
    skill_key: string;
    students: Array<{
      __typename?: "AnalyticsData";
      student_id: string;
      student_name: string;
      current_goal?: string | null;
      current_goal_skill?: string | null;
      assigned_date?: string | null;
      icon_base64?: string | null;
    }>;
  } | null>;
};

export type WritingScoreSummaryQueryVariables = Exact<{
  assignmentIDs?: InputMaybe<Array<Scalars["ID"]> | Scalars["ID"]>;
  sectionIDs?: InputMaybe<Array<Scalars["ID"]> | Scalars["ID"]>;
  schoolIDs?: InputMaybe<Array<Scalars["ID"]> | Scalars["ID"]>;
}>;

export type WritingScoreSummaryQuery = {
  __typename?: "Query";
  writingScoreSummary: Array<{
    __typename?: "ScoreDistribution";
    skill_label: string;
    skill_level: number;
    id: string;
    color: string;
    skill_key: string;
    skill_description: string;
    icon_base64: string;
    scores: Array<{
      __typename?: "Scores";
      skill_label: string;
      id: string;
      skill_level: number;
      color: string;
      skill_description: string;
      total_count: number;
      skill_key: string;
      students: Array<{
        __typename?: "AnalyticsData";
        student_id: string;
        student_name: string;
        current_goal?: string | null;
        current_goal_skill?: string | null;
        assigned_date?: string | null;
        icon_base64?: string | null;
      }>;
    } | null>;
  }>;
};

export type AssignmentSubmissionFragment = {
  __typename?: "Submission";
  id: string;
  submitted_at?: string | null;
  status: SubmissionStatus;
  student?: { __typename?: "User"; id: string; first_name: string; last_name: string } | null;
  assignment?: {
    __typename?: "Assignment";
    title: string;
    speaking_prompt: string;
    writing_prompt: string;
    is_common_prompt: boolean;
    speaking_prompt_file?: {
      __typename?: "PromptFile";
      id: string;
      url: string;
      file_name: string;
      file_type: string;
    } | null;
    writing_prompt_file?: {
      __typename?: "PromptFile";
      id: string;
      url: string;
      file_name: string;
      file_type: string;
    } | null;
    section?: {
      __typename?: "Section";
      id: string;
      name: string;
      school?: { __typename?: "School"; id: string; name: string } | null;
    } | null;
  } | null;
  gradingStatus: {
    __typename?: "GradingStatus";
    IsSpeakingScored: boolean;
    IsSpeakingGoalSet: boolean;
    IsWritingScored: boolean;
    IsWritingGoalSet: boolean;
    IsReviewed: boolean;
  };
};

export type AssignmentQueryVariables = Exact<{
  id: Scalars["ID"];
  limit?: InputMaybe<Scalars["Int"]>;
  page?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Scalars["String"]>;
  orderBy?: InputMaybe<OrderBy>;
  search?: InputMaybe<Scalars["String"]>;
  statusList?: InputMaybe<Array<InputMaybe<SubmissionStatus>> | InputMaybe<SubmissionStatus>>;
}>;

export type AssignmentQuery = {
  __typename?: "Query";
  assignment: {
    __typename?: "Assignment";
    id: string;
    title: string;
    start_at: string;
    close_at: string;
    resource_id: string;
    is_submissions_started: boolean;
    back_navigation_allowed: boolean;
    speaking_prompt: string;
    writing_prompt: string;
    is_common_prompt: boolean;
    speaking_prompt_file?: {
      __typename?: "PromptFile";
      id: string;
      url: string;
      file_name: string;
      file_type: string;
    } | null;
    writing_prompt_file?: {
      __typename?: "PromptFile";
      id: string;
      url: string;
      file_name: string;
      file_type: string;
    } | null;
    resource?: {
      __typename?: "StaticResource";
      file: { __typename?: "File"; id: string; url: string; thumbnail_url?: string | null };
    } | null;
    section?: { __typename?: "Section"; id: string; course: string; name: string; subject: string } | null;
    submissionsForTeacher: {
      __typename?: "SubmissionConnection";
      totalCount: number;
      nodes: Array<{
        __typename?: "Submission";
        id: string;
        submitted_at?: string | null;
        status: SubmissionStatus;
        student?: { __typename?: "User"; id: string; first_name: string; last_name: string } | null;
        assignment?: {
          __typename?: "Assignment";
          title: string;
          speaking_prompt: string;
          writing_prompt: string;
          is_common_prompt: boolean;
          speaking_prompt_file?: {
            __typename?: "PromptFile";
            id: string;
            url: string;
            file_name: string;
            file_type: string;
          } | null;
          writing_prompt_file?: {
            __typename?: "PromptFile";
            id: string;
            url: string;
            file_name: string;
            file_type: string;
          } | null;
          section?: {
            __typename?: "Section";
            id: string;
            name: string;
            school?: { __typename?: "School"; id: string; name: string } | null;
          } | null;
        } | null;
        gradingStatus: {
          __typename?: "GradingStatus";
          IsSpeakingScored: boolean;
          IsSpeakingGoalSet: boolean;
          IsWritingScored: boolean;
          IsWritingGoalSet: boolean;
          IsReviewed: boolean;
        };
      }>;
    };
  };
};

export type PtStudentsSlideoutQueryVariables = Exact<{
  assignId: Scalars["String"];
  status?: InputMaybe<Array<SubmissionStatus> | SubmissionStatus>;
}>;

export type PtStudentsSlideoutQuery = {
  __typename?: "Query";
  teacherPTSlideOut: {
    __typename?: "PTSlideOutResult";
    total_count: number;
    nodes: Array<{
      __typename?: "SlideOutStudents";
      first_name: string;
      last_name: string;
      submission_id?: string | null;
      submission_status: SubmissionStatus;
      student_number: string;
    }>;
  };
};

export type AssignmentsQueryVariables = Exact<{
  startAt?: InputMaybe<DateQuery>;
  closeAt?: InputMaybe<DateQuery>;
  page?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Scalars["String"]>;
  orderBy?: InputMaybe<OrderBy>;
  search?: InputMaybe<Scalars["String"]>;
}>;

export type AssignmentsQuery = {
  __typename?: "Query";
  getAllAssignmentsForTeacher: {
    __typename?: "AssignmentConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "Assignment";
      id: string;
      title: string;
      start_at: string;
      close_at: string;
      IsSubmissionsGoalsShared?: boolean | null;
      section?: {
        __typename?: "Section";
        id: string;
        name: string;
        school?: { __typename?: "School"; id: string; name: string } | null;
      } | null;
      status_aggregation?: {
        __typename?: "AssignmentSubmissionAggregation";
        NotStarted: number;
        InProgress: number;
        Submitted: number;
      } | null;
      resource?: {
        __typename?: "StaticResource";
        id: string;
        file: { __typename?: "File"; url: string; thumbnail_url?: string | null };
      } | null;
    }>;
  };
};

export type DeleteAssignmentMutationVariables = Exact<{
  input: Scalars["String"];
}>;

export type DeleteAssignmentMutation = { __typename?: "Mutation"; deleteAssignment?: string | null };

export type CreateAssignmentMutationVariables = Exact<{
  input: CreateAssignmentInput;
}>;

export type CreateAssignmentMutation = {
  __typename?: "Mutation";
  createAssignment?: { __typename?: "Assignment"; id: string } | null;
};

export type EditAssignmentMutationVariables = Exact<{
  input: EditAssignmentInput;
}>;

export type EditAssignmentMutation = {
  __typename?: "Mutation";
  editAssignment?: { __typename?: "Assignment"; id: string } | null;
};

export type SectionsQueryVariables = Exact<{ [key: string]: never }>;

export type SectionsQuery = {
  __typename?: "Query";
  sections: Array<{ __typename?: "Section"; id: string; course: string; name: string; subject: string }>;
};

export type SectionsWithGroupsQueryVariables = Exact<{ [key: string]: never }>;

export type SectionsWithGroupsQuery = {
  __typename?: "Query";
  sectionsToCreatePT: Array<{
    __typename?: "SectionWithGroups";
    id: string;
    course: string;
    name: string;
    subject: string;
    groups?: Array<{ __typename?: "Section"; id: string; name: string }> | null;
  }>;
};

export type StaticResourcesQueryVariables = Exact<{
  grade?: InputMaybe<Array<Scalars["ID"]> | Scalars["ID"]>;
  subjectAreas?: InputMaybe<Array<Scalars["ID"]> | Scalars["ID"]>;
  taskTypes?: InputMaybe<Array<Scalars["ID"]> | Scalars["ID"]>;
  keywords?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  resourceType: StaticResourceType;
}>;

export type StaticResourcesQuery = {
  __typename?: "Query";
  staticResources: Array<{
    __typename?: "StaticResource";
    id: string;
    title: string;
    grade: string;
    file: { __typename?: "File"; id: string; url: string; thumbnail_url?: string | null };
  }>;
};

export type SectionDetailQueryVariables = Exact<{
  id: Scalars["String"];
}>;

export type SectionDetailQuery = {
  __typename?: "Query";
  sectionDetails: {
    __typename?: "SectionDetails";
    id: string;
    name: string;
    school: string;
    school_id: string;
    section_number?: string | null;
    grades: Array<string>;
    period?: number | null;
    term_start?: string | null;
    term_end?: string | null;
    district_id: string;
    category: SectionCategory;
    student_ids: Array<string>;
    teachers: Array<{
      __typename?: "TeacherInfo";
      id: string;
      teacher_name?: string | null;
      email?: string | null;
      primary_teacher?: boolean | null;
      source: string;
    } | null>;
    districtData?: {
      __typename?: "DistrictData";
      id: string;
      state_id?: string | null;
      source?: string | null;
      state?: { __typename?: "State"; id: string } | null;
    } | null;
  };
};

export type ClassFormMutationVariables = Exact<{
  input: SectionInput;
}>;

export type ClassFormMutation = {
  __typename?: "Mutation";
  upsertSection: { __typename?: "SectionDetails"; id: string };
};

export type SectionDetailsFragmentFragment = {
  __typename?: "SectionDetails";
  id: string;
  name: string;
  period?: number | null;
  grades: Array<string>;
  active_pt?: boolean | null;
  school: string;
  school_id: string;
  district: string;
  district_id: string;
  category: SectionCategory;
  student_ids: Array<string>;
  source?: string | null;
  teachers: Array<{
    __typename?: "TeacherInfo";
    id: string;
    teacher_name?: string | null;
    email?: string | null;
    primary_teacher?: boolean | null;
    source: string;
  } | null>;
};

export type SectionsListQueryVariables = Exact<{
  stateId?: InputMaybe<Scalars["String"]>;
  districtId?: InputMaybe<Scalars["String"]>;
  schoolId?: InputMaybe<Scalars["String"]>;
  teacherId?: InputMaybe<Scalars["String"]>;
  studentId?: InputMaybe<Scalars["String"]>;
  agencyId?: InputMaybe<Scalars["String"]>;
  classTypes?: InputMaybe<Array<SectionCategory> | SectionCategory>;
  page?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<SectionsListSortType>;
  searchByName?: InputMaybe<Scalars["String"]>;
  orderBy?: InputMaybe<OrderBy>;
}>;

export type SectionsListQuery = {
  __typename?: "Query";
  sectionsList: {
    __typename?: "SectionConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "SectionDetails";
      id: string;
      name: string;
      period?: number | null;
      grades: Array<string>;
      active_pt?: boolean | null;
      school: string;
      school_id: string;
      district: string;
      district_id: string;
      category: SectionCategory;
      student_ids: Array<string>;
      source?: string | null;
      teachers: Array<{
        __typename?: "TeacherInfo";
        id: string;
        teacher_name?: string | null;
        email?: string | null;
        primary_teacher?: boolean | null;
        source: string;
      } | null>;
    }>;
  };
};

export type ManageStudentsStudentFragmentFragment = {
  __typename?: "Student";
  id: string;
  student_number: string;
  grade?: string | null;
  user?: { __typename?: "User"; first_name: string; last_name: string } | null;
};

export type ManageTeacherStudentsListQueryVariables = Exact<{
  schoolID?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<OrderBy>;
  sort?: InputMaybe<StudentListOfTeacherSortType>;
  search?: InputMaybe<Scalars["String"]>;
}>;

export type ManageTeacherStudentsListQuery = {
  __typename?: "Query";
  getAllStudentsOfTeacher: Array<{
    __typename?: "Student";
    id: string;
    student_number: string;
    grade?: string | null;
    user?: { __typename?: "User"; first_name: string; last_name: string } | null;
  }>;
};

export type UpsertClassMutationVariables = Exact<{
  input: SectionInput;
}>;

export type UpsertClassMutation = {
  __typename?: "Mutation";
  upsertSection: { __typename?: "SectionDetails"; id: string };
};

export type EnrollStudentsInCustomSectionMutationVariables = Exact<{
  input: EnrollmentInput;
}>;

export type EnrollStudentsInCustomSectionMutation = { __typename?: "Mutation"; enrollStudentsInCustomSection: string };

export type RemoveInProgressSubmissionsOfSectionMutationVariables = Exact<{
  sectionID: Scalars["ID"];
  studentIDs: Array<Scalars["ID"]> | Scalars["ID"];
}>;

export type RemoveInProgressSubmissionsOfSectionMutation = {
  __typename?: "Mutation";
  removeInProgressSubmissionsOfSection: string;
};

export type ManageClassUserFragmentFragment = {
  __typename?: "User";
  id: string;
  first_name: string;
  last_name: string;
  student?: { __typename?: "Student"; id: string; student_number: string; grade?: string | null } | null;
};

export type StudentUserConnectionFragmentFragment = {
  __typename?: "StudentUserConnection";
  totalCount: number;
  nodes: Array<{
    __typename?: "User";
    id: string;
    first_name: string;
    last_name: string;
    student?: { __typename?: "Student"; id: string; student_number: string; grade?: string | null } | null;
  }>;
};

export type CustomClassStudentListQueryVariables = Exact<{
  input: CustomClassAllocationInput;
}>;

export type CustomClassStudentListQuery = {
  __typename?: "Query";
  customClassStudentList: {
    __typename?: "StudentAllocationData";
    allocated_student_ids: Array<string>;
    available_student_ids: Array<string>;
    allocated_students: {
      __typename?: "StudentUserConnection";
      totalCount: number;
      nodes: Array<{
        __typename?: "User";
        id: string;
        first_name: string;
        last_name: string;
        student?: { __typename?: "Student"; id: string; student_number: string; grade?: string | null } | null;
      }>;
    };
    available_students: {
      __typename?: "StudentUserConnection";
      totalCount: number;
      nodes: Array<{
        __typename?: "User";
        id: string;
        first_name: string;
        last_name: string;
        student?: { __typename?: "Student"; id: string; student_number: string; grade?: string | null } | null;
      }>;
    };
  };
};

export type StudentsWithInProgressSubmissionQueryVariables = Exact<{
  sectionID: Scalars["ID"];
  studentIDs: Array<Scalars["ID"]> | Scalars["ID"];
}>;

export type StudentsWithInProgressSubmissionQuery = {
  __typename?: "Query";
  studentsWithInProgressSubmission: Array<{
    __typename?: "User";
    id: string;
    first_name: string;
    last_name: string;
    student?: { __typename?: "Student"; id: string } | null;
  }>;
};

export type CreateDemoDataMutationVariables = Exact<{
  input: CreateDemoUsersInput;
}>;

export type CreateDemoDataMutation = {
  __typename?: "Mutation";
  createDemoDataForDistrict: {
    __typename?: "CreateDemoUsersOutput";
    district: { __typename?: "DistrictData"; id: string; name: string; district_id: string; score_scheme_id: string };
    admins: Array<{
      __typename?: "DemoAdminDetails";
      id: string;
      user_id: string;
      first_name: string;
      last_name: string;
      middle_name?: string | null;
      email: string;
      password: string;
      admin_id: string;
    }>;
    teachers: Array<{
      __typename?: "DemoTeacherDetails";
      id: string;
      user_id: string;
      first_name: string;
      last_name: string;
      middle_name?: string | null;
      email: string;
      password: string;
      teacher_id: string;
    }>;
    students: Array<{
      __typename?: "DemoStudentDetails";
      id: string;
      user_id: string;
      first_name: string;
      last_name: string;
      middle_name?: string | null;
      email?: string | null;
      password: string;
      student_id: string;
    }>;
  };
};

export type CleverSyncListItemFragment = {
  __typename?: "CleverSyncListLineItem";
  id: string;
  owner_type: OwnerType;
  status: SyncStatus;
  created_at?: string | null;
  run_duration?: number | null;
  district_ids?: Array<string | null> | null;
  admin?: {
    __typename?: "UserAdmin";
    id: string;
    admin_id: string;
    job_title: string;
    user?: {
      __typename?: "User";
      id: string;
      first_name: string;
      last_name: string;
      middle_name: string;
      email: string;
      role: UserRole;
      showHelpVideo: boolean;
      permissions: Array<{ __typename?: "Permission"; id: string; permission: string }>;
    } | null;
  } | null;
};

export type CleverSyncListQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Scalars["String"]>;
  orderBy?: InputMaybe<OrderBy>;
  search?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Array<InputMaybe<SyncStatus>> | InputMaybe<SyncStatus>>;
  districtID?: InputMaybe<Scalars["ID"]>;
}>;

export type CleverSyncListQuery = {
  __typename?: "Query";
  listCleverSyncs?: {
    __typename?: "CleverSyncListConnection";
    totalCount: number;
    syncEnabled: boolean;
    nodes: Array<{
      __typename?: "CleverSyncListLineItem";
      id: string;
      owner_type: OwnerType;
      status: SyncStatus;
      created_at?: string | null;
      run_duration?: number | null;
      district_ids?: Array<string | null> | null;
      admin?: {
        __typename?: "UserAdmin";
        id: string;
        admin_id: string;
        job_title: string;
        user?: {
          __typename?: "User";
          id: string;
          first_name: string;
          last_name: string;
          middle_name: string;
          email: string;
          role: UserRole;
          showHelpVideo: boolean;
          permissions: Array<{ __typename?: "Permission"; id: string; permission: string }>;
        } | null;
      } | null;
    }>;
  } | null;
};

export type CleverSyncJobDetailsQueryVariables = Exact<{
  Id: Scalars["String"];
}>;

export type CleverSyncJobDetailsQuery = {
  __typename?: "Query";
  cleverSyncDetails?: {
    __typename?: "CleverSyncDetails";
    id: string;
    sync_type: SyncType;
    owner_type: OwnerType;
    status: SyncStatus;
    created_at?: string | null;
    run_duration?: number | null;
    message?: string | null;
    districts?: Array<{ __typename?: "DistrictDTO"; id: string; name: string } | null> | null;
    admin?: {
      __typename?: "UserAdmin";
      id: string;
      admin_id: string;
      job_title: string;
      user?: {
        __typename?: "User";
        id: string;
        first_name: string;
        last_name: string;
        middle_name: string;
        email: string;
        role: UserRole;
        showHelpVideo: boolean;
        permissions: Array<{ __typename?: "Permission"; id: string; permission: string }>;
      } | null;
    } | null;
  } | null;
};

export type UpdateCleverDistrictMutationVariables = Exact<{
  input: CleverDistrictImportInput;
}>;

export type UpdateCleverDistrictMutation = { __typename?: "Mutation"; importCleverDistrict?: string | null };

export type GetUninitialisedCleverDistrictsQueryVariables = Exact<{ [key: string]: never }>;

export type GetUninitialisedCleverDistrictsQuery = {
  __typename?: "Query";
  getUnImportedCleverDistricts?: Array<{
    __typename?: "CleverDistrict";
    clever_id: string;
    name: string;
    source: string;
  } | null> | null;
};

export type SyncImportedDistrictsMutationVariables = Exact<{
  districts: Array<Scalars["String"]> | Scalars["String"];
}>;

export type SyncImportedDistrictsMutation = { __typename?: "Mutation"; syncCleverDistricts: string };

export type GetDistrictsToSyncQueryVariables = Exact<{ [key: string]: never }>;

export type GetDistrictsToSyncQuery = {
  __typename?: "Query";
  syncDistrictsList: Array<{ __typename?: "DistrictItem"; id: string; name: string; sync_status?: string | null }>;
};

export type CreateDistrictMutationVariables = Exact<{
  input: DistrictInput;
}>;

export type CreateDistrictMutation = { __typename?: "Mutation"; createDistrict?: string | null };

export type UpdateDistrictMutationVariables = Exact<{
  id: Scalars["String"];
  input: DistrictInput;
}>;

export type UpdateDistrictMutation = { __typename?: "Mutation"; updateDistrict: string };

export type UpdateDistrictActiveFlagMutationVariables = Exact<{
  id: Scalars["String"];
  isDisabled: Scalars["Boolean"];
}>;

export type UpdateDistrictActiveFlagMutation = { __typename?: "Mutation"; updateDistrictDisabledFlag: string };

export type DistrictEditDataFragmentFragment = {
  __typename?: "DistrictData";
  id: string;
  state_id?: string | null;
  district_id: string;
  name: string;
  score_scheme_id: string;
  source?: string | null;
  has_active_assignments?: boolean | null;
  is_disabled?: boolean | null;
  nces_id?: string | null;
  mdr_number?: string | null;
  decouple_log?: { __typename?: "DistrictDecoupleLog"; id: string; created_at?: string | null; status: string } | null;
};

export type DistrictEditDataQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DistrictEditDataQuery = {
  __typename?: "Query";
  districtDetails: {
    __typename?: "DistrictData";
    id: string;
    state_id?: string | null;
    district_id: string;
    name: string;
    score_scheme_id: string;
    source?: string | null;
    has_active_assignments?: boolean | null;
    is_disabled?: boolean | null;
    nces_id?: string | null;
    mdr_number?: string | null;
    decouple_log?: {
      __typename?: "DistrictDecoupleLog";
      id: string;
      created_at?: string | null;
      status: string;
    } | null;
  };
};

export type ScoreSchemesFragment = {
  __typename?: "ScoreScheme";
  id: string;
  title_key: string;
  title: string;
  scoreDetail: Array<{
    __typename?: "ScoreDetail";
    id: string;
    title_key: string;
    title: string;
    score_value: number;
    description: string;
    color_code: string;
  }>;
};

export type ScoreSchemeListQueryVariables = Exact<{ [key: string]: never }>;

export type ScoreSchemeListQuery = {
  __typename?: "Query";
  scoreSchemeList: Array<{
    __typename?: "ScoreScheme";
    id: string;
    title_key: string;
    title: string;
    scoreDetail: Array<{
      __typename?: "ScoreDetail";
      id: string;
      title_key: string;
      title: string;
      score_value: number;
      description: string;
      color_code: string;
    }>;
  }>;
};

export type DecoupleDistrictMutationVariables = Exact<{
  district_id: Scalars["String"];
}>;

export type DecoupleDistrictMutation = { __typename?: "Mutation"; decoupleDistrict: string };

export type DeleteDistrictRequestMutationVariables = Exact<{
  district_id: Scalars["String"];
}>;

export type DeleteDistrictRequestMutation = { __typename?: "Mutation"; deleteDistrictRequest: string };

export type DistrictDetailsDatumFragment = {
  __typename?: "DistrictData";
  id: string;
  state_id?: string | null;
  agency_id?: string | null;
  source?: string | null;
  district_id: string;
  name: string;
  is_disabled?: boolean | null;
  source_id?: string | null;
  has_active_assignments?: boolean | null;
  last_synced_at?: string | null;
  updated_at?: string | null;
  initialized_at?: string | null;
  sync_status?: string | null;
  delete_district_status?: string | null;
  is_district_deleted?: boolean | null;
  nces_id?: string | null;
  mdr_number?: string | null;
  state?: { __typename?: "State"; id: string; name: string; state_id: string } | null;
  decouple_log?: {
    __typename?: "DistrictDecoupleLog";
    id: string;
    status: string;
    created_at?: string | null;
    updated_at?: string | null;
    message?: string | null;
    created_by?: { __typename?: "User"; id: string; first_name: string; last_name: string } | null;
  } | null;
  cognito_pending_users?: Array<{
    __typename?: "CognitoPendingUserDTO";
    first_name?: string | null;
    last_name?: string | null;
    external_id?: string | null;
    source: string;
    role: UserRole;
  } | null> | null;
};

export type DistrictDetailsQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DistrictDetailsQuery = {
  __typename?: "Query";
  districtDetails: {
    __typename?: "DistrictData";
    id: string;
    state_id?: string | null;
    agency_id?: string | null;
    source?: string | null;
    district_id: string;
    name: string;
    is_disabled?: boolean | null;
    source_id?: string | null;
    has_active_assignments?: boolean | null;
    last_synced_at?: string | null;
    updated_at?: string | null;
    initialized_at?: string | null;
    sync_status?: string | null;
    delete_district_status?: string | null;
    is_district_deleted?: boolean | null;
    nces_id?: string | null;
    mdr_number?: string | null;
    state?: { __typename?: "State"; id: string; name: string; state_id: string } | null;
    decouple_log?: {
      __typename?: "DistrictDecoupleLog";
      id: string;
      status: string;
      created_at?: string | null;
      updated_at?: string | null;
      message?: string | null;
      created_by?: { __typename?: "User"; id: string; first_name: string; last_name: string } | null;
    } | null;
    cognito_pending_users?: Array<{
      __typename?: "CognitoPendingUserDTO";
      first_name?: string | null;
      last_name?: string | null;
      external_id?: string | null;
      source: string;
      role: UserRole;
    } | null> | null;
  };
};

export type MyDistrictQueryVariables = Exact<{ [key: string]: never }>;

export type MyDistrictQuery = {
  __typename?: "Query";
  myDistrict: {
    __typename?: "DistrictData";
    id: string;
    state_id?: string | null;
    agency_id?: string | null;
    source?: string | null;
    district_id: string;
    name: string;
    is_disabled?: boolean | null;
    source_id?: string | null;
    has_active_assignments?: boolean | null;
    last_synced_at?: string | null;
    updated_at?: string | null;
    initialized_at?: string | null;
    sync_status?: string | null;
    delete_district_status?: string | null;
    is_district_deleted?: boolean | null;
    nces_id?: string | null;
    mdr_number?: string | null;
    state?: { __typename?: "State"; id: string; name: string; state_id: string } | null;
    decouple_log?: {
      __typename?: "DistrictDecoupleLog";
      id: string;
      status: string;
      created_at?: string | null;
      updated_at?: string | null;
      message?: string | null;
      created_by?: { __typename?: "User"; id: string; first_name: string; last_name: string } | null;
    } | null;
    cognito_pending_users?: Array<{
      __typename?: "CognitoPendingUserDTO";
      first_name?: string | null;
      last_name?: string | null;
      external_id?: string | null;
      source: string;
      role: UserRole;
    } | null> | null;
  };
};

export type DistrictsQueryVariables = Exact<{
  state_ids?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  page?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<DistrictListSortType>;
  searchByName?: InputMaybe<Scalars["String"]>;
  orderBy?: InputMaybe<OrderBy>;
  excludeDeleted?: InputMaybe<Scalars["Boolean"]>;
}>;

export type DistrictsQuery = {
  __typename?: "Query";
  districtList: {
    __typename?: "DistrictConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "DistrictDetails";
      id: string;
      state_id?: string | null;
      agency_id?: string | null;
      school_count: number;
      student_count: number;
      source?: string | null;
      district_id: string;
      name: string;
      score_scheme_id: string;
      state?: { __typename?: "State"; id: string; name: string; state_id: string } | null;
    }>;
  };
};

export type SendResetPasswordLinkMutationVariables = Exact<{
  email: Scalars["String"];
  host: Scalars["String"];
}>;

export type SendResetPasswordLinkMutation = { __typename?: "Mutation"; sendResetPasswordLink?: string | null };

export type SetPasswordByTokenMutationVariables = Exact<{
  password: Scalars["String"];
  token: Scalars["String"];
}>;

export type SetPasswordByTokenMutation = { __typename?: "Mutation"; setPasswordByToken?: string | null };

export type StudentGoalFragment = {
  __typename?: "StudentGoalListNode";
  id: string;
  assigned_date: string;
  submission_id: string;
  goal_status?: string | null;
  target_submission_id?: string | null;
  predefined_goal_id?: string | null;
  predefined_goal_description?: string | null;
  predefined_goal_example?: string | null;
  predefined_goal_rubric_id?: string | null;
  predefined_goal_rubric_title?: string | null;
  predefined_goal_rubric_icon_base_64?: string | null;
  predefined_goal_rubric_submission_type?: string | null;
  teacher_user_id?: string | null;
  teacher_user_first_name?: string | null;
  teacher_user_last_name?: string | null;
};

export type StudentGoalsFragment = {
  __typename?: "StudentGoalListResult";
  speaking: Array<{
    __typename?: "StudentGoalListNode";
    id: string;
    assigned_date: string;
    submission_id: string;
    goal_status?: string | null;
    target_submission_id?: string | null;
    predefined_goal_id?: string | null;
    predefined_goal_description?: string | null;
    predefined_goal_example?: string | null;
    predefined_goal_rubric_id?: string | null;
    predefined_goal_rubric_title?: string | null;
    predefined_goal_rubric_icon_base_64?: string | null;
    predefined_goal_rubric_submission_type?: string | null;
    teacher_user_id?: string | null;
    teacher_user_first_name?: string | null;
    teacher_user_last_name?: string | null;
  }>;
  writing: Array<{
    __typename?: "StudentGoalListNode";
    id: string;
    assigned_date: string;
    submission_id: string;
    goal_status?: string | null;
    target_submission_id?: string | null;
    predefined_goal_id?: string | null;
    predefined_goal_description?: string | null;
    predefined_goal_example?: string | null;
    predefined_goal_rubric_id?: string | null;
    predefined_goal_rubric_title?: string | null;
    predefined_goal_rubric_icon_base_64?: string | null;
    predefined_goal_rubric_submission_type?: string | null;
    teacher_user_id?: string | null;
    teacher_user_first_name?: string | null;
    teacher_user_last_name?: string | null;
  }>;
};

export type GetActiveGoalsQueryVariables = Exact<{
  goalType?: InputMaybe<GoalType>;
}>;

export type GetActiveGoalsQuery = {
  __typename?: "Query";
  getStudentGoals?: {
    __typename?: "StudentGoalListResult";
    speaking: Array<{
      __typename?: "StudentGoalListNode";
      id: string;
      assigned_date: string;
      submission_id: string;
      goal_status?: string | null;
      target_submission_id?: string | null;
      predefined_goal_id?: string | null;
      predefined_goal_description?: string | null;
      predefined_goal_example?: string | null;
      predefined_goal_rubric_id?: string | null;
      predefined_goal_rubric_title?: string | null;
      predefined_goal_rubric_icon_base_64?: string | null;
      predefined_goal_rubric_submission_type?: string | null;
      teacher_user_id?: string | null;
      teacher_user_first_name?: string | null;
      teacher_user_last_name?: string | null;
    }>;
    writing: Array<{
      __typename?: "StudentGoalListNode";
      id: string;
      assigned_date: string;
      submission_id: string;
      goal_status?: string | null;
      target_submission_id?: string | null;
      predefined_goal_id?: string | null;
      predefined_goal_description?: string | null;
      predefined_goal_example?: string | null;
      predefined_goal_rubric_id?: string | null;
      predefined_goal_rubric_title?: string | null;
      predefined_goal_rubric_icon_base_64?: string | null;
      predefined_goal_rubric_submission_type?: string | null;
      teacher_user_id?: string | null;
      teacher_user_first_name?: string | null;
      teacher_user_last_name?: string | null;
    }>;
  } | null;
};

export type CreateCustomGoalMutationVariables = Exact<{
  rubricId: Scalars["ID"];
  description: Scalars["String"];
  example?: InputMaybe<Scalars["String"]>;
  submissionId?: InputMaybe<Scalars["ID"]>;
  goalId?: InputMaybe<Scalars["ID"]>;
}>;

export type CreateCustomGoalMutation = {
  __typename?: "Mutation";
  createPredefinedGoal: {
    __typename?: "PredefinedGoalsList";
    speaking: Array<{
      __typename?: "GoalsData";
      rubric: { __typename?: "Rubric"; id: string; title: string; submission_type: string };
    }>;
    writing: Array<{
      __typename?: "GoalsData";
      rubric: { __typename?: "Rubric"; id: string; title: string; submission_type: string };
    }>;
  };
};

export type CreateUpdateStudentGoalMutationVariables = Exact<{
  id?: InputMaybe<Scalars["ID"]>;
  submissionId: Scalars["String"];
  goalId: Scalars["String"];
}>;

export type CreateUpdateStudentGoalMutation = {
  __typename?: "Mutation";
  createUpdateStudentGoal: {
    __typename?: "StudentGoal";
    id: string;
    submission_id: string;
    predefined_goal_id: string;
  };
};

export type GoalOptionsFragment = {
  __typename?: "GoalsData";
  rubric: { __typename?: "Rubric"; id: string; title: string };
  predefined_goals: Array<{
    __typename?: "CategorizedGoal";
    category: string;
    goal_list: Array<{ __typename?: "PredefinedGoal"; id: string; description: string; example?: string | null }>;
  }>;
};

export type GetPredefinedGoalsQueryVariables = Exact<{ [key: string]: never }>;

export type GetPredefinedGoalsQuery = {
  __typename?: "Query";
  getPredefinedGoals: {
    __typename?: "PredefinedGoalsList";
    speaking: Array<{
      __typename?: "GoalsData";
      rubric: { __typename?: "Rubric"; id: string; title: string };
      predefined_goals: Array<{
        __typename?: "CategorizedGoal";
        category: string;
        goal_list: Array<{ __typename?: "PredefinedGoal"; id: string; description: string; example?: string | null }>;
      }>;
    }>;
    writing: Array<{
      __typename?: "GoalsData";
      rubric: { __typename?: "Rubric"; id: string; title: string };
      predefined_goals: Array<{
        __typename?: "CategorizedGoal";
        category: string;
        goal_list: Array<{ __typename?: "PredefinedGoal"; id: string; description: string; example?: string | null }>;
      }>;
    }>;
  };
};

export type UpdateGoalStatusMutationVariables = Exact<{
  goalId: Scalars["ID"];
  status: GoalStatus;
}>;

export type UpdateGoalStatusMutation = {
  __typename?: "Mutation";
  updateGoalStatus: { __typename?: "Goal"; id: string; goal_status?: string | null };
};

export type CreateGroupMutationVariables = Exact<{
  input: GroupInput;
}>;

export type CreateGroupMutation = {
  __typename?: "Mutation";
  upsertGroup: { __typename?: "SectionDetails"; id: string };
};

export type EnrollStudentsInGroupMutationVariables = Exact<{
  input: EnrollmentInput;
}>;

export type EnrollStudentsInGroupMutation = { __typename?: "Mutation"; enrollStudentsInGroup: string };

export type DeleteGroupMutationVariables = Exact<{
  group_id: Scalars["ID"];
}>;

export type DeleteGroupMutation = { __typename?: "Mutation"; deleteGroup: string };

export type GroupStudentListQueryVariables = Exact<{
  input: GroupAllocationInput;
}>;

export type GroupStudentListQuery = {
  __typename?: "Query";
  studentGroupStudentsList: {
    __typename?: "StudentAllocationData";
    allocated_student_ids: Array<string>;
    available_student_ids: Array<string>;
    allocated_students: {
      __typename?: "StudentUserConnection";
      totalCount: number;
      nodes: Array<{
        __typename?: "User";
        id: string;
        first_name: string;
        last_name: string;
        student?: { __typename?: "Student"; id: string; student_number: string; grade?: string | null } | null;
      }>;
    };
    available_students: {
      __typename?: "StudentUserConnection";
      totalCount: number;
      nodes: Array<{
        __typename?: "User";
        id: string;
        first_name: string;
        last_name: string;
        student?: { __typename?: "Student"; id: string; student_number: string; grade?: string | null } | null;
      }>;
    };
  };
};

export type GroupDetailsQueryVariables = Exact<{
  id: Scalars["String"];
}>;

export type GroupDetailsQuery = {
  __typename?: "Query";
  groupDetails: {
    __typename?: "GroupDetails";
    id: string;
    name: string;
    parent_section_id: string;
    parent_section_name: string;
    student_count: number;
  };
};

export type GroupsFragment = {
  __typename?: "GroupDetails";
  id: string;
  name: string;
  parent_section_id: string;
  parent_section_name: string;
  student_count: number;
  school?: { __typename?: "School"; id: string; name: string } | null;
};

export type GroupsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]>;
  page?: InputMaybe<Scalars["Int"]>;
  section_id?: InputMaybe<Scalars["String"]>;
  school_id?: InputMaybe<Scalars["String"]>;
  search?: InputMaybe<Scalars["String"]>;
}>;

export type GroupsQuery = {
  __typename?: "Query";
  groupList: {
    __typename?: "GroupConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "GroupDetails";
      id: string;
      name: string;
      parent_section_id: string;
      parent_section_name: string;
      student_count: number;
      school?: { __typename?: "School"; id: string; name: string } | null;
    }>;
  };
};

export type SectionGroupsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]>;
  page?: InputMaybe<Scalars["Int"]>;
  section_id?: InputMaybe<Scalars["String"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<GroupsListSortType>;
  orderBy?: InputMaybe<OrderBy>;
}>;

export type SectionGroupsQuery = {
  __typename?: "Query";
  groupsBySection: {
    __typename?: "GroupConnection";
    totalCount: number;
    nodes: Array<{ __typename?: "GroupDetails"; id: string; name: string; student_count: number }>;
  };
};

export type GetResourceCountByDistrictQueryVariables = Exact<{
  districtId: Scalars["ID"];
}>;

export type GetResourceCountByDistrictQuery = {
  __typename?: "Query";
  resourceCountByDistrict?: {
    __typename?: "DistrictResourcesCount";
    school_count: number;
    class_count: number;
    school_admin_count: number;
    district_admin_count: number;
    teacher_count: number;
    student_count: number;
    sync_count: number;
  } | null;
};

export type GetResourceCountByTeacherQueryVariables = Exact<{
  teacherId: Scalars["ID"];
  schoolId?: InputMaybe<Scalars["ID"]>;
}>;

export type GetResourceCountByTeacherQuery = {
  __typename?: "Query";
  resourceCountByTeacher?: { __typename?: "TeacherResourcesCount"; student_count: number; class_count: number } | null;
};

export type GetResourceCountByStudentQueryVariables = Exact<{
  studentId: Scalars["ID"];
}>;

export type GetResourceCountByStudentQuery = {
  __typename?: "Query";
  resourceCountByStudent?: { __typename?: "StudentResourcesCount"; class_count: number } | null;
};

export type GetResourceCountBySectionQueryVariables = Exact<{
  sectionId: Scalars["ID"];
}>;

export type GetResourceCountBySectionQuery = {
  __typename?: "Query";
  resourceCountByClass?: { __typename?: "ClassResourcesCount"; student_count: number; group_count: number } | null;
};

export type GetResourceCountBySchoolQueryVariables = Exact<{
  schoolId: Scalars["ID"];
}>;

export type GetResourceCountBySchoolQuery = {
  __typename?: "Query";
  resourceCountBySchool?: {
    __typename?: "SchoolResourcesCount";
    student_count: number;
    class_count: number;
    teacher_count: number;
    school_admin_count: number;
  } | null;
};

export type GetDistrictByIdQueryVariables = Exact<{
  id: Scalars["String"];
}>;

export type GetDistrictByIdQuery = {
  __typename?: "Query";
  getDistrictById: {
    __typename?: "LoginDistrictDetails";
    id: string;
    name: string;
    district_id: string;
    source: string;
    source_id: string;
  };
};

export type DistrictQueryVariables = Exact<{ [key: string]: never }>;

export type DistrictQuery = {
  __typename?: "Query";
  district: Array<{ __typename?: "LoginDistrictDetails"; id: string; name: string; district_id: string }>;
};

export type BenchmarkComparisonCommonNodeFragment = {
  __typename?: "AdminBenchmarkScoresDataPoint";
  submission_type: string;
  ytd_change: string;
  boy_color: string;
  boy_score: number;
  moy_color: string;
  moy_score: number;
  eoy_color: string;
  eoy_score: number;
  boy_to_moy_progress: string;
  moy_to_eoy_progress: string;
};

export type AdminSchoolBenchmarkComparisonQueryVariables = Exact<{
  districtId: Scalars["String"];
  page?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<AdminBenchmarkReportingWidgetSortType>;
  orderBy?: InputMaybe<OrderBy>;
}>;

export type AdminSchoolBenchmarkComparisonQuery = {
  __typename?: "Query";
  adminBenchmarkSchoolsComparison: {
    __typename?: "AdminBenchmarkSchoolsComparisonResultNode";
    total_count: number;
    nodes: Array<{
      __typename?: "AdminBenchmarkSchoolsComparisonResult";
      id: string;
      name: string;
      benchmark_data_points: Array<{
        __typename?: "AdminBenchmarkScoresDataPoint";
        submission_type: string;
        ytd_change: string;
        boy_color: string;
        boy_score: number;
        moy_color: string;
        moy_score: number;
        eoy_color: string;
        eoy_score: number;
        boy_to_moy_progress: string;
        moy_to_eoy_progress: string;
      }>;
    } | null>;
  };
};

export type AdminTeacherBenchmarkComparisonQueryVariables = Exact<{
  schoolId: Scalars["String"];
  page?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<AdminBenchmarkReportingWidgetSortType>;
  orderBy?: InputMaybe<OrderBy>;
}>;

export type AdminTeacherBenchmarkComparisonQuery = {
  __typename?: "Query";
  adminBenchmarkTeacherComparison: {
    __typename?: "AdminBenchmarkSchoolsTeacherComparisonResultNode";
    total_count: number;
    nodes: Array<{
      __typename?: "AdminBenchmarkSchoolsTeacherComparisonResult";
      id: string;
      name: string;
      benchmark_data_points: Array<{
        __typename?: "AdminBenchmarkScoresDataPoint";
        submission_type: string;
        ytd_change: string;
        boy_color: string;
        boy_score: number;
        moy_color: string;
        moy_score: number;
        eoy_color: string;
        eoy_score: number;
        boy_to_moy_progress: string;
        moy_to_eoy_progress: string;
      }>;
    } | null>;
  };
};

export type AdminGradeBenchmarkComparisonQueryVariables = Exact<{
  districtId?: InputMaybe<Scalars["String"]>;
  schoolId?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<AdminBenchmarkReportingWidgetSortType>;
  orderBy?: InputMaybe<OrderBy>;
}>;

export type AdminGradeBenchmarkComparisonQuery = {
  __typename?: "Query";
  adminBenchmarkGradeComparison: {
    __typename?: "AdminBenchmarkGradeComparisonResultNode";
    total_count: number;
    nodes: Array<{
      __typename?: "AdminBenchmarkGradeComparisonResult";
      id: string;
      name: string;
      position: number;
      benchmark_data_points: Array<{
        __typename?: "AdminBenchmarkScoresDataPoint";
        submission_type: string;
        ytd_change: string;
        boy_color: string;
        boy_score: number;
        moy_color: string;
        moy_score: number;
        eoy_color: string;
        eoy_score: number;
        boy_to_moy_progress: string;
        moy_to_eoy_progress: string;
      }>;
    } | null>;
  };
};

export type RubricScoresForBenchmarkTypeFragmentFragment = {
  __typename?: "AdminBenchmarkIndicatorRubricLineItemScoreForBenchmarkType";
  benchmark_type: BenchmarkType;
  rubric_score_average: number;
  rubric_score_color: string;
};

export type RubricLineItemsFragmentFragment = {
  __typename?: "AdminBenchmarkIndicatorRubricLineItem";
  rubric_id: string;
  rubric_title: string;
  rubric_position: number;
  rubric_icon_base_64: string;
  ytd: number;
  ytd_formatted: string;
  rubric_scores_for_benchmark_type: Array<{
    __typename?: "AdminBenchmarkIndicatorRubricLineItemScoreForBenchmarkType";
    benchmark_type: BenchmarkType;
    rubric_score_average: number;
    rubric_score_color: string;
  }>;
};

export type AdminBenchmarkIndicatorScoreResultFragmentFragment = {
  __typename?: "AdminBenchmarkIndicatorScoresResult";
  submission_type: SubmissionType;
  rubric_line_items: Array<{
    __typename?: "AdminBenchmarkIndicatorRubricLineItem";
    rubric_id: string;
    rubric_title: string;
    rubric_position: number;
    rubric_icon_base_64: string;
    ytd: number;
    ytd_formatted: string;
    rubric_scores_for_benchmark_type: Array<{
      __typename?: "AdminBenchmarkIndicatorRubricLineItemScoreForBenchmarkType";
      benchmark_type: BenchmarkType;
      rubric_score_average: number;
      rubric_score_color: string;
    }>;
  }>;
};

export type AdminBenchmarkIndicatorScoresQueryVariables = Exact<{
  input: AdminBenchmarkInput;
}>;

export type AdminBenchmarkIndicatorScoresQuery = {
  __typename?: "Query";
  adminBenchmarkIndicatorScores: Array<{
    __typename?: "AdminBenchmarkIndicatorScoresResult";
    submission_type: SubmissionType;
    rubric_line_items: Array<{
      __typename?: "AdminBenchmarkIndicatorRubricLineItem";
      rubric_id: string;
      rubric_title: string;
      rubric_position: number;
      rubric_icon_base_64: string;
      ytd: number;
      ytd_formatted: string;
      rubric_scores_for_benchmark_type: Array<{
        __typename?: "AdminBenchmarkIndicatorRubricLineItemScoreForBenchmarkType";
        benchmark_type: BenchmarkType;
        rubric_score_average: number;
        rubric_score_color: string;
      }>;
    }>;
  }>;
};

export type AdminBenchmarkSubmissionStatusGrowthResultFragmentFragment = {
  __typename?: "AdminBenchmarkSubmissionStatusGrowthResult";
  total_submission: number;
  speaking_score: number;
  writing_score: number;
  writing_score_color: string;
  speaking_score_color: string;
  benchmark_type: BenchmarkType;
  submission_line_items: Array<{
    __typename?: "SubmissionLineItem";
    submission_count: number;
    submission_status: SubmissionStatus;
  }>;
};

export type AdminBenchmarkSubmissionStatusGrowthQueryVariables = Exact<{
  input: AdminBenchmarkInput;
}>;

export type AdminBenchmarkSubmissionStatusGrowthQuery = {
  __typename?: "Query";
  adminBenchmarkSubmissionStatusGrowth: Array<{
    __typename?: "AdminBenchmarkSubmissionStatusGrowthResult";
    total_submission: number;
    speaking_score: number;
    writing_score: number;
    writing_score_color: string;
    speaking_score_color: string;
    benchmark_type: BenchmarkType;
    submission_line_items: Array<{
      __typename?: "SubmissionLineItem";
      submission_count: number;
      submission_status: SubmissionStatus;
    }>;
  }>;
};

export type SchoolDetailsDatumFragment = {
  __typename?: "School";
  id: string;
  district_id: string;
  name: string;
  school_id: string;
  nces_id?: string | null;
  mdr_number?: string | null;
  updated_at?: string | null;
  district?: {
    __typename?: "DistrictData";
    id: string;
    name: string;
    district_id: string;
    source?: string | null;
    state?: { __typename?: "State"; id: string } | null;
  } | null;
};

export type SchoolDetailsQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type SchoolDetailsQuery = {
  __typename?: "Query";
  schoolDetails: {
    __typename?: "School";
    id: string;
    district_id: string;
    name: string;
    school_id: string;
    nces_id?: string | null;
    mdr_number?: string | null;
    updated_at?: string | null;
    district?: {
      __typename?: "DistrictData";
      id: string;
      name: string;
      district_id: string;
      source?: string | null;
      state?: { __typename?: "State"; id: string } | null;
    } | null;
  };
};

export type MySchoolQueryVariables = Exact<{ [key: string]: never }>;

export type MySchoolQuery = {
  __typename?: "Query";
  mySchool: {
    __typename?: "School";
    id: string;
    district_id: string;
    name: string;
    school_id: string;
    nces_id?: string | null;
    mdr_number?: string | null;
    updated_at?: string | null;
    district?: {
      __typename?: "DistrictData";
      id: string;
      name: string;
      district_id: string;
      source?: string | null;
      state?: { __typename?: "State"; id: string } | null;
    } | null;
  };
};

export type SchoolPredefinedGradeMappingsFragment = {
  __typename?: "School";
  id: string;
  district_id: string;
  name: string;
  school_id: string;
  school_predefined_grade_mappings?: Array<{
    __typename?: "SchoolPredefinedGradeMappings";
    id: string;
    school_id: string;
    predefined_grade_id: string;
    predefined_grades?: { __typename?: "PredefinedGrades"; id: string; grade: string; position: number } | null;
  } | null> | null;
};

export type SchoolPredefinedGradeMappingsQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type SchoolPredefinedGradeMappingsQuery = {
  __typename?: "Query";
  schoolDetails: {
    __typename?: "School";
    id: string;
    district_id: string;
    name: string;
    school_id: string;
    school_predefined_grade_mappings?: Array<{
      __typename?: "SchoolPredefinedGradeMappings";
      id: string;
      school_id: string;
      predefined_grade_id: string;
      predefined_grades?: { __typename?: "PredefinedGrades"; id: string; grade: string; position: number } | null;
    } | null> | null;
  };
};

export type PredefinedGradeFragmentFragment = {
  __typename?: "PredefinedGrades";
  id: string;
  grade: string;
  position: number;
};

export type PredefinedGradesListQueryVariables = Exact<{
  stateIDs?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  districtIDs?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  schoolIDs?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
}>;

export type PredefinedGradesListQuery = {
  __typename?: "Query";
  allPredefinedGrades: Array<{ __typename?: "PredefinedGrades"; id: string; grade: string; position: number }>;
};

export type SchoolFormMutationVariables = Exact<{
  input: SchoolInput;
}>;

export type SchoolFormMutation = { __typename?: "Mutation"; upsertSchool?: string | null };

export type SchoolFragmentFragment = {
  __typename?: "School";
  id: string;
  district_id: string;
  school_id: string;
  name: string;
  nces_id?: string | null;
  mdr_number?: string | null;
  district?: { __typename?: "DistrictData"; state_id?: string | null; source?: string | null } | null;
  school_predefined_grade_mappings?: Array<{
    __typename?: "SchoolPredefinedGradeMappings";
    id: string;
    school_id: string;
    predefined_grade_id: string;
    predefined_grades?: { __typename?: "PredefinedGrades"; id: string; grade: string; position: number } | null;
  } | null> | null;
};

export type SchoolEditDetailsQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type SchoolEditDetailsQuery = {
  __typename?: "Query";
  schoolDetails: {
    __typename?: "School";
    id: string;
    district_id: string;
    school_id: string;
    name: string;
    nces_id?: string | null;
    mdr_number?: string | null;
    district?: { __typename?: "DistrictData"; state_id?: string | null; source?: string | null } | null;
    school_predefined_grade_mappings?: Array<{
      __typename?: "SchoolPredefinedGradeMappings";
      id: string;
      school_id: string;
      predefined_grade_id: string;
      predefined_grades?: { __typename?: "PredefinedGrades"; id: string; grade: string; position: number } | null;
    } | null> | null;
  };
};

export type SchoolListDetailsFragment = {
  __typename?: "SchoolDetails";
  id: string;
  school_id: string;
  district_id: string;
  student_count: number;
  name: string;
  grade?: string | null;
  district_name: string;
  district_state_id?: string | null;
  district_source?: string | null;
  school_predefined_grade_mappings?: Array<{
    __typename?: "SchoolPredefinedGradeMappings";
    id: string;
    school_id: string;
    predefined_grade_id: string;
    predefined_grades?: { __typename?: "PredefinedGrades"; id: string; grade: string; position: number } | null;
  } | null> | null;
};

export type SchoolsQueryVariables = Exact<{
  stateId?: InputMaybe<Scalars["String"]>;
  districtId?: InputMaybe<Scalars["String"]>;
  agencyId?: InputMaybe<Scalars["String"]>;
  page?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<SchoolsListSortType>;
  searchByName?: InputMaybe<Scalars["String"]>;
  orderBy?: InputMaybe<OrderBy>;
}>;

export type SchoolsQuery = {
  __typename?: "Query";
  schoolsList: {
    __typename?: "SchoolsConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "SchoolDetails";
      id: string;
      school_id: string;
      district_id: string;
      student_count: number;
      name: string;
      grade?: string | null;
      district_name: string;
      district_state_id?: string | null;
      district_source?: string | null;
      school_predefined_grade_mappings?: Array<{
        __typename?: "SchoolPredefinedGradeMappings";
        id: string;
        school_id: string;
        predefined_grade_id: string;
        predefined_grades?: { __typename?: "PredefinedGrades"; id: string; grade: string; position: number } | null;
      } | null> | null;
    }>;
  };
};

export type AdditionalFeedbackMutationVariables = Exact<{
  input: AdditionalFeedbackInput;
}>;

export type AdditionalFeedbackMutation = {
  __typename?: "Mutation";
  updateAdditionalFeedBack: { __typename?: "Submission"; id: string };
};

export type DeleteFeedbackAudioMutationVariables = Exact<{
  id: Scalars["ID"];
  submission_id: Scalars["String"];
}>;

export type DeleteFeedbackAudioMutation = { __typename?: "Mutation"; deleteFeedbackAudio: boolean };

export type SubmissionRubricFragment = {
  __typename?: "Rubric";
  id: string;
  title: string;
  content: string;
  submission_type: string;
};

export type SubmissionGradeFragment = {
  __typename?: "Grade";
  id: string;
  score?: number | null;
  rubric: { __typename?: "Rubric"; id: string; title: string; content: string; submission_type: string };
};

export type SubmissionFeedbackFragment = {
  __typename?: "AdditionalFeedback";
  feedback_notes?: string | null;
  audio: Array<{ __typename?: "File"; id: string; url: string; file_type: string } | null>;
};

export type SubmissionTypeGoalFragment = {
  __typename?: "Goal";
  id: string;
  goal_status?: string | null;
  assigned_date: string;
  goal_details: {
    __typename?: "GoalsData";
    rubric: { __typename?: "Rubric"; id: string; title: string; content: string; submission_type: string };
    predefined_goals: Array<{
      __typename?: "CategorizedGoal";
      category: string;
      goal_list: Array<{ __typename?: "PredefinedGoal"; id: string; description: string; example?: string | null }>;
    }>;
  };
  teacher?: { __typename?: "User"; first_name: string; last_name: string } | null;
};

export type SubmissionGoalFragment = {
  __typename?: "UserGoal";
  speaking: Array<{
    __typename?: "Goal";
    id: string;
    goal_status?: string | null;
    assigned_date: string;
    goal_details: {
      __typename?: "GoalsData";
      rubric: { __typename?: "Rubric"; id: string; title: string; content: string; submission_type: string };
      predefined_goals: Array<{
        __typename?: "CategorizedGoal";
        category: string;
        goal_list: Array<{ __typename?: "PredefinedGoal"; id: string; description: string; example?: string | null }>;
      }>;
    };
    teacher?: { __typename?: "User"; first_name: string; last_name: string } | null;
  }>;
  writing: Array<{
    __typename?: "Goal";
    id: string;
    goal_status?: string | null;
    assigned_date: string;
    goal_details: {
      __typename?: "GoalsData";
      rubric: { __typename?: "Rubric"; id: string; title: string; content: string; submission_type: string };
      predefined_goals: Array<{
        __typename?: "CategorizedGoal";
        category: string;
        goal_list: Array<{ __typename?: "PredefinedGoal"; id: string; description: string; example?: string | null }>;
      }>;
    };
    teacher?: { __typename?: "User"; first_name: string; last_name: string } | null;
  }>;
};

export type SubmissionScoreFragmentFragment = {
  __typename?: "Submission";
  id: string;
  submitted_at?: string | null;
  status: SubmissionStatus;
  writing?: string | null;
  feedback_notes?: string | null;
  student?: {
    __typename?: "User";
    id: string;
    first_name: string;
    last_name: string;
    middle_name: string;
    student?: { __typename?: "Student"; grade?: string | null } | null;
  } | null;
  annotations: Array<{ __typename?: "Annotation"; id: string; title: string; x_position: number; y_position: number }>;
  speakings: Array<{ __typename?: "File"; id: string; url: string; file_type: string }>;
  grades: Array<{
    __typename?: "Grade";
    id: string;
    score?: number | null;
    rubric: { __typename?: "Rubric"; id: string; title: string; content: string; submission_type: string };
  }>;
  assignment?: {
    __typename?: "Assignment";
    id: string;
    title: string;
    resource?: {
      __typename?: "StaticResource";
      id: string;
      image_height: number;
      image_width: number;
      file: { __typename?: "File"; id: string; url: string; thumbnail_url?: string | null };
    } | null;
  } | null;
  getGoalsForSubmission: {
    __typename?: "UserGoal";
    speaking: Array<{
      __typename?: "Goal";
      id: string;
      goal_status?: string | null;
      assigned_date: string;
      goal_details: {
        __typename?: "GoalsData";
        rubric: { __typename?: "Rubric"; id: string; title: string; content: string; submission_type: string };
        predefined_goals: Array<{
          __typename?: "CategorizedGoal";
          category: string;
          goal_list: Array<{ __typename?: "PredefinedGoal"; id: string; description: string; example?: string | null }>;
        }>;
      };
      teacher?: { __typename?: "User"; first_name: string; last_name: string } | null;
    }>;
    writing: Array<{
      __typename?: "Goal";
      id: string;
      goal_status?: string | null;
      assigned_date: string;
      goal_details: {
        __typename?: "GoalsData";
        rubric: { __typename?: "Rubric"; id: string; title: string; content: string; submission_type: string };
        predefined_goals: Array<{
          __typename?: "CategorizedGoal";
          category: string;
          goal_list: Array<{ __typename?: "PredefinedGoal"; id: string; description: string; example?: string | null }>;
        }>;
      };
      teacher?: { __typename?: "User"; first_name: string; last_name: string } | null;
    }>;
  };
  getAdditionalFeedback: {
    __typename?: "AdditionalFeedback";
    feedback_notes?: string | null;
    audio: Array<{ __typename?: "File"; id: string; url: string; file_type: string } | null>;
  };
};

export type SubmissionQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type SubmissionQuery = {
  __typename?: "Query";
  submission?: {
    __typename?: "Submission";
    id: string;
    submitted_at?: string | null;
    status: SubmissionStatus;
    writing?: string | null;
    feedback_notes?: string | null;
    student?: {
      __typename?: "User";
      id: string;
      first_name: string;
      last_name: string;
      middle_name: string;
      student?: { __typename?: "Student"; grade?: string | null } | null;
    } | null;
    annotations: Array<{
      __typename?: "Annotation";
      id: string;
      title: string;
      x_position: number;
      y_position: number;
    }>;
    speakings: Array<{ __typename?: "File"; id: string; url: string; file_type: string }>;
    grades: Array<{
      __typename?: "Grade";
      id: string;
      score?: number | null;
      rubric: { __typename?: "Rubric"; id: string; title: string; content: string; submission_type: string };
    }>;
    assignment?: {
      __typename?: "Assignment";
      id: string;
      title: string;
      resource?: {
        __typename?: "StaticResource";
        id: string;
        image_height: number;
        image_width: number;
        file: { __typename?: "File"; id: string; url: string; thumbnail_url?: string | null };
      } | null;
    } | null;
    getGoalsForSubmission: {
      __typename?: "UserGoal";
      speaking: Array<{
        __typename?: "Goal";
        id: string;
        goal_status?: string | null;
        assigned_date: string;
        goal_details: {
          __typename?: "GoalsData";
          rubric: { __typename?: "Rubric"; id: string; title: string; content: string; submission_type: string };
          predefined_goals: Array<{
            __typename?: "CategorizedGoal";
            category: string;
            goal_list: Array<{
              __typename?: "PredefinedGoal";
              id: string;
              description: string;
              example?: string | null;
            }>;
          }>;
        };
        teacher?: { __typename?: "User"; first_name: string; last_name: string } | null;
      }>;
      writing: Array<{
        __typename?: "Goal";
        id: string;
        goal_status?: string | null;
        assigned_date: string;
        goal_details: {
          __typename?: "GoalsData";
          rubric: { __typename?: "Rubric"; id: string; title: string; content: string; submission_type: string };
          predefined_goals: Array<{
            __typename?: "CategorizedGoal";
            category: string;
            goal_list: Array<{
              __typename?: "PredefinedGoal";
              id: string;
              description: string;
              example?: string | null;
            }>;
          }>;
        };
        teacher?: { __typename?: "User"; first_name: string; last_name: string } | null;
      }>;
    };
    getAdditionalFeedback: {
      __typename?: "AdditionalFeedback";
      feedback_notes?: string | null;
      audio: Array<{ __typename?: "File"; id: string; url: string; file_type: string } | null>;
    };
  } | null;
};

export type NewSubmissionQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type NewSubmissionQuery = {
  __typename?: "Query";
  newSubmission: {
    __typename?: "NewSubmission";
    id: string;
    submitted_at?: string | null;
    status: SubmissionStatus;
    writing?: string | null;
    feedback_notes?: string | null;
    student?: {
      __typename?: "User";
      id: string;
      first_name: string;
      last_name: string;
      middle_name: string;
      student?: { __typename?: "Student"; grade?: string | null } | null;
    } | null;
    annotations: Array<{
      __typename?: "Annotation";
      id: string;
      title: string;
      x_position: number;
      y_position: number;
    }>;
    speakings: Array<{ __typename?: "File"; id: string; url: string; file_type: string }>;
    assignment?: {
      __typename?: "Assignment";
      id: string;
      title: string;
      speaking_prompt: string;
      writing_prompt: string;
      is_common_prompt: boolean;
      resource?: {
        __typename?: "StaticResource";
        id: string;
        image_height: number;
        image_width: number;
        file: { __typename?: "File"; id: string; url: string; thumbnail_url?: string | null };
      } | null;
      speaking_prompt_file?: {
        __typename?: "PromptFile";
        id: string;
        url: string;
        file_name: string;
        file_type: string;
      } | null;
      writing_prompt_file?: {
        __typename?: "PromptFile";
        id: string;
        url: string;
        file_name: string;
        file_type: string;
      } | null;
    } | null;
    scoring_details: Array<{
      __typename?: "ScoreDetail";
      id: string;
      title_key: string;
      title: string;
      score_value: number;
      description: string;
      color_code: string;
    }>;
    submission_type_scores?: Array<{
      __typename?: "SubmissionTypeGroup";
      submission_type: string;
      rubric_line_items: Array<{
        __typename?: "SubmissionTypeRubricScoreLineItem";
        grade_id: string;
        rubric_id: string;
        rubric_title: string;
        rubric_content: string;
        rubric_title_key: string;
        score_detail_id?: string | null;
      }>;
    }> | null;
    getGoalsForSubmission: {
      __typename?: "UserGoal";
      speaking: Array<{
        __typename?: "Goal";
        id: string;
        goal_status?: string | null;
        assigned_date: string;
        goal_details: {
          __typename?: "GoalsData";
          rubric: { __typename?: "Rubric"; id: string; title: string; content: string; submission_type: string };
          predefined_goals: Array<{
            __typename?: "CategorizedGoal";
            category: string;
            goal_list: Array<{
              __typename?: "PredefinedGoal";
              id: string;
              description: string;
              example?: string | null;
            }>;
          }>;
        };
        teacher?: { __typename?: "User"; first_name: string; last_name: string } | null;
      }>;
      writing: Array<{
        __typename?: "Goal";
        id: string;
        goal_status?: string | null;
        assigned_date: string;
        goal_details: {
          __typename?: "GoalsData";
          rubric: { __typename?: "Rubric"; id: string; title: string; content: string; submission_type: string };
          predefined_goals: Array<{
            __typename?: "CategorizedGoal";
            category: string;
            goal_list: Array<{
              __typename?: "PredefinedGoal";
              id: string;
              description: string;
              example?: string | null;
            }>;
          }>;
        };
        teacher?: { __typename?: "User"; first_name: string; last_name: string } | null;
      }>;
    };
    getAdditionalFeedback: {
      __typename?: "AdditionalFeedback";
      feedback_notes?: string | null;
      audio: Array<{ __typename?: "File"; id: string; url: string; file_type: string } | null>;
    };
  };
};

export type NewSubmissionScoreFragmentFragment = {
  __typename?: "NewSubmission";
  id: string;
  submitted_at?: string | null;
  status: SubmissionStatus;
  writing?: string | null;
  feedback_notes?: string | null;
  student?: {
    __typename?: "User";
    id: string;
    first_name: string;
    last_name: string;
    middle_name: string;
    student?: { __typename?: "Student"; grade?: string | null } | null;
  } | null;
  annotations: Array<{ __typename?: "Annotation"; id: string; title: string; x_position: number; y_position: number }>;
  speakings: Array<{ __typename?: "File"; id: string; url: string; file_type: string }>;
  assignment?: {
    __typename?: "Assignment";
    id: string;
    title: string;
    speaking_prompt: string;
    writing_prompt: string;
    is_common_prompt: boolean;
    resource?: {
      __typename?: "StaticResource";
      id: string;
      image_height: number;
      image_width: number;
      file: { __typename?: "File"; id: string; url: string; thumbnail_url?: string | null };
    } | null;
    speaking_prompt_file?: {
      __typename?: "PromptFile";
      id: string;
      url: string;
      file_name: string;
      file_type: string;
    } | null;
    writing_prompt_file?: {
      __typename?: "PromptFile";
      id: string;
      url: string;
      file_name: string;
      file_type: string;
    } | null;
  } | null;
  scoring_details: Array<{
    __typename?: "ScoreDetail";
    id: string;
    title_key: string;
    title: string;
    score_value: number;
    description: string;
    color_code: string;
  }>;
  submission_type_scores?: Array<{
    __typename?: "SubmissionTypeGroup";
    submission_type: string;
    rubric_line_items: Array<{
      __typename?: "SubmissionTypeRubricScoreLineItem";
      grade_id: string;
      rubric_id: string;
      rubric_title: string;
      rubric_content: string;
      rubric_title_key: string;
      score_detail_id?: string | null;
    }>;
  }> | null;
  getGoalsForSubmission: {
    __typename?: "UserGoal";
    speaking: Array<{
      __typename?: "Goal";
      id: string;
      goal_status?: string | null;
      assigned_date: string;
      goal_details: {
        __typename?: "GoalsData";
        rubric: { __typename?: "Rubric"; id: string; title: string; content: string; submission_type: string };
        predefined_goals: Array<{
          __typename?: "CategorizedGoal";
          category: string;
          goal_list: Array<{ __typename?: "PredefinedGoal"; id: string; description: string; example?: string | null }>;
        }>;
      };
      teacher?: { __typename?: "User"; first_name: string; last_name: string } | null;
    }>;
    writing: Array<{
      __typename?: "Goal";
      id: string;
      goal_status?: string | null;
      assigned_date: string;
      goal_details: {
        __typename?: "GoalsData";
        rubric: { __typename?: "Rubric"; id: string; title: string; content: string; submission_type: string };
        predefined_goals: Array<{
          __typename?: "CategorizedGoal";
          category: string;
          goal_list: Array<{ __typename?: "PredefinedGoal"; id: string; description: string; example?: string | null }>;
        }>;
      };
      teacher?: { __typename?: "User"; first_name: string; last_name: string } | null;
    }>;
  };
  getAdditionalFeedback: {
    __typename?: "AdditionalFeedback";
    feedback_notes?: string | null;
    audio: Array<{ __typename?: "File"; id: string; url: string; file_type: string } | null>;
  };
};

export type ScoreDetailFragmentFragment = {
  __typename?: "ScoreDetail";
  id: string;
  title_key: string;
  title: string;
  score_value: number;
  description: string;
  color_code: string;
};

export type SubmissionTypeGroupFragmentFragment = {
  __typename?: "SubmissionTypeGroup";
  submission_type: string;
  rubric_line_items: Array<{
    __typename?: "SubmissionTypeRubricScoreLineItem";
    grade_id: string;
    rubric_id: string;
    rubric_title: string;
    rubric_content: string;
    rubric_title_key: string;
    score_detail_id?: string | null;
  }>;
};

export type RubricScoreLineItemFragmentFragment = {
  __typename?: "SubmissionTypeRubricScoreLineItem";
  grade_id: string;
  rubric_id: string;
  rubric_title: string;
  rubric_content: string;
  rubric_title_key: string;
  score_detail_id?: string | null;
};

export type LastInactiveGoalFragment = {
  __typename?: "Goal";
  id: string;
  submission_id: string;
  target_submission_id?: string | null;
  goal_details: {
    __typename?: "GoalsData";
    rubric: { __typename?: "Rubric"; id: string; title: string; content: string; submission_type: string };
    predefined_goals: Array<{
      __typename?: "CategorizedGoal";
      category: string;
      goal_list: Array<{ __typename?: "PredefinedGoal"; id: string; description: string; example?: string | null }>;
    }>;
  };
};

export type SubmissionGoalInfoFragmentFragment = {
  __typename?: "SubmissionTypeGoalInfo";
  speaking?: { __typename?: "SubmissionGoalInfo"; inactive_goal_count?: number | null } | null;
  writing?: { __typename?: "SubmissionGoalInfo"; inactive_goal_count?: number | null } | null;
};

export type SubmissionGoalInfoQueryVariables = Exact<{
  submissionID: Scalars["ID"];
}>;

export type SubmissionGoalInfoQuery = {
  __typename?: "Query";
  getSubmissionGoalInfo: {
    __typename?: "SubmissionTypeGoalInfo";
    speaking?: { __typename?: "SubmissionGoalInfo"; inactive_goal_count?: number | null } | null;
    writing?: { __typename?: "SubmissionGoalInfo"; inactive_goal_count?: number | null } | null;
  };
};

export type SubmissionsIdQueueQueryVariables = Exact<{
  sort?: InputMaybe<Scalars["String"]>;
  orderBy?: InputMaybe<OrderBy>;
  search?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Array<InputMaybe<SubmissionStatus>> | InputMaybe<SubmissionStatus>>;
  section_id?: InputMaybe<Scalars["String"]>;
  school_id?: InputMaybe<Scalars["String"]>;
}>;

export type SubmissionsIdQueueQuery = {
  __typename?: "Query";
  submissionQueue?: { __typename?: "SubmissionQueueResponse"; IDs?: Array<string> | null } | null;
};

export type MarkReviewCompleteMutationVariables = Exact<{
  submissionId: Scalars["String"];
}>;

export type MarkReviewCompleteMutation = {
  __typename?: "Mutation";
  markReviewComplete: { __typename?: "Submission"; status: SubmissionStatus };
};

export type UpdateFeedbackNotesMutationVariables = Exact<{
  id: Scalars["ID"];
  notes?: InputMaybe<Scalars["String"]>;
}>;

export type UpdateFeedbackNotesMutation = {
  __typename?: "Mutation";
  updateFeedbackNotes: { __typename?: "Submission"; id: string; feedback_notes?: string | null };
};

export type UpdateScoreMutationVariables = Exact<{
  gradeId: Scalars["String"];
  scoreDetailID: Scalars["String"];
}>;

export type UpdateScoreMutation = { __typename?: "Mutation"; updateScore: string };

export type UpdateBenchmarkScoreMutationVariables = Exact<{
  submissionId?: InputMaybe<Scalars["ID"]>;
  gradeId: Scalars["String"];
  scoreDetailID: Scalars["String"];
}>;

export type UpdateBenchmarkScoreMutation = { __typename?: "Mutation"; updateBenchmarkScore: string };

export type UpdateBenchmarkFeedbackNotesMutationVariables = Exact<{
  id: Scalars["ID"];
  notes?: InputMaybe<Scalars["String"]>;
}>;

export type UpdateBenchmarkFeedbackNotesMutation = {
  __typename?: "Mutation";
  updateBenchmarkFeedbackNotes: { __typename?: "Submission"; id: string; feedback_notes?: string | null };
};

export type UpdateBenchmarkAdditionalFeedBackMutationVariables = Exact<{
  input: AdditionalFeedbackInput;
}>;

export type UpdateBenchmarkAdditionalFeedBackMutation = {
  __typename?: "Mutation";
  updateBenchmarkAdditionalFeedBack: { __typename?: "Submission"; id: string };
};

export type DeleteBenchmarkFeedbackAudioMutationVariables = Exact<{
  id: Scalars["ID"];
  submission_id: Scalars["String"];
}>;

export type DeleteBenchmarkFeedbackAudioMutation = { __typename?: "Mutation"; benchmarkDeleteFeedbackAudio: boolean };

export type MarkBenchmarkReviewCompleteMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type MarkBenchmarkReviewCompleteMutation = {
  __typename?: "Mutation";
  markBenchmarkReviewComplete: { __typename?: "Submission"; status: SubmissionStatus };
};

export type BenchmarkScoreDetailFragmentFragment = {
  __typename?: "ScoreDetail";
  id: string;
  title_key: string;
  title: string;
  score_value: number;
  description: string;
  color_code: string;
};

export type BenchmarkSubmissionFeedbackFragment = {
  __typename?: "AdditionalFeedback";
  feedback_notes?: string | null;
  audio: Array<{ __typename?: "File"; id: string; url: string; file_type: string } | null>;
};

export type BenchmarkSubmissionTypeGroupFragmentFragment = {
  __typename?: "SubmissionTypeGroup";
  submission_type: string;
  rubric_line_items: Array<{
    __typename?: "SubmissionTypeRubricScoreLineItem";
    grade_id: string;
    rubric_id: string;
    rubric_title: string;
    rubric_content: string;
    rubric_title_key: string;
    score_detail_id?: string | null;
  }>;
};

export type BenchmarkSubmissionScoreFragmentFragment = {
  __typename?: "BenchmarkSubmission";
  id: string;
  writing?: string | null;
  submitted_at?: string | null;
  status: SubmissionStatus;
  reviewed_at?: string | null;
  teacher_id: string;
  feedback_notes?: string | null;
  annotationsBM: Array<{
    __typename?: "Annotation";
    id: string;
    title: string;
    x_position: number;
    y_position: number;
  }>;
  speakings: Array<{ __typename?: "File"; id: string; url: string; file_type: string }>;
  grading_status: {
    __typename?: "GradingStatus";
    IsSpeakingScored: boolean;
    IsWritingScored: boolean;
    IsReviewed: boolean;
  };
  additional_feedback: {
    __typename?: "AdditionalFeedback";
    feedback_notes?: string | null;
    audio: Array<{ __typename?: "File"; id: string; url: string; file_type: string } | null>;
  };
  scoring_details?: Array<{
    __typename?: "ScoreDetail";
    id: string;
    title_key: string;
    title: string;
    score_value: number;
    description: string;
    color_code: string;
  } | null> | null;
  submission_type_scores?: Array<{
    __typename?: "SubmissionTypeGroup";
    submission_type: string;
    rubric_line_items: Array<{
      __typename?: "SubmissionTypeRubricScoreLineItem";
      grade_id: string;
      rubric_id: string;
      rubric_title: string;
      rubric_content: string;
      rubric_title_key: string;
      score_detail_id?: string | null;
    }>;
  }> | null;
  student: {
    __typename?: "User";
    id: string;
    first_name: string;
    last_name: string;
    middle_name: string;
    student?: { __typename?: "Student"; grade?: string | null } | null;
  };
  benchmark: {
    __typename?: "BenchmarkPT";
    id: string;
    benchmark_type: BenchmarkType;
    title: string;
    graded_by: BenchmarkGradedBy;
    speaking_prompt: string;
    writing_prompt: string;
    is_common_prompt: boolean;
    resource: {
      __typename?: "StaticResource";
      id: string;
      image_height: number;
      image_width: number;
      file: { __typename?: "File"; id: string; url: string; thumbnail_url?: string | null };
    };
    speaking_prompt_file?: {
      __typename?: "PromptFile";
      id: string;
      url: string;
      file_name: string;
      file_type: string;
    } | null;
    writing_prompt_file?: {
      __typename?: "PromptFile";
      id: string;
      url: string;
      file_name: string;
      file_type: string;
    } | null;
  };
};

export type BenchmarkSubmissionQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type BenchmarkSubmissionQuery = {
  __typename?: "Query";
  benchmarkSubmission: {
    __typename?: "BenchmarkSubmission";
    id: string;
    writing?: string | null;
    submitted_at?: string | null;
    status: SubmissionStatus;
    reviewed_at?: string | null;
    teacher_id: string;
    feedback_notes?: string | null;
    annotationsBM: Array<{
      __typename?: "Annotation";
      id: string;
      title: string;
      x_position: number;
      y_position: number;
    }>;
    speakings: Array<{ __typename?: "File"; id: string; url: string; file_type: string }>;
    grading_status: {
      __typename?: "GradingStatus";
      IsSpeakingScored: boolean;
      IsWritingScored: boolean;
      IsReviewed: boolean;
    };
    additional_feedback: {
      __typename?: "AdditionalFeedback";
      feedback_notes?: string | null;
      audio: Array<{ __typename?: "File"; id: string; url: string; file_type: string } | null>;
    };
    scoring_details?: Array<{
      __typename?: "ScoreDetail";
      id: string;
      title_key: string;
      title: string;
      score_value: number;
      description: string;
      color_code: string;
    } | null> | null;
    submission_type_scores?: Array<{
      __typename?: "SubmissionTypeGroup";
      submission_type: string;
      rubric_line_items: Array<{
        __typename?: "SubmissionTypeRubricScoreLineItem";
        grade_id: string;
        rubric_id: string;
        rubric_title: string;
        rubric_content: string;
        rubric_title_key: string;
        score_detail_id?: string | null;
      }>;
    }> | null;
    student: {
      __typename?: "User";
      id: string;
      first_name: string;
      last_name: string;
      middle_name: string;
      student?: { __typename?: "Student"; grade?: string | null } | null;
    };
    benchmark: {
      __typename?: "BenchmarkPT";
      id: string;
      benchmark_type: BenchmarkType;
      title: string;
      graded_by: BenchmarkGradedBy;
      speaking_prompt: string;
      writing_prompt: string;
      is_common_prompt: boolean;
      resource: {
        __typename?: "StaticResource";
        id: string;
        image_height: number;
        image_width: number;
        file: { __typename?: "File"; id: string; url: string; thumbnail_url?: string | null };
      };
      speaking_prompt_file?: {
        __typename?: "PromptFile";
        id: string;
        url: string;
        file_name: string;
        file_type: string;
      } | null;
      writing_prompt_file?: {
        __typename?: "PromptFile";
        id: string;
        url: string;
        file_name: string;
        file_type: string;
      } | null;
    };
  };
};

export type TeacherBenchmarkGradingQueueQueryVariables = Exact<{
  grade_statuses?: InputMaybe<Array<SubmissionStatus> | SubmissionStatus>;
  benchmark_types?: InputMaybe<Array<BenchmarkType> | BenchmarkType>;
  page?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<TeacherBenchmarkSubmissionListSortType>;
  order_by?: InputMaybe<OrderBy>;
  graded_by?: InputMaybe<Array<BenchmarkGradedBy> | BenchmarkGradedBy>;
}>;

export type TeacherBenchmarkGradingQueueQuery = {
  __typename?: "Query";
  teacherBenchmarkGradingQueue?: Array<string> | null;
};

export type EditAdminResourceMutationVariables = Exact<{
  input: UpdateAdminResourceInput;
}>;

export type EditAdminResourceMutation = { __typename?: "Mutation"; updateAdminImageResource: string };

export type DeleteAdminResourceMutationVariables = Exact<{
  input: Scalars["String"];
}>;

export type DeleteAdminResourceMutation = { __typename?: "Mutation"; archiveStaticResource?: string | null };

export type StaticResourceDetailsQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type StaticResourceDetailsQuery = {
  __typename?: "Query";
  staticResource: {
    __typename?: "AdminResource";
    id: string;
    title: string;
    grade: Array<string>;
    thumbnail_height: number;
    thumbnail_width: number;
    subject_areas: Array<string>;
    task_types: Array<string>;
    keywords: Array<string>;
    file: {
      __typename?: "AdminFile";
      id: string;
      url: string;
      file_type: string;
      file_key: string;
      thumbnail_url?: string | null;
      thumbnail_file_type?: string | null;
      thumbnail_file_key?: string | null;
    };
  };
};

export type CreateSuperAdminMutationVariables = Exact<{
  ID?: InputMaybe<Scalars["String"]>;
  firstName: Scalars["String"];
  middleName: Scalars["String"];
  lastName: Scalars["String"];
  adminID: Scalars["String"];
  title: Scalars["String"];
  email: Scalars["String"];
  phoneNumber: Scalars["String"];
  password?: InputMaybe<Scalars["String"]>;
}>;

export type CreateSuperAdminMutation = { __typename?: "Mutation"; upsertSuperAdmin: string };

export type GetSuperAdminDetailsQueryVariables = Exact<{
  Id: Scalars["ID"];
}>;

export type GetSuperAdminDetailsQuery = {
  __typename?: "Query";
  superAdminDetails: {
    __typename?: "SuperAdmin";
    id: string;
    title: string;
    user_id: string;
    first_name: string;
    admin_id: string;
    email: string;
    last_name: string;
    middle_name?: string | null;
    phone_number?: string | null;
  };
};

export type ListSuperAdminQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<AdminListSortType>;
  orderBy?: InputMaybe<OrderBy>;
  limit?: InputMaybe<Scalars["Int"]>;
  searchByName?: InputMaybe<Scalars["String"]>;
}>;

export type ListSuperAdminQuery = {
  __typename?: "Query";
  superAdminList: {
    __typename?: "AdminConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "Admin";
      id: string;
      user_id: string;
      first_name: string;
      middle_name?: string | null;
      last_name: string;
      job_title: string;
      updated_by_first_name?: string | null;
      updated_by_last_name?: string | null;
      updated_at?: string | null;
      email: string;
    }>;
  };
};

export type AdminResourceMutationVariables = Exact<{
  input: CreateAdminResourceInput;
}>;

export type AdminResourceMutation = { __typename?: "Mutation"; createAdminImageResource: Array<string> };

export type SubjectAreasQueryVariables = Exact<{ [key: string]: never }>;

export type SubjectAreasQuery = {
  __typename?: "Query";
  subjectArea?: Array<{ __typename?: "SubjectArea"; id?: string | null; title?: string | null }> | null;
};

export type BackpackAssignmentFragment = {
  __typename?: "Assignment";
  id: string;
  title: string;
  close_at: string;
  start_at: string;
  is_common_prompt: boolean;
  speaking_prompt: string;
  writing_prompt: string;
  resource?: {
    __typename?: "StaticResource";
    id: string;
    image_height: number;
    image_width: number;
    file: { __typename?: "File"; id: string; url: string; thumbnail_url?: string | null };
  } | null;
  speaking_prompt_file?: {
    __typename?: "PromptFile";
    id: string;
    url: string;
    file_type: string;
    file_name: string;
  } | null;
  writing_prompt_file?: {
    __typename?: "PromptFile";
    id: string;
    url: string;
    file_type: string;
    file_name: string;
  } | null;
};

export type BackpackBenchmarkFragment = {
  __typename?: "BenchmarkPT";
  id: string;
  title: string;
  close_at: string;
  start_at: string;
  graded_by: BenchmarkGradedBy;
  benchmark_type: BenchmarkType;
  is_common_prompt: boolean;
  speaking_prompt: string;
  writing_prompt: string;
  resource: {
    __typename?: "StaticResource";
    id: string;
    image_height: number;
    image_width: number;
    file: { __typename?: "File"; id: string; url: string; thumbnail_url?: string | null };
  };
  speaking_prompt_file?: {
    __typename?: "PromptFile";
    id: string;
    url: string;
    file_type: string;
    file_name: string;
  } | null;
  writing_prompt_file?: {
    __typename?: "PromptFile";
    id: string;
    url: string;
    file_type: string;
    file_name: string;
  } | null;
};

export type BackPackSubmissionFragment = {
  __typename?: "Submission";
  id: string;
  status: SubmissionStatus;
  writing?: string | null;
  submitted_at?: string | null;
  speakings: Array<{ __typename?: "File"; url: string; file_type: string }>;
  annotations: Array<{ __typename?: "Annotation"; id: string; x_position: number; y_position: number; title: string }>;
  assignment?: {
    __typename?: "Assignment";
    id: string;
    title: string;
    close_at: string;
    start_at: string;
    is_common_prompt: boolean;
    speaking_prompt: string;
    writing_prompt: string;
    resource?: {
      __typename?: "StaticResource";
      id: string;
      image_height: number;
      image_width: number;
      file: { __typename?: "File"; id: string; url: string; thumbnail_url?: string | null };
    } | null;
    speaking_prompt_file?: {
      __typename?: "PromptFile";
      id: string;
      url: string;
      file_type: string;
      file_name: string;
    } | null;
    writing_prompt_file?: {
      __typename?: "PromptFile";
      id: string;
      url: string;
      file_type: string;
      file_name: string;
    } | null;
  } | null;
  getBenchmark?: {
    __typename?: "BenchmarkPT";
    id: string;
    title: string;
    close_at: string;
    start_at: string;
    graded_by: BenchmarkGradedBy;
    benchmark_type: BenchmarkType;
    is_common_prompt: boolean;
    speaking_prompt: string;
    writing_prompt: string;
    resource: {
      __typename?: "StaticResource";
      id: string;
      image_height: number;
      image_width: number;
      file: { __typename?: "File"; id: string; url: string; thumbnail_url?: string | null };
    };
    speaking_prompt_file?: {
      __typename?: "PromptFile";
      id: string;
      url: string;
      file_type: string;
      file_name: string;
    } | null;
    writing_prompt_file?: {
      __typename?: "PromptFile";
      id: string;
      url: string;
      file_type: string;
      file_name: string;
    } | null;
  } | null;
  getAdditionalFeedback: {
    __typename?: "AdditionalFeedback";
    feedback_notes?: string | null;
    audio: Array<{ __typename?: "File"; id: string; url: string; file_type: string } | null>;
  };
};

export type GetBackPackSubmissionQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetBackPackSubmissionQuery = {
  __typename?: "Query";
  submission?: {
    __typename?: "Submission";
    id: string;
    status: SubmissionStatus;
    writing?: string | null;
    submitted_at?: string | null;
    speakings: Array<{ __typename?: "File"; url: string; file_type: string }>;
    annotations: Array<{
      __typename?: "Annotation";
      id: string;
      x_position: number;
      y_position: number;
      title: string;
    }>;
    assignment?: {
      __typename?: "Assignment";
      id: string;
      title: string;
      close_at: string;
      start_at: string;
      is_common_prompt: boolean;
      speaking_prompt: string;
      writing_prompt: string;
      resource?: {
        __typename?: "StaticResource";
        id: string;
        image_height: number;
        image_width: number;
        file: { __typename?: "File"; id: string; url: string; thumbnail_url?: string | null };
      } | null;
      speaking_prompt_file?: {
        __typename?: "PromptFile";
        id: string;
        url: string;
        file_type: string;
        file_name: string;
      } | null;
      writing_prompt_file?: {
        __typename?: "PromptFile";
        id: string;
        url: string;
        file_type: string;
        file_name: string;
      } | null;
    } | null;
    getBenchmark?: {
      __typename?: "BenchmarkPT";
      id: string;
      title: string;
      close_at: string;
      start_at: string;
      graded_by: BenchmarkGradedBy;
      benchmark_type: BenchmarkType;
      is_common_prompt: boolean;
      speaking_prompt: string;
      writing_prompt: string;
      resource: {
        __typename?: "StaticResource";
        id: string;
        image_height: number;
        image_width: number;
        file: { __typename?: "File"; id: string; url: string; thumbnail_url?: string | null };
      };
      speaking_prompt_file?: {
        __typename?: "PromptFile";
        id: string;
        url: string;
        file_type: string;
        file_name: string;
      } | null;
      writing_prompt_file?: {
        __typename?: "PromptFile";
        id: string;
        url: string;
        file_type: string;
        file_name: string;
      } | null;
    } | null;
    getAdditionalFeedback: {
      __typename?: "AdditionalFeedback";
      feedback_notes?: string | null;
      audio: Array<{ __typename?: "File"; id: string; url: string; file_type: string } | null>;
    };
  } | null;
};

export type StudentViewScoreSummaryFragment = {
  __typename?: "StudentViewScoreSummary";
  max_score_count: number;
  score_details: Array<{ __typename?: "ScoreDetail"; title_key: string; color_code: string }>;
  rubric_groups: Array<{
    __typename?: "StudentViewScoreSummaryRubricGroup";
    submission_type: string;
    score_line_items: Array<{
      __typename?: "StudentViewScoreLineItem";
      rubric_id: string;
      rubric_title: string;
      rubric_icon_base_64: string;
      score_detail_id: string;
      score_detail_title: string;
      score_detail_description: string;
      score_detail_color_code: string;
      count: number;
    }>;
  }>;
};

export type GetScoreSummaryQueryVariables = Exact<{
  assignmentIDs?: InputMaybe<Array<Scalars["ID"]> | Scalars["ID"]>;
  sectionIDs?: InputMaybe<Array<Scalars["ID"]> | Scalars["ID"]>;
  submissionID: Scalars["ID"];
  teacherID?: InputMaybe<Scalars["ID"]>;
}>;

export type GetScoreSummaryQuery = {
  __typename?: "Query";
  scoreSummaryForStudent: {
    __typename?: "StudentViewScoreSummary";
    max_score_count: number;
    score_details: Array<{ __typename?: "ScoreDetail"; title_key: string; color_code: string }>;
    rubric_groups: Array<{
      __typename?: "StudentViewScoreSummaryRubricGroup";
      submission_type: string;
      score_line_items: Array<{
        __typename?: "StudentViewScoreLineItem";
        rubric_id: string;
        rubric_title: string;
        rubric_icon_base_64: string;
        score_detail_id: string;
        score_detail_title: string;
        score_detail_description: string;
        score_detail_color_code: string;
        count: number;
      }>;
    }>;
  };
};

export type StudentAssignmentsQueryVariables = Exact<{
  input?: InputMaybe<StudentAssignmentAndBenchmarkInput>;
}>;

export type StudentAssignmentsQuery = {
  __typename?: "Query";
  getAllAssignmentsAndBenchmarksForStudent: {
    __typename?: "StudentBenchmarkAndAssignmentResult";
    total_count: number;
    score_details: Array<{ __typename?: "ScoreDetail"; score_value: number; color_code: string }>;
    assignments: Array<{
      __typename?: "StudentAssignment";
      id: string;
      title: string;
      start_at: string;
      close_at: string;
      section_id?: string | null;
      section_name?: string | null;
      submission_id?: string | null;
      submission_status?: string | null;
      is_common_prompt: boolean;
      resource_id: string;
      resource_url: string;
      thumbnail_url?: string | null;
      submitted_at?: string | null;
      teacher_user_id?: string | null;
      teachers?: Array<string> | null;
      speaking_prompt_url: string;
      writing_prompt_title: string;
      writing_prompt_url: string;
      is_submissions_started: boolean;
      back_navigation_allowed: boolean;
      speaking_prompt_title: string;
      submission_speaking_average_score?: number | null;
      submission_writing_average_score?: number | null;
    }>;
    benchmarks: Array<{
      __typename?: "StudentBenchmark";
      id: string;
      title: string;
      start_at: string;
      close_at: string;
      resource_id: string;
      resource_url: string;
      thumbnail_url?: string | null;
      back_navigation_allowed: boolean;
      speaking_prompt_title?: string | null;
      speaking_prompt_url?: string | null;
      writing_prompt_title?: string | null;
      writing_prompt_url?: string | null;
      is_common_prompt: boolean;
      submission_id?: string | null;
      submission_status?: string | null;
      submission_speaking_average_score?: number | null;
      submission_writing_average_score?: number | null;
      submitted_at?: string | null;
    }>;
  };
};

export type BenchmarkForStudentFragment = {
  __typename?: "StudentBenchmark";
  id: string;
  title: string;
  start_at: string;
  close_at: string;
  resource_id: string;
  resource_url: string;
  thumbnail_url?: string | null;
  back_navigation_allowed: boolean;
  speaking_prompt_title?: string | null;
  speaking_prompt_url?: string | null;
  writing_prompt_title?: string | null;
  writing_prompt_url?: string | null;
  is_common_prompt: boolean;
  submission_id?: string | null;
  submission_status?: string | null;
  submission_speaking_average_score?: number | null;
  submission_writing_average_score?: number | null;
  submitted_at?: string | null;
};

export type AssignmentForStudentFragment = {
  __typename?: "StudentAssignment";
  id: string;
  title: string;
  start_at: string;
  close_at: string;
  section_id?: string | null;
  section_name?: string | null;
  submission_id?: string | null;
  submission_status?: string | null;
  is_common_prompt: boolean;
  resource_id: string;
  resource_url: string;
  thumbnail_url?: string | null;
  submitted_at?: string | null;
  teacher_user_id?: string | null;
  teachers?: Array<string> | null;
  speaking_prompt_url: string;
  writing_prompt_title: string;
  writing_prompt_url: string;
  is_submissions_started: boolean;
  back_navigation_allowed: boolean;
  speaking_prompt_title: string;
  submission_speaking_average_score?: number | null;
  submission_writing_average_score?: number | null;
};

export type GetAllAssignmentsForStudentQueryVariables = Exact<{
  input?: InputMaybe<StudentAssignmentAndBenchmarkInput>;
}>;

export type GetAllAssignmentsForStudentQuery = {
  __typename?: "Query";
  getAllAssignmentsAndBenchmarksForStudent: {
    __typename?: "StudentBenchmarkAndAssignmentResult";
    total_count: number;
    assignments: Array<{
      __typename?: "StudentAssignment";
      id: string;
      title: string;
      start_at: string;
      close_at: string;
      section_id?: string | null;
      section_name?: string | null;
      submission_id?: string | null;
      submission_status?: string | null;
      is_common_prompt: boolean;
      resource_id: string;
      resource_url: string;
      thumbnail_url?: string | null;
      submitted_at?: string | null;
      teacher_user_id?: string | null;
      teachers?: Array<string> | null;
      speaking_prompt_url: string;
      writing_prompt_title: string;
      writing_prompt_url: string;
      is_submissions_started: boolean;
      back_navigation_allowed: boolean;
      speaking_prompt_title: string;
      submission_speaking_average_score?: number | null;
      submission_writing_average_score?: number | null;
    }>;
    benchmarks: Array<{
      __typename?: "StudentBenchmark";
      id: string;
      title: string;
      start_at: string;
      close_at: string;
      resource_id: string;
      resource_url: string;
      thumbnail_url?: string | null;
      back_navigation_allowed: boolean;
      speaking_prompt_title?: string | null;
      speaking_prompt_url?: string | null;
      writing_prompt_title?: string | null;
      writing_prompt_url?: string | null;
      is_common_prompt: boolean;
      submission_id?: string | null;
      submission_status?: string | null;
      submission_speaking_average_score?: number | null;
      submission_writing_average_score?: number | null;
      submitted_at?: string | null;
    }>;
  };
};

export type StudentDashboardAssignmentFragment = {
  __typename?: "StudentAssignmentData";
  id: string;
  title: string;
  start_at: string;
  close_at: string;
  section_id?: string | null;
  resource_id: string;
  resource_url: string;
  thumbnail_url?: string | null;
  submission_id?: string | null;
  submission_status?: string | null;
  teacher_user_id?: string | null;
  teachers?: Array<string> | null;
};

export type StudentDashboardBenchmarkFragment = {
  __typename?: "StudentBenchmarkData";
  id: string;
  title: string;
  start_at: string;
  close_at: string;
  resource_id: string;
  resource_url: string;
  thumbnail_url?: string | null;
  submission_id?: string | null;
  submission_status?: string | null;
};

export type GetTeachersAssociatedWithSectionsQueryVariables = Exact<{
  sectionIDs?: InputMaybe<Array<Scalars["ID"]> | Scalars["ID"]>;
}>;

export type GetTeachersAssociatedWithSectionsQuery = {
  __typename?: "Query";
  teachersOfSection?: Array<{
    __typename?: "SectionDTO";
    id: string;
    teachers: Array<{
      __typename?: "TeacherInfoV2";
      id: string;
      first_name: string;
      last_name: string;
      primary_teacher: boolean;
    } | null>;
  }> | null;
};

export type GetStudentDashboardAssignBenchmarksQueryVariables = Exact<{
  input?: InputMaybe<StudentAssignmentAndBenchmarkInput>;
}>;

export type GetStudentDashboardAssignBenchmarksQuery = {
  __typename?: "Query";
  getPTDetailsForStudent: {
    __typename?: "StudentDashboardPTResult";
    total_count: number;
    assignments: Array<{
      __typename?: "StudentAssignmentData";
      id: string;
      title: string;
      start_at: string;
      close_at: string;
      section_id?: string | null;
      resource_id: string;
      resource_url: string;
      thumbnail_url?: string | null;
      submission_id?: string | null;
      submission_status?: string | null;
      teacher_user_id?: string | null;
      teachers?: Array<string> | null;
    }>;
    benchmarks: Array<{
      __typename?: "StudentBenchmarkData";
      id: string;
      title: string;
      start_at: string;
      close_at: string;
      resource_id: string;
      resource_url: string;
      thumbnail_url?: string | null;
      submission_id?: string | null;
      submission_status?: string | null;
    }>;
  };
};

export type CreateSubmissionMutationVariables = Exact<{
  assignment_id: Scalars["String"];
}>;

export type CreateSubmissionMutation = { __typename?: "Mutation"; createSubmission: string };

export type CreateBenchmarkSubmissionMutationVariables = Exact<{
  benchmark_id: Scalars["String"];
}>;

export type CreateBenchmarkSubmissionMutation = { __typename?: "Mutation"; createBenchmarkSubmission: string };

export type MarkFeedbackViewedMutationVariables = Exact<{
  submissionIds: Array<Scalars["String"]> | Scalars["String"];
}>;

export type MarkFeedbackViewedMutation = { __typename?: "Mutation"; markFeedbackViewed?: Array<string> | null };

export type UnReadFeedbacksFragment = {
  __typename?: "Submission";
  id: string;
  submitted_at?: string | null;
  teacher_id: string;
  assignment?: {
    __typename?: "Assignment";
    id: string;
    title: string;
    close_at: string;
    start_at: string;
    resource?: {
      __typename?: "StaticResource";
      id: string;
      image_height: number;
      image_width: number;
      file: { __typename?: "File"; id: string; url: string; thumbnail_url?: string | null };
    } | null;
  } | null;
  getBenchmark?: {
    __typename?: "BenchmarkPT";
    id: string;
    title: string;
    close_at: string;
    start_at: string;
    resource: {
      __typename?: "StaticResource";
      id: string;
      image_height: number;
      image_width: number;
      file: { __typename?: "File"; id: string; url: string; thumbnail_url?: string | null };
    };
  } | null;
  getAdditionalFeedback: {
    __typename?: "AdditionalFeedback";
    feedback_notes?: string | null;
    audio: Array<{ __typename?: "File"; id: string; url: string; file_type: string } | null>;
  };
};

export type GetUnreadFeedbackOfStudentQueryVariables = Exact<{
  sectionID?: InputMaybe<Scalars["ID"]>;
  teacherID?: InputMaybe<Scalars["ID"]>;
}>;

export type GetUnreadFeedbackOfStudentQuery = {
  __typename?: "Query";
  unreadFeedbackOfStudent?: Array<{
    __typename?: "Submission";
    id: string;
    submitted_at?: string | null;
    teacher_id: string;
    assignment?: {
      __typename?: "Assignment";
      id: string;
      title: string;
      close_at: string;
      start_at: string;
      resource?: {
        __typename?: "StaticResource";
        id: string;
        image_height: number;
        image_width: number;
        file: { __typename?: "File"; id: string; url: string; thumbnail_url?: string | null };
      } | null;
    } | null;
    getBenchmark?: {
      __typename?: "BenchmarkPT";
      id: string;
      title: string;
      close_at: string;
      start_at: string;
      resource: {
        __typename?: "StaticResource";
        id: string;
        image_height: number;
        image_width: number;
        file: { __typename?: "File"; id: string; url: string; thumbnail_url?: string | null };
      };
    } | null;
    getAdditionalFeedback: {
      __typename?: "AdditionalFeedback";
      feedback_notes?: string | null;
      audio: Array<{ __typename?: "File"; id: string; url: string; file_type: string } | null>;
    };
  }> | null;
};

export type RemoveStudentsFromClassesMutationVariables = Exact<{
  input: RemoveStudentsFromClassInput;
}>;

export type RemoveStudentsFromClassesMutation = {
  __typename?: "Mutation";
  removeStudentsFromClasses?: Array<string> | null;
};

export type StudentsSectionFragFragment = {
  __typename?: "Student";
  id: string;
  user?: { __typename?: "User"; first_name: string; last_name: string; middle_name: string } | null;
  section?: Array<{
    __typename?: "Section";
    id: string;
    name: string;
    teachers?: Array<{
      __typename?: "TeacherInfo";
      id: string;
      teacher_name?: string | null;
      primary_teacher?: boolean | null;
    }> | null;
  }> | null;
};

export type GetStudentsWithClassesQueryVariables = Exact<{
  studentIDs?: InputMaybe<Array<InputMaybe<Scalars["ID"]>> | InputMaybe<Scalars["ID"]>>;
}>;

export type GetStudentsWithClassesQuery = {
  __typename?: "Query";
  getStudentsWithClasses: Array<{
    __typename?: "Student";
    id: string;
    user?: { __typename?: "User"; first_name: string; last_name: string; middle_name: string } | null;
    section?: Array<{
      __typename?: "Section";
      id: string;
      name: string;
      teachers?: Array<{
        __typename?: "TeacherInfo";
        id: string;
        teacher_name?: string | null;
        primary_teacher?: boolean | null;
      }> | null;
    }> | null;
  }>;
};

export type ViewStudentQueryVariables = Exact<{
  id: Scalars["String"];
}>;

export type ViewStudentQuery = {
  __typename?: "Query";
  getAllAssignmentsAndBenchmarksForStudent: {
    __typename?: "StudentBenchmarkAndAssignmentResult";
    total_count: number;
    score_details: Array<{ __typename?: "ScoreDetail"; score_value: number; color_code: string }>;
    assignments: Array<{
      __typename?: "StudentAssignment";
      start_at: string;
      close_at: string;
      id: string;
      title: string;
      section_id?: string | null;
      section_name?: string | null;
      submission_id?: string | null;
      submission_status?: string | null;
      is_common_prompt: boolean;
      resource_id: string;
      resource_url: string;
      thumbnail_url?: string | null;
      teacher_user_id?: string | null;
      teachers?: Array<string> | null;
      speaking_prompt_url: string;
      writing_prompt_title: string;
      writing_prompt_url: string;
      is_submissions_started: boolean;
      back_navigation_allowed: boolean;
      speaking_prompt_title: string;
      submission_speaking_average_score?: number | null;
      submission_writing_average_score?: number | null;
      submitted_at?: string | null;
    }>;
  };
};

export type StudentDetailQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type StudentDetailQuery = {
  __typename?: "Query";
  student: {
    __typename?: "Student";
    id: string;
    student_number: string;
    grade?: string | null;
    ell_status: string;
    user?: { __typename?: "User"; first_name: string; middle_name: string; last_name: string } | null;
  };
};

export type GetGoalsQueryVariables = Exact<{
  goalType?: InputMaybe<GoalType>;
  submissionID?: InputMaybe<Scalars["ID"]>;
  studentID?: InputMaybe<Scalars["ID"]>;
  sectionID?: InputMaybe<Scalars["ID"]>;
  assignmentID?: InputMaybe<Scalars["ID"]>;
}>;

export type GetGoalsQuery = {
  __typename?: "Query";
  getGoalsByStudentID?: {
    __typename?: "StudentGoalListResult";
    speaking: Array<{
      __typename?: "StudentGoalListNode";
      id: string;
      assigned_date: string;
      submission_id: string;
      goal_status?: string | null;
      target_submission_id?: string | null;
      predefined_goal_id?: string | null;
      predefined_goal_description?: string | null;
      predefined_goal_example?: string | null;
      predefined_goal_rubric_id?: string | null;
      predefined_goal_rubric_title?: string | null;
      predefined_goal_rubric_icon_base_64?: string | null;
      predefined_goal_rubric_submission_type?: string | null;
      teacher_user_id?: string | null;
      teacher_user_first_name?: string | null;
      teacher_user_last_name?: string | null;
    }>;
    writing: Array<{
      __typename?: "StudentGoalListNode";
      id: string;
      assigned_date: string;
      submission_id: string;
      goal_status?: string | null;
      target_submission_id?: string | null;
      predefined_goal_id?: string | null;
      predefined_goal_description?: string | null;
      predefined_goal_example?: string | null;
      predefined_goal_rubric_id?: string | null;
      predefined_goal_rubric_title?: string | null;
      predefined_goal_rubric_icon_base_64?: string | null;
      predefined_goal_rubric_submission_type?: string | null;
      teacher_user_id?: string | null;
      teacher_user_first_name?: string | null;
      teacher_user_last_name?: string | null;
    }>;
  } | null;
};

export type StudentScoreSummaryFragment = {
  __typename?: "StudentViewScoreSummary";
  max_score_count: number;
  score_details: Array<{ __typename?: "ScoreDetail"; score_value: number; color_code: string }>;
  rubric_groups: Array<{
    __typename?: "StudentViewScoreSummaryRubricGroup";
    submission_type: string;
    score_line_items: Array<{
      __typename?: "StudentViewScoreLineItem";
      rubric_id: string;
      rubric_title: string;
      rubric_icon_base_64: string;
      score_detail_id: string;
      score_detail_title: string;
      score_detail_description: string;
      score_detail_color_code: string;
      count: number;
    }>;
  }>;
};

export type GetStudentScoreSummaryQueryVariables = Exact<{
  assignmentIDs?: InputMaybe<Array<Scalars["ID"]> | Scalars["ID"]>;
  studentID: Scalars["ID"];
  sectionIDs?: InputMaybe<Array<Scalars["ID"]> | Scalars["ID"]>;
}>;

export type GetStudentScoreSummaryQuery = {
  __typename?: "Query";
  studentScoreSummary: {
    __typename?: "StudentViewScoreSummary";
    max_score_count: number;
    score_details: Array<{ __typename?: "ScoreDetail"; score_value: number; color_code: string }>;
    rubric_groups: Array<{
      __typename?: "StudentViewScoreSummaryRubricGroup";
      submission_type: string;
      score_line_items: Array<{
        __typename?: "StudentViewScoreLineItem";
        rubric_id: string;
        rubric_title: string;
        rubric_icon_base_64: string;
        score_detail_id: string;
        score_detail_title: string;
        score_detail_description: string;
        score_detail_color_code: string;
        count: number;
      }>;
    }>;
  };
};

export type GetAllPtStudentSubmissionsForTeacherQueryVariables = Exact<{
  student_id?: InputMaybe<Scalars["String"]>;
  section_id?: InputMaybe<Scalars["String"]>;
  assignment_id?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<AssignmentSubmissionsForTeacherSortType>;
  order_by?: InputMaybe<OrderBy>;
}>;

export type GetAllPtStudentSubmissionsForTeacherQuery = {
  __typename?: "Query";
  getAllAssignmentSubmissionsForTeacher: {
    __typename?: "TeacherAssignmentSubmissionsResult";
    total_count: number;
    assignments: Array<{
      __typename?: "TeacherAssignmentSubmissionNode";
      assignment_id: string;
      assignment_title: string;
      assignment_assigned_at: string;
      submission_id: string;
      submission_status: string;
      submission_speaking_average_score: number;
      submission_writing_average_score: number;
      submission_submitted_at: string;
    }>;
  };
};

export type PrimarySectionsFilterFragment = {
  __typename?: "SectionDetails";
  id: string;
  name: string;
  teachers: Array<{ __typename?: "TeacherInfo"; id: string; primary_teacher?: boolean | null } | null>;
};

export type PrimarySectionsOfTeacherQueryVariables = Exact<{
  schoolID?: InputMaybe<Scalars["String"]>;
  classTypes?: InputMaybe<Array<SectionCategory> | SectionCategory>;
}>;

export type PrimarySectionsOfTeacherQuery = {
  __typename?: "Query";
  primarySectionsOfTeacher: Array<{
    __typename?: "SectionDetails";
    id: string;
    name: string;
    teachers: Array<{ __typename?: "TeacherInfo"; id: string; primary_teacher?: boolean | null } | null>;
  }>;
};

export type SearchStudentFragmentFragment = {
  __typename?: "Student";
  id: string;
  student_number: string;
  grade?: string | null;
  user?: { __typename?: "User"; first_name: string; last_name: string } | null;
};

export type SearchStudentQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<OrderBy>;
  sort?: InputMaybe<StudentListOfTeacherSortType>;
  search?: InputMaybe<Scalars["String"]>;
}>;

export type SearchStudentQuery = {
  __typename?: "Query";
  getAllStudentsOfTeacher: Array<{
    __typename?: "Student";
    id: string;
    student_number: string;
    grade?: string | null;
    user?: { __typename?: "User"; first_name: string; last_name: string } | null;
  }>;
};

export type SchoolPredefinedGradesMappingQueryVariables = Exact<{
  schoolID: Scalars["String"];
}>;

export type SchoolPredefinedGradesMappingQuery = {
  __typename?: "Query";
  schoolPredefinedGrades?: Array<{
    __typename?: "SchoolPredefinedGradeMappings";
    id: string;
    school_id: string;
    predefined_grade_id: string;
    predefined_grades?: { __typename?: "PredefinedGrades"; id: string; grade: string; position: number } | null;
  } | null> | null;
};

export type StudentsFragFragment = {
  __typename?: "Student";
  id: string;
  grade?: string | null;
  ell_status: string;
  student_number: string;
  heritage_language?: string | null;
  language_class?: string | null;
  language_level?: string | null;
  user?: { __typename?: "User"; first_name: string; last_name: string; middle_name: string; source: string } | null;
  section?: Array<{
    __typename?: "Section";
    id: string;
    name: string;
    teachers?: Array<{
      __typename?: "TeacherInfo";
      id: string;
      teacher_name?: string | null;
      primary_teacher?: boolean | null;
    }> | null;
  }> | null;
  school?: { __typename?: "School"; id: string; name: string } | null;
};

export type StudentsQueryVariables = Exact<{
  sectionID?: InputMaybe<Scalars["ID"]>;
  page?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Scalars["String"]>;
  orderBy?: InputMaybe<OrderBy>;
  search?: InputMaybe<Scalars["String"]>;
  schoolID?: InputMaybe<Scalars["ID"]>;
}>;

export type StudentsQuery = {
  __typename?: "Query";
  students: {
    __typename?: "StudentConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "Student";
      id: string;
      grade?: string | null;
      ell_status: string;
      student_number: string;
      heritage_language?: string | null;
      language_class?: string | null;
      language_level?: string | null;
      user?: { __typename?: "User"; first_name: string; last_name: string; middle_name: string; source: string } | null;
      section?: Array<{
        __typename?: "Section";
        id: string;
        name: string;
        teachers?: Array<{
          __typename?: "TeacherInfo";
          id: string;
          teacher_name?: string | null;
          primary_teacher?: boolean | null;
        }> | null;
      }> | null;
      school?: { __typename?: "School"; id: string; name: string } | null;
    }>;
  };
};

export type TeacherByUserIdFragmentFragment = { __typename?: "Teacher"; id: string; source: string; email: string };

export type GetTeacherByUserQueryVariables = Exact<{
  userID: Scalars["ID"];
}>;

export type GetTeacherByUserQuery = {
  __typename?: "Query";
  teacherByUser: { __typename?: "Teacher"; id: string; source: string; email: string };
};

export type UpdateSpeakingMutationVariables = Exact<{
  input: UpdateSpeakingInput;
}>;

export type UpdateSpeakingMutation = {
  __typename?: "Mutation";
  updateSpeaking: {
    __typename?: "Submission";
    status: SubmissionStatus;
    speakings: Array<{ __typename?: "File"; url: string; file_type: string }>;
  };
};

export type UpdateSpeakingCompleteMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type UpdateSpeakingCompleteMutation = {
  __typename?: "Mutation";
  updateSpeakingComplete: { __typename?: "Submission"; status: SubmissionStatus };
};

export type CreateOrSkipAnnotationsMutationVariables = Exact<{
  annotations?: InputMaybe<Array<CreateAnnotationsInput> | CreateAnnotationsInput>;
  submission_id: Scalars["String"];
}>;

export type CreateOrSkipAnnotationsMutation = {
  __typename?: "Mutation";
  createOrSkipAnnotations?: Array<{ __typename?: "Annotation"; id: string }> | null;
};

export type UpdateWritingMutationVariables = Exact<{
  id: Scalars["String"];
  writing?: InputMaybe<Scalars["String"]>;
}>;

export type UpdateWritingMutation = {
  __typename?: "Mutation";
  updateWriting: { __typename?: "Submission"; id: string; status: SubmissionStatus };
};

export type UpdateSubmissionMutationVariables = Exact<{
  id: Scalars["ID"];
  input: SubmissionUpdateInput;
}>;

export type UpdateSubmissionMutation = {
  __typename?: "Mutation";
  updateSubmission?: { __typename?: "Submission"; id: string } | null;
};

export type StudentSubmissionFragmentFragment = {
  __typename?: "Submission";
  id: string;
  status: SubmissionStatus;
  writing?: string | null;
  speakings: Array<{ __typename?: "File"; url: string; file_type: string }>;
  annotations: Array<{ __typename?: "Annotation"; id: string; x_position: number; y_position: number; title: string }>;
  assignment?: {
    __typename?: "Assignment";
    id: string;
    speaking_prompt: string;
    writing_prompt: string;
    is_common_prompt: boolean;
    back_navigation_allowed: boolean;
    resource?: {
      __typename?: "StaticResource";
      id: string;
      image_height: number;
      image_width: number;
      file: { __typename?: "File"; id: string; url: string };
    } | null;
    speaking_prompt_file?: {
      __typename?: "PromptFile";
      id: string;
      url: string;
      file_name: string;
      file_type: string;
    } | null;
    writing_prompt_file?: {
      __typename?: "PromptFile";
      id: string;
      url: string;
      file_name: string;
      file_type: string;
    } | null;
  } | null;
  getBenchmark?: {
    __typename?: "BenchmarkPT";
    id: string;
    speaking_prompt: string;
    writing_prompt: string;
    is_common_prompt: boolean;
    back_navigation_allowed: boolean;
    resource: {
      __typename?: "StaticResource";
      id: string;
      image_height: number;
      image_width: number;
      file: { __typename?: "File"; id: string; url: string };
    };
    speaking_prompt_file?: {
      __typename?: "PromptFile";
      id: string;
      url: string;
      file_name: string;
      file_type: string;
    } | null;
    writing_prompt_file?: {
      __typename?: "PromptFile";
      id: string;
      url: string;
      file_name: string;
      file_type: string;
    } | null;
  } | null;
  getGoalsForSubmission: {
    __typename?: "UserGoal";
    speaking: Array<{
      __typename?: "Goal";
      id: string;
      goal_status?: string | null;
      assigned_date: string;
      goal_details: {
        __typename?: "GoalsData";
        rubric: { __typename?: "Rubric"; id: string; title: string; content: string; submission_type: string };
        predefined_goals: Array<{
          __typename?: "CategorizedGoal";
          category: string;
          goal_list: Array<{ __typename?: "PredefinedGoal"; id: string; description: string; example?: string | null }>;
        }>;
      };
      teacher?: { __typename?: "User"; first_name: string; last_name: string } | null;
    }>;
    writing: Array<{
      __typename?: "Goal";
      id: string;
      goal_status?: string | null;
      assigned_date: string;
      goal_details: {
        __typename?: "GoalsData";
        rubric: { __typename?: "Rubric"; id: string; title: string; content: string; submission_type: string };
        predefined_goals: Array<{
          __typename?: "CategorizedGoal";
          category: string;
          goal_list: Array<{ __typename?: "PredefinedGoal"; id: string; description: string; example?: string | null }>;
        }>;
      };
      teacher?: { __typename?: "User"; first_name: string; last_name: string } | null;
    }>;
  };
};

export type GetSubmissionQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetSubmissionQuery = {
  __typename?: "Query";
  submission?: {
    __typename?: "Submission";
    id: string;
    status: SubmissionStatus;
    writing?: string | null;
    speakings: Array<{ __typename?: "File"; url: string; file_type: string }>;
    annotations: Array<{
      __typename?: "Annotation";
      id: string;
      x_position: number;
      y_position: number;
      title: string;
    }>;
    assignment?: {
      __typename?: "Assignment";
      id: string;
      speaking_prompt: string;
      writing_prompt: string;
      is_common_prompt: boolean;
      back_navigation_allowed: boolean;
      resource?: {
        __typename?: "StaticResource";
        id: string;
        image_height: number;
        image_width: number;
        file: { __typename?: "File"; id: string; url: string };
      } | null;
      speaking_prompt_file?: {
        __typename?: "PromptFile";
        id: string;
        url: string;
        file_name: string;
        file_type: string;
      } | null;
      writing_prompt_file?: {
        __typename?: "PromptFile";
        id: string;
        url: string;
        file_name: string;
        file_type: string;
      } | null;
    } | null;
    getBenchmark?: {
      __typename?: "BenchmarkPT";
      id: string;
      speaking_prompt: string;
      writing_prompt: string;
      is_common_prompt: boolean;
      back_navigation_allowed: boolean;
      resource: {
        __typename?: "StaticResource";
        id: string;
        image_height: number;
        image_width: number;
        file: { __typename?: "File"; id: string; url: string };
      };
      speaking_prompt_file?: {
        __typename?: "PromptFile";
        id: string;
        url: string;
        file_name: string;
        file_type: string;
      } | null;
      writing_prompt_file?: {
        __typename?: "PromptFile";
        id: string;
        url: string;
        file_name: string;
        file_type: string;
      } | null;
    } | null;
    getGoalsForSubmission: {
      __typename?: "UserGoal";
      speaking: Array<{
        __typename?: "Goal";
        id: string;
        goal_status?: string | null;
        assigned_date: string;
        goal_details: {
          __typename?: "GoalsData";
          rubric: { __typename?: "Rubric"; id: string; title: string; content: string; submission_type: string };
          predefined_goals: Array<{
            __typename?: "CategorizedGoal";
            category: string;
            goal_list: Array<{
              __typename?: "PredefinedGoal";
              id: string;
              description: string;
              example?: string | null;
            }>;
          }>;
        };
        teacher?: { __typename?: "User"; first_name: string; last_name: string } | null;
      }>;
      writing: Array<{
        __typename?: "Goal";
        id: string;
        goal_status?: string | null;
        assigned_date: string;
        goal_details: {
          __typename?: "GoalsData";
          rubric: { __typename?: "Rubric"; id: string; title: string; content: string; submission_type: string };
          predefined_goals: Array<{
            __typename?: "CategorizedGoal";
            category: string;
            goal_list: Array<{
              __typename?: "PredefinedGoal";
              id: string;
              description: string;
              example?: string | null;
            }>;
          }>;
        };
        teacher?: { __typename?: "User"; first_name: string; last_name: string } | null;
      }>;
    };
  } | null;
};

export type TeacherBenchmarkGradesListQueryVariables = Exact<{
  grade_statuses?: InputMaybe<Array<SubmissionStatus> | SubmissionStatus>;
  benchmark_types?: InputMaybe<Array<BenchmarkType> | BenchmarkType>;
  page?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<TeacherBenchmarkSubmissionListSortType>;
  order_by?: InputMaybe<OrderBy>;
  graded_by?: InputMaybe<Array<BenchmarkGradedBy> | BenchmarkGradedBy>;
}>;

export type TeacherBenchmarkGradesListQuery = {
  __typename?: "Query";
  teacherBenchmarkSubmissionList?: {
    __typename?: "TeacherBenchmarkSubmissionListResult";
    node_count?: number | null;
    nodes?: Array<{
      __typename?: "TeacherBenchmarkSubmissionListNode";
      id: string;
      status: string;
      user_first_name?: string | null;
      user_last_name?: string | null;
      benchmark_id: string;
      benchmark_title: string;
      benchmark_type: string;
      benchmark_image_url: string;
      benchmark_thumbnail_image_url?: string | null;
      submitted_at: string;
      student_school_id: string;
      student_school_title: string;
      graded_by: BenchmarkGradedBy;
    }> | null;
  } | null;
};

export type TeacherBmStudentsSlideoutQueryVariables = Exact<{
  benchmarkId: Scalars["String"];
  status?: InputMaybe<Array<SubmissionStatus> | SubmissionStatus>;
}>;

export type TeacherBmStudentsSlideoutQuery = {
  __typename?: "Query";
  teacherBenchmarkSlideOut: {
    __typename?: "BenchmarkSlideOutResult";
    total_count: number;
    nodes: Array<{
      __typename?: "SlideOutStudents";
      first_name: string;
      last_name: string;
      submission_id?: string | null;
      submission_status: SubmissionStatus;
      student_number: string;
    }>;
  };
};

export type TeacherBenchmarksListQueryVariables = Exact<{
  startAt?: InputMaybe<DateQuery>;
  closeAt?: InputMaybe<DateQuery>;
  page?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<BenchmarkTeacherDtoSortType>;
  orderBy?: InputMaybe<OrderBy>;
}>;

export type TeacherBenchmarksListQuery = {
  __typename?: "Query";
  teacherBenchmarks: {
    __typename?: "BenchmarkTeacherDTOConnection";
    total_count: number;
    nodes?: Array<{
      __typename?: "BenchmarkTeacherDTO";
      benchmark_id: string;
      benchmark_title: string;
      start_date: string;
      close_date: string;
      benchmark_type: BenchmarkType;
      graded_by: BenchmarkGradedBy;
      resource: {
        __typename?: "StaticResource";
        id: string;
        file: { __typename?: "File"; url: string; thumbnail_url?: string | null };
      };
      status_aggregation: {
        __typename?: "AssignmentSubmissionAggregation";
        Submitted: number;
        InProgress: number;
        NotStarted: number;
      };
    }> | null;
  };
};

export type OverallBenchmarkIndicatorScoresQueryVariables = Exact<{
  input: OverallBenchmarkIndicatorInput;
}>;

export type OverallBenchmarkIndicatorScoresQuery = {
  __typename?: "Query";
  overallBenchmarkIndicatorScores?: Array<{
    __typename?: "OverallBenchmarkIndicatorResult";
    submission_type: SubmissionType;
    rubric_line_items: Array<{
      __typename?: "OverallBenchmarkIndicatorRubricLineItem";
      rubric_id: string;
      rubric_title: string;
      rubric_icon: string;
      rubric_score_color: string;
      rubric_score_average: number;
    }>;
  }> | null;
};

export type OverallPerformanceLevelSlideOutQueryVariables = Exact<{
  input: OverallPerformanceLevelSlideOutInput;
}>;

export type OverallPerformanceLevelSlideOutQuery = {
  __typename?: "Query";
  overallPerformanceLevelSlideOut: {
    __typename?: "OverallPerformanceLevelSlideOutResult";
    total_count: number;
    slideout_line_items: Array<{
      __typename?: "OverallPerformanceLevelSlideOutLineItem";
      student_id: string;
      student_user_id: string;
      student_first_name: string;
      student_last_name?: string | null;
      student_average_score: number;
      score_level_color: string;
    }>;
  };
};

export type OverallPerformanceLevelQueryVariables = Exact<{
  input: OverallPerformanceLevelInput;
}>;

export type OverallPerformanceLevelQuery = {
  __typename?: "Query";
  overallPerformanceLevel?: Array<{
    __typename?: "OverallPerformanceLevelResult";
    submission_type: SubmissionType;
    average_score: number;
    score_line_items: Array<{
      __typename?: "OverallPerformanceLevelScoreLineItem";
      score_level_id: string;
      score_level_title: string;
      score_level_abbr: string;
      score_level_color: string;
      student_count: number;
    }>;
  }> | null;
};

export type SubmissionStatusSlideoutQueryVariables = Exact<{
  input: SubmissionStatusSlideoutDataInput;
}>;

export type SubmissionStatusSlideoutQuery = {
  __typename?: "Query";
  submissionStatusSlideout: {
    __typename?: "SubmissionStatusSlideoutResult";
    total_count: number;
    slideout_line_items: Array<{
      __typename?: "SubmissionStatusSlideoutLineItem";
      student_id: string;
      student_user_id: string;
      student_first_name: string;
      student_last_name: string;
      submission_status: TeacherBenchmarkSubmissionStatus;
    }>;
  };
};

export type BenchmarkSubmissionStatusQueryVariables = Exact<{
  input: SubmissionStatusDataInput;
}>;

export type BenchmarkSubmissionStatusQuery = {
  __typename?: "Query";
  submissionStatus: {
    __typename?: "SubmissionStatusResult";
    total_submissions: number;
    submission_line_items: Array<{
      __typename?: "SubmissionStatusLineItem";
      submission_status: TeacherBenchmarkSubmissionStatus;
      student_count: number;
    }>;
  };
};

export type BenchmarkIndividualVsGradeLevelRubricLineItemFragmentFragment = {
  __typename?: "BenchmarkIndividualVsGradeLevelRubricLineItem";
  rubric_icon: string;
  rubric_title: string;
  rubric_score_average: number;
  rubric_score_color: string;
  rubric_position: number;
  rubric_district_grade_score_average: number;
};

export type BenchmarkIndividualVsGradeLevelInfoQueryVariables = Exact<{
  input: BenchmarkIndividualVsGradeLevelInput;
}>;

export type BenchmarkIndividualVsGradeLevelInfoQuery = {
  __typename?: "Query";
  benchmarkIndividualVsGradeLevelInfo?: {
    __typename?: "BenchmarkIndividualVsGradeLevelInfoResult";
    submission_type: string;
    rubric_line_items?: Array<{
      __typename?: "BenchmarkIndividualVsGradeLevelRubricLineItem";
      rubric_icon: string;
      rubric_title: string;
      rubric_score_average: number;
      rubric_score_color: string;
      rubric_position: number;
      rubric_district_grade_score_average: number;
    } | null> | null;
  } | null;
};

export type BenchmarkScoresLineItemFragmentFragment = {
  __typename?: "BenchmarkScoresLineItem";
  benchmark_type: BenchmarkType;
  benchmark_title: string;
  submission_id?: string | null;
  submission_status?: SubmissionStatus | null;
  speaking_score_average?: number | null;
  speaking_score_color?: string | null;
  writing_score_average?: number | null;
  writing_score_color?: string | null;
};

export type BenchmarkScoresQueryVariables = Exact<{
  input: BenchmarkScoresInput;
}>;

export type BenchmarkScoresQuery = {
  __typename?: "Query";
  benchmarkScores: {
    __typename?: "BenchmarkScoresResult";
    current_page: number;
    total_count: number;
    submission_line_items: Array<{
      __typename?: "BenchmarkScoresLineItem";
      benchmark_type: BenchmarkType;
      benchmark_title: string;
      submission_id?: string | null;
      submission_status?: SubmissionStatus | null;
      speaking_score_average?: number | null;
      speaking_score_color?: string | null;
      writing_score_average?: number | null;
      writing_score_color?: string | null;
    }>;
  };
};

export type BenchmarkStudentGrowthReportQueryVariables = Exact<{
  input: BenchmarkStudentGrowthReportInput;
}>;

export type BenchmarkStudentGrowthReportQuery = {
  __typename?: "Query";
  benchmarkStudentGrowthReport?: Array<{
    __typename?: "BenchmarkStudentGrowthReportResult";
    submission_speaking_score_average?: number | null;
    submission_writing_score_average?: number | null;
    submission_submitted_at_timestamp: string;
    benchmark_type: string;
  }> | null;
};

export type TeacherBenchmarkStudentListQueryVariables = Exact<{
  input: TeacherBenchmarkStudentListInput;
}>;

export type TeacherBenchmarkStudentListQuery = {
  __typename?: "Query";
  teacherBenchmarkStudentList?: Array<{
    __typename?: "TeacherBenchmarkStudentLineItem";
    student_first_name?: string | null;
    student_last_name?: string | null;
    student_user_id: string;
  }> | null;
};

export type BenchmarkProgressScoreQueryVariables = Exact<{
  input: BenchmarkProgressScoreInput;
}>;

export type BenchmarkProgressScoreQuery = {
  __typename?: "Query";
  benchmarkProgressScore: {
    __typename?: "BenchmarkProgressScoreResult";
    total_count: number;
    nodes: Array<{
      __typename?: "BenchmarkProgressScoreNode";
      student_id: string;
      student_user_id: string;
      student_first_name: string;
      student_last_name: string;
      grade: string;
      has_reviewed_submissions: boolean;
      score_by_submission_type: Array<{
        __typename?: "BenchmarkProgressScoreSubmissionType";
        submission_type: SubmissionType;
        moy_score?: number | null;
        moy_color?: string | null;
        boy_score?: number | null;
        boy_color?: string | null;
        boy_to_moy_progress?: string | null;
        eoy_score?: number | null;
        eoy_color?: string | null;
        moy_to_eoy_progress?: string | null;
        ytd_change?: string | null;
      }>;
    }>;
  };
};

export type BenchmarkProgressScoreFragmentFragment = {
  __typename?: "BenchmarkProgressScoreNode";
  student_id: string;
  student_user_id: string;
  student_first_name: string;
  student_last_name: string;
  grade: string;
  has_reviewed_submissions: boolean;
  score_by_submission_type: Array<{
    __typename?: "BenchmarkProgressScoreSubmissionType";
    submission_type: SubmissionType;
    moy_score?: number | null;
    moy_color?: string | null;
    boy_score?: number | null;
    boy_color?: string | null;
    boy_to_moy_progress?: string | null;
    eoy_score?: number | null;
    eoy_color?: string | null;
    moy_to_eoy_progress?: string | null;
    ytd_change?: string | null;
  }>;
};

export type AddTypeformUserMappingMutationVariables = Exact<{
  typeformId: Scalars["String"];
  submitted?: InputMaybe<Scalars["Boolean"]>;
}>;

export type AddTypeformUserMappingMutation = { __typename?: "Mutation"; AddTypeformUserMapping?: string | null };

export type SubmissionsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]>;
  page?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Scalars["String"]>;
  orderBy?: InputMaybe<OrderBy>;
  search?: InputMaybe<Scalars["String"]>;
  statusList?: InputMaybe<Array<InputMaybe<SubmissionStatus>> | InputMaybe<SubmissionStatus>>;
  section_id?: InputMaybe<Scalars["String"]>;
  school_id?: InputMaybe<Scalars["String"]>;
}>;

export type SubmissionsQuery = {
  __typename?: "Query";
  submissions: {
    __typename?: "SubmissionConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "Submission";
      id: string;
      submitted_at?: string | null;
      status: SubmissionStatus;
      student?: { __typename?: "User"; id: string; first_name: string; last_name: string } | null;
      assignment?: {
        __typename?: "Assignment";
        title: string;
        speaking_prompt: string;
        writing_prompt: string;
        is_common_prompt: boolean;
        speaking_prompt_file?: {
          __typename?: "PromptFile";
          id: string;
          url: string;
          file_name: string;
          file_type: string;
        } | null;
        writing_prompt_file?: {
          __typename?: "PromptFile";
          id: string;
          url: string;
          file_name: string;
          file_type: string;
        } | null;
        section?: {
          __typename?: "Section";
          id: string;
          name: string;
          school?: { __typename?: "School"; id: string; name: string } | null;
        } | null;
      } | null;
      gradingStatus: {
        __typename?: "GradingStatus";
        IsSpeakingScored: boolean;
        IsSpeakingGoalSet: boolean;
        IsWritingScored: boolean;
        IsWritingGoalSet: boolean;
        IsReviewed: boolean;
      };
    }>;
  };
};

export type GetBenchmarkV2TypeformQueryVariables = Exact<{ [key: string]: never }>;

export type GetBenchmarkV2TypeformQuery = {
  __typename?: "Query";
  getBenchmarkV2Typeform: {
    __typename?: "Typeform";
    id: string;
    typeform_form_id: string;
    typeform_name: string;
    show_typeform: boolean;
  };
};

export type GetTeacherDashboardSurveyTypeformQueryVariables = Exact<{ [key: string]: never }>;

export type GetTeacherDashboardSurveyTypeformQuery = {
  __typename?: "Query";
  getTeacherDashboardSurveyTypeform: {
    __typename?: "Typeform";
    id: string;
    typeform_form_id: string;
    typeform_name: string;
    show_typeform: boolean;
  };
};

export type GetCsvDownloadSurveyTypeformQueryVariables = Exact<{ [key: string]: never }>;

export type GetCsvDownloadSurveyTypeformQuery = {
  __typename?: "Query";
  getCSVDownloadSurveyTypeform: {
    __typename?: "Typeform";
    id: string;
    typeform_form_id: string;
    typeform_name: string;
    show_typeform: boolean;
  };
};

export type GetCsvSurveyTypeformQueryVariables = Exact<{ [key: string]: never }>;

export type GetCsvSurveyTypeformQuery = {
  __typename?: "Query";
  getCSVSurveyTypeform: {
    __typename?: "Typeform";
    id: string;
    typeform_form_id: string;
    typeform_name: string;
    show_typeform: boolean;
  };
};

export type GetTeacherBenchmarkDashboardWalkthroughTypeformQueryVariables = Exact<{ [key: string]: never }>;

export type GetTeacherBenchmarkDashboardWalkthroughTypeformQuery = {
  __typename?: "Query";
  getTeacherBenchmarkDashboardWalkthrough: {
    __typename?: "Typeform";
    id: string;
    typeform_form_id: string;
    typeform_name: string;
    show_typeform: boolean;
  };
};

export type GetTeacherBenchmarkDashboardSurveyTypeformQueryVariables = Exact<{ [key: string]: never }>;

export type GetTeacherBenchmarkDashboardSurveyTypeformQuery = {
  __typename?: "Query";
  getTeacherBenchmarkDashboardSurvey: {
    __typename?: "Typeform";
    id: string;
    typeform_form_id: string;
    typeform_name: string;
    show_typeform: boolean;
  };
};

export type GetBtsTypeformQueryVariables = Exact<{ [key: string]: never }>;

export type GetBtsTypeformQuery = {
  __typename?: "Query";
  getBTSTypeform: {
    __typename?: "Typeform";
    id: string;
    typeform_form_id: string;
    typeform_name: string;
    show_typeform: boolean;
  };
};

export type GetCreateClassesSurveyTypeformQueryVariables = Exact<{ [key: string]: never }>;

export type GetCreateClassesSurveyTypeformQuery = {
  __typename?: "Query";
  getCreateClassesSurveyTypeform: {
    __typename?: "Typeform";
    id: string;
    typeform_form_id: string;
    typeform_name: string;
    show_typeform: boolean;
  };
};

export type GetBtsSideTabTypeformQueryVariables = Exact<{ [key: string]: never }>;

export type GetBtsSideTabTypeformQuery = {
  __typename?: "Query";
  getBTSSideTabTypeform: {
    __typename?: "Typeform";
    id: string;
    typeform_form_id: string;
    typeform_name: string;
    show_typeform: boolean;
  };
};

export type GetAdminDashboardTourTypeformQueryVariables = Exact<{ [key: string]: never }>;

export type GetAdminDashboardTourTypeformQuery = {
  __typename?: "Query";
  getAdminDashboardTourTypeform: {
    __typename?: "Typeform";
    id: string;
    typeform_form_id: string;
    typeform_name: string;
    show_typeform: boolean;
  };
};

export type GetAdminDashboardSurveyTypeformQueryVariables = Exact<{ [key: string]: never }>;

export type GetAdminDashboardSurveyTypeformQuery = {
  __typename?: "Query";
  getAdminDashboardSurveyTypeform: {
    __typename?: "Typeform";
    id: string;
    typeform_form_id: string;
    typeform_name: string;
    show_typeform: boolean;
  };
};

export type GetTeacherDashboardIndividualStudentTourTypeformQueryVariables = Exact<{ [key: string]: never }>;

export type GetTeacherDashboardIndividualStudentTourTypeformQuery = {
  __typename?: "Query";
  getTeacherDashboardIndividualStudentTourTypeform: {
    __typename?: "Typeform";
    id: string;
    typeform_form_id: string;
    typeform_name: string;
    show_typeform: boolean;
  };
};

export type GetTeacherDashboardIndividualStudentSurveyTypeformQueryVariables = Exact<{ [key: string]: never }>;

export type GetTeacherDashboardIndividualStudentSurveyTypeformQuery = {
  __typename?: "Query";
  getTeacherDashboardIndividualStudentSurveyTypeform: {
    __typename?: "Typeform";
    id: string;
    typeform_form_id: string;
    typeform_name: string;
    show_typeform: boolean;
  };
};

export type GetAdminDashboardTourTypeformPhase2QueryVariables = Exact<{ [key: string]: never }>;

export type GetAdminDashboardTourTypeformPhase2Query = {
  __typename?: "Query";
  getAdminDashboardTourTypeformPhase2: {
    __typename?: "Typeform";
    id: string;
    typeform_form_id: string;
    typeform_name: string;
    show_typeform: boolean;
  };
};

export type GetAdminDashboardSurveyTypeformPhase2QueryVariables = Exact<{ [key: string]: never }>;

export type GetAdminDashboardSurveyTypeformPhase2Query = {
  __typename?: "Query";
  getAdminDashboardSurveyTypeformPhase2: {
    __typename?: "Typeform";
    id: string;
    typeform_form_id: string;
    typeform_name: string;
    show_typeform: boolean;
  };
};

export type TeacherDetailsQueryVariables = Exact<{
  id: Scalars["String"];
}>;

export type TeacherDetailsQuery = {
  __typename?: "Query";
  teacher: {
    __typename?: "Teacher";
    id: string;
    first_name: string;
    last_name: string;
    teacher_id: string;
    email: string;
    job_title: string;
    user_id: string;
    district: string;
    district_id: string;
    state_id: string;
    state_code?: string | null;
    source: string;
    schools: Array<{ __typename?: "SchoolInfo"; id: string; name: string }>;
  };
};

export type TeacherEditFormQueryVariables = Exact<{
  id: Scalars["String"];
  classTypes?: InputMaybe<Array<SectionCategory> | SectionCategory>;
}>;

export type TeacherEditFormQuery = {
  __typename?: "Query";
  teacher: {
    __typename?: "Teacher";
    id: string;
    first_name: string;
    last_name: string;
    teacher_id: string;
    email: string;
    job_title: string;
    user_id: string;
    district: string;
    state_code?: string | null;
    district_id: string;
    state_id: string;
    title: string;
    phone_number?: string | null;
    schools: Array<{ __typename?: "SchoolInfo"; id: string }>;
    school_sections: Array<{
      __typename?: "SchoolSection";
      school_id: string;
      section_id: string;
      primary_teacher: boolean;
      school_name: string;
      section_name: string;
      source: string;
    }>;
  };
};

export type CreateTeacherFormMutationVariables = Exact<{
  input: CreateTeacherInput;
}>;

export type CreateTeacherFormMutation = { __typename?: "Mutation"; createTeacher?: string | null };

export type UpdateTeacherFormMutationVariables = Exact<{
  input: UpdateTeacherInput;
}>;

export type UpdateTeacherFormMutation = { __typename?: "Mutation"; updateTeacher?: string | null };

export type TeacherDetailsFragmentFragment = {
  __typename?: "TeacherList";
  id: string;
  first_name: string;
  last_name: string;
  source: string;
  teacher_id: string;
  district: string;
  district_id: string;
  job_title: string;
  school: Array<{ __typename?: "SchoolInfo"; id: string; name: string } | null>;
};

export type TeachersListQueryVariables = Exact<{
  stateId?: InputMaybe<Scalars["String"]>;
  districtId?: InputMaybe<Scalars["String"]>;
  schoolID?: InputMaybe<Scalars["String"]>;
  agencyId?: InputMaybe<Scalars["String"]>;
  page?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<TeachersListSortType>;
  searchByName?: InputMaybe<Scalars["String"]>;
  orderBy?: InputMaybe<OrderBy>;
}>;

export type TeachersListQuery = {
  __typename?: "Query";
  teacherList: {
    __typename?: "TeacherConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "TeacherList";
      id: string;
      first_name: string;
      last_name: string;
      source: string;
      teacher_id: string;
      district: string;
      district_id: string;
      job_title: string;
      school: Array<{ __typename?: "SchoolInfo"; id: string; name: string } | null>;
    }>;
  };
};

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "User";
    id: string;
    first_name: string;
    last_name: string;
    email: string;
    role: UserRole;
    showHelpVideo: boolean;
    permissions: Array<{ __typename?: "Permission"; id: string; permission: string }>;
    score_scheme?: {
      __typename?: "ScoreScheme";
      id: string;
      title_key: string;
      title: string;
      scoreDetail: Array<{
        __typename?: "ScoreDetail";
        id: string;
        title_key: string;
        title: string;
        score_value: number;
        description: string;
        color_code: string;
        range_start: number;
        range_end: number;
      }>;
    } | null;
  } | null;
};

export const AssignmentFilterFragmentFragmentDoc = gql`
  fragment AssignmentFilterFragment on Assignment {
    id
    title
    section {
      id
      name
    }
  }
`;
export const ClassFilterFragmentFragmentDoc = gql`
  fragment ClassFilterFragment on SectionWSchoolDTO {
    id
    name
    school_id
    school_name
  }
`;
export const DistrictFilterFragmentFragmentDoc = gql`
  fragment DistrictFilterFragment on DistrictDetails {
    id
    name
    district_id
    state_id
    source
  }
`;
export const DistrictFragmentDataFragmentDoc = gql`
  fragment DistrictFragmentData on DistrictData {
    id
    state_id
  }
`;
export const SchoolFilterFragmentFragmentDoc = gql`
  fragment SchoolFilterFragment on SchoolDetails {
    id
    name
    district_id
    district_state_id
    school_predefined_grade_mappings {
      id
      predefined_grade_id
    }
  }
`;
export const SectionFilterFragmentFragmentDoc = gql`
  fragment SectionFilterFragment on Section {
    id
    course
    name
    subject
  }
`;
export const TeacherInfoFragmentFragmentDoc = gql`
  fragment TeacherInfoFragment on TeacherInfo {
    teacher_name
    email
    primary_teacher
    id
  }
`;
export const AdminDashboardSectionFragmentFragmentDoc = gql`
  fragment AdminDashboardSectionFragment on SectionForAdminDashboardFilterNode {
    id
    name
    school_id
    teachers {
      id
    }
    gradesInfo {
      id
      grade
      position
    }
  }
`;
export const StateFilterFragmentFragmentDoc = gql`
  fragment StateFilterFragment on State {
    id
    state_id
    name
  }
`;
export const StudentFilterFragmentFragmentDoc = gql`
  fragment StudentFilterFragment on Student {
    id
    student_number
    user {
      first_name
      last_name
    }
  }
`;
export const SubmissionFilterFragmentDoc = gql`
  fragment SubmissionFilter on SubmissionFilterData {
    submission_id
    title
    benchmark_id
    assignment_id
  }
`;
export const TeacherFilterFragmentFragmentDoc = gql`
  fragment TeacherFilterFragment on TeacherList {
    id
    first_name
    last_name
    school {
      id
    }
  }
`;
export const TeacherOfStudentFilterFragmentFragmentDoc = gql`
  fragment TeacherOfStudentFilterFragment on TeachersFilter {
    id
    first_name
    last_name
    middle_name
  }
`;
export const AdminFragmentFragmentDoc = gql`
  fragment AdminFragment on Admin {
    id
    district_id
    school_id
    first_name
    middle_name
    last_name
    admin_id
    title
    job_title
    email
    phone_number
    schoolData {
      id
    }
    districtData {
      id
      state_id
    }
  }
`;
export const StudentDetailsFragmentFragmentDoc = gql`
  fragment StudentDetailsFragment on StudentListDTO {
    id
    first_name
    last_name
    student_id
    is_active_submission
    grade
    school_id
    school_name
    district_id
    district_name
    source
    source_id
    teachers {
      id
      teacher_name
      email
      primary_teacher
    }
  }
`;
export const AdminDetailsFragmentFragmentDoc = gql`
  fragment AdminDetailsFragment on Admin {
    id
    first_name
    last_name
    admin_id
    email
    user_id
    source
    districtData {
      source
      name
      id
      state {
        id
        state_id
      }
    }
    schoolData {
      id
      name
    }
  }
`;
export const AdminListFragmentFragmentDoc = gql`
  fragment AdminListFragment on Admin {
    id
    admin_id
    first_name
    last_name
    job_title
    source
    districtData {
      source
    }
    school
    school_id
    district
    district_id
    created_by_first_name
    created_by_last_name
  }
`;
export const ScoresFragmentFragmentDoc = gql`
  fragment ScoresFragment on Scores {
    skill_label
    id
    skill_level
    color
    skill_description
    total_count
    skill_key
    students {
      student_id
      student_name
      current_goal
      current_goal_skill
      assigned_date
      icon_base64
    }
  }
`;
export const ScoreDistributionFragmentDoc = gql`
  fragment ScoreDistribution on ScoreDistribution {
    skill_label
    skill_level
    id
    color
    skill_key
    skill_description
    icon_base64
    scores {
      ...ScoresFragment
    }
  }
  ${ScoresFragmentFragmentDoc}
`;
export const AssignmentSubmissionFragmentDoc = gql`
  fragment AssignmentSubmission on Submission {
    id
    submitted_at
    status
    student {
      id
      first_name
      last_name
    }
    assignment {
      title
      speaking_prompt
      writing_prompt
      is_common_prompt
      speaking_prompt_file {
        id
        url
        file_name
        file_type
      }
      writing_prompt_file {
        id
        url
        file_name
        file_type
      }
      section {
        id
        name
        school {
          id
          name
        }
      }
    }
    gradingStatus {
      IsSpeakingScored
      IsSpeakingGoalSet
      IsWritingScored
      IsWritingGoalSet
      IsReviewed
    }
  }
`;
export const SectionDetailsFragmentFragmentDoc = gql`
  fragment SectionDetailsFragment on SectionDetails {
    id
    name
    teachers {
      id
      teacher_name
      email
      primary_teacher
      source
    }
    period
    grades
    active_pt
    school
    school_id
    district
    district_id
    category
    student_ids
    source
  }
`;
export const ManageStudentsStudentFragmentFragmentDoc = gql`
  fragment ManageStudentsStudentFragment on Student {
    id
    student_number
    user {
      first_name
      last_name
    }
    grade
  }
`;
export const ManageClassUserFragmentFragmentDoc = gql`
  fragment ManageClassUserFragment on User {
    id
    first_name
    last_name
    student {
      id
      student_number
      grade
    }
  }
`;
export const StudentUserConnectionFragmentFragmentDoc = gql`
  fragment StudentUserConnectionFragment on StudentUserConnection {
    totalCount
    nodes {
      ...ManageClassUserFragment
    }
  }
  ${ManageClassUserFragmentFragmentDoc}
`;
export const CleverSyncListItemFragmentDoc = gql`
  fragment CleverSyncListItem on CleverSyncListLineItem {
    id
    owner_type
    status
    created_at
    run_duration
    district_ids
    admin {
      id
      admin_id
      job_title
      user {
        id
        first_name
        last_name
        middle_name
        email
        role
        permissions {
          id
          permission
        }
        showHelpVideo
      }
    }
  }
`;
export const DistrictEditDataFragmentFragmentDoc = gql`
  fragment DistrictEditDataFragment on DistrictData {
    id
    state_id
    district_id
    name
    score_scheme_id
    source
    has_active_assignments
    is_disabled
    nces_id
    mdr_number
    decouple_log {
      id
      created_at
      status
    }
  }
`;
export const ScoreSchemesFragmentDoc = gql`
  fragment ScoreSchemes on ScoreScheme {
    id
    title_key
    title
    scoreDetail {
      id
      title_key
      title
      score_value
      description
      color_code
    }
  }
`;
export const DistrictDetailsDatumFragmentDoc = gql`
  fragment DistrictDetailsDatum on DistrictData {
    id
    state_id
    agency_id
    source
    district_id
    name
    is_disabled
    source_id
    state {
      id
      name
      state_id
    }
    has_active_assignments
    last_synced_at
    updated_at
    initialized_at
    sync_status
    delete_district_status
    is_district_deleted
    nces_id
    mdr_number
    decouple_log {
      id
      status
      created_at
      updated_at
      message
      created_by {
        id
        first_name
        last_name
      }
    }
    cognito_pending_users {
      first_name
      last_name
      external_id
      source
      role
    }
  }
`;
export const StudentGoalFragmentDoc = gql`
  fragment StudentGoal on StudentGoalListNode {
    id
    assigned_date
    submission_id
    goal_status
    target_submission_id
    predefined_goal_id
    predefined_goal_description
    predefined_goal_example
    predefined_goal_rubric_id
    predefined_goal_rubric_title
    predefined_goal_rubric_icon_base_64
    predefined_goal_rubric_submission_type
    teacher_user_id
    teacher_user_first_name
    teacher_user_last_name
  }
`;
export const StudentGoalsFragmentDoc = gql`
  fragment StudentGoals on StudentGoalListResult {
    speaking {
      ...StudentGoal
    }
    writing {
      ...StudentGoal
    }
  }
  ${StudentGoalFragmentDoc}
`;
export const GoalOptionsFragmentDoc = gql`
  fragment GoalOptions on GoalsData {
    rubric {
      id
      title
    }
    predefined_goals {
      category
      goal_list {
        id
        description
        example
      }
    }
  }
`;
export const GroupsFragmentDoc = gql`
  fragment Groups on GroupDetails {
    id
    name
    parent_section_id
    parent_section_name
    student_count
    school {
      id
      name
    }
  }
`;
export const BenchmarkComparisonCommonNodeFragmentDoc = gql`
  fragment BenchmarkComparisonCommonNode on AdminBenchmarkScoresDataPoint {
    submission_type
    ytd_change
    boy_color
    boy_score
    moy_color
    moy_score
    eoy_color
    eoy_score
    boy_to_moy_progress
    moy_to_eoy_progress
  }
`;
export const RubricScoresForBenchmarkTypeFragmentFragmentDoc = gql`
  fragment RubricScoresForBenchmarkTypeFragment on AdminBenchmarkIndicatorRubricLineItemScoreForBenchmarkType {
    benchmark_type
    rubric_score_average
    rubric_score_color
  }
`;
export const RubricLineItemsFragmentFragmentDoc = gql`
  fragment RubricLineItemsFragment on AdminBenchmarkIndicatorRubricLineItem {
    rubric_id
    rubric_title
    rubric_position
    rubric_icon_base_64
    ytd
    ytd_formatted
    rubric_scores_for_benchmark_type {
      ...RubricScoresForBenchmarkTypeFragment
    }
  }
  ${RubricScoresForBenchmarkTypeFragmentFragmentDoc}
`;
export const AdminBenchmarkIndicatorScoreResultFragmentFragmentDoc = gql`
  fragment AdminBenchmarkIndicatorScoreResultFragment on AdminBenchmarkIndicatorScoresResult {
    submission_type
    rubric_line_items {
      ...RubricLineItemsFragment
    }
  }
  ${RubricLineItemsFragmentFragmentDoc}
`;
export const AdminBenchmarkSubmissionStatusGrowthResultFragmentFragmentDoc = gql`
  fragment AdminBenchmarkSubmissionStatusGrowthResultFragment on AdminBenchmarkSubmissionStatusGrowthResult {
    total_submission
    speaking_score
    writing_score
    writing_score_color
    speaking_score_color
    benchmark_type
    submission_line_items {
      submission_count
      submission_status
    }
  }
`;
export const SchoolDetailsDatumFragmentDoc = gql`
  fragment SchoolDetailsDatum on School {
    id
    district_id
    name
    school_id
    nces_id
    mdr_number
    updated_at
    district {
      id
      name
      district_id
      source
      state {
        id
      }
    }
  }
`;
export const SchoolPredefinedGradeMappingsFragmentDoc = gql`
  fragment SchoolPredefinedGradeMappings on School {
    id
    district_id
    name
    school_id
    school_predefined_grade_mappings {
      id
      school_id
      predefined_grade_id
      predefined_grades {
        id
        grade
        position
      }
    }
  }
`;
export const PredefinedGradeFragmentFragmentDoc = gql`
  fragment PredefinedGradeFragment on PredefinedGrades {
    id
    grade
    position
  }
`;
export const SchoolFragmentFragmentDoc = gql`
  fragment SchoolFragment on School {
    id
    district_id
    school_id
    name
    nces_id
    mdr_number
    district {
      state_id
      source
    }
    school_predefined_grade_mappings {
      id
      school_id
      predefined_grade_id
      predefined_grades {
        id
        grade
        position
      }
    }
  }
`;
export const SchoolListDetailsFragmentDoc = gql`
  fragment SchoolListDetails on SchoolDetails {
    id
    school_id
    district_id
    student_count
    name
    grade
    district_name
    district_state_id
    district_source
    student_count
    school_predefined_grade_mappings {
      id
      school_id
      predefined_grade_id
      predefined_grades {
        id
        grade
        position
      }
    }
  }
`;
export const SubmissionRubricFragmentDoc = gql`
  fragment SubmissionRubric on Rubric {
    id
    title
    content
    submission_type
  }
`;
export const SubmissionGradeFragmentDoc = gql`
  fragment SubmissionGrade on Grade {
    id
    score
    rubric {
      ...SubmissionRubric
    }
  }
  ${SubmissionRubricFragmentDoc}
`;
export const SubmissionTypeGoalFragmentDoc = gql`
  fragment SubmissionTypeGoal on Goal {
    id
    goal_status
    assigned_date
    goal_details {
      rubric {
        ...SubmissionRubric
      }
      predefined_goals {
        category
        goal_list {
          id
          description
          example
        }
      }
    }
    teacher {
      first_name
      last_name
    }
  }
  ${SubmissionRubricFragmentDoc}
`;
export const SubmissionGoalFragmentDoc = gql`
  fragment SubmissionGoal on UserGoal {
    speaking {
      ...SubmissionTypeGoal
    }
    writing {
      ...SubmissionTypeGoal
    }
  }
  ${SubmissionTypeGoalFragmentDoc}
`;
export const SubmissionFeedbackFragmentDoc = gql`
  fragment SubmissionFeedback on AdditionalFeedback {
    feedback_notes
    audio {
      id
      url
      file_type
    }
  }
`;
export const SubmissionScoreFragmentFragmentDoc = gql`
  fragment SubmissionScoreFragment on Submission {
    id
    submitted_at
    status
    writing
    feedback_notes
    student {
      id
      first_name
      last_name
      middle_name
      student {
        grade
      }
    }
    annotations {
      id
      title
      x_position
      y_position
    }
    speakings {
      id
      url
      file_type
    }
    grades {
      ...SubmissionGrade
    }
    assignment {
      id
      title
      resource {
        id
        image_height
        image_width
        file {
          id
          url
          thumbnail_url
        }
      }
    }
    getGoalsForSubmission {
      ...SubmissionGoal
    }
    getAdditionalFeedback {
      ...SubmissionFeedback
    }
  }
  ${SubmissionGradeFragmentDoc}
  ${SubmissionGoalFragmentDoc}
  ${SubmissionFeedbackFragmentDoc}
`;
export const ScoreDetailFragmentFragmentDoc = gql`
  fragment ScoreDetailFragment on ScoreDetail {
    id
    title_key
    title
    score_value
    description
    color_code
  }
`;
export const RubricScoreLineItemFragmentFragmentDoc = gql`
  fragment RubricScoreLineItemFragment on SubmissionTypeRubricScoreLineItem {
    grade_id
    rubric_id
    rubric_title
    rubric_content
    rubric_title_key
    score_detail_id
  }
`;
export const SubmissionTypeGroupFragmentFragmentDoc = gql`
  fragment SubmissionTypeGroupFragment on SubmissionTypeGroup {
    submission_type
    rubric_line_items {
      ...RubricScoreLineItemFragment
    }
  }
  ${RubricScoreLineItemFragmentFragmentDoc}
`;
export const NewSubmissionScoreFragmentFragmentDoc = gql`
  fragment NewSubmissionScoreFragment on NewSubmission {
    id
    submitted_at
    status
    writing
    feedback_notes
    student {
      id
      first_name
      last_name
      middle_name
      student {
        grade
      }
    }
    annotations {
      id
      title
      x_position
      y_position
    }
    speakings {
      id
      url
      file_type
    }
    assignment {
      id
      title
      resource {
        id
        image_height
        image_width
        file {
          id
          url
          thumbnail_url
        }
      }
      speaking_prompt
      speaking_prompt_file {
        id
        url
        file_name
        file_type
      }
      writing_prompt
      writing_prompt_file {
        id
        url
        file_name
        file_type
      }
      is_common_prompt
    }
    scoring_details {
      ...ScoreDetailFragment
    }
    submission_type_scores {
      ...SubmissionTypeGroupFragment
    }
    getGoalsForSubmission {
      ...SubmissionGoal
    }
    getAdditionalFeedback {
      ...SubmissionFeedback
    }
  }
  ${ScoreDetailFragmentFragmentDoc}
  ${SubmissionTypeGroupFragmentFragmentDoc}
  ${SubmissionGoalFragmentDoc}
  ${SubmissionFeedbackFragmentDoc}
`;
export const LastInactiveGoalFragmentDoc = gql`
  fragment LastInactiveGoal on Goal {
    id
    submission_id
    target_submission_id
    goal_details {
      rubric {
        ...SubmissionRubric
      }
      predefined_goals {
        category
        goal_list {
          id
          description
          example
        }
      }
    }
  }
  ${SubmissionRubricFragmentDoc}
`;
export const SubmissionGoalInfoFragmentFragmentDoc = gql`
  fragment SubmissionGoalInfoFragment on SubmissionTypeGoalInfo {
    speaking {
      inactive_goal_count
    }
    writing {
      inactive_goal_count
    }
  }
`;
export const BenchmarkSubmissionFeedbackFragmentDoc = gql`
  fragment BenchmarkSubmissionFeedback on AdditionalFeedback {
    feedback_notes
    audio {
      id
      url
      file_type
    }
  }
`;
export const BenchmarkScoreDetailFragmentFragmentDoc = gql`
  fragment BenchmarkScoreDetailFragment on ScoreDetail {
    id
    title_key
    title
    score_value
    description
    color_code
  }
`;
export const BenchmarkSubmissionTypeGroupFragmentFragmentDoc = gql`
  fragment BenchmarkSubmissionTypeGroupFragment on SubmissionTypeGroup {
    submission_type
    rubric_line_items {
      ...RubricScoreLineItemFragment
    }
  }
  ${RubricScoreLineItemFragmentFragmentDoc}
`;
export const BenchmarkSubmissionScoreFragmentFragmentDoc = gql`
  fragment BenchmarkSubmissionScoreFragment on BenchmarkSubmission {
    id
    annotationsBM {
      id
      title
      x_position
      y_position
    }
    speakings: speakingsBM {
      id
      url
      file_type
    }
    writing
    submitted_at
    status
    reviewed_at
    teacher_id
    grading_status: grading_statusBM {
      IsSpeakingScored
      IsWritingScored
      IsReviewed
    }
    feedback_notes
    additional_feedback: additional_feedbackBM {
      ...BenchmarkSubmissionFeedback
    }
    scoring_details {
      ...BenchmarkScoreDetailFragment
    }
    submission_type_scores {
      ...BenchmarkSubmissionTypeGroupFragment
    }
    student: studentBM {
      id
      first_name
      last_name
      middle_name
      student {
        grade
      }
    }
    benchmark: benchmarkPT {
      id
      benchmark_type
      title
      graded_by
      resource {
        id
        image_height
        image_width
        file {
          id
          url
          thumbnail_url
        }
      }
      speaking_prompt
      speaking_prompt_file {
        id
        url
        file_name
        file_type
      }
      writing_prompt
      writing_prompt_file {
        id
        url
        file_name
        file_type
      }
      is_common_prompt
    }
  }
  ${BenchmarkSubmissionFeedbackFragmentDoc}
  ${BenchmarkScoreDetailFragmentFragmentDoc}
  ${BenchmarkSubmissionTypeGroupFragmentFragmentDoc}
`;
export const BackpackAssignmentFragmentDoc = gql`
  fragment BackpackAssignment on Assignment {
    id
    title
    close_at
    start_at
    resource {
      id
      image_height
      image_width
      file {
        id
        url
        thumbnail_url
      }
    }
    is_common_prompt
    speaking_prompt
    writing_prompt
    speaking_prompt_file {
      id
      url
      file_type
      file_name
    }
    writing_prompt_file {
      id
      url
      file_type
      file_name
    }
  }
`;
export const BackpackBenchmarkFragmentDoc = gql`
  fragment BackpackBenchmark on BenchmarkPT {
    id
    title
    close_at
    start_at
    graded_by
    benchmark_type
    resource {
      id
      image_height
      image_width
      file {
        id
        url
        thumbnail_url
      }
    }
    is_common_prompt
    speaking_prompt
    writing_prompt
    speaking_prompt_file {
      id
      url
      file_type
      file_name
    }
    writing_prompt_file {
      id
      url
      file_type
      file_name
    }
  }
`;
export const BackPackSubmissionFragmentDoc = gql`
  fragment BackPackSubmission on Submission {
    id
    status
    writing
    submitted_at
    speakings {
      url
      file_type
    }
    annotations {
      id
      x_position
      y_position
      title
    }
    assignment {
      ...BackpackAssignment
    }
    getBenchmark {
      ...BackpackBenchmark
    }
    getAdditionalFeedback {
      feedback_notes
      audio {
        id
        url
        file_type
      }
    }
  }
  ${BackpackAssignmentFragmentDoc}
  ${BackpackBenchmarkFragmentDoc}
`;
export const StudentViewScoreSummaryFragmentDoc = gql`
  fragment StudentViewScoreSummary on StudentViewScoreSummary {
    max_score_count
    score_details {
      title_key
      color_code
    }
    rubric_groups {
      submission_type
      score_line_items {
        rubric_id
        rubric_title
        rubric_icon_base_64
        score_detail_id
        score_detail_title
        score_detail_description
        score_detail_color_code
        count
      }
    }
  }
`;
export const BenchmarkForStudentFragmentDoc = gql`
  fragment BenchmarkForStudent on StudentBenchmark {
    id
    title
    start_at
    close_at
    resource_id
    resource_url
    thumbnail_url
    back_navigation_allowed
    speaking_prompt_title
    speaking_prompt_url
    writing_prompt_title
    writing_prompt_url
    is_common_prompt
    submission_id
    submission_status
    submission_speaking_average_score
    submission_writing_average_score
    submitted_at
  }
`;
export const AssignmentForStudentFragmentDoc = gql`
  fragment AssignmentForStudent on StudentAssignment {
    id
    title
    start_at
    close_at
    section_id
    section_name
    submission_id
    submission_status
    is_common_prompt
    resource_id
    resource_url
    thumbnail_url
    section_id
    section_name
    submission_id
    submission_status
    submitted_at
    teacher_user_id
    teachers
    speaking_prompt_url
    writing_prompt_title
    writing_prompt_url
    is_submissions_started
    back_navigation_allowed
    speaking_prompt_title
    submission_speaking_average_score
    submission_writing_average_score
  }
`;
export const StudentDashboardAssignmentFragmentDoc = gql`
  fragment StudentDashboardAssignment on StudentAssignmentData {
    id
    title
    start_at
    close_at
    section_id
    resource_id
    resource_url
    thumbnail_url
    submission_id
    submission_status
    teacher_user_id
    teachers
  }
`;
export const StudentDashboardBenchmarkFragmentDoc = gql`
  fragment StudentDashboardBenchmark on StudentBenchmarkData {
    id
    title
    start_at
    close_at
    resource_id
    resource_url
    thumbnail_url
    submission_id
    submission_status
  }
`;
export const UnReadFeedbacksFragmentDoc = gql`
  fragment UnReadFeedbacks on Submission {
    id
    submitted_at
    teacher_id
    assignment {
      id
      title
      close_at
      start_at
      resource {
        id
        image_height
        image_width
        file {
          id
          url
          thumbnail_url
        }
      }
    }
    getBenchmark {
      id
      title
      close_at
      start_at
      resource {
        id
        image_height
        image_width
        file {
          id
          url
          thumbnail_url
        }
      }
    }
    getAdditionalFeedback {
      feedback_notes
      audio {
        id
        url
        file_type
      }
    }
  }
`;
export const StudentsSectionFragFragmentDoc = gql`
  fragment StudentsSectionFrag on Student {
    id
    user {
      first_name
      last_name
      middle_name
    }
    section {
      id
      name
      teachers {
        id
        teacher_name
        primary_teacher
      }
    }
  }
`;
export const StudentScoreSummaryFragmentDoc = gql`
  fragment StudentScoreSummary on StudentViewScoreSummary {
    max_score_count
    score_details {
      score_value
      color_code
    }
    rubric_groups {
      submission_type
      score_line_items {
        rubric_id
        rubric_title
        rubric_icon_base_64
        score_detail_id
        score_detail_title
        score_detail_description
        score_detail_color_code
        count
      }
    }
  }
`;
export const PrimarySectionsFilterFragmentDoc = gql`
  fragment PrimarySectionsFilter on SectionDetails {
    id
    name
    teachers {
      id
      primary_teacher
    }
  }
`;
export const SearchStudentFragmentFragmentDoc = gql`
  fragment SearchStudentFragment on Student {
    id
    user {
      first_name
      last_name
    }
    student_number
    grade
  }
`;
export const StudentsFragFragmentDoc = gql`
  fragment StudentsFrag on Student {
    id
    user {
      first_name
      last_name
      middle_name
      source
    }
    grade
    ell_status
    student_number
    heritage_language
    language_class
    language_level
    section {
      id
      name
      teachers {
        id
        teacher_name
        primary_teacher
      }
    }
    school {
      id
      name
    }
  }
`;
export const TeacherByUserIdFragmentFragmentDoc = gql`
  fragment TeacherByUserIDFragment on Teacher {
    id
    source
    email
  }
`;
export const StudentSubmissionFragmentFragmentDoc = gql`
  fragment StudentSubmissionFragment on Submission {
    id
    status
    writing
    speakings {
      url
      file_type
    }
    annotations {
      id
      x_position
      y_position
      title
    }
    assignment {
      id
      resource {
        id
        image_height
        image_width
        file {
          id
          url
        }
      }
      speaking_prompt
      speaking_prompt_file {
        id
        url
        file_name
        file_type
      }
      writing_prompt
      writing_prompt_file {
        id
        url
        file_name
        file_type
      }
      is_common_prompt
      back_navigation_allowed
    }
    getBenchmark {
      id
      resource {
        id
        image_height
        image_width
        file {
          id
          url
        }
      }
      speaking_prompt
      speaking_prompt_file {
        id
        url
        file_name
        file_type
      }
      writing_prompt
      writing_prompt_file {
        id
        url
        file_name
        file_type
      }
      is_common_prompt
      back_navigation_allowed
    }
    getGoalsForSubmission {
      ...SubmissionGoal
    }
  }
  ${SubmissionGoalFragmentDoc}
`;
export const BenchmarkIndividualVsGradeLevelRubricLineItemFragmentFragmentDoc = gql`
  fragment BenchmarkIndividualVsGradeLevelRubricLineItemFragment on BenchmarkIndividualVsGradeLevelRubricLineItem {
    rubric_icon
    rubric_title
    rubric_score_average
    rubric_score_color
    rubric_position
    rubric_district_grade_score_average
  }
`;
export const BenchmarkScoresLineItemFragmentFragmentDoc = gql`
  fragment BenchmarkScoresLineItemFragment on BenchmarkScoresLineItem {
    benchmark_type
    benchmark_title
    submission_id
    submission_status
    speaking_score_average
    speaking_score_color
    writing_score_average
    writing_score_color
  }
`;
export const BenchmarkProgressScoreFragmentFragmentDoc = gql`
  fragment BenchmarkProgressScoreFragment on BenchmarkProgressScoreNode {
    student_id
    student_user_id
    student_first_name
    student_last_name
    grade
    has_reviewed_submissions
    score_by_submission_type {
      submission_type
      moy_score
      moy_color
      boy_score
      boy_color
      boy_to_moy_progress
      eoy_score
      eoy_color
      moy_to_eoy_progress
      ytd_change
    }
  }
`;
export const TeacherDetailsFragmentFragmentDoc = gql`
  fragment TeacherDetailsFragment on TeacherList {
    id
    first_name
    last_name
    source
    teacher_id
    school {
      id
      name
    }
    district
    district_id
    job_title
  }
`;
export const AddKeywordsDocument = gql`
  mutation AddKeywords($search: [String!]!) {
    addKeyword(input: $search)
  }
`;
export type AddKeywordsMutationFn = ApolloReactCommon.MutationFunction<
  AddKeywordsMutation,
  AddKeywordsMutationVariables
>;

/**
 * __useAddKeywordsMutation__
 *
 * To run a mutation, you first call `useAddKeywordsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddKeywordsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addKeywordsMutation, { data, loading, error }] = useAddKeywordsMutation({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useAddKeywordsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<AddKeywordsMutation, AddKeywordsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<AddKeywordsMutation, AddKeywordsMutationVariables>(AddKeywordsDocument, options);
}
export type AddKeywordsMutationHookResult = ReturnType<typeof useAddKeywordsMutation>;
export type AddKeywordsMutationResult = ApolloReactCommon.MutationResult<AddKeywordsMutation>;
export type AddKeywordsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddKeywordsMutation,
  AddKeywordsMutationVariables
>;
export const GetKeywordsDocument = gql`
  query GetKeywords {
    keyword {
      id
      keyword
    }
  }
`;

/**
 * __useGetKeywordsQuery__
 *
 * To run a query within a React component, call `useGetKeywordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKeywordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKeywordsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetKeywordsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetKeywordsQuery, GetKeywordsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetKeywordsQuery, GetKeywordsQueryVariables>(GetKeywordsDocument, options);
}
export function useGetKeywordsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetKeywordsQuery, GetKeywordsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetKeywordsQuery, GetKeywordsQueryVariables>(GetKeywordsDocument, options);
}
export type GetKeywordsQueryHookResult = ReturnType<typeof useGetKeywordsQuery>;
export type GetKeywordsLazyQueryHookResult = ReturnType<typeof useGetKeywordsLazyQuery>;
export type GetKeywordsQueryResult = ApolloReactCommon.QueryResult<GetKeywordsQuery, GetKeywordsQueryVariables>;
export const AssignmentsFilterDocument = gql`
  query AssignmentsFilter($sectionId: String, $sort: String, $orderBy: OrderBy, $schoolId: String) {
    getAllAssignmentsForTeacher(sectionId: $sectionId, sort: $sort, orderBy: $orderBy, schoolId: $schoolId) {
      totalCount
      nodes {
        ...AssignmentFilterFragment
      }
    }
  }
  ${AssignmentFilterFragmentFragmentDoc}
`;

/**
 * __useAssignmentsFilterQuery__
 *
 * To run a query within a React component, call `useAssignmentsFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssignmentsFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssignmentsFilterQuery({
 *   variables: {
 *      sectionId: // value for 'sectionId'
 *      sort: // value for 'sort'
 *      orderBy: // value for 'orderBy'
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useAssignmentsFilterQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AssignmentsFilterQuery, AssignmentsFilterQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AssignmentsFilterQuery, AssignmentsFilterQueryVariables>(
    AssignmentsFilterDocument,
    options
  );
}
export function useAssignmentsFilterLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AssignmentsFilterQuery, AssignmentsFilterQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AssignmentsFilterQuery, AssignmentsFilterQueryVariables>(
    AssignmentsFilterDocument,
    options
  );
}
export type AssignmentsFilterQueryHookResult = ReturnType<typeof useAssignmentsFilterQuery>;
export type AssignmentsFilterLazyQueryHookResult = ReturnType<typeof useAssignmentsFilterLazyQuery>;
export type AssignmentsFilterQueryResult = ApolloReactCommon.QueryResult<
  AssignmentsFilterQuery,
  AssignmentsFilterQueryVariables
>;
export const StudentAssignmentsFilterDocument = gql`
  query StudentAssignmentsFilter($studentId: String!, $sectionId: String, $sort: String, $orderBy: OrderBy) {
    getAllAssignmentsForStudentByID(studentID: $studentId, sectionID: $sectionId, sort: $sort, orderBy: $orderBy) {
      totalCount
      nodes {
        ...AssignmentFilterFragment
      }
    }
  }
  ${AssignmentFilterFragmentFragmentDoc}
`;

/**
 * __useStudentAssignmentsFilterQuery__
 *
 * To run a query within a React component, call `useStudentAssignmentsFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentAssignmentsFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentAssignmentsFilterQuery({
 *   variables: {
 *      studentId: // value for 'studentId'
 *      sectionId: // value for 'sectionId'
 *      sort: // value for 'sort'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useStudentAssignmentsFilterQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<StudentAssignmentsFilterQuery, StudentAssignmentsFilterQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<StudentAssignmentsFilterQuery, StudentAssignmentsFilterQueryVariables>(
    StudentAssignmentsFilterDocument,
    options
  );
}
export function useStudentAssignmentsFilterLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    StudentAssignmentsFilterQuery,
    StudentAssignmentsFilterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<StudentAssignmentsFilterQuery, StudentAssignmentsFilterQueryVariables>(
    StudentAssignmentsFilterDocument,
    options
  );
}
export type StudentAssignmentsFilterQueryHookResult = ReturnType<typeof useStudentAssignmentsFilterQuery>;
export type StudentAssignmentsFilterLazyQueryHookResult = ReturnType<typeof useStudentAssignmentsFilterLazyQuery>;
export type StudentAssignmentsFilterQueryResult = ApolloReactCommon.QueryResult<
  StudentAssignmentsFilterQuery,
  StudentAssignmentsFilterQueryVariables
>;
export const ClassesFilterDocument = gql`
  query ClassesFilter(
    $stateID: String
    $districtID: String
    $schoolIDs: [String!]
    $teacherID: [String!]
    $classTypes: [SectionCategory!]
  ) {
    sectionsFilterList(
      state_id: $stateID
      district_id: $districtID
      school_ids: $schoolIDs
      teacher_ids: $teacherID
      class_types: $classTypes
    ) {
      ...ClassFilterFragment
    }
  }
  ${ClassFilterFragmentFragmentDoc}
`;

/**
 * __useClassesFilterQuery__
 *
 * To run a query within a React component, call `useClassesFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassesFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassesFilterQuery({
 *   variables: {
 *      stateID: // value for 'stateID'
 *      districtID: // value for 'districtID'
 *      schoolIDs: // value for 'schoolIDs'
 *      teacherID: // value for 'teacherID'
 *      classTypes: // value for 'classTypes'
 *   },
 * });
 */
export function useClassesFilterQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<ClassesFilterQuery, ClassesFilterQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ClassesFilterQuery, ClassesFilterQueryVariables>(ClassesFilterDocument, options);
}
export function useClassesFilterLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClassesFilterQuery, ClassesFilterQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ClassesFilterQuery, ClassesFilterQueryVariables>(ClassesFilterDocument, options);
}
export type ClassesFilterQueryHookResult = ReturnType<typeof useClassesFilterQuery>;
export type ClassesFilterLazyQueryHookResult = ReturnType<typeof useClassesFilterLazyQuery>;
export type ClassesFilterQueryResult = ApolloReactCommon.QueryResult<ClassesFilterQuery, ClassesFilterQueryVariables>;
export const CleanupTablesDocument = gql`
  mutation CleanupTables {
    cleanupTables
  }
`;
export type CleanupTablesMutationFn = ApolloReactCommon.MutationFunction<
  CleanupTablesMutation,
  CleanupTablesMutationVariables
>;

/**
 * __useCleanupTablesMutation__
 *
 * To run a mutation, you first call `useCleanupTablesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCleanupTablesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cleanupTablesMutation, { data, loading, error }] = useCleanupTablesMutation({
 *   variables: {
 *   },
 * });
 */
export function useCleanupTablesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CleanupTablesMutation, CleanupTablesMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CleanupTablesMutation, CleanupTablesMutationVariables>(
    CleanupTablesDocument,
    options
  );
}
export type CleanupTablesMutationHookResult = ReturnType<typeof useCleanupTablesMutation>;
export type CleanupTablesMutationResult = ApolloReactCommon.MutationResult<CleanupTablesMutation>;
export type CleanupTablesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CleanupTablesMutation,
  CleanupTablesMutationVariables
>;
export const DistrictsFilterDocument = gql`
  query DistrictsFilter($stateIDs: [String!], $source: [String], $excludeDeleted: Boolean) {
    districtList(state_ids: $stateIDs, sort: name, orderBy: Asc, source: $source, excludeDeleted: $excludeDeleted) {
      totalCount
      nodes {
        ...DistrictFilterFragment
      }
    }
  }
  ${DistrictFilterFragmentFragmentDoc}
`;

/**
 * __useDistrictsFilterQuery__
 *
 * To run a query within a React component, call `useDistrictsFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useDistrictsFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDistrictsFilterQuery({
 *   variables: {
 *      stateIDs: // value for 'stateIDs'
 *      source: // value for 'source'
 *      excludeDeleted: // value for 'excludeDeleted'
 *   },
 * });
 */
export function useDistrictsFilterQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<DistrictsFilterQuery, DistrictsFilterQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<DistrictsFilterQuery, DistrictsFilterQueryVariables>(
    DistrictsFilterDocument,
    options
  );
}
export function useDistrictsFilterLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DistrictsFilterQuery, DistrictsFilterQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<DistrictsFilterQuery, DistrictsFilterQueryVariables>(
    DistrictsFilterDocument,
    options
  );
}
export type DistrictsFilterQueryHookResult = ReturnType<typeof useDistrictsFilterQuery>;
export type DistrictsFilterLazyQueryHookResult = ReturnType<typeof useDistrictsFilterLazyQuery>;
export type DistrictsFilterQueryResult = ApolloReactCommon.QueryResult<
  DistrictsFilterQuery,
  DistrictsFilterQueryVariables
>;
export const TeacherListForAdminBenchmarkDocument = gql`
  query TeacherListForAdminBenchmark($input: TeacherListForAdminBenchmarkInput!) {
    teacherListForAdminBenchmark(input: $input) {
      totalCount
      nodes {
        id
        first_name
        middle_name
        last_name
      }
    }
  }
`;

/**
 * __useTeacherListForAdminBenchmarkQuery__
 *
 * To run a query within a React component, call `useTeacherListForAdminBenchmarkQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeacherListForAdminBenchmarkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeacherListForAdminBenchmarkQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTeacherListForAdminBenchmarkQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    TeacherListForAdminBenchmarkQuery,
    TeacherListForAdminBenchmarkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<TeacherListForAdminBenchmarkQuery, TeacherListForAdminBenchmarkQueryVariables>(
    TeacherListForAdminBenchmarkDocument,
    options
  );
}
export function useTeacherListForAdminBenchmarkLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    TeacherListForAdminBenchmarkQuery,
    TeacherListForAdminBenchmarkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<TeacherListForAdminBenchmarkQuery, TeacherListForAdminBenchmarkQueryVariables>(
    TeacherListForAdminBenchmarkDocument,
    options
  );
}
export type TeacherListForAdminBenchmarkQueryHookResult = ReturnType<typeof useTeacherListForAdminBenchmarkQuery>;
export type TeacherListForAdminBenchmarkLazyQueryHookResult = ReturnType<
  typeof useTeacherListForAdminBenchmarkLazyQuery
>;
export type TeacherListForAdminBenchmarkQueryResult = ApolloReactCommon.QueryResult<
  TeacherListForAdminBenchmarkQuery,
  TeacherListForAdminBenchmarkQueryVariables
>;
export const TeacherStudentGradesFilterDocument = gql`
  query teacherStudentGradesFilter($sortBy: TeacherStudentGradesSortType, $orderBy: OrderBy) {
    teacherStudentGradesFilter(sort_by: $sortBy, order_by: $orderBy) {
      id
      grade
    }
  }
`;

/**
 * __useTeacherStudentGradesFilterQuery__
 *
 * To run a query within a React component, call `useTeacherStudentGradesFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeacherStudentGradesFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeacherStudentGradesFilterQuery({
 *   variables: {
 *      sortBy: // value for 'sortBy'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useTeacherStudentGradesFilterQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    TeacherStudentGradesFilterQuery,
    TeacherStudentGradesFilterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<TeacherStudentGradesFilterQuery, TeacherStudentGradesFilterQueryVariables>(
    TeacherStudentGradesFilterDocument,
    options
  );
}
export function useTeacherStudentGradesFilterLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    TeacherStudentGradesFilterQuery,
    TeacherStudentGradesFilterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<TeacherStudentGradesFilterQuery, TeacherStudentGradesFilterQueryVariables>(
    TeacherStudentGradesFilterDocument,
    options
  );
}
export type TeacherStudentGradesFilterQueryHookResult = ReturnType<typeof useTeacherStudentGradesFilterQuery>;
export type TeacherStudentGradesFilterLazyQueryHookResult = ReturnType<typeof useTeacherStudentGradesFilterLazyQuery>;
export type TeacherStudentGradesFilterQueryResult = ApolloReactCommon.QueryResult<
  TeacherStudentGradesFilterQuery,
  TeacherStudentGradesFilterQueryVariables
>;
export const MarkHelpVideoSeenDocument = gql`
  mutation MarkHelpVideoSeen {
    markHelpVideoSeen {
      id
      first_name
      last_name
      email
      role
      showHelpVideo
    }
  }
`;
export type MarkHelpVideoSeenMutationFn = ApolloReactCommon.MutationFunction<
  MarkHelpVideoSeenMutation,
  MarkHelpVideoSeenMutationVariables
>;

/**
 * __useMarkHelpVideoSeenMutation__
 *
 * To run a mutation, you first call `useMarkHelpVideoSeenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkHelpVideoSeenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markHelpVideoSeenMutation, { data, loading, error }] = useMarkHelpVideoSeenMutation({
 *   variables: {
 *   },
 * });
 */
export function useMarkHelpVideoSeenMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<MarkHelpVideoSeenMutation, MarkHelpVideoSeenMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<MarkHelpVideoSeenMutation, MarkHelpVideoSeenMutationVariables>(
    MarkHelpVideoSeenDocument,
    options
  );
}
export type MarkHelpVideoSeenMutationHookResult = ReturnType<typeof useMarkHelpVideoSeenMutation>;
export type MarkHelpVideoSeenMutationResult = ApolloReactCommon.MutationResult<MarkHelpVideoSeenMutation>;
export type MarkHelpVideoSeenMutationOptions = ApolloReactCommon.BaseMutationOptions<
  MarkHelpVideoSeenMutation,
  MarkHelpVideoSeenMutationVariables
>;
export const SchoolsFilterDocument = gql`
  query SchoolsFilter($stateIDs: [String!], $districtIDs: [String!], $gradeIDs: [String!]) {
    schoolsFilterList(state_ids: $stateIDs, district_ids: $districtIDs, grade_ids: $gradeIDs) {
      ...SchoolFilterFragment
    }
  }
  ${SchoolFilterFragmentFragmentDoc}
`;

/**
 * __useSchoolsFilterQuery__
 *
 * To run a query within a React component, call `useSchoolsFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolsFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolsFilterQuery({
 *   variables: {
 *      stateIDs: // value for 'stateIDs'
 *      districtIDs: // value for 'districtIDs'
 *      gradeIDs: // value for 'gradeIDs'
 *   },
 * });
 */
export function useSchoolsFilterQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<SchoolsFilterQuery, SchoolsFilterQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<SchoolsFilterQuery, SchoolsFilterQueryVariables>(SchoolsFilterDocument, options);
}
export function useSchoolsFilterLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SchoolsFilterQuery, SchoolsFilterQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<SchoolsFilterQuery, SchoolsFilterQueryVariables>(SchoolsFilterDocument, options);
}
export type SchoolsFilterQueryHookResult = ReturnType<typeof useSchoolsFilterQuery>;
export type SchoolsFilterLazyQueryHookResult = ReturnType<typeof useSchoolsFilterLazyQuery>;
export type SchoolsFilterQueryResult = ApolloReactCommon.QueryResult<SchoolsFilterQuery, SchoolsFilterQueryVariables>;
export const TeacherSchoolFilterDocument = gql`
  query TeacherSchoolFilter {
    teacherSchools {
      id
      name
      district_id
    }
  }
`;

/**
 * __useTeacherSchoolFilterQuery__
 *
 * To run a query within a React component, call `useTeacherSchoolFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeacherSchoolFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeacherSchoolFilterQuery({
 *   variables: {
 *   },
 * });
 */
export function useTeacherSchoolFilterQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<TeacherSchoolFilterQuery, TeacherSchoolFilterQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<TeacherSchoolFilterQuery, TeacherSchoolFilterQueryVariables>(
    TeacherSchoolFilterDocument,
    options
  );
}
export function useTeacherSchoolFilterLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeacherSchoolFilterQuery, TeacherSchoolFilterQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<TeacherSchoolFilterQuery, TeacherSchoolFilterQueryVariables>(
    TeacherSchoolFilterDocument,
    options
  );
}
export type TeacherSchoolFilterQueryHookResult = ReturnType<typeof useTeacherSchoolFilterQuery>;
export type TeacherSchoolFilterLazyQueryHookResult = ReturnType<typeof useTeacherSchoolFilterLazyQuery>;
export type TeacherSchoolFilterQueryResult = ApolloReactCommon.QueryResult<
  TeacherSchoolFilterQuery,
  TeacherSchoolFilterQueryVariables
>;
export const SectionsFilterDocument = gql`
  query SectionsFilter($studentID: String, $hideGroups: Boolean, $schoolIDs: [String!]) {
    sections(studentID: $studentID, hideGroups: $hideGroups, schoolIDs: $schoolIDs) {
      ...SectionFilterFragment
    }
  }
  ${SectionFilterFragmentFragmentDoc}
`;

/**
 * __useSectionsFilterQuery__
 *
 * To run a query within a React component, call `useSectionsFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useSectionsFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSectionsFilterQuery({
 *   variables: {
 *      studentID: // value for 'studentID'
 *      hideGroups: // value for 'hideGroups'
 *      schoolIDs: // value for 'schoolIDs'
 *   },
 * });
 */
export function useSectionsFilterQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<SectionsFilterQuery, SectionsFilterQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<SectionsFilterQuery, SectionsFilterQueryVariables>(SectionsFilterDocument, options);
}
export function useSectionsFilterLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SectionsFilterQuery, SectionsFilterQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<SectionsFilterQuery, SectionsFilterQueryVariables>(
    SectionsFilterDocument,
    options
  );
}
export type SectionsFilterQueryHookResult = ReturnType<typeof useSectionsFilterQuery>;
export type SectionsFilterLazyQueryHookResult = ReturnType<typeof useSectionsFilterLazyQuery>;
export type SectionsFilterQueryResult = ApolloReactCommon.QueryResult<
  SectionsFilterQuery,
  SectionsFilterQueryVariables
>;
export const SectionTeachersFilterDocument = gql`
  query SectionTeachersFilter($id: ID!) {
    sectionTeachers(id: $id) {
      ...TeacherInfoFragment
    }
  }
  ${TeacherInfoFragmentFragmentDoc}
`;

/**
 * __useSectionTeachersFilterQuery__
 *
 * To run a query within a React component, call `useSectionTeachersFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useSectionTeachersFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSectionTeachersFilterQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSectionTeachersFilterQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<SectionTeachersFilterQuery, SectionTeachersFilterQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<SectionTeachersFilterQuery, SectionTeachersFilterQueryVariables>(
    SectionTeachersFilterDocument,
    options
  );
}
export function useSectionTeachersFilterLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SectionTeachersFilterQuery, SectionTeachersFilterQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<SectionTeachersFilterQuery, SectionTeachersFilterQueryVariables>(
    SectionTeachersFilterDocument,
    options
  );
}
export type SectionTeachersFilterQueryHookResult = ReturnType<typeof useSectionTeachersFilterQuery>;
export type SectionTeachersFilterLazyQueryHookResult = ReturnType<typeof useSectionTeachersFilterLazyQuery>;
export type SectionTeachersFilterQueryResult = ApolloReactCommon.QueryResult<
  SectionTeachersFilterQuery,
  SectionTeachersFilterQueryVariables
>;
export const AdminDashboardSectionFilterListDocument = gql`
  query AdminDashboardSectionFilterList(
    $stateID: String
    $districtID: String
    $schoolID: [String!]
    $teacherID: [String!]
    $classTypes: [SectionCategory!]
  ) {
    adminDashboardSectionFilterList(
      state_id: $stateID
      district_id: $districtID
      school_ids: $schoolID
      teacher_ids: $teacherID
      class_types: $classTypes
    ) {
      ...AdminDashboardSectionFragment
    }
  }
  ${AdminDashboardSectionFragmentFragmentDoc}
`;

/**
 * __useAdminDashboardSectionFilterListQuery__
 *
 * To run a query within a React component, call `useAdminDashboardSectionFilterListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminDashboardSectionFilterListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminDashboardSectionFilterListQuery({
 *   variables: {
 *      stateID: // value for 'stateID'
 *      districtID: // value for 'districtID'
 *      schoolID: // value for 'schoolID'
 *      teacherID: // value for 'teacherID'
 *      classTypes: // value for 'classTypes'
 *   },
 * });
 */
export function useAdminDashboardSectionFilterListQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AdminDashboardSectionFilterListQuery,
    AdminDashboardSectionFilterListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AdminDashboardSectionFilterListQuery, AdminDashboardSectionFilterListQueryVariables>(
    AdminDashboardSectionFilterListDocument,
    options
  );
}
export function useAdminDashboardSectionFilterListLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AdminDashboardSectionFilterListQuery,
    AdminDashboardSectionFilterListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    AdminDashboardSectionFilterListQuery,
    AdminDashboardSectionFilterListQueryVariables
  >(AdminDashboardSectionFilterListDocument, options);
}
export type AdminDashboardSectionFilterListQueryHookResult = ReturnType<typeof useAdminDashboardSectionFilterListQuery>;
export type AdminDashboardSectionFilterListLazyQueryHookResult = ReturnType<
  typeof useAdminDashboardSectionFilterListLazyQuery
>;
export type AdminDashboardSectionFilterListQueryResult = ApolloReactCommon.QueryResult<
  AdminDashboardSectionFilterListQuery,
  AdminDashboardSectionFilterListQueryVariables
>;
export const SectionsOfStudentFilterDocument = gql`
  query SectionsOfStudentFilter {
    sectionsOfStudent {
      ...SectionFilterFragment
    }
  }
  ${SectionFilterFragmentFragmentDoc}
`;

/**
 * __useSectionsOfStudentFilterQuery__
 *
 * To run a query within a React component, call `useSectionsOfStudentFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useSectionsOfStudentFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSectionsOfStudentFilterQuery({
 *   variables: {
 *   },
 * });
 */
export function useSectionsOfStudentFilterQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<SectionsOfStudentFilterQuery, SectionsOfStudentFilterQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<SectionsOfStudentFilterQuery, SectionsOfStudentFilterQueryVariables>(
    SectionsOfStudentFilterDocument,
    options
  );
}
export function useSectionsOfStudentFilterLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SectionsOfStudentFilterQuery,
    SectionsOfStudentFilterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<SectionsOfStudentFilterQuery, SectionsOfStudentFilterQueryVariables>(
    SectionsOfStudentFilterDocument,
    options
  );
}
export type SectionsOfStudentFilterQueryHookResult = ReturnType<typeof useSectionsOfStudentFilterQuery>;
export type SectionsOfStudentFilterLazyQueryHookResult = ReturnType<typeof useSectionsOfStudentFilterLazyQuery>;
export type SectionsOfStudentFilterQueryResult = ApolloReactCommon.QueryResult<
  SectionsOfStudentFilterQuery,
  SectionsOfStudentFilterQueryVariables
>;
export const GetAllPredefinedGradesDocument = gql`
  query GetAllPredefinedGrades($districtID: [String!], $schoolIDs: [String!], $sort: GradeSortType, $order: OrderBy) {
    allPredefinedGrades(districtIDs: $districtID, schoolIDs: $schoolIDs, sort: $sort, order_by: $order) {
      id
      grade
      position
    }
  }
`;

/**
 * __useGetAllPredefinedGradesQuery__
 *
 * To run a query within a React component, call `useGetAllPredefinedGradesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPredefinedGradesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPredefinedGradesQuery({
 *   variables: {
 *      districtID: // value for 'districtID'
 *      schoolIDs: // value for 'schoolIDs'
 *      sort: // value for 'sort'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetAllPredefinedGradesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllPredefinedGradesQuery, GetAllPredefinedGradesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetAllPredefinedGradesQuery, GetAllPredefinedGradesQueryVariables>(
    GetAllPredefinedGradesDocument,
    options
  );
}
export function useGetAllPredefinedGradesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllPredefinedGradesQuery, GetAllPredefinedGradesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetAllPredefinedGradesQuery, GetAllPredefinedGradesQueryVariables>(
    GetAllPredefinedGradesDocument,
    options
  );
}
export type GetAllPredefinedGradesQueryHookResult = ReturnType<typeof useGetAllPredefinedGradesQuery>;
export type GetAllPredefinedGradesLazyQueryHookResult = ReturnType<typeof useGetAllPredefinedGradesLazyQuery>;
export type GetAllPredefinedGradesQueryResult = ApolloReactCommon.QueryResult<
  GetAllPredefinedGradesQuery,
  GetAllPredefinedGradesQueryVariables
>;
export const SubmissionCountDocument = gql`
  query SubmissionCount(
    $search: String
    $studentID: String
    $sectionID: String
    $assignmentID: String
    $status: [SubmissionStatus]
  ) {
    submissionsCount(
      search: $search
      student_id: $studentID
      section_id: $sectionID
      assignment_id: $assignmentID
      status: $status
    )
  }
`;

/**
 * __useSubmissionCountQuery__
 *
 * To run a query within a React component, call `useSubmissionCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubmissionCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubmissionCountQuery({
 *   variables: {
 *      search: // value for 'search'
 *      studentID: // value for 'studentID'
 *      sectionID: // value for 'sectionID'
 *      assignmentID: // value for 'assignmentID'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useSubmissionCountQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<SubmissionCountQuery, SubmissionCountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<SubmissionCountQuery, SubmissionCountQueryVariables>(
    SubmissionCountDocument,
    options
  );
}
export function useSubmissionCountLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SubmissionCountQuery, SubmissionCountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<SubmissionCountQuery, SubmissionCountQueryVariables>(
    SubmissionCountDocument,
    options
  );
}
export type SubmissionCountQueryHookResult = ReturnType<typeof useSubmissionCountQuery>;
export type SubmissionCountLazyQueryHookResult = ReturnType<typeof useSubmissionCountLazyQuery>;
export type SubmissionCountQueryResult = ApolloReactCommon.QueryResult<
  SubmissionCountQuery,
  SubmissionCountQueryVariables
>;
export const BenchmarkGradesCountDocument = gql`
  query BenchmarkGradesCount($gradeStatus: [SubmissionStatus!]) {
    adminBenchmarkSubmissionsCount(grade_statuses: $gradeStatus)
  }
`;

/**
 * __useBenchmarkGradesCountQuery__
 *
 * To run a query within a React component, call `useBenchmarkGradesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useBenchmarkGradesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBenchmarkGradesCountQuery({
 *   variables: {
 *      gradeStatus: // value for 'gradeStatus'
 *   },
 * });
 */
export function useBenchmarkGradesCountQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<BenchmarkGradesCountQuery, BenchmarkGradesCountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<BenchmarkGradesCountQuery, BenchmarkGradesCountQueryVariables>(
    BenchmarkGradesCountDocument,
    options
  );
}
export function useBenchmarkGradesCountLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BenchmarkGradesCountQuery, BenchmarkGradesCountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<BenchmarkGradesCountQuery, BenchmarkGradesCountQueryVariables>(
    BenchmarkGradesCountDocument,
    options
  );
}
export type BenchmarkGradesCountQueryHookResult = ReturnType<typeof useBenchmarkGradesCountQuery>;
export type BenchmarkGradesCountLazyQueryHookResult = ReturnType<typeof useBenchmarkGradesCountLazyQuery>;
export type BenchmarkGradesCountQueryResult = ApolloReactCommon.QueryResult<
  BenchmarkGradesCountQuery,
  BenchmarkGradesCountQueryVariables
>;
export const SectionCountDocument = gql`
  query SectionCount(
    $stateId: String
    $districtId: String
    $schoolId: String
    $teacherId: String
    $studentId: String
    $classTypes: [SectionCategory!]
  ) {
    sectionCount(
      state_id: $stateId
      district_id: $districtId
      school_id: $schoolId
      teacher_id: $teacherId
      student_id: $studentId
      class_types: $classTypes
    )
  }
`;

/**
 * __useSectionCountQuery__
 *
 * To run a query within a React component, call `useSectionCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useSectionCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSectionCountQuery({
 *   variables: {
 *      stateId: // value for 'stateId'
 *      districtId: // value for 'districtId'
 *      schoolId: // value for 'schoolId'
 *      teacherId: // value for 'teacherId'
 *      studentId: // value for 'studentId'
 *      classTypes: // value for 'classTypes'
 *   },
 * });
 */
export function useSectionCountQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<SectionCountQuery, SectionCountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<SectionCountQuery, SectionCountQueryVariables>(SectionCountDocument, options);
}
export function useSectionCountLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SectionCountQuery, SectionCountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<SectionCountQuery, SectionCountQueryVariables>(SectionCountDocument, options);
}
export type SectionCountQueryHookResult = ReturnType<typeof useSectionCountQuery>;
export type SectionCountLazyQueryHookResult = ReturnType<typeof useSectionCountLazyQuery>;
export type SectionCountQueryResult = ApolloReactCommon.QueryResult<SectionCountQuery, SectionCountQueryVariables>;
export const StatesFilterDocument = gql`
  query StatesFilter {
    states {
      ...StateFilterFragment
    }
  }
  ${StateFilterFragmentFragmentDoc}
`;

/**
 * __useStatesFilterQuery__
 *
 * To run a query within a React component, call `useStatesFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatesFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatesFilterQuery({
 *   variables: {
 *   },
 * });
 */
export function useStatesFilterQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<StatesFilterQuery, StatesFilterQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<StatesFilterQuery, StatesFilterQueryVariables>(StatesFilterDocument, options);
}
export function useStatesFilterLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StatesFilterQuery, StatesFilterQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<StatesFilterQuery, StatesFilterQueryVariables>(StatesFilterDocument, options);
}
export type StatesFilterQueryHookResult = ReturnType<typeof useStatesFilterQuery>;
export type StatesFilterLazyQueryHookResult = ReturnType<typeof useStatesFilterLazyQuery>;
export type StatesFilterQueryResult = ApolloReactCommon.QueryResult<StatesFilterQuery, StatesFilterQueryVariables>;
export const StudentsFilterDocument = gql`
  query StudentsFilter {
    students {
      totalCount
      nodes {
        ...StudentFilterFragment
      }
    }
  }
  ${StudentFilterFragmentFragmentDoc}
`;

/**
 * __useStudentsFilterQuery__
 *
 * To run a query within a React component, call `useStudentsFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentsFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentsFilterQuery({
 *   variables: {
 *   },
 * });
 */
export function useStudentsFilterQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<StudentsFilterQuery, StudentsFilterQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<StudentsFilterQuery, StudentsFilterQueryVariables>(StudentsFilterDocument, options);
}
export function useStudentsFilterLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StudentsFilterQuery, StudentsFilterQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<StudentsFilterQuery, StudentsFilterQueryVariables>(
    StudentsFilterDocument,
    options
  );
}
export type StudentsFilterQueryHookResult = ReturnType<typeof useStudentsFilterQuery>;
export type StudentsFilterLazyQueryHookResult = ReturnType<typeof useStudentsFilterLazyQuery>;
export type StudentsFilterQueryResult = ApolloReactCommon.QueryResult<
  StudentsFilterQuery,
  StudentsFilterQueryVariables
>;
export const MySubmissionsFilterDocument = gql`
  query MySubmissionsFilter($status: [SubmissionStatus]) {
    allSubmissionsOfStudent(status: $status) {
      ...SubmissionFilter
    }
  }
  ${SubmissionFilterFragmentDoc}
`;

/**
 * __useMySubmissionsFilterQuery__
 *
 * To run a query within a React component, call `useMySubmissionsFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useMySubmissionsFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMySubmissionsFilterQuery({
 *   variables: {
 *      status: // value for 'status'
 *   },
 * });
 */
export function useMySubmissionsFilterQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<MySubmissionsFilterQuery, MySubmissionsFilterQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<MySubmissionsFilterQuery, MySubmissionsFilterQueryVariables>(
    MySubmissionsFilterDocument,
    options
  );
}
export function useMySubmissionsFilterLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MySubmissionsFilterQuery, MySubmissionsFilterQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<MySubmissionsFilterQuery, MySubmissionsFilterQueryVariables>(
    MySubmissionsFilterDocument,
    options
  );
}
export type MySubmissionsFilterQueryHookResult = ReturnType<typeof useMySubmissionsFilterQuery>;
export type MySubmissionsFilterLazyQueryHookResult = ReturnType<typeof useMySubmissionsFilterLazyQuery>;
export type MySubmissionsFilterQueryResult = ApolloReactCommon.QueryResult<
  MySubmissionsFilterQuery,
  MySubmissionsFilterQueryVariables
>;
export const TaskTypesFilterDocument = gql`
  query TaskTypesFilter {
    taskType {
      id
      category
      title
    }
  }
`;

/**
 * __useTaskTypesFilterQuery__
 *
 * To run a query within a React component, call `useTaskTypesFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskTypesFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskTypesFilterQuery({
 *   variables: {
 *   },
 * });
 */
export function useTaskTypesFilterQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<TaskTypesFilterQuery, TaskTypesFilterQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<TaskTypesFilterQuery, TaskTypesFilterQueryVariables>(
    TaskTypesFilterDocument,
    options
  );
}
export function useTaskTypesFilterLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TaskTypesFilterQuery, TaskTypesFilterQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<TaskTypesFilterQuery, TaskTypesFilterQueryVariables>(
    TaskTypesFilterDocument,
    options
  );
}
export type TaskTypesFilterQueryHookResult = ReturnType<typeof useTaskTypesFilterQuery>;
export type TaskTypesFilterLazyQueryHookResult = ReturnType<typeof useTaskTypesFilterLazyQuery>;
export type TaskTypesFilterQueryResult = ApolloReactCommon.QueryResult<
  TaskTypesFilterQuery,
  TaskTypesFilterQueryVariables
>;
export const TeachersFilterDocument = gql`
  query TeachersFilter($stateID: String, $districtID: String, $schoolID: String) {
    teacherList(state_id: $stateID, district_id: $districtID, school_id: $schoolID) {
      totalCount
      nodes {
        ...TeacherFilterFragment
      }
    }
  }
  ${TeacherFilterFragmentFragmentDoc}
`;

/**
 * __useTeachersFilterQuery__
 *
 * To run a query within a React component, call `useTeachersFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeachersFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeachersFilterQuery({
 *   variables: {
 *      stateID: // value for 'stateID'
 *      districtID: // value for 'districtID'
 *      schoolID: // value for 'schoolID'
 *   },
 * });
 */
export function useTeachersFilterQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<TeachersFilterQuery, TeachersFilterQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<TeachersFilterQuery, TeachersFilterQueryVariables>(TeachersFilterDocument, options);
}
export function useTeachersFilterLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeachersFilterQuery, TeachersFilterQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<TeachersFilterQuery, TeachersFilterQueryVariables>(
    TeachersFilterDocument,
    options
  );
}
export type TeachersFilterQueryHookResult = ReturnType<typeof useTeachersFilterQuery>;
export type TeachersFilterLazyQueryHookResult = ReturnType<typeof useTeachersFilterLazyQuery>;
export type TeachersFilterQueryResult = ApolloReactCommon.QueryResult<
  TeachersFilterQuery,
  TeachersFilterQueryVariables
>;
export const TeacherListForTeacherSchoolDocument = gql`
  query TeacherListForTeacherSchool($schoolID: String) {
    teacherListForTeacherSchool(school_id: $schoolID) {
      totalCount
      nodes {
        ...TeacherFilterFragment
      }
    }
  }
  ${TeacherFilterFragmentFragmentDoc}
`;

/**
 * __useTeacherListForTeacherSchoolQuery__
 *
 * To run a query within a React component, call `useTeacherListForTeacherSchoolQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeacherListForTeacherSchoolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeacherListForTeacherSchoolQuery({
 *   variables: {
 *      schoolID: // value for 'schoolID'
 *   },
 * });
 */
export function useTeacherListForTeacherSchoolQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    TeacherListForTeacherSchoolQuery,
    TeacherListForTeacherSchoolQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<TeacherListForTeacherSchoolQuery, TeacherListForTeacherSchoolQueryVariables>(
    TeacherListForTeacherSchoolDocument,
    options
  );
}
export function useTeacherListForTeacherSchoolLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    TeacherListForTeacherSchoolQuery,
    TeacherListForTeacherSchoolQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<TeacherListForTeacherSchoolQuery, TeacherListForTeacherSchoolQueryVariables>(
    TeacherListForTeacherSchoolDocument,
    options
  );
}
export type TeacherListForTeacherSchoolQueryHookResult = ReturnType<typeof useTeacherListForTeacherSchoolQuery>;
export type TeacherListForTeacherSchoolLazyQueryHookResult = ReturnType<typeof useTeacherListForTeacherSchoolLazyQuery>;
export type TeacherListForTeacherSchoolQueryResult = ApolloReactCommon.QueryResult<
  TeacherListForTeacherSchoolQuery,
  TeacherListForTeacherSchoolQueryVariables
>;
export const TeachersOfstudentFilterDocument = gql`
  query TeachersOfstudentFilter {
    teachersOfStudent {
      ...TeacherOfStudentFilterFragment
    }
  }
  ${TeacherOfStudentFilterFragmentFragmentDoc}
`;

/**
 * __useTeachersOfstudentFilterQuery__
 *
 * To run a query within a React component, call `useTeachersOfstudentFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeachersOfstudentFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeachersOfstudentFilterQuery({
 *   variables: {
 *   },
 * });
 */
export function useTeachersOfstudentFilterQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<TeachersOfstudentFilterQuery, TeachersOfstudentFilterQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<TeachersOfstudentFilterQuery, TeachersOfstudentFilterQueryVariables>(
    TeachersOfstudentFilterDocument,
    options
  );
}
export function useTeachersOfstudentFilterLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    TeachersOfstudentFilterQuery,
    TeachersOfstudentFilterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<TeachersOfstudentFilterQuery, TeachersOfstudentFilterQueryVariables>(
    TeachersOfstudentFilterDocument,
    options
  );
}
export type TeachersOfstudentFilterQueryHookResult = ReturnType<typeof useTeachersOfstudentFilterQuery>;
export type TeachersOfstudentFilterLazyQueryHookResult = ReturnType<typeof useTeachersOfstudentFilterLazyQuery>;
export type TeachersOfstudentFilterQueryResult = ApolloReactCommon.QueryResult<
  TeachersOfstudentFilterQuery,
  TeachersOfstudentFilterQueryVariables
>;
export const AnchorToggleStatusDocument = gql`
  query AnchorToggleStatus {
    anchorToggle
  }
`;

/**
 * __useAnchorToggleStatusQuery__
 *
 * To run a query within a React component, call `useAnchorToggleStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnchorToggleStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnchorToggleStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useAnchorToggleStatusQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AnchorToggleStatusQuery, AnchorToggleStatusQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AnchorToggleStatusQuery, AnchorToggleStatusQueryVariables>(
    AnchorToggleStatusDocument,
    options
  );
}
export function useAnchorToggleStatusLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AnchorToggleStatusQuery, AnchorToggleStatusQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AnchorToggleStatusQuery, AnchorToggleStatusQueryVariables>(
    AnchorToggleStatusDocument,
    options
  );
}
export type AnchorToggleStatusQueryHookResult = ReturnType<typeof useAnchorToggleStatusQuery>;
export type AnchorToggleStatusLazyQueryHookResult = ReturnType<typeof useAnchorToggleStatusLazyQuery>;
export type AnchorToggleStatusQueryResult = ApolloReactCommon.QueryResult<
  AnchorToggleStatusQuery,
  AnchorToggleStatusQueryVariables
>;
export const AnchorToggleUpdateDocument = gql`
  mutation AnchorToggleUpdate($showToggle: Boolean!) {
    anchorToggle(show_toggle: $showToggle)
  }
`;
export type AnchorToggleUpdateMutationFn = ApolloReactCommon.MutationFunction<
  AnchorToggleUpdateMutation,
  AnchorToggleUpdateMutationVariables
>;

/**
 * __useAnchorToggleUpdateMutation__
 *
 * To run a mutation, you first call `useAnchorToggleUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAnchorToggleUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [anchorToggleUpdateMutation, { data, loading, error }] = useAnchorToggleUpdateMutation({
 *   variables: {
 *      showToggle: // value for 'showToggle'
 *   },
 * });
 */
export function useAnchorToggleUpdateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<AnchorToggleUpdateMutation, AnchorToggleUpdateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<AnchorToggleUpdateMutation, AnchorToggleUpdateMutationVariables>(
    AnchorToggleUpdateDocument,
    options
  );
}
export type AnchorToggleUpdateMutationHookResult = ReturnType<typeof useAnchorToggleUpdateMutation>;
export type AnchorToggleUpdateMutationResult = ApolloReactCommon.MutationResult<AnchorToggleUpdateMutation>;
export type AnchorToggleUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AnchorToggleUpdateMutation,
  AnchorToggleUpdateMutationVariables
>;
export const RubricsDocument = gql`
  query Rubrics {
    getRubrics {
      id
      title
      content
      submission_type
    }
  }
`;

/**
 * __useRubricsQuery__
 *
 * To run a query within a React component, call `useRubricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRubricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRubricsQuery({
 *   variables: {
 *   },
 * });
 */
export function useRubricsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RubricsQuery, RubricsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<RubricsQuery, RubricsQueryVariables>(RubricsDocument, options);
}
export function useRubricsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RubricsQuery, RubricsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<RubricsQuery, RubricsQueryVariables>(RubricsDocument, options);
}
export type RubricsQueryHookResult = ReturnType<typeof useRubricsQuery>;
export type RubricsLazyQueryHookResult = ReturnType<typeof useRubricsLazyQuery>;
export type RubricsQueryResult = ApolloReactCommon.QueryResult<RubricsQuery, RubricsQueryVariables>;
export const SchoolAdminEditDetailsDocument = gql`
  query SchoolAdminEditDetails($id: ID!) {
    schoolAdmin(id: $id) {
      ...AdminFragment
    }
  }
  ${AdminFragmentFragmentDoc}
`;

/**
 * __useSchoolAdminEditDetailsQuery__
 *
 * To run a query within a React component, call `useSchoolAdminEditDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolAdminEditDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolAdminEditDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSchoolAdminEditDetailsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<SchoolAdminEditDetailsQuery, SchoolAdminEditDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<SchoolAdminEditDetailsQuery, SchoolAdminEditDetailsQueryVariables>(
    SchoolAdminEditDetailsDocument,
    options
  );
}
export function useSchoolAdminEditDetailsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SchoolAdminEditDetailsQuery, SchoolAdminEditDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<SchoolAdminEditDetailsQuery, SchoolAdminEditDetailsQueryVariables>(
    SchoolAdminEditDetailsDocument,
    options
  );
}
export type SchoolAdminEditDetailsQueryHookResult = ReturnType<typeof useSchoolAdminEditDetailsQuery>;
export type SchoolAdminEditDetailsLazyQueryHookResult = ReturnType<typeof useSchoolAdminEditDetailsLazyQuery>;
export type SchoolAdminEditDetailsQueryResult = ApolloReactCommon.QueryResult<
  SchoolAdminEditDetailsQuery,
  SchoolAdminEditDetailsQueryVariables
>;
export const DistrictAdminEditDetailsDocument = gql`
  query DistrictAdminEditDetails($id: ID!) {
    districtAdmin(id: $id) {
      ...AdminFragment
    }
  }
  ${AdminFragmentFragmentDoc}
`;

/**
 * __useDistrictAdminEditDetailsQuery__
 *
 * To run a query within a React component, call `useDistrictAdminEditDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDistrictAdminEditDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDistrictAdminEditDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDistrictAdminEditDetailsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<DistrictAdminEditDetailsQuery, DistrictAdminEditDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<DistrictAdminEditDetailsQuery, DistrictAdminEditDetailsQueryVariables>(
    DistrictAdminEditDetailsDocument,
    options
  );
}
export function useDistrictAdminEditDetailsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    DistrictAdminEditDetailsQuery,
    DistrictAdminEditDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<DistrictAdminEditDetailsQuery, DistrictAdminEditDetailsQueryVariables>(
    DistrictAdminEditDetailsDocument,
    options
  );
}
export type DistrictAdminEditDetailsQueryHookResult = ReturnType<typeof useDistrictAdminEditDetailsQuery>;
export type DistrictAdminEditDetailsLazyQueryHookResult = ReturnType<typeof useDistrictAdminEditDetailsLazyQuery>;
export type DistrictAdminEditDetailsQueryResult = ApolloReactCommon.QueryResult<
  DistrictAdminEditDetailsQuery,
  DistrictAdminEditDetailsQueryVariables
>;
export const DistrictAdminFormDocument = gql`
  mutation DistrictAdminForm($input: AdminInput!) {
    upsertDistrictAdmin(input: $input)
  }
`;
export type DistrictAdminFormMutationFn = ApolloReactCommon.MutationFunction<
  DistrictAdminFormMutation,
  DistrictAdminFormMutationVariables
>;

/**
 * __useDistrictAdminFormMutation__
 *
 * To run a mutation, you first call `useDistrictAdminFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDistrictAdminFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [districtAdminFormMutation, { data, loading, error }] = useDistrictAdminFormMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDistrictAdminFormMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DistrictAdminFormMutation, DistrictAdminFormMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DistrictAdminFormMutation, DistrictAdminFormMutationVariables>(
    DistrictAdminFormDocument,
    options
  );
}
export type DistrictAdminFormMutationHookResult = ReturnType<typeof useDistrictAdminFormMutation>;
export type DistrictAdminFormMutationResult = ApolloReactCommon.MutationResult<DistrictAdminFormMutation>;
export type DistrictAdminFormMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DistrictAdminFormMutation,
  DistrictAdminFormMutationVariables
>;
export const SchoolAdminFormDocument = gql`
  mutation SchoolAdminForm($input: AdminInput!) {
    upsertSchoolAdmin(input: $input)
  }
`;
export type SchoolAdminFormMutationFn = ApolloReactCommon.MutationFunction<
  SchoolAdminFormMutation,
  SchoolAdminFormMutationVariables
>;

/**
 * __useSchoolAdminFormMutation__
 *
 * To run a mutation, you first call `useSchoolAdminFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSchoolAdminFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [schoolAdminFormMutation, { data, loading, error }] = useSchoolAdminFormMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSchoolAdminFormMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<SchoolAdminFormMutation, SchoolAdminFormMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<SchoolAdminFormMutation, SchoolAdminFormMutationVariables>(
    SchoolAdminFormDocument,
    options
  );
}
export type SchoolAdminFormMutationHookResult = ReturnType<typeof useSchoolAdminFormMutation>;
export type SchoolAdminFormMutationResult = ApolloReactCommon.MutationResult<SchoolAdminFormMutation>;
export type SchoolAdminFormMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SchoolAdminFormMutation,
  SchoolAdminFormMutationVariables
>;
export const GetAcademicSessionDocument = gql`
  query GetAcademicSession {
    getAcademicSession {
      id
      session_start_date
      session_end_date
    }
  }
`;

/**
 * __useGetAcademicSessionQuery__
 *
 * To run a query within a React component, call `useGetAcademicSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAcademicSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAcademicSessionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAcademicSessionQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetAcademicSessionQuery, GetAcademicSessionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetAcademicSessionQuery, GetAcademicSessionQueryVariables>(
    GetAcademicSessionDocument,
    options
  );
}
export function useGetAcademicSessionLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAcademicSessionQuery, GetAcademicSessionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetAcademicSessionQuery, GetAcademicSessionQueryVariables>(
    GetAcademicSessionDocument,
    options
  );
}
export type GetAcademicSessionQueryHookResult = ReturnType<typeof useGetAcademicSessionQuery>;
export type GetAcademicSessionLazyQueryHookResult = ReturnType<typeof useGetAcademicSessionLazyQuery>;
export type GetAcademicSessionQueryResult = ApolloReactCommon.QueryResult<
  GetAcademicSessionQuery,
  GetAcademicSessionQueryVariables
>;
export const DistrictWideSchoolDataDocument = gql`
  query DistrictWideSchoolData(
    $district_id: String
    $school_id: String
    $limit: Int
    $page: Int
    $sort: DistrictWideSchoolDetailsSortType
    $orderBy: OrderBy
  ) {
    districtWideSchoolData(
      district_id: $district_id
      school_id: $school_id
      limit: $limit
      page: $page
      sort: $sort
      orderBy: $orderBy
    ) {
      districtWideSchoolDetailsCount
      districtWideSchoolDetails {
        school_id
        school_name
        teachers
        students
        goals_assigned
        pts_assigned
        pts_completed
        avg_speaking_score
        avg_writing_score
      }
    }
  }
`;

/**
 * __useDistrictWideSchoolDataQuery__
 *
 * To run a query within a React component, call `useDistrictWideSchoolDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useDistrictWideSchoolDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDistrictWideSchoolDataQuery({
 *   variables: {
 *      district_id: // value for 'district_id'
 *      school_id: // value for 'school_id'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      sort: // value for 'sort'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useDistrictWideSchoolDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<DistrictWideSchoolDataQuery, DistrictWideSchoolDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<DistrictWideSchoolDataQuery, DistrictWideSchoolDataQueryVariables>(
    DistrictWideSchoolDataDocument,
    options
  );
}
export function useDistrictWideSchoolDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DistrictWideSchoolDataQuery, DistrictWideSchoolDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<DistrictWideSchoolDataQuery, DistrictWideSchoolDataQueryVariables>(
    DistrictWideSchoolDataDocument,
    options
  );
}
export type DistrictWideSchoolDataQueryHookResult = ReturnType<typeof useDistrictWideSchoolDataQuery>;
export type DistrictWideSchoolDataLazyQueryHookResult = ReturnType<typeof useDistrictWideSchoolDataLazyQuery>;
export type DistrictWideSchoolDataQueryResult = ApolloReactCommon.QueryResult<
  DistrictWideSchoolDataQuery,
  DistrictWideSchoolDataQueryVariables
>;
export const DistrictWideSchoolSpecificDataDocument = gql`
  query DistrictWideSchoolSpecificData(
    $school_id: String!
    $limit: Int
    $page: Int
    $sort: DistrictWideSchoolSpecificDetailsSortType
    $orderBy: OrderBy
  ) {
    districtWideSchoolSpecificData(school_id: $school_id, limit: $limit, page: $page, sort: $sort, orderBy: $orderBy) {
      DistrictWideSchoolSpecificDetailsCount
      districtWideSchoolSpecificDetails {
        teacher
        grade
        grade_position
        section
        avg_speaking_score
        avg_writing_score
      }
    }
  }
`;

/**
 * __useDistrictWideSchoolSpecificDataQuery__
 *
 * To run a query within a React component, call `useDistrictWideSchoolSpecificDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useDistrictWideSchoolSpecificDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDistrictWideSchoolSpecificDataQuery({
 *   variables: {
 *      school_id: // value for 'school_id'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      sort: // value for 'sort'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useDistrictWideSchoolSpecificDataQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    DistrictWideSchoolSpecificDataQuery,
    DistrictWideSchoolSpecificDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<DistrictWideSchoolSpecificDataQuery, DistrictWideSchoolSpecificDataQueryVariables>(
    DistrictWideSchoolSpecificDataDocument,
    options
  );
}
export function useDistrictWideSchoolSpecificDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    DistrictWideSchoolSpecificDataQuery,
    DistrictWideSchoolSpecificDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    DistrictWideSchoolSpecificDataQuery,
    DistrictWideSchoolSpecificDataQueryVariables
  >(DistrictWideSchoolSpecificDataDocument, options);
}
export type DistrictWideSchoolSpecificDataQueryHookResult = ReturnType<typeof useDistrictWideSchoolSpecificDataQuery>;
export type DistrictWideSchoolSpecificDataLazyQueryHookResult = ReturnType<
  typeof useDistrictWideSchoolSpecificDataLazyQuery
>;
export type DistrictWideSchoolSpecificDataQueryResult = ApolloReactCommon.QueryResult<
  DistrictWideSchoolSpecificDataQuery,
  DistrictWideSchoolSpecificDataQueryVariables
>;
export const DistrictWideGradeDataDocument = gql`
  query DistrictWideGradeData($input: DistrictWideGradeInput) {
    districtWideGradeInfo(input: $input) {
      grade_id
      grade_title
      teacher_count
      student_count
      total_goals_assigned
      total_submissions_count
      total_submissions_completed_count
      speaking_overall_score
      writing_overall_score
    }
  }
`;

/**
 * __useDistrictWideGradeDataQuery__
 *
 * To run a query within a React component, call `useDistrictWideGradeDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useDistrictWideGradeDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDistrictWideGradeDataQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDistrictWideGradeDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<DistrictWideGradeDataQuery, DistrictWideGradeDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<DistrictWideGradeDataQuery, DistrictWideGradeDataQueryVariables>(
    DistrictWideGradeDataDocument,
    options
  );
}
export function useDistrictWideGradeDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DistrictWideGradeDataQuery, DistrictWideGradeDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<DistrictWideGradeDataQuery, DistrictWideGradeDataQueryVariables>(
    DistrictWideGradeDataDocument,
    options
  );
}
export type DistrictWideGradeDataQueryHookResult = ReturnType<typeof useDistrictWideGradeDataQuery>;
export type DistrictWideGradeDataLazyQueryHookResult = ReturnType<typeof useDistrictWideGradeDataLazyQuery>;
export type DistrictWideGradeDataQueryResult = ApolloReactCommon.QueryResult<
  DistrictWideGradeDataQuery,
  DistrictWideGradeDataQueryVariables
>;
export const DistrictWideGradeSpecificDataDocument = gql`
  query DistrictWideGradeSpecificData(
    $district_id: String
    $school_id: String
    $grade_id: String!
    $limit: Int
    $page: Int
    $sort: DistrictWideGradeSpecificDetailsSortType
    $orderBy: OrderBy
  ) {
    districtWideGradeSpecificData(
      district_id: $district_id
      school_id: $school_id
      grade_id: $grade_id
      limit: $limit
      page: $page
      sort: $sort
      orderBy: $orderBy
    ) {
      districtWideGradeSpecificDetailsCount
      districtWideGradeSpecificDetails {
        teacher
        teacher_id
        school
        school_id
        section
        section_id
        avg_speaking_score
        avg_writing_score
      }
    }
  }
`;

/**
 * __useDistrictWideGradeSpecificDataQuery__
 *
 * To run a query within a React component, call `useDistrictWideGradeSpecificDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useDistrictWideGradeSpecificDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDistrictWideGradeSpecificDataQuery({
 *   variables: {
 *      district_id: // value for 'district_id'
 *      school_id: // value for 'school_id'
 *      grade_id: // value for 'grade_id'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      sort: // value for 'sort'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useDistrictWideGradeSpecificDataQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    DistrictWideGradeSpecificDataQuery,
    DistrictWideGradeSpecificDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<DistrictWideGradeSpecificDataQuery, DistrictWideGradeSpecificDataQueryVariables>(
    DistrictWideGradeSpecificDataDocument,
    options
  );
}
export function useDistrictWideGradeSpecificDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    DistrictWideGradeSpecificDataQuery,
    DistrictWideGradeSpecificDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<DistrictWideGradeSpecificDataQuery, DistrictWideGradeSpecificDataQueryVariables>(
    DistrictWideGradeSpecificDataDocument,
    options
  );
}
export type DistrictWideGradeSpecificDataQueryHookResult = ReturnType<typeof useDistrictWideGradeSpecificDataQuery>;
export type DistrictWideGradeSpecificDataLazyQueryHookResult = ReturnType<
  typeof useDistrictWideGradeSpecificDataLazyQuery
>;
export type DistrictWideGradeSpecificDataQueryResult = ApolloReactCommon.QueryResult<
  DistrictWideGradeSpecificDataQuery,
  DistrictWideGradeSpecificDataQueryVariables
>;
export const CompletedAssignmentsLearningGoalsWidgetDocument = gql`
  query CompletedAssignmentsLearningGoalsWidget(
    $district_id: String
    $school_id: String
    $input: AdminDashboardFilterInput
  ) {
    learningGoalsCompletedPTsMostRecentGoalsData(district_id: $district_id, school_id: $school_id, input: $input) {
      month
      year
      unique_student_completed_pts_count
      most_recent_goals_count
      average_no_of_most_recent_goals_count
    }
  }
`;

/**
 * __useCompletedAssignmentsLearningGoalsWidgetQuery__
 *
 * To run a query within a React component, call `useCompletedAssignmentsLearningGoalsWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompletedAssignmentsLearningGoalsWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompletedAssignmentsLearningGoalsWidgetQuery({
 *   variables: {
 *      district_id: // value for 'district_id'
 *      school_id: // value for 'school_id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompletedAssignmentsLearningGoalsWidgetQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CompletedAssignmentsLearningGoalsWidgetQuery,
    CompletedAssignmentsLearningGoalsWidgetQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    CompletedAssignmentsLearningGoalsWidgetQuery,
    CompletedAssignmentsLearningGoalsWidgetQueryVariables
  >(CompletedAssignmentsLearningGoalsWidgetDocument, options);
}
export function useCompletedAssignmentsLearningGoalsWidgetLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CompletedAssignmentsLearningGoalsWidgetQuery,
    CompletedAssignmentsLearningGoalsWidgetQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    CompletedAssignmentsLearningGoalsWidgetQuery,
    CompletedAssignmentsLearningGoalsWidgetQueryVariables
  >(CompletedAssignmentsLearningGoalsWidgetDocument, options);
}
export type CompletedAssignmentsLearningGoalsWidgetQueryHookResult = ReturnType<
  typeof useCompletedAssignmentsLearningGoalsWidgetQuery
>;
export type CompletedAssignmentsLearningGoalsWidgetLazyQueryHookResult = ReturnType<
  typeof useCompletedAssignmentsLearningGoalsWidgetLazyQuery
>;
export type CompletedAssignmentsLearningGoalsWidgetQueryResult = ApolloReactCommon.QueryResult<
  CompletedAssignmentsLearningGoalsWidgetQuery,
  CompletedAssignmentsLearningGoalsWidgetQueryVariables
>;
export const GoalsAchievementGraphWidgetDocument = gql`
  query GoalsAchievementGraphWidget($input: GoalAchievementGraphInput!) {
    goalAchievementGraph(input: $input) {
      max_count
      goal_achievement_by_category {
        label
        data {
          timestamp
          value
        }
      }
    }
  }
`;

/**
 * __useGoalsAchievementGraphWidgetQuery__
 *
 * To run a query within a React component, call `useGoalsAchievementGraphWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGoalsAchievementGraphWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGoalsAchievementGraphWidgetQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGoalsAchievementGraphWidgetQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GoalsAchievementGraphWidgetQuery,
    GoalsAchievementGraphWidgetQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GoalsAchievementGraphWidgetQuery, GoalsAchievementGraphWidgetQueryVariables>(
    GoalsAchievementGraphWidgetDocument,
    options
  );
}
export function useGoalsAchievementGraphWidgetLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GoalsAchievementGraphWidgetQuery,
    GoalsAchievementGraphWidgetQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GoalsAchievementGraphWidgetQuery, GoalsAchievementGraphWidgetQueryVariables>(
    GoalsAchievementGraphWidgetDocument,
    options
  );
}
export type GoalsAchievementGraphWidgetQueryHookResult = ReturnType<typeof useGoalsAchievementGraphWidgetQuery>;
export type GoalsAchievementGraphWidgetLazyQueryHookResult = ReturnType<typeof useGoalsAchievementGraphWidgetLazyQuery>;
export type GoalsAchievementGraphWidgetQueryResult = ApolloReactCommon.QueryResult<
  GoalsAchievementGraphWidgetQuery,
  GoalsAchievementGraphWidgetQueryVariables
>;
export const LearningScoresAndGoalsWidgetDocument = gql`
  query LearningScoresAndGoalsWidget($input: LearningGoalsScoresByIndicatorInput) {
    learningScoresAndGoalsWidget(input: $input) {
      rubric_title
      goals_count
      average_score
    }
  }
`;

/**
 * __useLearningScoresAndGoalsWidgetQuery__
 *
 * To run a query within a React component, call `useLearningScoresAndGoalsWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useLearningScoresAndGoalsWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLearningScoresAndGoalsWidgetQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLearningScoresAndGoalsWidgetQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    LearningScoresAndGoalsWidgetQuery,
    LearningScoresAndGoalsWidgetQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<LearningScoresAndGoalsWidgetQuery, LearningScoresAndGoalsWidgetQueryVariables>(
    LearningScoresAndGoalsWidgetDocument,
    options
  );
}
export function useLearningScoresAndGoalsWidgetLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    LearningScoresAndGoalsWidgetQuery,
    LearningScoresAndGoalsWidgetQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<LearningScoresAndGoalsWidgetQuery, LearningScoresAndGoalsWidgetQueryVariables>(
    LearningScoresAndGoalsWidgetDocument,
    options
  );
}
export type LearningScoresAndGoalsWidgetQueryHookResult = ReturnType<typeof useLearningScoresAndGoalsWidgetQuery>;
export type LearningScoresAndGoalsWidgetLazyQueryHookResult = ReturnType<
  typeof useLearningScoresAndGoalsWidgetLazyQuery
>;
export type LearningScoresAndGoalsWidgetQueryResult = ApolloReactCommon.QueryResult<
  LearningScoresAndGoalsWidgetQuery,
  LearningScoresAndGoalsWidgetQueryVariables
>;
export const LearningGoalsPerformanceWidgetDocument = gql`
  query LearningGoalsPerformanceWidget($district_id: String, $school_id: String, $input: AdminDashboardFilterInput) {
    learningGoalsPerformanceData(district_id: $district_id, school_id: $school_id, input: $input) {
      color
      title
      total_students
      percentage_of_students
    }
  }
`;

/**
 * __useLearningGoalsPerformanceWidgetQuery__
 *
 * To run a query within a React component, call `useLearningGoalsPerformanceWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useLearningGoalsPerformanceWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLearningGoalsPerformanceWidgetQuery({
 *   variables: {
 *      district_id: // value for 'district_id'
 *      school_id: // value for 'school_id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLearningGoalsPerformanceWidgetQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    LearningGoalsPerformanceWidgetQuery,
    LearningGoalsPerformanceWidgetQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<LearningGoalsPerformanceWidgetQuery, LearningGoalsPerformanceWidgetQueryVariables>(
    LearningGoalsPerformanceWidgetDocument,
    options
  );
}
export function useLearningGoalsPerformanceWidgetLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    LearningGoalsPerformanceWidgetQuery,
    LearningGoalsPerformanceWidgetQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    LearningGoalsPerformanceWidgetQuery,
    LearningGoalsPerformanceWidgetQueryVariables
  >(LearningGoalsPerformanceWidgetDocument, options);
}
export type LearningGoalsPerformanceWidgetQueryHookResult = ReturnType<typeof useLearningGoalsPerformanceWidgetQuery>;
export type LearningGoalsPerformanceWidgetLazyQueryHookResult = ReturnType<
  typeof useLearningGoalsPerformanceWidgetLazyQuery
>;
export type LearningGoalsPerformanceWidgetQueryResult = ApolloReactCommon.QueryResult<
  LearningGoalsPerformanceWidgetQuery,
  LearningGoalsPerformanceWidgetQueryVariables
>;
export const LearningGoalsByIndicatorWidgetDocument = gql`
  query LearningGoalsByIndicatorWidget($district_id: String, $school_id: String, $input: AdminDashboardFilterInput) {
    learningGoalsIndicatorData(district_id: $district_id, school_id: $school_id, input: $input) {
      total_goals
      learningGoalsbyIndicator {
        skill_label
        skill_level
        skill_key
        skill_description
        color
        goals_count
        id
      }
    }
  }
`;

/**
 * __useLearningGoalsByIndicatorWidgetQuery__
 *
 * To run a query within a React component, call `useLearningGoalsByIndicatorWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useLearningGoalsByIndicatorWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLearningGoalsByIndicatorWidgetQuery({
 *   variables: {
 *      district_id: // value for 'district_id'
 *      school_id: // value for 'school_id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLearningGoalsByIndicatorWidgetQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    LearningGoalsByIndicatorWidgetQuery,
    LearningGoalsByIndicatorWidgetQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<LearningGoalsByIndicatorWidgetQuery, LearningGoalsByIndicatorWidgetQueryVariables>(
    LearningGoalsByIndicatorWidgetDocument,
    options
  );
}
export function useLearningGoalsByIndicatorWidgetLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    LearningGoalsByIndicatorWidgetQuery,
    LearningGoalsByIndicatorWidgetQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    LearningGoalsByIndicatorWidgetQuery,
    LearningGoalsByIndicatorWidgetQueryVariables
  >(LearningGoalsByIndicatorWidgetDocument, options);
}
export type LearningGoalsByIndicatorWidgetQueryHookResult = ReturnType<typeof useLearningGoalsByIndicatorWidgetQuery>;
export type LearningGoalsByIndicatorWidgetLazyQueryHookResult = ReturnType<
  typeof useLearningGoalsByIndicatorWidgetLazyQuery
>;
export type LearningGoalsByIndicatorWidgetQueryResult = ApolloReactCommon.QueryResult<
  LearningGoalsByIndicatorWidgetQuery,
  LearningGoalsByIndicatorWidgetQueryVariables
>;
export const LearningGoalsPerformanceWidgetSlideoutDocument = gql`
  query LearningGoalsPerformanceWidgetSlideout($input: LearningGoalWidgetSlideOutInput!) {
    learningGoalWidgetSlideOut(input: $input) {
      total_count
      nodes {
        first_name
        last_name
        student_id
        grade
        school_id
        school_name
        section_id
        section_name
        teacher_id
        teacher_first_name
        teacher_last_name
      }
    }
  }
`;

/**
 * __useLearningGoalsPerformanceWidgetSlideoutQuery__
 *
 * To run a query within a React component, call `useLearningGoalsPerformanceWidgetSlideoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useLearningGoalsPerformanceWidgetSlideoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLearningGoalsPerformanceWidgetSlideoutQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLearningGoalsPerformanceWidgetSlideoutQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    LearningGoalsPerformanceWidgetSlideoutQuery,
    LearningGoalsPerformanceWidgetSlideoutQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    LearningGoalsPerformanceWidgetSlideoutQuery,
    LearningGoalsPerformanceWidgetSlideoutQueryVariables
  >(LearningGoalsPerformanceWidgetSlideoutDocument, options);
}
export function useLearningGoalsPerformanceWidgetSlideoutLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    LearningGoalsPerformanceWidgetSlideoutQuery,
    LearningGoalsPerformanceWidgetSlideoutQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    LearningGoalsPerformanceWidgetSlideoutQuery,
    LearningGoalsPerformanceWidgetSlideoutQueryVariables
  >(LearningGoalsPerformanceWidgetSlideoutDocument, options);
}
export type LearningGoalsPerformanceWidgetSlideoutQueryHookResult = ReturnType<
  typeof useLearningGoalsPerformanceWidgetSlideoutQuery
>;
export type LearningGoalsPerformanceWidgetSlideoutLazyQueryHookResult = ReturnType<
  typeof useLearningGoalsPerformanceWidgetSlideoutLazyQuery
>;
export type LearningGoalsPerformanceWidgetSlideoutQueryResult = ApolloReactCommon.QueryResult<
  LearningGoalsPerformanceWidgetSlideoutQuery,
  LearningGoalsPerformanceWidgetSlideoutQueryVariables
>;
export const OverallPerformanceWidgetDocument = gql`
  query OverallPerformanceWidget($input: OverallPerformanceWidgetInput) {
    overallPerformanceInfo(input: $input) {
      current_day_average_score
      historical_score {
        date_timestamp
        average_score
        scored_pt_count
        unique_student_count
      }
    }
  }
`;

/**
 * __useOverallPerformanceWidgetQuery__
 *
 * To run a query within a React component, call `useOverallPerformanceWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useOverallPerformanceWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOverallPerformanceWidgetQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOverallPerformanceWidgetQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<OverallPerformanceWidgetQuery, OverallPerformanceWidgetQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<OverallPerformanceWidgetQuery, OverallPerformanceWidgetQueryVariables>(
    OverallPerformanceWidgetDocument,
    options
  );
}
export function useOverallPerformanceWidgetLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OverallPerformanceWidgetQuery,
    OverallPerformanceWidgetQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<OverallPerformanceWidgetQuery, OverallPerformanceWidgetQueryVariables>(
    OverallPerformanceWidgetDocument,
    options
  );
}
export type OverallPerformanceWidgetQueryHookResult = ReturnType<typeof useOverallPerformanceWidgetQuery>;
export type OverallPerformanceWidgetLazyQueryHookResult = ReturnType<typeof useOverallPerformanceWidgetLazyQuery>;
export type OverallPerformanceWidgetQueryResult = ApolloReactCommon.QueryResult<
  OverallPerformanceWidgetQuery,
  OverallPerformanceWidgetQueryVariables
>;
export const PerformanceWidgetDocument = gql`
  query PerformanceWidget($input: PerformanceWidgetInput!) {
    performanceWidget(input: $input) {
      id
      skill_key
      skill_label
      skill_description
      skill_level
      color
      total_count
    }
  }
`;

/**
 * __usePerformanceWidgetQuery__
 *
 * To run a query within a React component, call `usePerformanceWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `usePerformanceWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePerformanceWidgetQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePerformanceWidgetQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<PerformanceWidgetQuery, PerformanceWidgetQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<PerformanceWidgetQuery, PerformanceWidgetQueryVariables>(
    PerformanceWidgetDocument,
    options
  );
}
export function usePerformanceWidgetLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PerformanceWidgetQuery, PerformanceWidgetQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<PerformanceWidgetQuery, PerformanceWidgetQueryVariables>(
    PerformanceWidgetDocument,
    options
  );
}
export type PerformanceWidgetQueryHookResult = ReturnType<typeof usePerformanceWidgetQuery>;
export type PerformanceWidgetLazyQueryHookResult = ReturnType<typeof usePerformanceWidgetLazyQuery>;
export type PerformanceWidgetQueryResult = ApolloReactCommon.QueryResult<
  PerformanceWidgetQuery,
  PerformanceWidgetQueryVariables
>;
export const StudentCompletionRatesWidgetDocument = gql`
  query StudentCompletionRatesWidget($district_id: String, $school_id: String, $input: AdminDashboardFilterInput) {
    performanceWideStudentCompletionRateData(district_id: $district_id, school_id: $school_id, input: $input) {
      percentage_of_pts_submitted_and_graded_for_ytd
      academic_session_end_date
      academic_session_start_date
      performanceWideStudentCompletionRateBar {
        month
        pts_assigned
        pts_submitted_and_graded
        year
      }
    }
  }
`;

/**
 * __useStudentCompletionRatesWidgetQuery__
 *
 * To run a query within a React component, call `useStudentCompletionRatesWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentCompletionRatesWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentCompletionRatesWidgetQuery({
 *   variables: {
 *      district_id: // value for 'district_id'
 *      school_id: // value for 'school_id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStudentCompletionRatesWidgetQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    StudentCompletionRatesWidgetQuery,
    StudentCompletionRatesWidgetQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<StudentCompletionRatesWidgetQuery, StudentCompletionRatesWidgetQueryVariables>(
    StudentCompletionRatesWidgetDocument,
    options
  );
}
export function useStudentCompletionRatesWidgetLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    StudentCompletionRatesWidgetQuery,
    StudentCompletionRatesWidgetQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<StudentCompletionRatesWidgetQuery, StudentCompletionRatesWidgetQueryVariables>(
    StudentCompletionRatesWidgetDocument,
    options
  );
}
export type StudentCompletionRatesWidgetQueryHookResult = ReturnType<typeof useStudentCompletionRatesWidgetQuery>;
export type StudentCompletionRatesWidgetLazyQueryHookResult = ReturnType<
  typeof useStudentCompletionRatesWidgetLazyQuery
>;
export type StudentCompletionRatesWidgetQueryResult = ApolloReactCommon.QueryResult<
  StudentCompletionRatesWidgetQuery,
  StudentCompletionRatesWidgetQueryVariables
>;
export const PerformanceWidgetSlideOutDocument = gql`
  query PerformanceWidgetSlideOut($input: PerformanceWidgetSlideOutInput!) {
    performanceWidgetSlideOut(input: $input) {
      total_count
      nodes {
        first_name
        last_name
        student_id
        grade
        school_id
        school_name
        section_id
        section_name
        teacher_id
        teacher_first_name
        teacher_last_name
      }
    }
  }
`;

/**
 * __usePerformanceWidgetSlideOutQuery__
 *
 * To run a query within a React component, call `usePerformanceWidgetSlideOutQuery` and pass it any options that fit your needs.
 * When your component renders, `usePerformanceWidgetSlideOutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePerformanceWidgetSlideOutQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePerformanceWidgetSlideOutQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    PerformanceWidgetSlideOutQuery,
    PerformanceWidgetSlideOutQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<PerformanceWidgetSlideOutQuery, PerformanceWidgetSlideOutQueryVariables>(
    PerformanceWidgetSlideOutDocument,
    options
  );
}
export function usePerformanceWidgetSlideOutLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PerformanceWidgetSlideOutQuery,
    PerformanceWidgetSlideOutQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<PerformanceWidgetSlideOutQuery, PerformanceWidgetSlideOutQueryVariables>(
    PerformanceWidgetSlideOutDocument,
    options
  );
}
export type PerformanceWidgetSlideOutQueryHookResult = ReturnType<typeof usePerformanceWidgetSlideOutQuery>;
export type PerformanceWidgetSlideOutLazyQueryHookResult = ReturnType<typeof usePerformanceWidgetSlideOutLazyQuery>;
export type PerformanceWidgetSlideOutQueryResult = ApolloReactCommon.QueryResult<
  PerformanceWidgetSlideOutQuery,
  PerformanceWidgetSlideOutQueryVariables
>;
export const TasksAssignedAndCompletedWidgetDocument = gql`
  query TasksAssignedAndCompletedWidget($district_id: String, $school_id: String, $input: AdminDashboardFilterInput) {
    performanceWideTaskAssignedAndCompletedData(district_id: $district_id, school_id: $school_id, input: $input) {
      count_of_tasks_assigned
      count_of_students
      average_tasks_assigned_per_teacher
      tasks_completed_per_student
    }
  }
`;

/**
 * __useTasksAssignedAndCompletedWidgetQuery__
 *
 * To run a query within a React component, call `useTasksAssignedAndCompletedWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useTasksAssignedAndCompletedWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTasksAssignedAndCompletedWidgetQuery({
 *   variables: {
 *      district_id: // value for 'district_id'
 *      school_id: // value for 'school_id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTasksAssignedAndCompletedWidgetQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    TasksAssignedAndCompletedWidgetQuery,
    TasksAssignedAndCompletedWidgetQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<TasksAssignedAndCompletedWidgetQuery, TasksAssignedAndCompletedWidgetQueryVariables>(
    TasksAssignedAndCompletedWidgetDocument,
    options
  );
}
export function useTasksAssignedAndCompletedWidgetLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    TasksAssignedAndCompletedWidgetQuery,
    TasksAssignedAndCompletedWidgetQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    TasksAssignedAndCompletedWidgetQuery,
    TasksAssignedAndCompletedWidgetQueryVariables
  >(TasksAssignedAndCompletedWidgetDocument, options);
}
export type TasksAssignedAndCompletedWidgetQueryHookResult = ReturnType<typeof useTasksAssignedAndCompletedWidgetQuery>;
export type TasksAssignedAndCompletedWidgetLazyQueryHookResult = ReturnType<
  typeof useTasksAssignedAndCompletedWidgetLazyQuery
>;
export type TasksAssignedAndCompletedWidgetQueryResult = ApolloReactCommon.QueryResult<
  TasksAssignedAndCompletedWidgetQuery,
  TasksAssignedAndCompletedWidgetQueryVariables
>;
export const GetLastSnowflakeUpdateTimeDocument = gql`
  query GetLastSnowflakeUpdateTime {
    getLastSnowflakeUpdateTime
  }
`;

/**
 * __useGetLastSnowflakeUpdateTimeQuery__
 *
 * To run a query within a React component, call `useGetLastSnowflakeUpdateTimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLastSnowflakeUpdateTimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLastSnowflakeUpdateTimeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLastSnowflakeUpdateTimeQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetLastSnowflakeUpdateTimeQuery,
    GetLastSnowflakeUpdateTimeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetLastSnowflakeUpdateTimeQuery, GetLastSnowflakeUpdateTimeQueryVariables>(
    GetLastSnowflakeUpdateTimeDocument,
    options
  );
}
export function useGetLastSnowflakeUpdateTimeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetLastSnowflakeUpdateTimeQuery,
    GetLastSnowflakeUpdateTimeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetLastSnowflakeUpdateTimeQuery, GetLastSnowflakeUpdateTimeQueryVariables>(
    GetLastSnowflakeUpdateTimeDocument,
    options
  );
}
export type GetLastSnowflakeUpdateTimeQueryHookResult = ReturnType<typeof useGetLastSnowflakeUpdateTimeQuery>;
export type GetLastSnowflakeUpdateTimeLazyQueryHookResult = ReturnType<typeof useGetLastSnowflakeUpdateTimeLazyQuery>;
export type GetLastSnowflakeUpdateTimeQueryResult = ApolloReactCommon.QueryResult<
  GetLastSnowflakeUpdateTimeQuery,
  GetLastSnowflakeUpdateTimeQueryVariables
>;
export const GetTotalBenchmarkStudentsDocument = gql`
  query getTotalBenchmarkStudents($districtIds: [String!]!, $gradeIds: [String!], $schoolIds: [String!]) {
    totalBenchmarkStudentCountByFilters(
      input: { district_ids: $districtIds, grade_ids: $gradeIds, school_ids: $schoolIds }
    )
  }
`;

/**
 * __useGetTotalBenchmarkStudentsQuery__
 *
 * To run a query within a React component, call `useGetTotalBenchmarkStudentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTotalBenchmarkStudentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTotalBenchmarkStudentsQuery({
 *   variables: {
 *      districtIds: // value for 'districtIds'
 *      gradeIds: // value for 'gradeIds'
 *      schoolIds: // value for 'schoolIds'
 *   },
 * });
 */
export function useGetTotalBenchmarkStudentsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetTotalBenchmarkStudentsQuery,
    GetTotalBenchmarkStudentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetTotalBenchmarkStudentsQuery, GetTotalBenchmarkStudentsQueryVariables>(
    GetTotalBenchmarkStudentsDocument,
    options
  );
}
export function useGetTotalBenchmarkStudentsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetTotalBenchmarkStudentsQuery,
    GetTotalBenchmarkStudentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetTotalBenchmarkStudentsQuery, GetTotalBenchmarkStudentsQueryVariables>(
    GetTotalBenchmarkStudentsDocument,
    options
  );
}
export type GetTotalBenchmarkStudentsQueryHookResult = ReturnType<typeof useGetTotalBenchmarkStudentsQuery>;
export type GetTotalBenchmarkStudentsLazyQueryHookResult = ReturnType<typeof useGetTotalBenchmarkStudentsLazyQuery>;
export type GetTotalBenchmarkStudentsQueryResult = ApolloReactCommon.QueryResult<
  GetTotalBenchmarkStudentsQuery,
  GetTotalBenchmarkStudentsQueryVariables
>;
export const GetBenchmarkStudentsDocument = gql`
  query getBenchmarkStudents(
    $districtIds: [String!]!
    $gradeIds: [String]
    $schoolIds: [String]
    $page: Int
    $limit: Int
    $sort: BenchmarkAssignToSortType
    $orderBy: OrderBy
  ) {
    benchmarkStudentCountByFilters(
      input: {
        district_ids: $districtIds
        grade_ids: $gradeIds
        school_ids: $schoolIds
        page: $page
        limit: $limit
        sort: $sort
        order_by: $orderBy
      }
    ) {
      node_count
      nodes {
        district_id
        district_name
        grade_id
        grade_name
        school_id
        school_name
        student_count
      }
    }
  }
`;

/**
 * __useGetBenchmarkStudentsQuery__
 *
 * To run a query within a React component, call `useGetBenchmarkStudentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBenchmarkStudentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBenchmarkStudentsQuery({
 *   variables: {
 *      districtIds: // value for 'districtIds'
 *      gradeIds: // value for 'gradeIds'
 *      schoolIds: // value for 'schoolIds'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetBenchmarkStudentsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetBenchmarkStudentsQuery, GetBenchmarkStudentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetBenchmarkStudentsQuery, GetBenchmarkStudentsQueryVariables>(
    GetBenchmarkStudentsDocument,
    options
  );
}
export function useGetBenchmarkStudentsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBenchmarkStudentsQuery, GetBenchmarkStudentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetBenchmarkStudentsQuery, GetBenchmarkStudentsQueryVariables>(
    GetBenchmarkStudentsDocument,
    options
  );
}
export type GetBenchmarkStudentsQueryHookResult = ReturnType<typeof useGetBenchmarkStudentsQuery>;
export type GetBenchmarkStudentsLazyQueryHookResult = ReturnType<typeof useGetBenchmarkStudentsLazyQuery>;
export type GetBenchmarkStudentsQueryResult = ApolloReactCommon.QueryResult<
  GetBenchmarkStudentsQuery,
  GetBenchmarkStudentsQueryVariables
>;
export const CreateBenchmarkDocument = gql`
  mutation CreateBenchmark($input: CreateBenchmarkInput!) {
    createBenchmark(input: $input)
  }
`;
export type CreateBenchmarkMutationFn = ApolloReactCommon.MutationFunction<
  CreateBenchmarkMutation,
  CreateBenchmarkMutationVariables
>;

/**
 * __useCreateBenchmarkMutation__
 *
 * To run a mutation, you first call `useCreateBenchmarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBenchmarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBenchmarkMutation, { data, loading, error }] = useCreateBenchmarkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBenchmarkMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBenchmarkMutation, CreateBenchmarkMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateBenchmarkMutation, CreateBenchmarkMutationVariables>(
    CreateBenchmarkDocument,
    options
  );
}
export type CreateBenchmarkMutationHookResult = ReturnType<typeof useCreateBenchmarkMutation>;
export type CreateBenchmarkMutationResult = ApolloReactCommon.MutationResult<CreateBenchmarkMutation>;
export type CreateBenchmarkMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateBenchmarkMutation,
  CreateBenchmarkMutationVariables
>;
export const BenchmarkDetailDocument = gql`
  query BenchmarkDetail($id: ID!) {
    benchmark(id: $id) {
      id
      title
      start_at
      close_at
      graded_by
      benchmark_type
      back_navigation_allowed
      district_names
      grade_titles
      school_names
      is_submissions_started
    }
  }
`;

/**
 * __useBenchmarkDetailQuery__
 *
 * To run a query within a React component, call `useBenchmarkDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useBenchmarkDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBenchmarkDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBenchmarkDetailQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<BenchmarkDetailQuery, BenchmarkDetailQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<BenchmarkDetailQuery, BenchmarkDetailQueryVariables>(
    BenchmarkDetailDocument,
    options
  );
}
export function useBenchmarkDetailLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BenchmarkDetailQuery, BenchmarkDetailQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<BenchmarkDetailQuery, BenchmarkDetailQueryVariables>(
    BenchmarkDetailDocument,
    options
  );
}
export type BenchmarkDetailQueryHookResult = ReturnType<typeof useBenchmarkDetailQuery>;
export type BenchmarkDetailLazyQueryHookResult = ReturnType<typeof useBenchmarkDetailLazyQuery>;
export type BenchmarkDetailQueryResult = ApolloReactCommon.QueryResult<
  BenchmarkDetailQuery,
  BenchmarkDetailQueryVariables
>;
export const BenchmarkEditFormDocument = gql`
  mutation BenchmarkEditForm($input: EditBenchmarkInput!) {
    editBenchmark(input: $input)
  }
`;
export type BenchmarkEditFormMutationFn = ApolloReactCommon.MutationFunction<
  BenchmarkEditFormMutation,
  BenchmarkEditFormMutationVariables
>;

/**
 * __useBenchmarkEditFormMutation__
 *
 * To run a mutation, you first call `useBenchmarkEditFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBenchmarkEditFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [benchmarkEditFormMutation, { data, loading, error }] = useBenchmarkEditFormMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBenchmarkEditFormMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<BenchmarkEditFormMutation, BenchmarkEditFormMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<BenchmarkEditFormMutation, BenchmarkEditFormMutationVariables>(
    BenchmarkEditFormDocument,
    options
  );
}
export type BenchmarkEditFormMutationHookResult = ReturnType<typeof useBenchmarkEditFormMutation>;
export type BenchmarkEditFormMutationResult = ApolloReactCommon.MutationResult<BenchmarkEditFormMutation>;
export type BenchmarkEditFormMutationOptions = ApolloReactCommon.BaseMutationOptions<
  BenchmarkEditFormMutation,
  BenchmarkEditFormMutationVariables
>;
export const BenchmarkGradesListDocument = gql`
  query BenchmarkGradesList(
    $state_ids: [String!]
    $district_ids: [String!]
    $school_ids: [String!]
    $predefined_grade_ids: [String!]
    $grade_statuses: [SubmissionStatus!]
    $benchmark_types: [BenchmarkType!]
    $graded_by: [BenchmarkGradedBy!]
    $search: String
    $page: Int
    $limit: Int
    $sort: BenchmarkSubmissionListSortType
    $order_by: OrderBy
  ) {
    adminBenchmarkSubmissionList(
      input: {
        state_ids: $state_ids
        district_ids: $district_ids
        school_ids: $school_ids
        predefined_grade_ids: $predefined_grade_ids
        grade_statuses: $grade_statuses
        benchmark_types: $benchmark_types
        graded_by: $graded_by
        search: $search
        page: $page
        limit: $limit
        sort: $sort
        order_by: $order_by
      }
    ) {
      nodes {
        id
        status
        student_id
        submitted_at
        user_first_name
        user_last_name
        student_grade_id
        student_grade_title
        student_district_id
        student_district_title
        graded_by
        benchmark_id
        benchmark_title
        benchmark_image_url
        benchmark_image_title
        benchmark_thumbnail_image_url
      }
      node_count
    }
  }
`;

/**
 * __useBenchmarkGradesListQuery__
 *
 * To run a query within a React component, call `useBenchmarkGradesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useBenchmarkGradesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBenchmarkGradesListQuery({
 *   variables: {
 *      state_ids: // value for 'state_ids'
 *      district_ids: // value for 'district_ids'
 *      school_ids: // value for 'school_ids'
 *      predefined_grade_ids: // value for 'predefined_grade_ids'
 *      grade_statuses: // value for 'grade_statuses'
 *      benchmark_types: // value for 'benchmark_types'
 *      graded_by: // value for 'graded_by'
 *      search: // value for 'search'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useBenchmarkGradesListQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<BenchmarkGradesListQuery, BenchmarkGradesListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<BenchmarkGradesListQuery, BenchmarkGradesListQueryVariables>(
    BenchmarkGradesListDocument,
    options
  );
}
export function useBenchmarkGradesListLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BenchmarkGradesListQuery, BenchmarkGradesListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<BenchmarkGradesListQuery, BenchmarkGradesListQueryVariables>(
    BenchmarkGradesListDocument,
    options
  );
}
export type BenchmarkGradesListQueryHookResult = ReturnType<typeof useBenchmarkGradesListQuery>;
export type BenchmarkGradesListLazyQueryHookResult = ReturnType<typeof useBenchmarkGradesListLazyQuery>;
export type BenchmarkGradesListQueryResult = ApolloReactCommon.QueryResult<
  BenchmarkGradesListQuery,
  BenchmarkGradesListQueryVariables
>;
export const AdminBenchmarkGradingQueueDocument = gql`
  query AdminBenchmarkGradingQueue(
    $state_ids: [String!]
    $district_ids: [String!]
    $school_ids: [String!]
    $predefined_grade_ids: [String!]
    $grade_statuses: [SubmissionStatus!]
    $benchmark_types: [BenchmarkType!]
    $graded_by: [BenchmarkGradedBy!]
    $search: String
    $page: Int
    $limit: Int
    $sort: BenchmarkSubmissionListSortType
    $order_by: OrderBy
  ) {
    adminBenchmarkGradingQueue(
      input: {
        state_ids: $state_ids
        district_ids: $district_ids
        school_ids: $school_ids
        predefined_grade_ids: $predefined_grade_ids
        grade_statuses: $grade_statuses
        benchmark_types: $benchmark_types
        graded_by: $graded_by
        search: $search
        page: $page
        limit: $limit
        sort: $sort
        order_by: $order_by
      }
    )
  }
`;

/**
 * __useAdminBenchmarkGradingQueueQuery__
 *
 * To run a query within a React component, call `useAdminBenchmarkGradingQueueQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminBenchmarkGradingQueueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminBenchmarkGradingQueueQuery({
 *   variables: {
 *      state_ids: // value for 'state_ids'
 *      district_ids: // value for 'district_ids'
 *      school_ids: // value for 'school_ids'
 *      predefined_grade_ids: // value for 'predefined_grade_ids'
 *      grade_statuses: // value for 'grade_statuses'
 *      benchmark_types: // value for 'benchmark_types'
 *      graded_by: // value for 'graded_by'
 *      search: // value for 'search'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useAdminBenchmarkGradingQueueQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AdminBenchmarkGradingQueueQuery,
    AdminBenchmarkGradingQueueQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AdminBenchmarkGradingQueueQuery, AdminBenchmarkGradingQueueQueryVariables>(
    AdminBenchmarkGradingQueueDocument,
    options
  );
}
export function useAdminBenchmarkGradingQueueLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AdminBenchmarkGradingQueueQuery,
    AdminBenchmarkGradingQueueQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AdminBenchmarkGradingQueueQuery, AdminBenchmarkGradingQueueQueryVariables>(
    AdminBenchmarkGradingQueueDocument,
    options
  );
}
export type AdminBenchmarkGradingQueueQueryHookResult = ReturnType<typeof useAdminBenchmarkGradingQueueQuery>;
export type AdminBenchmarkGradingQueueLazyQueryHookResult = ReturnType<typeof useAdminBenchmarkGradingQueueLazyQuery>;
export type AdminBenchmarkGradingQueueQueryResult = ApolloReactCommon.QueryResult<
  AdminBenchmarkGradingQueueQuery,
  AdminBenchmarkGradingQueueQueryVariables
>;
export const AdminBmStudentsSlideoutDocument = gql`
  query adminBMStudentsSlideout($benchmarkId: String!, $status: [SubmissionStatus!]) {
    adminBenchmarkSlideOut(input: { benchmark_id: $benchmarkId, status_list: $status }) {
      total_count
      nodes {
        first_name
        last_name
        submission_id
        submission_status
        student_number
      }
    }
  }
`;

/**
 * __useAdminBmStudentsSlideoutQuery__
 *
 * To run a query within a React component, call `useAdminBmStudentsSlideoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminBmStudentsSlideoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminBmStudentsSlideoutQuery({
 *   variables: {
 *      benchmarkId: // value for 'benchmarkId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useAdminBmStudentsSlideoutQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<AdminBmStudentsSlideoutQuery, AdminBmStudentsSlideoutQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AdminBmStudentsSlideoutQuery, AdminBmStudentsSlideoutQueryVariables>(
    AdminBmStudentsSlideoutDocument,
    options
  );
}
export function useAdminBmStudentsSlideoutLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AdminBmStudentsSlideoutQuery,
    AdminBmStudentsSlideoutQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AdminBmStudentsSlideoutQuery, AdminBmStudentsSlideoutQueryVariables>(
    AdminBmStudentsSlideoutDocument,
    options
  );
}
export type AdminBmStudentsSlideoutQueryHookResult = ReturnType<typeof useAdminBmStudentsSlideoutQuery>;
export type AdminBmStudentsSlideoutLazyQueryHookResult = ReturnType<typeof useAdminBmStudentsSlideoutLazyQuery>;
export type AdminBmStudentsSlideoutQueryResult = ApolloReactCommon.QueryResult<
  AdminBmStudentsSlideoutQuery,
  AdminBmStudentsSlideoutQueryVariables
>;
export const BenchmarksListDocument = gql`
  query BenchmarksList(
    $startAt: DateQuery
    $closeAt: DateQuery
    $page: Int
    $limit: Int
    $sort: BenchmarkSuperAdminDTOSortType
    $orderBy: OrderBy
    $search: String
  ) {
    getAllBenchmarksForSuperAdmin(
      startAt: $startAt
      closeAt: $closeAt
      page: $page
      limit: $limit
      sort: $sort
      orderBy: $orderBy
      search: $search
    ) {
      totalCount
      nodes {
        benchmark_id
        benchmark_title
        start_date
        close_date
        district_id
        district_name
        school_id
        school_name
        grade_title
        status_aggregation {
          NotStarted
          InProgress
          Submitted
        }
        resource {
          id
          file {
            url
            thumbnail_url
          }
        }
      }
    }
  }
`;

/**
 * __useBenchmarksListQuery__
 *
 * To run a query within a React component, call `useBenchmarksListQuery` and pass it any options that fit your needs.
 * When your component renders, `useBenchmarksListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBenchmarksListQuery({
 *   variables: {
 *      startAt: // value for 'startAt'
 *      closeAt: // value for 'closeAt'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *      orderBy: // value for 'orderBy'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useBenchmarksListQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<BenchmarksListQuery, BenchmarksListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<BenchmarksListQuery, BenchmarksListQueryVariables>(BenchmarksListDocument, options);
}
export function useBenchmarksListLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BenchmarksListQuery, BenchmarksListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<BenchmarksListQuery, BenchmarksListQueryVariables>(
    BenchmarksListDocument,
    options
  );
}
export type BenchmarksListQueryHookResult = ReturnType<typeof useBenchmarksListQuery>;
export type BenchmarksListLazyQueryHookResult = ReturnType<typeof useBenchmarksListLazyQuery>;
export type BenchmarksListQueryResult = ApolloReactCommon.QueryResult<
  BenchmarksListQuery,
  BenchmarksListQueryVariables
>;
export const DeleteBenchmarkDocument = gql`
  mutation DeleteBenchmark($id: String!) {
    deleteBenchmark(id: $id)
  }
`;
export type DeleteBenchmarkMutationFn = ApolloReactCommon.MutationFunction<
  DeleteBenchmarkMutation,
  DeleteBenchmarkMutationVariables
>;

/**
 * __useDeleteBenchmarkMutation__
 *
 * To run a mutation, you first call `useDeleteBenchmarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBenchmarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBenchmarkMutation, { data, loading, error }] = useDeleteBenchmarkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBenchmarkMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteBenchmarkMutation, DeleteBenchmarkMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeleteBenchmarkMutation, DeleteBenchmarkMutationVariables>(
    DeleteBenchmarkDocument,
    options
  );
}
export type DeleteBenchmarkMutationHookResult = ReturnType<typeof useDeleteBenchmarkMutation>;
export type DeleteBenchmarkMutationResult = ApolloReactCommon.MutationResult<DeleteBenchmarkMutation>;
export type DeleteBenchmarkMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteBenchmarkMutation,
  DeleteBenchmarkMutationVariables
>;
export const DashboardDataDocument = gql`
  query DashboardData {
    dashboardData {
      role
      states
      districts
      school_admins
      district_admins
      schools
      students
      teachers
      classes
    }
  }
`;

/**
 * __useDashboardDataQuery__
 *
 * To run a query within a React component, call `useDashboardDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useDashboardDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<DashboardDataQuery, DashboardDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<DashboardDataQuery, DashboardDataQueryVariables>(DashboardDataDocument, options);
}
export function useDashboardDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DashboardDataQuery, DashboardDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<DashboardDataQuery, DashboardDataQueryVariables>(DashboardDataDocument, options);
}
export type DashboardDataQueryHookResult = ReturnType<typeof useDashboardDataQuery>;
export type DashboardDataLazyQueryHookResult = ReturnType<typeof useDashboardDataLazyQuery>;
export type DashboardDataQueryResult = ApolloReactCommon.QueryResult<DashboardDataQuery, DashboardDataQueryVariables>;
export const AdminStudentDetailsDocument = gql`
  query AdminStudentDetails($id: ID!) {
    viewStudent(id: $id) {
      id
      user_id
      first_name
      last_name
      email
      student_id
      grade
      school_id
      district_id
      state_id
      school_name
      language_level
      heritage_language
      demographic_info
      source
    }
  }
`;

/**
 * __useAdminStudentDetailsQuery__
 *
 * To run a query within a React component, call `useAdminStudentDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminStudentDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminStudentDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminStudentDetailsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<AdminStudentDetailsQuery, AdminStudentDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AdminStudentDetailsQuery, AdminStudentDetailsQueryVariables>(
    AdminStudentDetailsDocument,
    options
  );
}
export function useAdminStudentDetailsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdminStudentDetailsQuery, AdminStudentDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AdminStudentDetailsQuery, AdminStudentDetailsQueryVariables>(
    AdminStudentDetailsDocument,
    options
  );
}
export type AdminStudentDetailsQueryHookResult = ReturnType<typeof useAdminStudentDetailsQuery>;
export type AdminStudentDetailsLazyQueryHookResult = ReturnType<typeof useAdminStudentDetailsLazyQuery>;
export type AdminStudentDetailsQueryResult = ApolloReactCommon.QueryResult<
  AdminStudentDetailsQuery,
  AdminStudentDetailsQueryVariables
>;
export const StudentEditFormDocument = gql`
  query StudentEditForm($id: ID!) {
    viewStudent(id: $id) {
      id
      first_name
      middle_name
      last_name
      student_id
      email
      grade
      school_id
      school_name
      sections {
        id
      }
      heritage_language
      language_class
      language_level
      demographic_info
      state_id
      district_id
      source
    }
  }
`;

/**
 * __useStudentEditFormQuery__
 *
 * To run a query within a React component, call `useStudentEditFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentEditFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentEditFormQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStudentEditFormQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<StudentEditFormQuery, StudentEditFormQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<StudentEditFormQuery, StudentEditFormQueryVariables>(
    StudentEditFormDocument,
    options
  );
}
export function useStudentEditFormLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StudentEditFormQuery, StudentEditFormQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<StudentEditFormQuery, StudentEditFormQueryVariables>(
    StudentEditFormDocument,
    options
  );
}
export type StudentEditFormQueryHookResult = ReturnType<typeof useStudentEditFormQuery>;
export type StudentEditFormLazyQueryHookResult = ReturnType<typeof useStudentEditFormLazyQuery>;
export type StudentEditFormQueryResult = ApolloReactCommon.QueryResult<
  StudentEditFormQuery,
  StudentEditFormQueryVariables
>;
export const CreateStudentFormDocument = gql`
  mutation CreateStudentForm($input: CreateStudentInput!) {
    createStudent(input: $input)
  }
`;
export type CreateStudentFormMutationFn = ApolloReactCommon.MutationFunction<
  CreateStudentFormMutation,
  CreateStudentFormMutationVariables
>;

/**
 * __useCreateStudentFormMutation__
 *
 * To run a mutation, you first call `useCreateStudentFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStudentFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStudentFormMutation, { data, loading, error }] = useCreateStudentFormMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStudentFormMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateStudentFormMutation, CreateStudentFormMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateStudentFormMutation, CreateStudentFormMutationVariables>(
    CreateStudentFormDocument,
    options
  );
}
export type CreateStudentFormMutationHookResult = ReturnType<typeof useCreateStudentFormMutation>;
export type CreateStudentFormMutationResult = ApolloReactCommon.MutationResult<CreateStudentFormMutation>;
export type CreateStudentFormMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateStudentFormMutation,
  CreateStudentFormMutationVariables
>;
export const UpdateStudentFormDocument = gql`
  mutation UpdateStudentForm($input: UpdateStudentInput!) {
    updateStudent(input: $input)
  }
`;
export type UpdateStudentFormMutationFn = ApolloReactCommon.MutationFunction<
  UpdateStudentFormMutation,
  UpdateStudentFormMutationVariables
>;

/**
 * __useUpdateStudentFormMutation__
 *
 * To run a mutation, you first call `useUpdateStudentFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStudentFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStudentFormMutation, { data, loading, error }] = useUpdateStudentFormMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStudentFormMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateStudentFormMutation, UpdateStudentFormMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateStudentFormMutation, UpdateStudentFormMutationVariables>(
    UpdateStudentFormDocument,
    options
  );
}
export type UpdateStudentFormMutationHookResult = ReturnType<typeof useUpdateStudentFormMutation>;
export type UpdateStudentFormMutationResult = ApolloReactCommon.MutationResult<UpdateStudentFormMutation>;
export type UpdateStudentFormMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateStudentFormMutation,
  UpdateStudentFormMutationVariables
>;
export const StudentsListDocument = gql`
  query StudentsList(
    $stateId: String
    $districtId: String
    $schoolId: String
    $agencyId: String
    $sectionId: String
    $teacherId: String
    $page: Int
    $limit: Int
    $sort: AdminStudentsListSortType
    $searchByName: String
    $orderBy: OrderBy
  ) {
    allStudentsList(
      page: $page
      limit: $limit
      sort: $sort
      searchByName: $searchByName
      orderBy: $orderBy
      state_id: $stateId
      district_id: $districtId
      agency_id: $agencyId
      school_id: $schoolId
      section_id: $sectionId
      teacher_id: $teacherId
    ) {
      totalCount
      nodes {
        ...StudentDetailsFragment
      }
    }
  }
  ${StudentDetailsFragmentFragmentDoc}
`;

/**
 * __useStudentsListQuery__
 *
 * To run a query within a React component, call `useStudentsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentsListQuery({
 *   variables: {
 *      stateId: // value for 'stateId'
 *      districtId: // value for 'districtId'
 *      schoolId: // value for 'schoolId'
 *      agencyId: // value for 'agencyId'
 *      sectionId: // value for 'sectionId'
 *      teacherId: // value for 'teacherId'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *      searchByName: // value for 'searchByName'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useStudentsListQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<StudentsListQuery, StudentsListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<StudentsListQuery, StudentsListQueryVariables>(StudentsListDocument, options);
}
export function useStudentsListLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StudentsListQuery, StudentsListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<StudentsListQuery, StudentsListQueryVariables>(StudentsListDocument, options);
}
export type StudentsListQueryHookResult = ReturnType<typeof useStudentsListQuery>;
export type StudentsListLazyQueryHookResult = ReturnType<typeof useStudentsListLazyQuery>;
export type StudentsListQueryResult = ApolloReactCommon.QueryResult<StudentsListQuery, StudentsListQueryVariables>;
export const DistrictAdminDocument = gql`
  query DistrictAdmin($id: ID!) {
    districtAdmin(id: $id) {
      ...AdminDetailsFragment
    }
  }
  ${AdminDetailsFragmentFragmentDoc}
`;

/**
 * __useDistrictAdminQuery__
 *
 * To run a query within a React component, call `useDistrictAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useDistrictAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDistrictAdminQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDistrictAdminQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<DistrictAdminQuery, DistrictAdminQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<DistrictAdminQuery, DistrictAdminQueryVariables>(DistrictAdminDocument, options);
}
export function useDistrictAdminLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DistrictAdminQuery, DistrictAdminQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<DistrictAdminQuery, DistrictAdminQueryVariables>(DistrictAdminDocument, options);
}
export type DistrictAdminQueryHookResult = ReturnType<typeof useDistrictAdminQuery>;
export type DistrictAdminLazyQueryHookResult = ReturnType<typeof useDistrictAdminLazyQuery>;
export type DistrictAdminQueryResult = ApolloReactCommon.QueryResult<DistrictAdminQuery, DistrictAdminQueryVariables>;
export const SchoolAdminDocument = gql`
  query SchoolAdmin($id: ID!) {
    schoolAdmin(id: $id) {
      ...AdminDetailsFragment
    }
  }
  ${AdminDetailsFragmentFragmentDoc}
`;

/**
 * __useSchoolAdminQuery__
 *
 * To run a query within a React component, call `useSchoolAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolAdminQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSchoolAdminQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<SchoolAdminQuery, SchoolAdminQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<SchoolAdminQuery, SchoolAdminQueryVariables>(SchoolAdminDocument, options);
}
export function useSchoolAdminLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SchoolAdminQuery, SchoolAdminQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<SchoolAdminQuery, SchoolAdminQueryVariables>(SchoolAdminDocument, options);
}
export type SchoolAdminQueryHookResult = ReturnType<typeof useSchoolAdminQuery>;
export type SchoolAdminLazyQueryHookResult = ReturnType<typeof useSchoolAdminLazyQuery>;
export type SchoolAdminQueryResult = ApolloReactCommon.QueryResult<SchoolAdminQuery, SchoolAdminQueryVariables>;
export const SchoolAdminListDocument = gql`
  query SchoolAdminList(
    $stateId: String
    $districtId: String
    $schoolId: String
    $page: Int
    $limit: Int
    $sort: AdminListSortType
    $searchByName: String
    $orderBy: OrderBy
  ) {
    schoolAdminList(
      page: $page
      limit: $limit
      sort: $sort
      searchByName: $searchByName
      orderBy: $orderBy
      state_id: $stateId
      district_id: $districtId
      school_id: $schoolId
    ) {
      totalCount
      nodes {
        ...AdminListFragment
      }
    }
  }
  ${AdminListFragmentFragmentDoc}
`;

/**
 * __useSchoolAdminListQuery__
 *
 * To run a query within a React component, call `useSchoolAdminListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolAdminListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolAdminListQuery({
 *   variables: {
 *      stateId: // value for 'stateId'
 *      districtId: // value for 'districtId'
 *      schoolId: // value for 'schoolId'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *      searchByName: // value for 'searchByName'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useSchoolAdminListQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<SchoolAdminListQuery, SchoolAdminListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<SchoolAdminListQuery, SchoolAdminListQueryVariables>(
    SchoolAdminListDocument,
    options
  );
}
export function useSchoolAdminListLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SchoolAdminListQuery, SchoolAdminListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<SchoolAdminListQuery, SchoolAdminListQueryVariables>(
    SchoolAdminListDocument,
    options
  );
}
export type SchoolAdminListQueryHookResult = ReturnType<typeof useSchoolAdminListQuery>;
export type SchoolAdminListLazyQueryHookResult = ReturnType<typeof useSchoolAdminListLazyQuery>;
export type SchoolAdminListQueryResult = ApolloReactCommon.QueryResult<
  SchoolAdminListQuery,
  SchoolAdminListQueryVariables
>;
export const DistrictAdminListDocument = gql`
  query DistrictAdminList(
    $stateId: String
    $districtId: String
    $schoolId: String
    $page: Int
    $limit: Int
    $sort: AdminListSortType
    $searchByName: String
    $orderBy: OrderBy
  ) {
    districtAdminList(
      page: $page
      limit: $limit
      sort: $sort
      searchByName: $searchByName
      orderBy: $orderBy
      state_id: $stateId
      district_id: $districtId
      school_id: $schoolId
    ) {
      totalCount
      nodes {
        ...AdminListFragment
      }
    }
  }
  ${AdminListFragmentFragmentDoc}
`;

/**
 * __useDistrictAdminListQuery__
 *
 * To run a query within a React component, call `useDistrictAdminListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDistrictAdminListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDistrictAdminListQuery({
 *   variables: {
 *      stateId: // value for 'stateId'
 *      districtId: // value for 'districtId'
 *      schoolId: // value for 'schoolId'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *      searchByName: // value for 'searchByName'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useDistrictAdminListQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<DistrictAdminListQuery, DistrictAdminListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<DistrictAdminListQuery, DistrictAdminListQueryVariables>(
    DistrictAdminListDocument,
    options
  );
}
export function useDistrictAdminListLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DistrictAdminListQuery, DistrictAdminListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<DistrictAdminListQuery, DistrictAdminListQueryVariables>(
    DistrictAdminListDocument,
    options
  );
}
export type DistrictAdminListQueryHookResult = ReturnType<typeof useDistrictAdminListQuery>;
export type DistrictAdminListLazyQueryHookResult = ReturnType<typeof useDistrictAdminListLazyQuery>;
export type DistrictAdminListQueryResult = ApolloReactCommon.QueryResult<
  DistrictAdminListQuery,
  DistrictAdminListQueryVariables
>;
export const ScoreSummaryDocument = gql`
  query scoreSummary($assignmentIDs: [ID!], $sectionIDs: [ID!], $studentIDs: [ID!], $schoolIDs: [ID!]) {
    scoreSummary(
      input: {
        assignment_ids: $assignmentIDs
        section_ids: $sectionIDs
        student_ids: $studentIDs
        school_ids: $schoolIDs
        sort_by: skill_level
        order_by: Asc
      }
    ) {
      submission_type
      score_distribution {
        ...ScoresFragment
      }
    }
  }
  ${ScoresFragmentFragmentDoc}
`;

/**
 * __useScoreSummaryQuery__
 *
 * To run a query within a React component, call `useScoreSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useScoreSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScoreSummaryQuery({
 *   variables: {
 *      assignmentIDs: // value for 'assignmentIDs'
 *      sectionIDs: // value for 'sectionIDs'
 *      studentIDs: // value for 'studentIDs'
 *      schoolIDs: // value for 'schoolIDs'
 *   },
 * });
 */
export function useScoreSummaryQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<ScoreSummaryQuery, ScoreSummaryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ScoreSummaryQuery, ScoreSummaryQueryVariables>(ScoreSummaryDocument, options);
}
export function useScoreSummaryLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ScoreSummaryQuery, ScoreSummaryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ScoreSummaryQuery, ScoreSummaryQueryVariables>(ScoreSummaryDocument, options);
}
export type ScoreSummaryQueryHookResult = ReturnType<typeof useScoreSummaryQuery>;
export type ScoreSummaryLazyQueryHookResult = ReturnType<typeof useScoreSummaryLazyQuery>;
export type ScoreSummaryQueryResult = ApolloReactCommon.QueryResult<ScoreSummaryQuery, ScoreSummaryQueryVariables>;
export const ActiveGoalSummaryDocument = gql`
  query activeGoalSummary($assignmentIDs: [ID!], $sectionIDs: [ID!], $schoolID: ID) {
    activeGoalsSummary(assignmentIDs: $assignmentIDs, sectionIDs: $sectionIDs, schoolID: $schoolID) {
      submission_type
      score_distribution {
        ...ScoresFragment
      }
    }
  }
  ${ScoresFragmentFragmentDoc}
`;

/**
 * __useActiveGoalSummaryQuery__
 *
 * To run a query within a React component, call `useActiveGoalSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveGoalSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveGoalSummaryQuery({
 *   variables: {
 *      assignmentIDs: // value for 'assignmentIDs'
 *      sectionIDs: // value for 'sectionIDs'
 *      schoolID: // value for 'schoolID'
 *   },
 * });
 */
export function useActiveGoalSummaryQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<ActiveGoalSummaryQuery, ActiveGoalSummaryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ActiveGoalSummaryQuery, ActiveGoalSummaryQueryVariables>(
    ActiveGoalSummaryDocument,
    options
  );
}
export function useActiveGoalSummaryLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ActiveGoalSummaryQuery, ActiveGoalSummaryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ActiveGoalSummaryQuery, ActiveGoalSummaryQueryVariables>(
    ActiveGoalSummaryDocument,
    options
  );
}
export type ActiveGoalSummaryQueryHookResult = ReturnType<typeof useActiveGoalSummaryQuery>;
export type ActiveGoalSummaryLazyQueryHookResult = ReturnType<typeof useActiveGoalSummaryLazyQuery>;
export type ActiveGoalSummaryQueryResult = ApolloReactCommon.QueryResult<
  ActiveGoalSummaryQuery,
  ActiveGoalSummaryQueryVariables
>;
export const SpeakingScoreSummaryDocument = gql`
  query SpeakingScoreSummary($assignmentIDs: [ID!], $sectionIDs: [ID!], $schoolIDs: [ID!]) {
    speakingScoreSummary(
      input: {
        assignment_ids: $assignmentIDs
        section_ids: $sectionIDs
        school_ids: $schoolIDs
        sort_by: skill_level
        order_by: Asc
      }
    ) {
      ...ScoreDistribution
    }
  }
  ${ScoreDistributionFragmentDoc}
`;

/**
 * __useSpeakingScoreSummaryQuery__
 *
 * To run a query within a React component, call `useSpeakingScoreSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpeakingScoreSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpeakingScoreSummaryQuery({
 *   variables: {
 *      assignmentIDs: // value for 'assignmentIDs'
 *      sectionIDs: // value for 'sectionIDs'
 *      schoolIDs: // value for 'schoolIDs'
 *   },
 * });
 */
export function useSpeakingScoreSummaryQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<SpeakingScoreSummaryQuery, SpeakingScoreSummaryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<SpeakingScoreSummaryQuery, SpeakingScoreSummaryQueryVariables>(
    SpeakingScoreSummaryDocument,
    options
  );
}
export function useSpeakingScoreSummaryLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SpeakingScoreSummaryQuery, SpeakingScoreSummaryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<SpeakingScoreSummaryQuery, SpeakingScoreSummaryQueryVariables>(
    SpeakingScoreSummaryDocument,
    options
  );
}
export type SpeakingScoreSummaryQueryHookResult = ReturnType<typeof useSpeakingScoreSummaryQuery>;
export type SpeakingScoreSummaryLazyQueryHookResult = ReturnType<typeof useSpeakingScoreSummaryLazyQuery>;
export type SpeakingScoreSummaryQueryResult = ApolloReactCommon.QueryResult<
  SpeakingScoreSummaryQuery,
  SpeakingScoreSummaryQueryVariables
>;
export const WritingScoreSummaryDocument = gql`
  query WritingScoreSummary($assignmentIDs: [ID!], $sectionIDs: [ID!], $schoolIDs: [ID!]) {
    writingScoreSummary(
      input: {
        assignment_ids: $assignmentIDs
        section_ids: $sectionIDs
        school_ids: $schoolIDs
        sort_by: skill_level
        order_by: Asc
      }
    ) {
      ...ScoreDistribution
    }
  }
  ${ScoreDistributionFragmentDoc}
`;

/**
 * __useWritingScoreSummaryQuery__
 *
 * To run a query within a React component, call `useWritingScoreSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useWritingScoreSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWritingScoreSummaryQuery({
 *   variables: {
 *      assignmentIDs: // value for 'assignmentIDs'
 *      sectionIDs: // value for 'sectionIDs'
 *      schoolIDs: // value for 'schoolIDs'
 *   },
 * });
 */
export function useWritingScoreSummaryQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<WritingScoreSummaryQuery, WritingScoreSummaryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<WritingScoreSummaryQuery, WritingScoreSummaryQueryVariables>(
    WritingScoreSummaryDocument,
    options
  );
}
export function useWritingScoreSummaryLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<WritingScoreSummaryQuery, WritingScoreSummaryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<WritingScoreSummaryQuery, WritingScoreSummaryQueryVariables>(
    WritingScoreSummaryDocument,
    options
  );
}
export type WritingScoreSummaryQueryHookResult = ReturnType<typeof useWritingScoreSummaryQuery>;
export type WritingScoreSummaryLazyQueryHookResult = ReturnType<typeof useWritingScoreSummaryLazyQuery>;
export type WritingScoreSummaryQueryResult = ApolloReactCommon.QueryResult<
  WritingScoreSummaryQuery,
  WritingScoreSummaryQueryVariables
>;
export const AssignmentDocument = gql`
  query Assignment(
    $id: ID!
    $limit: Int
    $page: Int
    $sort: String
    $orderBy: OrderBy
    $search: String
    $statusList: [SubmissionStatus]
  ) {
    assignment(id: $id) {
      id
      title
      start_at
      close_at
      resource_id
      is_submissions_started
      back_navigation_allowed
      speaking_prompt
      writing_prompt
      is_common_prompt
      speaking_prompt_file {
        id
        url
        file_name
        file_type
      }
      writing_prompt_file {
        id
        url
        file_name
        file_type
      }
      resource {
        file {
          id
          url
          thumbnail_url
        }
      }
      section {
        id
        course
        name
        subject
      }
      submissionsForTeacher(
        page: $page
        limit: $limit
        sort: $sort
        orderBy: $orderBy
        search: $search
        status: $statusList
      ) {
        totalCount
        nodes {
          ...AssignmentSubmission
        }
      }
    }
  }
  ${AssignmentSubmissionFragmentDoc}
`;

/**
 * __useAssignmentQuery__
 *
 * To run a query within a React component, call `useAssignmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssignmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssignmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      sort: // value for 'sort'
 *      orderBy: // value for 'orderBy'
 *      search: // value for 'search'
 *      statusList: // value for 'statusList'
 *   },
 * });
 */
export function useAssignmentQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<AssignmentQuery, AssignmentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AssignmentQuery, AssignmentQueryVariables>(AssignmentDocument, options);
}
export function useAssignmentLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AssignmentQuery, AssignmentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AssignmentQuery, AssignmentQueryVariables>(AssignmentDocument, options);
}
export type AssignmentQueryHookResult = ReturnType<typeof useAssignmentQuery>;
export type AssignmentLazyQueryHookResult = ReturnType<typeof useAssignmentLazyQuery>;
export type AssignmentQueryResult = ApolloReactCommon.QueryResult<AssignmentQuery, AssignmentQueryVariables>;
export const PtStudentsSlideoutDocument = gql`
  query PTStudentsSlideout($assignId: String!, $status: [SubmissionStatus!]) {
    teacherPTSlideOut(input: { assignment_id: $assignId, status_list: $status }) {
      total_count
      nodes {
        first_name
        last_name
        submission_id
        submission_status
        student_number
      }
    }
  }
`;

/**
 * __usePtStudentsSlideoutQuery__
 *
 * To run a query within a React component, call `usePtStudentsSlideoutQuery` and pass it any options that fit your needs.
 * When your component renders, `usePtStudentsSlideoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePtStudentsSlideoutQuery({
 *   variables: {
 *      assignId: // value for 'assignId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function usePtStudentsSlideoutQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<PtStudentsSlideoutQuery, PtStudentsSlideoutQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<PtStudentsSlideoutQuery, PtStudentsSlideoutQueryVariables>(
    PtStudentsSlideoutDocument,
    options
  );
}
export function usePtStudentsSlideoutLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PtStudentsSlideoutQuery, PtStudentsSlideoutQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<PtStudentsSlideoutQuery, PtStudentsSlideoutQueryVariables>(
    PtStudentsSlideoutDocument,
    options
  );
}
export type PtStudentsSlideoutQueryHookResult = ReturnType<typeof usePtStudentsSlideoutQuery>;
export type PtStudentsSlideoutLazyQueryHookResult = ReturnType<typeof usePtStudentsSlideoutLazyQuery>;
export type PtStudentsSlideoutQueryResult = ApolloReactCommon.QueryResult<
  PtStudentsSlideoutQuery,
  PtStudentsSlideoutQueryVariables
>;
export const AssignmentsDocument = gql`
  query Assignments(
    $startAt: DateQuery
    $closeAt: DateQuery
    $page: Int
    $limit: Int
    $sort: String
    $orderBy: OrderBy
    $search: String
  ) {
    getAllAssignmentsForTeacher(
      startAt: $startAt
      closeAt: $closeAt
      page: $page
      limit: $limit
      sort: $sort
      orderBy: $orderBy
      search: $search
      loadAggregatedStatus: true
    ) {
      totalCount
      nodes {
        id
        title
        start_at
        close_at
        IsSubmissionsGoalsShared
        section {
          id
          name
          school {
            id
            name
          }
        }
        status_aggregation {
          NotStarted
          InProgress
          Submitted
        }
        resource {
          id
          file {
            url
            thumbnail_url
          }
        }
      }
    }
  }
`;

/**
 * __useAssignmentsQuery__
 *
 * To run a query within a React component, call `useAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssignmentsQuery({
 *   variables: {
 *      startAt: // value for 'startAt'
 *      closeAt: // value for 'closeAt'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *      orderBy: // value for 'orderBy'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useAssignmentsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AssignmentsQuery, AssignmentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AssignmentsQuery, AssignmentsQueryVariables>(AssignmentsDocument, options);
}
export function useAssignmentsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AssignmentsQuery, AssignmentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AssignmentsQuery, AssignmentsQueryVariables>(AssignmentsDocument, options);
}
export type AssignmentsQueryHookResult = ReturnType<typeof useAssignmentsQuery>;
export type AssignmentsLazyQueryHookResult = ReturnType<typeof useAssignmentsLazyQuery>;
export type AssignmentsQueryResult = ApolloReactCommon.QueryResult<AssignmentsQuery, AssignmentsQueryVariables>;
export const DeleteAssignmentDocument = gql`
  mutation DeleteAssignment($input: String!) {
    deleteAssignment(id: $input)
  }
`;
export type DeleteAssignmentMutationFn = ApolloReactCommon.MutationFunction<
  DeleteAssignmentMutation,
  DeleteAssignmentMutationVariables
>;

/**
 * __useDeleteAssignmentMutation__
 *
 * To run a mutation, you first call `useDeleteAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAssignmentMutation, { data, loading, error }] = useDeleteAssignmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAssignmentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteAssignmentMutation, DeleteAssignmentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeleteAssignmentMutation, DeleteAssignmentMutationVariables>(
    DeleteAssignmentDocument,
    options
  );
}
export type DeleteAssignmentMutationHookResult = ReturnType<typeof useDeleteAssignmentMutation>;
export type DeleteAssignmentMutationResult = ApolloReactCommon.MutationResult<DeleteAssignmentMutation>;
export type DeleteAssignmentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteAssignmentMutation,
  DeleteAssignmentMutationVariables
>;
export const CreateAssignmentDocument = gql`
  mutation CreateAssignment($input: CreateAssignmentInput!) {
    createAssignment(input: $input) {
      id
    }
  }
`;
export type CreateAssignmentMutationFn = ApolloReactCommon.MutationFunction<
  CreateAssignmentMutation,
  CreateAssignmentMutationVariables
>;

/**
 * __useCreateAssignmentMutation__
 *
 * To run a mutation, you first call `useCreateAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssignmentMutation, { data, loading, error }] = useCreateAssignmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAssignmentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateAssignmentMutation, CreateAssignmentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateAssignmentMutation, CreateAssignmentMutationVariables>(
    CreateAssignmentDocument,
    options
  );
}
export type CreateAssignmentMutationHookResult = ReturnType<typeof useCreateAssignmentMutation>;
export type CreateAssignmentMutationResult = ApolloReactCommon.MutationResult<CreateAssignmentMutation>;
export type CreateAssignmentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateAssignmentMutation,
  CreateAssignmentMutationVariables
>;
export const EditAssignmentDocument = gql`
  mutation EditAssignment($input: EditAssignmentInput!) {
    editAssignment(input: $input) {
      id
    }
  }
`;
export type EditAssignmentMutationFn = ApolloReactCommon.MutationFunction<
  EditAssignmentMutation,
  EditAssignmentMutationVariables
>;

/**
 * __useEditAssignmentMutation__
 *
 * To run a mutation, you first call `useEditAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAssignmentMutation, { data, loading, error }] = useEditAssignmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditAssignmentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<EditAssignmentMutation, EditAssignmentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<EditAssignmentMutation, EditAssignmentMutationVariables>(
    EditAssignmentDocument,
    options
  );
}
export type EditAssignmentMutationHookResult = ReturnType<typeof useEditAssignmentMutation>;
export type EditAssignmentMutationResult = ApolloReactCommon.MutationResult<EditAssignmentMutation>;
export type EditAssignmentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  EditAssignmentMutation,
  EditAssignmentMutationVariables
>;
export const SectionsDocument = gql`
  query Sections {
    sections {
      id
      course
      name
      subject
    }
  }
`;

/**
 * __useSectionsQuery__
 *
 * To run a query within a React component, call `useSectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSectionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSectionsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<SectionsQuery, SectionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<SectionsQuery, SectionsQueryVariables>(SectionsDocument, options);
}
export function useSectionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SectionsQuery, SectionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<SectionsQuery, SectionsQueryVariables>(SectionsDocument, options);
}
export type SectionsQueryHookResult = ReturnType<typeof useSectionsQuery>;
export type SectionsLazyQueryHookResult = ReturnType<typeof useSectionsLazyQuery>;
export type SectionsQueryResult = ApolloReactCommon.QueryResult<SectionsQuery, SectionsQueryVariables>;
export const SectionsWithGroupsDocument = gql`
  query SectionsWithGroups {
    sectionsToCreatePT {
      id
      course
      name
      subject
      groups {
        id
        name
      }
    }
  }
`;

/**
 * __useSectionsWithGroupsQuery__
 *
 * To run a query within a React component, call `useSectionsWithGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSectionsWithGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSectionsWithGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSectionsWithGroupsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<SectionsWithGroupsQuery, SectionsWithGroupsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<SectionsWithGroupsQuery, SectionsWithGroupsQueryVariables>(
    SectionsWithGroupsDocument,
    options
  );
}
export function useSectionsWithGroupsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SectionsWithGroupsQuery, SectionsWithGroupsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<SectionsWithGroupsQuery, SectionsWithGroupsQueryVariables>(
    SectionsWithGroupsDocument,
    options
  );
}
export type SectionsWithGroupsQueryHookResult = ReturnType<typeof useSectionsWithGroupsQuery>;
export type SectionsWithGroupsLazyQueryHookResult = ReturnType<typeof useSectionsWithGroupsLazyQuery>;
export type SectionsWithGroupsQueryResult = ApolloReactCommon.QueryResult<
  SectionsWithGroupsQuery,
  SectionsWithGroupsQueryVariables
>;
export const StaticResourcesDocument = gql`
  query StaticResources(
    $grade: [ID!]
    $subjectAreas: [ID!]
    $taskTypes: [ID!]
    $keywords: [String!]
    $resourceType: StaticResourceType!
  ) {
    staticResources(
      input: {
        grades: $grade
        subject_areas: $subjectAreas
        task_types: $taskTypes
        keywords: $keywords
        resourceType: $resourceType
      }
    ) {
      id
      file {
        id
        url
        thumbnail_url
      }
      title
      grade
    }
  }
`;

/**
 * __useStaticResourcesQuery__
 *
 * To run a query within a React component, call `useStaticResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaticResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaticResourcesQuery({
 *   variables: {
 *      grade: // value for 'grade'
 *      subjectAreas: // value for 'subjectAreas'
 *      taskTypes: // value for 'taskTypes'
 *      keywords: // value for 'keywords'
 *      resourceType: // value for 'resourceType'
 *   },
 * });
 */
export function useStaticResourcesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<StaticResourcesQuery, StaticResourcesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<StaticResourcesQuery, StaticResourcesQueryVariables>(
    StaticResourcesDocument,
    options
  );
}
export function useStaticResourcesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StaticResourcesQuery, StaticResourcesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<StaticResourcesQuery, StaticResourcesQueryVariables>(
    StaticResourcesDocument,
    options
  );
}
export type StaticResourcesQueryHookResult = ReturnType<typeof useStaticResourcesQuery>;
export type StaticResourcesLazyQueryHookResult = ReturnType<typeof useStaticResourcesLazyQuery>;
export type StaticResourcesQueryResult = ApolloReactCommon.QueryResult<
  StaticResourcesQuery,
  StaticResourcesQueryVariables
>;
export const SectionDetailDocument = gql`
  query SectionDetail($id: String!) {
    sectionDetails(id: $id) {
      id
      name
      school
      school_id
      teachers {
        id
        teacher_name
        email
        primary_teacher
        source
      }
      section_number
      grades
      period
      term_start
      term_end
      district_id
      category
      districtData {
        id
        state {
          id
        }
        state_id
        source
      }
      student_ids
    }
  }
`;

/**
 * __useSectionDetailQuery__
 *
 * To run a query within a React component, call `useSectionDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useSectionDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSectionDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSectionDetailQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<SectionDetailQuery, SectionDetailQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<SectionDetailQuery, SectionDetailQueryVariables>(SectionDetailDocument, options);
}
export function useSectionDetailLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SectionDetailQuery, SectionDetailQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<SectionDetailQuery, SectionDetailQueryVariables>(SectionDetailDocument, options);
}
export type SectionDetailQueryHookResult = ReturnType<typeof useSectionDetailQuery>;
export type SectionDetailLazyQueryHookResult = ReturnType<typeof useSectionDetailLazyQuery>;
export type SectionDetailQueryResult = ApolloReactCommon.QueryResult<SectionDetailQuery, SectionDetailQueryVariables>;
export const ClassFormDocument = gql`
  mutation ClassForm($input: SectionInput!) {
    upsertSection(input: $input) {
      id
    }
  }
`;
export type ClassFormMutationFn = ApolloReactCommon.MutationFunction<ClassFormMutation, ClassFormMutationVariables>;

/**
 * __useClassFormMutation__
 *
 * To run a mutation, you first call `useClassFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClassFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [classFormMutation, { data, loading, error }] = useClassFormMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClassFormMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<ClassFormMutation, ClassFormMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<ClassFormMutation, ClassFormMutationVariables>(ClassFormDocument, options);
}
export type ClassFormMutationHookResult = ReturnType<typeof useClassFormMutation>;
export type ClassFormMutationResult = ApolloReactCommon.MutationResult<ClassFormMutation>;
export type ClassFormMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ClassFormMutation,
  ClassFormMutationVariables
>;
export const SectionsListDocument = gql`
  query SectionsList(
    $stateId: String
    $districtId: String
    $schoolId: String
    $teacherId: String
    $studentId: String
    $agencyId: String
    $classTypes: [SectionCategory!]
    $page: Int
    $limit: Int
    $sort: SectionsListSortType
    $searchByName: String
    $orderBy: OrderBy
  ) {
    sectionsList(
      page: $page
      limit: $limit
      sort: $sort
      searchByName: $searchByName
      orderBy: $orderBy
      state_id: $stateId
      district_id: $districtId
      agency_id: $agencyId
      school_id: $schoolId
      teacher_id: $teacherId
      student_id: $studentId
      class_types: $classTypes
    ) {
      totalCount
      nodes {
        ...SectionDetailsFragment
      }
    }
  }
  ${SectionDetailsFragmentFragmentDoc}
`;

/**
 * __useSectionsListQuery__
 *
 * To run a query within a React component, call `useSectionsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSectionsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSectionsListQuery({
 *   variables: {
 *      stateId: // value for 'stateId'
 *      districtId: // value for 'districtId'
 *      schoolId: // value for 'schoolId'
 *      teacherId: // value for 'teacherId'
 *      studentId: // value for 'studentId'
 *      agencyId: // value for 'agencyId'
 *      classTypes: // value for 'classTypes'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *      searchByName: // value for 'searchByName'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useSectionsListQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<SectionsListQuery, SectionsListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<SectionsListQuery, SectionsListQueryVariables>(SectionsListDocument, options);
}
export function useSectionsListLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SectionsListQuery, SectionsListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<SectionsListQuery, SectionsListQueryVariables>(SectionsListDocument, options);
}
export type SectionsListQueryHookResult = ReturnType<typeof useSectionsListQuery>;
export type SectionsListLazyQueryHookResult = ReturnType<typeof useSectionsListLazyQuery>;
export type SectionsListQueryResult = ApolloReactCommon.QueryResult<SectionsListQuery, SectionsListQueryVariables>;
export const ManageTeacherStudentsListDocument = gql`
  query ManageTeacherStudentsList(
    $schoolID: String
    $limit: Int
    $orderBy: OrderBy
    $sort: StudentListOfTeacherSortType
    $search: String
  ) {
    getAllStudentsOfTeacher(schoolID: $schoolID, limit: $limit, orderBy: $orderBy, sort: $sort, search: $search) {
      ...ManageStudentsStudentFragment
    }
  }
  ${ManageStudentsStudentFragmentFragmentDoc}
`;

/**
 * __useManageTeacherStudentsListQuery__
 *
 * To run a query within a React component, call `useManageTeacherStudentsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useManageTeacherStudentsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManageTeacherStudentsListQuery({
 *   variables: {
 *      schoolID: // value for 'schoolID'
 *      limit: // value for 'limit'
 *      orderBy: // value for 'orderBy'
 *      sort: // value for 'sort'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useManageTeacherStudentsListQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ManageTeacherStudentsListQuery,
    ManageTeacherStudentsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ManageTeacherStudentsListQuery, ManageTeacherStudentsListQueryVariables>(
    ManageTeacherStudentsListDocument,
    options
  );
}
export function useManageTeacherStudentsListLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ManageTeacherStudentsListQuery,
    ManageTeacherStudentsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ManageTeacherStudentsListQuery, ManageTeacherStudentsListQueryVariables>(
    ManageTeacherStudentsListDocument,
    options
  );
}
export type ManageTeacherStudentsListQueryHookResult = ReturnType<typeof useManageTeacherStudentsListQuery>;
export type ManageTeacherStudentsListLazyQueryHookResult = ReturnType<typeof useManageTeacherStudentsListLazyQuery>;
export type ManageTeacherStudentsListQueryResult = ApolloReactCommon.QueryResult<
  ManageTeacherStudentsListQuery,
  ManageTeacherStudentsListQueryVariables
>;
export const UpsertClassDocument = gql`
  mutation UpsertClass($input: SectionInput!) {
    upsertSection(input: $input) {
      id
    }
  }
`;
export type UpsertClassMutationFn = ApolloReactCommon.MutationFunction<
  UpsertClassMutation,
  UpsertClassMutationVariables
>;

/**
 * __useUpsertClassMutation__
 *
 * To run a mutation, you first call `useUpsertClassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertClassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertClassMutation, { data, loading, error }] = useUpsertClassMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertClassMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpsertClassMutation, UpsertClassMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpsertClassMutation, UpsertClassMutationVariables>(UpsertClassDocument, options);
}
export type UpsertClassMutationHookResult = ReturnType<typeof useUpsertClassMutation>;
export type UpsertClassMutationResult = ApolloReactCommon.MutationResult<UpsertClassMutation>;
export type UpsertClassMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpsertClassMutation,
  UpsertClassMutationVariables
>;
export const EnrollStudentsInCustomSectionDocument = gql`
  mutation EnrollStudentsInCustomSection($input: EnrollmentInput!) {
    enrollStudentsInCustomSection(input: $input)
  }
`;
export type EnrollStudentsInCustomSectionMutationFn = ApolloReactCommon.MutationFunction<
  EnrollStudentsInCustomSectionMutation,
  EnrollStudentsInCustomSectionMutationVariables
>;

/**
 * __useEnrollStudentsInCustomSectionMutation__
 *
 * To run a mutation, you first call `useEnrollStudentsInCustomSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnrollStudentsInCustomSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enrollStudentsInCustomSectionMutation, { data, loading, error }] = useEnrollStudentsInCustomSectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEnrollStudentsInCustomSectionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    EnrollStudentsInCustomSectionMutation,
    EnrollStudentsInCustomSectionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    EnrollStudentsInCustomSectionMutation,
    EnrollStudentsInCustomSectionMutationVariables
  >(EnrollStudentsInCustomSectionDocument, options);
}
export type EnrollStudentsInCustomSectionMutationHookResult = ReturnType<
  typeof useEnrollStudentsInCustomSectionMutation
>;
export type EnrollStudentsInCustomSectionMutationResult =
  ApolloReactCommon.MutationResult<EnrollStudentsInCustomSectionMutation>;
export type EnrollStudentsInCustomSectionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  EnrollStudentsInCustomSectionMutation,
  EnrollStudentsInCustomSectionMutationVariables
>;
export const RemoveInProgressSubmissionsOfSectionDocument = gql`
  mutation RemoveInProgressSubmissionsOfSection($sectionID: ID!, $studentIDs: [ID!]!) {
    removeInProgressSubmissionsOfSection(section_id: $sectionID, student_ids: $studentIDs)
  }
`;
export type RemoveInProgressSubmissionsOfSectionMutationFn = ApolloReactCommon.MutationFunction<
  RemoveInProgressSubmissionsOfSectionMutation,
  RemoveInProgressSubmissionsOfSectionMutationVariables
>;

/**
 * __useRemoveInProgressSubmissionsOfSectionMutation__
 *
 * To run a mutation, you first call `useRemoveInProgressSubmissionsOfSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveInProgressSubmissionsOfSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeInProgressSubmissionsOfSectionMutation, { data, loading, error }] = useRemoveInProgressSubmissionsOfSectionMutation({
 *   variables: {
 *      sectionID: // value for 'sectionID'
 *      studentIDs: // value for 'studentIDs'
 *   },
 * });
 */
export function useRemoveInProgressSubmissionsOfSectionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RemoveInProgressSubmissionsOfSectionMutation,
    RemoveInProgressSubmissionsOfSectionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    RemoveInProgressSubmissionsOfSectionMutation,
    RemoveInProgressSubmissionsOfSectionMutationVariables
  >(RemoveInProgressSubmissionsOfSectionDocument, options);
}
export type RemoveInProgressSubmissionsOfSectionMutationHookResult = ReturnType<
  typeof useRemoveInProgressSubmissionsOfSectionMutation
>;
export type RemoveInProgressSubmissionsOfSectionMutationResult =
  ApolloReactCommon.MutationResult<RemoveInProgressSubmissionsOfSectionMutation>;
export type RemoveInProgressSubmissionsOfSectionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RemoveInProgressSubmissionsOfSectionMutation,
  RemoveInProgressSubmissionsOfSectionMutationVariables
>;
export const CustomClassStudentListDocument = gql`
  query CustomClassStudentList($input: CustomClassAllocationInput!) {
    customClassStudentList(input: $input) {
      allocated_students {
        ...StudentUserConnectionFragment
      }
      available_students {
        ...StudentUserConnectionFragment
      }
      allocated_student_ids
      available_student_ids
    }
  }
  ${StudentUserConnectionFragmentFragmentDoc}
`;

/**
 * __useCustomClassStudentListQuery__
 *
 * To run a query within a React component, call `useCustomClassStudentListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomClassStudentListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomClassStudentListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCustomClassStudentListQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<CustomClassStudentListQuery, CustomClassStudentListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CustomClassStudentListQuery, CustomClassStudentListQueryVariables>(
    CustomClassStudentListDocument,
    options
  );
}
export function useCustomClassStudentListLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CustomClassStudentListQuery, CustomClassStudentListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CustomClassStudentListQuery, CustomClassStudentListQueryVariables>(
    CustomClassStudentListDocument,
    options
  );
}
export type CustomClassStudentListQueryHookResult = ReturnType<typeof useCustomClassStudentListQuery>;
export type CustomClassStudentListLazyQueryHookResult = ReturnType<typeof useCustomClassStudentListLazyQuery>;
export type CustomClassStudentListQueryResult = ApolloReactCommon.QueryResult<
  CustomClassStudentListQuery,
  CustomClassStudentListQueryVariables
>;
export const StudentsWithInProgressSubmissionDocument = gql`
  query StudentsWithInProgressSubmission($sectionID: ID!, $studentIDs: [ID!]!) {
    studentsWithInProgressSubmission(section_id: $sectionID, student_ids: $studentIDs) {
      id
      first_name
      last_name
      student {
        id
      }
    }
  }
`;

/**
 * __useStudentsWithInProgressSubmissionQuery__
 *
 * To run a query within a React component, call `useStudentsWithInProgressSubmissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentsWithInProgressSubmissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentsWithInProgressSubmissionQuery({
 *   variables: {
 *      sectionID: // value for 'sectionID'
 *      studentIDs: // value for 'studentIDs'
 *   },
 * });
 */
export function useStudentsWithInProgressSubmissionQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    StudentsWithInProgressSubmissionQuery,
    StudentsWithInProgressSubmissionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    StudentsWithInProgressSubmissionQuery,
    StudentsWithInProgressSubmissionQueryVariables
  >(StudentsWithInProgressSubmissionDocument, options);
}
export function useStudentsWithInProgressSubmissionLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    StudentsWithInProgressSubmissionQuery,
    StudentsWithInProgressSubmissionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    StudentsWithInProgressSubmissionQuery,
    StudentsWithInProgressSubmissionQueryVariables
  >(StudentsWithInProgressSubmissionDocument, options);
}
export type StudentsWithInProgressSubmissionQueryHookResult = ReturnType<
  typeof useStudentsWithInProgressSubmissionQuery
>;
export type StudentsWithInProgressSubmissionLazyQueryHookResult = ReturnType<
  typeof useStudentsWithInProgressSubmissionLazyQuery
>;
export type StudentsWithInProgressSubmissionQueryResult = ApolloReactCommon.QueryResult<
  StudentsWithInProgressSubmissionQuery,
  StudentsWithInProgressSubmissionQueryVariables
>;
export const CreateDemoDataDocument = gql`
  mutation CreateDemoData($input: CreateDemoUsersInput!) {
    createDemoDataForDistrict(input: $input) {
      district {
        id
        name
        district_id
        score_scheme_id
      }
      admins {
        id
        user_id
        first_name
        last_name
        middle_name
        email
        password
        admin_id
      }
      teachers {
        id
        user_id
        first_name
        last_name
        middle_name
        email
        password
        teacher_id
      }
      students {
        id
        user_id
        first_name
        last_name
        middle_name
        email
        password
        student_id
      }
    }
  }
`;
export type CreateDemoDataMutationFn = ApolloReactCommon.MutationFunction<
  CreateDemoDataMutation,
  CreateDemoDataMutationVariables
>;

/**
 * __useCreateDemoDataMutation__
 *
 * To run a mutation, you first call `useCreateDemoDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDemoDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDemoDataMutation, { data, loading, error }] = useCreateDemoDataMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDemoDataMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateDemoDataMutation, CreateDemoDataMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateDemoDataMutation, CreateDemoDataMutationVariables>(
    CreateDemoDataDocument,
    options
  );
}
export type CreateDemoDataMutationHookResult = ReturnType<typeof useCreateDemoDataMutation>;
export type CreateDemoDataMutationResult = ApolloReactCommon.MutationResult<CreateDemoDataMutation>;
export type CreateDemoDataMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateDemoDataMutation,
  CreateDemoDataMutationVariables
>;
export const CleverSyncListDocument = gql`
  query CleverSyncList(
    $page: Int
    $limit: Int
    $sort: String
    $orderBy: OrderBy
    $search: String
    $status: [SyncStatus]
    $districtID: ID
  ) {
    listCleverSyncs(
      input: {
        page: $page
        limit: $limit
        sort: $sort
        orderBy: $orderBy
        search: $search
        status: $status
        districtID: $districtID
      }
    ) {
      totalCount
      syncEnabled
      nodes {
        ...CleverSyncListItem
      }
    }
  }
  ${CleverSyncListItemFragmentDoc}
`;

/**
 * __useCleverSyncListQuery__
 *
 * To run a query within a React component, call `useCleverSyncListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCleverSyncListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCleverSyncListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *      orderBy: // value for 'orderBy'
 *      search: // value for 'search'
 *      status: // value for 'status'
 *      districtID: // value for 'districtID'
 *   },
 * });
 */
export function useCleverSyncListQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<CleverSyncListQuery, CleverSyncListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CleverSyncListQuery, CleverSyncListQueryVariables>(CleverSyncListDocument, options);
}
export function useCleverSyncListLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CleverSyncListQuery, CleverSyncListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CleverSyncListQuery, CleverSyncListQueryVariables>(
    CleverSyncListDocument,
    options
  );
}
export type CleverSyncListQueryHookResult = ReturnType<typeof useCleverSyncListQuery>;
export type CleverSyncListLazyQueryHookResult = ReturnType<typeof useCleverSyncListLazyQuery>;
export type CleverSyncListQueryResult = ApolloReactCommon.QueryResult<
  CleverSyncListQuery,
  CleverSyncListQueryVariables
>;
export const CleverSyncJobDetailsDocument = gql`
  query CleverSyncJobDetails($Id: String!) {
    cleverSyncDetails(id: $Id) {
      id
      sync_type
      owner_type
      status
      created_at
      run_duration
      message
      districts {
        id
        name
      }
      admin {
        id
        admin_id
        job_title
        user {
          id
          first_name
          last_name
          middle_name
          email
          role
          permissions {
            id
            permission
          }
          showHelpVideo
        }
      }
    }
  }
`;

/**
 * __useCleverSyncJobDetailsQuery__
 *
 * To run a query within a React component, call `useCleverSyncJobDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCleverSyncJobDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCleverSyncJobDetailsQuery({
 *   variables: {
 *      Id: // value for 'Id'
 *   },
 * });
 */
export function useCleverSyncJobDetailsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<CleverSyncJobDetailsQuery, CleverSyncJobDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CleverSyncJobDetailsQuery, CleverSyncJobDetailsQueryVariables>(
    CleverSyncJobDetailsDocument,
    options
  );
}
export function useCleverSyncJobDetailsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CleverSyncJobDetailsQuery, CleverSyncJobDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CleverSyncJobDetailsQuery, CleverSyncJobDetailsQueryVariables>(
    CleverSyncJobDetailsDocument,
    options
  );
}
export type CleverSyncJobDetailsQueryHookResult = ReturnType<typeof useCleverSyncJobDetailsQuery>;
export type CleverSyncJobDetailsLazyQueryHookResult = ReturnType<typeof useCleverSyncJobDetailsLazyQuery>;
export type CleverSyncJobDetailsQueryResult = ApolloReactCommon.QueryResult<
  CleverSyncJobDetailsQuery,
  CleverSyncJobDetailsQueryVariables
>;
export const UpdateCleverDistrictDocument = gql`
  mutation updateCleverDistrict($input: CleverDistrictImportInput!) {
    importCleverDistrict(input: $input)
  }
`;
export type UpdateCleverDistrictMutationFn = ApolloReactCommon.MutationFunction<
  UpdateCleverDistrictMutation,
  UpdateCleverDistrictMutationVariables
>;

/**
 * __useUpdateCleverDistrictMutation__
 *
 * To run a mutation, you first call `useUpdateCleverDistrictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCleverDistrictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCleverDistrictMutation, { data, loading, error }] = useUpdateCleverDistrictMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCleverDistrictMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateCleverDistrictMutation,
    UpdateCleverDistrictMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateCleverDistrictMutation, UpdateCleverDistrictMutationVariables>(
    UpdateCleverDistrictDocument,
    options
  );
}
export type UpdateCleverDistrictMutationHookResult = ReturnType<typeof useUpdateCleverDistrictMutation>;
export type UpdateCleverDistrictMutationResult = ApolloReactCommon.MutationResult<UpdateCleverDistrictMutation>;
export type UpdateCleverDistrictMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateCleverDistrictMutation,
  UpdateCleverDistrictMutationVariables
>;
export const GetUninitialisedCleverDistrictsDocument = gql`
  query getUninitialisedCleverDistricts {
    getUnImportedCleverDistricts {
      clever_id
      name
      source
    }
  }
`;

/**
 * __useGetUninitialisedCleverDistrictsQuery__
 *
 * To run a query within a React component, call `useGetUninitialisedCleverDistrictsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUninitialisedCleverDistrictsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUninitialisedCleverDistrictsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUninitialisedCleverDistrictsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetUninitialisedCleverDistrictsQuery,
    GetUninitialisedCleverDistrictsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetUninitialisedCleverDistrictsQuery, GetUninitialisedCleverDistrictsQueryVariables>(
    GetUninitialisedCleverDistrictsDocument,
    options
  );
}
export function useGetUninitialisedCleverDistrictsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetUninitialisedCleverDistrictsQuery,
    GetUninitialisedCleverDistrictsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetUninitialisedCleverDistrictsQuery,
    GetUninitialisedCleverDistrictsQueryVariables
  >(GetUninitialisedCleverDistrictsDocument, options);
}
export type GetUninitialisedCleverDistrictsQueryHookResult = ReturnType<typeof useGetUninitialisedCleverDistrictsQuery>;
export type GetUninitialisedCleverDistrictsLazyQueryHookResult = ReturnType<
  typeof useGetUninitialisedCleverDistrictsLazyQuery
>;
export type GetUninitialisedCleverDistrictsQueryResult = ApolloReactCommon.QueryResult<
  GetUninitialisedCleverDistrictsQuery,
  GetUninitialisedCleverDistrictsQueryVariables
>;
export const SyncImportedDistrictsDocument = gql`
  mutation syncImportedDistricts($districts: [String!]!) {
    syncCleverDistricts(input: $districts)
  }
`;
export type SyncImportedDistrictsMutationFn = ApolloReactCommon.MutationFunction<
  SyncImportedDistrictsMutation,
  SyncImportedDistrictsMutationVariables
>;

/**
 * __useSyncImportedDistrictsMutation__
 *
 * To run a mutation, you first call `useSyncImportedDistrictsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncImportedDistrictsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncImportedDistrictsMutation, { data, loading, error }] = useSyncImportedDistrictsMutation({
 *   variables: {
 *      districts: // value for 'districts'
 *   },
 * });
 */
export function useSyncImportedDistrictsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SyncImportedDistrictsMutation,
    SyncImportedDistrictsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<SyncImportedDistrictsMutation, SyncImportedDistrictsMutationVariables>(
    SyncImportedDistrictsDocument,
    options
  );
}
export type SyncImportedDistrictsMutationHookResult = ReturnType<typeof useSyncImportedDistrictsMutation>;
export type SyncImportedDistrictsMutationResult = ApolloReactCommon.MutationResult<SyncImportedDistrictsMutation>;
export type SyncImportedDistrictsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SyncImportedDistrictsMutation,
  SyncImportedDistrictsMutationVariables
>;
export const GetDistrictsToSyncDocument = gql`
  query getDistrictsToSync {
    syncDistrictsList {
      id
      name
      sync_status
    }
  }
`;

/**
 * __useGetDistrictsToSyncQuery__
 *
 * To run a query within a React component, call `useGetDistrictsToSyncQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDistrictsToSyncQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDistrictsToSyncQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDistrictsToSyncQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetDistrictsToSyncQuery, GetDistrictsToSyncQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetDistrictsToSyncQuery, GetDistrictsToSyncQueryVariables>(
    GetDistrictsToSyncDocument,
    options
  );
}
export function useGetDistrictsToSyncLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDistrictsToSyncQuery, GetDistrictsToSyncQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetDistrictsToSyncQuery, GetDistrictsToSyncQueryVariables>(
    GetDistrictsToSyncDocument,
    options
  );
}
export type GetDistrictsToSyncQueryHookResult = ReturnType<typeof useGetDistrictsToSyncQuery>;
export type GetDistrictsToSyncLazyQueryHookResult = ReturnType<typeof useGetDistrictsToSyncLazyQuery>;
export type GetDistrictsToSyncQueryResult = ApolloReactCommon.QueryResult<
  GetDistrictsToSyncQuery,
  GetDistrictsToSyncQueryVariables
>;
export const CreateDistrictDocument = gql`
  mutation CreateDistrict($input: DistrictInput!) {
    createDistrict(input: $input)
  }
`;
export type CreateDistrictMutationFn = ApolloReactCommon.MutationFunction<
  CreateDistrictMutation,
  CreateDistrictMutationVariables
>;

/**
 * __useCreateDistrictMutation__
 *
 * To run a mutation, you first call `useCreateDistrictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDistrictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDistrictMutation, { data, loading, error }] = useCreateDistrictMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDistrictMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateDistrictMutation, CreateDistrictMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateDistrictMutation, CreateDistrictMutationVariables>(
    CreateDistrictDocument,
    options
  );
}
export type CreateDistrictMutationHookResult = ReturnType<typeof useCreateDistrictMutation>;
export type CreateDistrictMutationResult = ApolloReactCommon.MutationResult<CreateDistrictMutation>;
export type CreateDistrictMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateDistrictMutation,
  CreateDistrictMutationVariables
>;
export const UpdateDistrictDocument = gql`
  mutation UpdateDistrict($id: String!, $input: DistrictInput!) {
    updateDistrict(id: $id, input: $input)
  }
`;
export type UpdateDistrictMutationFn = ApolloReactCommon.MutationFunction<
  UpdateDistrictMutation,
  UpdateDistrictMutationVariables
>;

/**
 * __useUpdateDistrictMutation__
 *
 * To run a mutation, you first call `useUpdateDistrictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDistrictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDistrictMutation, { data, loading, error }] = useUpdateDistrictMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDistrictMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDistrictMutation, UpdateDistrictMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateDistrictMutation, UpdateDistrictMutationVariables>(
    UpdateDistrictDocument,
    options
  );
}
export type UpdateDistrictMutationHookResult = ReturnType<typeof useUpdateDistrictMutation>;
export type UpdateDistrictMutationResult = ApolloReactCommon.MutationResult<UpdateDistrictMutation>;
export type UpdateDistrictMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateDistrictMutation,
  UpdateDistrictMutationVariables
>;
export const UpdateDistrictActiveFlagDocument = gql`
  mutation UpdateDistrictActiveFlag($id: String!, $isDisabled: Boolean!) {
    updateDistrictDisabledFlag(id: $id, flag: $isDisabled)
  }
`;
export type UpdateDistrictActiveFlagMutationFn = ApolloReactCommon.MutationFunction<
  UpdateDistrictActiveFlagMutation,
  UpdateDistrictActiveFlagMutationVariables
>;

/**
 * __useUpdateDistrictActiveFlagMutation__
 *
 * To run a mutation, you first call `useUpdateDistrictActiveFlagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDistrictActiveFlagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDistrictActiveFlagMutation, { data, loading, error }] = useUpdateDistrictActiveFlagMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isDisabled: // value for 'isDisabled'
 *   },
 * });
 */
export function useUpdateDistrictActiveFlagMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateDistrictActiveFlagMutation,
    UpdateDistrictActiveFlagMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateDistrictActiveFlagMutation, UpdateDistrictActiveFlagMutationVariables>(
    UpdateDistrictActiveFlagDocument,
    options
  );
}
export type UpdateDistrictActiveFlagMutationHookResult = ReturnType<typeof useUpdateDistrictActiveFlagMutation>;
export type UpdateDistrictActiveFlagMutationResult = ApolloReactCommon.MutationResult<UpdateDistrictActiveFlagMutation>;
export type UpdateDistrictActiveFlagMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateDistrictActiveFlagMutation,
  UpdateDistrictActiveFlagMutationVariables
>;
export const DistrictEditDataDocument = gql`
  query DistrictEditData($id: ID!) {
    districtDetails(id: $id) {
      ...DistrictEditDataFragment
    }
  }
  ${DistrictEditDataFragmentFragmentDoc}
`;

/**
 * __useDistrictEditDataQuery__
 *
 * To run a query within a React component, call `useDistrictEditDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useDistrictEditDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDistrictEditDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDistrictEditDataQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<DistrictEditDataQuery, DistrictEditDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<DistrictEditDataQuery, DistrictEditDataQueryVariables>(
    DistrictEditDataDocument,
    options
  );
}
export function useDistrictEditDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DistrictEditDataQuery, DistrictEditDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<DistrictEditDataQuery, DistrictEditDataQueryVariables>(
    DistrictEditDataDocument,
    options
  );
}
export type DistrictEditDataQueryHookResult = ReturnType<typeof useDistrictEditDataQuery>;
export type DistrictEditDataLazyQueryHookResult = ReturnType<typeof useDistrictEditDataLazyQuery>;
export type DistrictEditDataQueryResult = ApolloReactCommon.QueryResult<
  DistrictEditDataQuery,
  DistrictEditDataQueryVariables
>;
export const ScoreSchemeListDocument = gql`
  query ScoreSchemeList {
    scoreSchemeList {
      ...ScoreSchemes
    }
  }
  ${ScoreSchemesFragmentDoc}
`;

/**
 * __useScoreSchemeListQuery__
 *
 * To run a query within a React component, call `useScoreSchemeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useScoreSchemeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScoreSchemeListQuery({
 *   variables: {
 *   },
 * });
 */
export function useScoreSchemeListQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<ScoreSchemeListQuery, ScoreSchemeListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ScoreSchemeListQuery, ScoreSchemeListQueryVariables>(
    ScoreSchemeListDocument,
    options
  );
}
export function useScoreSchemeListLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ScoreSchemeListQuery, ScoreSchemeListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ScoreSchemeListQuery, ScoreSchemeListQueryVariables>(
    ScoreSchemeListDocument,
    options
  );
}
export type ScoreSchemeListQueryHookResult = ReturnType<typeof useScoreSchemeListQuery>;
export type ScoreSchemeListLazyQueryHookResult = ReturnType<typeof useScoreSchemeListLazyQuery>;
export type ScoreSchemeListQueryResult = ApolloReactCommon.QueryResult<
  ScoreSchemeListQuery,
  ScoreSchemeListQueryVariables
>;
export const DecoupleDistrictDocument = gql`
  mutation decoupleDistrict($district_id: String!) {
    decoupleDistrict(districtID: $district_id)
  }
`;
export type DecoupleDistrictMutationFn = ApolloReactCommon.MutationFunction<
  DecoupleDistrictMutation,
  DecoupleDistrictMutationVariables
>;

/**
 * __useDecoupleDistrictMutation__
 *
 * To run a mutation, you first call `useDecoupleDistrictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDecoupleDistrictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [decoupleDistrictMutation, { data, loading, error }] = useDecoupleDistrictMutation({
 *   variables: {
 *      district_id: // value for 'district_id'
 *   },
 * });
 */
export function useDecoupleDistrictMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DecoupleDistrictMutation, DecoupleDistrictMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DecoupleDistrictMutation, DecoupleDistrictMutationVariables>(
    DecoupleDistrictDocument,
    options
  );
}
export type DecoupleDistrictMutationHookResult = ReturnType<typeof useDecoupleDistrictMutation>;
export type DecoupleDistrictMutationResult = ApolloReactCommon.MutationResult<DecoupleDistrictMutation>;
export type DecoupleDistrictMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DecoupleDistrictMutation,
  DecoupleDistrictMutationVariables
>;
export const DeleteDistrictRequestDocument = gql`
  mutation deleteDistrictRequest($district_id: String!) {
    deleteDistrictRequest(district_id: $district_id)
  }
`;
export type DeleteDistrictRequestMutationFn = ApolloReactCommon.MutationFunction<
  DeleteDistrictRequestMutation,
  DeleteDistrictRequestMutationVariables
>;

/**
 * __useDeleteDistrictRequestMutation__
 *
 * To run a mutation, you first call `useDeleteDistrictRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDistrictRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDistrictRequestMutation, { data, loading, error }] = useDeleteDistrictRequestMutation({
 *   variables: {
 *      district_id: // value for 'district_id'
 *   },
 * });
 */
export function useDeleteDistrictRequestMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteDistrictRequestMutation,
    DeleteDistrictRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeleteDistrictRequestMutation, DeleteDistrictRequestMutationVariables>(
    DeleteDistrictRequestDocument,
    options
  );
}
export type DeleteDistrictRequestMutationHookResult = ReturnType<typeof useDeleteDistrictRequestMutation>;
export type DeleteDistrictRequestMutationResult = ApolloReactCommon.MutationResult<DeleteDistrictRequestMutation>;
export type DeleteDistrictRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteDistrictRequestMutation,
  DeleteDistrictRequestMutationVariables
>;
export const DistrictDetailsDocument = gql`
  query DistrictDetails($id: ID!) {
    districtDetails(id: $id) {
      ...DistrictDetailsDatum
    }
  }
  ${DistrictDetailsDatumFragmentDoc}
`;

/**
 * __useDistrictDetailsQuery__
 *
 * To run a query within a React component, call `useDistrictDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDistrictDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDistrictDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDistrictDetailsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<DistrictDetailsQuery, DistrictDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<DistrictDetailsQuery, DistrictDetailsQueryVariables>(
    DistrictDetailsDocument,
    options
  );
}
export function useDistrictDetailsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DistrictDetailsQuery, DistrictDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<DistrictDetailsQuery, DistrictDetailsQueryVariables>(
    DistrictDetailsDocument,
    options
  );
}
export type DistrictDetailsQueryHookResult = ReturnType<typeof useDistrictDetailsQuery>;
export type DistrictDetailsLazyQueryHookResult = ReturnType<typeof useDistrictDetailsLazyQuery>;
export type DistrictDetailsQueryResult = ApolloReactCommon.QueryResult<
  DistrictDetailsQuery,
  DistrictDetailsQueryVariables
>;
export const MyDistrictDocument = gql`
  query MyDistrict {
    myDistrict {
      ...DistrictDetailsDatum
    }
  }
  ${DistrictDetailsDatumFragmentDoc}
`;

/**
 * __useMyDistrictQuery__
 *
 * To run a query within a React component, call `useMyDistrictQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyDistrictQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyDistrictQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyDistrictQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<MyDistrictQuery, MyDistrictQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<MyDistrictQuery, MyDistrictQueryVariables>(MyDistrictDocument, options);
}
export function useMyDistrictLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MyDistrictQuery, MyDistrictQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<MyDistrictQuery, MyDistrictQueryVariables>(MyDistrictDocument, options);
}
export type MyDistrictQueryHookResult = ReturnType<typeof useMyDistrictQuery>;
export type MyDistrictLazyQueryHookResult = ReturnType<typeof useMyDistrictLazyQuery>;
export type MyDistrictQueryResult = ApolloReactCommon.QueryResult<MyDistrictQuery, MyDistrictQueryVariables>;
export const DistrictsDocument = gql`
  query Districts(
    $state_ids: [String!]
    $page: Int
    $limit: Int
    $sort: DistrictListSortType
    $searchByName: String
    $orderBy: OrderBy
    $excludeDeleted: Boolean
  ) {
    districtList(
      page: $page
      limit: $limit
      sort: $sort
      searchByName: $searchByName
      orderBy: $orderBy
      state_ids: $state_ids
      excludeDeleted: $excludeDeleted
    ) {
      totalCount
      nodes {
        id
        state_id
        agency_id
        school_count
        student_count
        source
        district_id
        name
        score_scheme_id
        state {
          id
          name
          state_id
        }
      }
    }
  }
`;

/**
 * __useDistrictsQuery__
 *
 * To run a query within a React component, call `useDistrictsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDistrictsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDistrictsQuery({
 *   variables: {
 *      state_ids: // value for 'state_ids'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *      searchByName: // value for 'searchByName'
 *      orderBy: // value for 'orderBy'
 *      excludeDeleted: // value for 'excludeDeleted'
 *   },
 * });
 */
export function useDistrictsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<DistrictsQuery, DistrictsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<DistrictsQuery, DistrictsQueryVariables>(DistrictsDocument, options);
}
export function useDistrictsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DistrictsQuery, DistrictsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<DistrictsQuery, DistrictsQueryVariables>(DistrictsDocument, options);
}
export type DistrictsQueryHookResult = ReturnType<typeof useDistrictsQuery>;
export type DistrictsLazyQueryHookResult = ReturnType<typeof useDistrictsLazyQuery>;
export type DistrictsQueryResult = ApolloReactCommon.QueryResult<DistrictsQuery, DistrictsQueryVariables>;
export const SendResetPasswordLinkDocument = gql`
  mutation SendResetPasswordLink($email: String!, $host: String!) {
    sendResetPasswordLink(email: $email, host: $host)
  }
`;
export type SendResetPasswordLinkMutationFn = ApolloReactCommon.MutationFunction<
  SendResetPasswordLinkMutation,
  SendResetPasswordLinkMutationVariables
>;

/**
 * __useSendResetPasswordLinkMutation__
 *
 * To run a mutation, you first call `useSendResetPasswordLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendResetPasswordLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendResetPasswordLinkMutation, { data, loading, error }] = useSendResetPasswordLinkMutation({
 *   variables: {
 *      email: // value for 'email'
 *      host: // value for 'host'
 *   },
 * });
 */
export function useSendResetPasswordLinkMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SendResetPasswordLinkMutation,
    SendResetPasswordLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<SendResetPasswordLinkMutation, SendResetPasswordLinkMutationVariables>(
    SendResetPasswordLinkDocument,
    options
  );
}
export type SendResetPasswordLinkMutationHookResult = ReturnType<typeof useSendResetPasswordLinkMutation>;
export type SendResetPasswordLinkMutationResult = ApolloReactCommon.MutationResult<SendResetPasswordLinkMutation>;
export type SendResetPasswordLinkMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SendResetPasswordLinkMutation,
  SendResetPasswordLinkMutationVariables
>;
export const SetPasswordByTokenDocument = gql`
  mutation SetPasswordByToken($password: String!, $token: String!) {
    setPasswordByToken(password: $password, token: $token)
  }
`;
export type SetPasswordByTokenMutationFn = ApolloReactCommon.MutationFunction<
  SetPasswordByTokenMutation,
  SetPasswordByTokenMutationVariables
>;

/**
 * __useSetPasswordByTokenMutation__
 *
 * To run a mutation, you first call `useSetPasswordByTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPasswordByTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPasswordByTokenMutation, { data, loading, error }] = useSetPasswordByTokenMutation({
 *   variables: {
 *      password: // value for 'password'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useSetPasswordByTokenMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<SetPasswordByTokenMutation, SetPasswordByTokenMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<SetPasswordByTokenMutation, SetPasswordByTokenMutationVariables>(
    SetPasswordByTokenDocument,
    options
  );
}
export type SetPasswordByTokenMutationHookResult = ReturnType<typeof useSetPasswordByTokenMutation>;
export type SetPasswordByTokenMutationResult = ApolloReactCommon.MutationResult<SetPasswordByTokenMutation>;
export type SetPasswordByTokenMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SetPasswordByTokenMutation,
  SetPasswordByTokenMutationVariables
>;
export const GetActiveGoalsDocument = gql`
  query GetActiveGoals($goalType: GoalType) {
    getStudentGoals(target_submission_id: $goalType) {
      ...StudentGoals
    }
  }
  ${StudentGoalsFragmentDoc}
`;

/**
 * __useGetActiveGoalsQuery__
 *
 * To run a query within a React component, call `useGetActiveGoalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveGoalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveGoalsQuery({
 *   variables: {
 *      goalType: // value for 'goalType'
 *   },
 * });
 */
export function useGetActiveGoalsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetActiveGoalsQuery, GetActiveGoalsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetActiveGoalsQuery, GetActiveGoalsQueryVariables>(GetActiveGoalsDocument, options);
}
export function useGetActiveGoalsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetActiveGoalsQuery, GetActiveGoalsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetActiveGoalsQuery, GetActiveGoalsQueryVariables>(
    GetActiveGoalsDocument,
    options
  );
}
export type GetActiveGoalsQueryHookResult = ReturnType<typeof useGetActiveGoalsQuery>;
export type GetActiveGoalsLazyQueryHookResult = ReturnType<typeof useGetActiveGoalsLazyQuery>;
export type GetActiveGoalsQueryResult = ApolloReactCommon.QueryResult<
  GetActiveGoalsQuery,
  GetActiveGoalsQueryVariables
>;
export const CreateCustomGoalDocument = gql`
  mutation CreateCustomGoal($rubricId: ID!, $description: String!, $example: String, $submissionId: ID, $goalId: ID) {
    createPredefinedGoal(
      input: {
        rubric_id: $rubricId
        description: $description
        example: $example
        submission_id: $submissionId
        existing_goal_id: $goalId
      }
    ) {
      speaking {
        rubric {
          id
          title
          submission_type
        }
      }
      writing {
        rubric {
          id
          title
          submission_type
        }
      }
    }
  }
`;
export type CreateCustomGoalMutationFn = ApolloReactCommon.MutationFunction<
  CreateCustomGoalMutation,
  CreateCustomGoalMutationVariables
>;

/**
 * __useCreateCustomGoalMutation__
 *
 * To run a mutation, you first call `useCreateCustomGoalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomGoalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomGoalMutation, { data, loading, error }] = useCreateCustomGoalMutation({
 *   variables: {
 *      rubricId: // value for 'rubricId'
 *      description: // value for 'description'
 *      example: // value for 'example'
 *      submissionId: // value for 'submissionId'
 *      goalId: // value for 'goalId'
 *   },
 * });
 */
export function useCreateCustomGoalMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCustomGoalMutation, CreateCustomGoalMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateCustomGoalMutation, CreateCustomGoalMutationVariables>(
    CreateCustomGoalDocument,
    options
  );
}
export type CreateCustomGoalMutationHookResult = ReturnType<typeof useCreateCustomGoalMutation>;
export type CreateCustomGoalMutationResult = ApolloReactCommon.MutationResult<CreateCustomGoalMutation>;
export type CreateCustomGoalMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateCustomGoalMutation,
  CreateCustomGoalMutationVariables
>;
export const CreateUpdateStudentGoalDocument = gql`
  mutation CreateUpdateStudentGoal($id: ID, $submissionId: String!, $goalId: String!) {
    createUpdateStudentGoal(input: { id: $id, submission_id: $submissionId, predefined_goal_id: $goalId }) {
      id
      submission_id
      predefined_goal_id
    }
  }
`;
export type CreateUpdateStudentGoalMutationFn = ApolloReactCommon.MutationFunction<
  CreateUpdateStudentGoalMutation,
  CreateUpdateStudentGoalMutationVariables
>;

/**
 * __useCreateUpdateStudentGoalMutation__
 *
 * To run a mutation, you first call `useCreateUpdateStudentGoalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUpdateStudentGoalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUpdateStudentGoalMutation, { data, loading, error }] = useCreateUpdateStudentGoalMutation({
 *   variables: {
 *      id: // value for 'id'
 *      submissionId: // value for 'submissionId'
 *      goalId: // value for 'goalId'
 *   },
 * });
 */
export function useCreateUpdateStudentGoalMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateUpdateStudentGoalMutation,
    CreateUpdateStudentGoalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateUpdateStudentGoalMutation, CreateUpdateStudentGoalMutationVariables>(
    CreateUpdateStudentGoalDocument,
    options
  );
}
export type CreateUpdateStudentGoalMutationHookResult = ReturnType<typeof useCreateUpdateStudentGoalMutation>;
export type CreateUpdateStudentGoalMutationResult = ApolloReactCommon.MutationResult<CreateUpdateStudentGoalMutation>;
export type CreateUpdateStudentGoalMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateUpdateStudentGoalMutation,
  CreateUpdateStudentGoalMutationVariables
>;
export const GetPredefinedGoalsDocument = gql`
  query GetPredefinedGoals {
    getPredefinedGoals {
      speaking {
        ...GoalOptions
      }
      writing {
        ...GoalOptions
      }
    }
  }
  ${GoalOptionsFragmentDoc}
`;

/**
 * __useGetPredefinedGoalsQuery__
 *
 * To run a query within a React component, call `useGetPredefinedGoalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPredefinedGoalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPredefinedGoalsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPredefinedGoalsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetPredefinedGoalsQuery, GetPredefinedGoalsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetPredefinedGoalsQuery, GetPredefinedGoalsQueryVariables>(
    GetPredefinedGoalsDocument,
    options
  );
}
export function useGetPredefinedGoalsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPredefinedGoalsQuery, GetPredefinedGoalsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetPredefinedGoalsQuery, GetPredefinedGoalsQueryVariables>(
    GetPredefinedGoalsDocument,
    options
  );
}
export type GetPredefinedGoalsQueryHookResult = ReturnType<typeof useGetPredefinedGoalsQuery>;
export type GetPredefinedGoalsLazyQueryHookResult = ReturnType<typeof useGetPredefinedGoalsLazyQuery>;
export type GetPredefinedGoalsQueryResult = ApolloReactCommon.QueryResult<
  GetPredefinedGoalsQuery,
  GetPredefinedGoalsQueryVariables
>;
export const UpdateGoalStatusDocument = gql`
  mutation UpdateGoalStatus($goalId: ID!, $status: GoalStatus!) {
    updateGoalStatus(input: { goal_id: $goalId, status: $status }) {
      id
      goal_status
    }
  }
`;
export type UpdateGoalStatusMutationFn = ApolloReactCommon.MutationFunction<
  UpdateGoalStatusMutation,
  UpdateGoalStatusMutationVariables
>;

/**
 * __useUpdateGoalStatusMutation__
 *
 * To run a mutation, you first call `useUpdateGoalStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGoalStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGoalStatusMutation, { data, loading, error }] = useUpdateGoalStatusMutation({
 *   variables: {
 *      goalId: // value for 'goalId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateGoalStatusMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateGoalStatusMutation, UpdateGoalStatusMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateGoalStatusMutation, UpdateGoalStatusMutationVariables>(
    UpdateGoalStatusDocument,
    options
  );
}
export type UpdateGoalStatusMutationHookResult = ReturnType<typeof useUpdateGoalStatusMutation>;
export type UpdateGoalStatusMutationResult = ApolloReactCommon.MutationResult<UpdateGoalStatusMutation>;
export type UpdateGoalStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateGoalStatusMutation,
  UpdateGoalStatusMutationVariables
>;
export const CreateGroupDocument = gql`
  mutation CreateGroup($input: GroupInput!) {
    upsertGroup(input: $input) {
      id
    }
  }
`;
export type CreateGroupMutationFn = ApolloReactCommon.MutationFunction<
  CreateGroupMutation,
  CreateGroupMutationVariables
>;

/**
 * __useCreateGroupMutation__
 *
 * To run a mutation, you first call `useCreateGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGroupMutation, { data, loading, error }] = useCreateGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateGroupMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateGroupMutation, CreateGroupMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateGroupMutation, CreateGroupMutationVariables>(CreateGroupDocument, options);
}
export type CreateGroupMutationHookResult = ReturnType<typeof useCreateGroupMutation>;
export type CreateGroupMutationResult = ApolloReactCommon.MutationResult<CreateGroupMutation>;
export type CreateGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateGroupMutation,
  CreateGroupMutationVariables
>;
export const EnrollStudentsInGroupDocument = gql`
  mutation EnrollStudentsInGroup($input: EnrollmentInput!) {
    enrollStudentsInGroup(input: $input)
  }
`;
export type EnrollStudentsInGroupMutationFn = ApolloReactCommon.MutationFunction<
  EnrollStudentsInGroupMutation,
  EnrollStudentsInGroupMutationVariables
>;

/**
 * __useEnrollStudentsInGroupMutation__
 *
 * To run a mutation, you first call `useEnrollStudentsInGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnrollStudentsInGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enrollStudentsInGroupMutation, { data, loading, error }] = useEnrollStudentsInGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEnrollStudentsInGroupMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    EnrollStudentsInGroupMutation,
    EnrollStudentsInGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<EnrollStudentsInGroupMutation, EnrollStudentsInGroupMutationVariables>(
    EnrollStudentsInGroupDocument,
    options
  );
}
export type EnrollStudentsInGroupMutationHookResult = ReturnType<typeof useEnrollStudentsInGroupMutation>;
export type EnrollStudentsInGroupMutationResult = ApolloReactCommon.MutationResult<EnrollStudentsInGroupMutation>;
export type EnrollStudentsInGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<
  EnrollStudentsInGroupMutation,
  EnrollStudentsInGroupMutationVariables
>;
export const DeleteGroupDocument = gql`
  mutation DeleteGroup($group_id: ID!) {
    deleteGroup(group_id: $group_id)
  }
`;
export type DeleteGroupMutationFn = ApolloReactCommon.MutationFunction<
  DeleteGroupMutation,
  DeleteGroupMutationVariables
>;

/**
 * __useDeleteGroupMutation__
 *
 * To run a mutation, you first call `useDeleteGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGroupMutation, { data, loading, error }] = useDeleteGroupMutation({
 *   variables: {
 *      group_id: // value for 'group_id'
 *   },
 * });
 */
export function useDeleteGroupMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteGroupMutation, DeleteGroupMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeleteGroupMutation, DeleteGroupMutationVariables>(DeleteGroupDocument, options);
}
export type DeleteGroupMutationHookResult = ReturnType<typeof useDeleteGroupMutation>;
export type DeleteGroupMutationResult = ApolloReactCommon.MutationResult<DeleteGroupMutation>;
export type DeleteGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteGroupMutation,
  DeleteGroupMutationVariables
>;
export const GroupStudentListDocument = gql`
  query GroupStudentList($input: GroupAllocationInput!) {
    studentGroupStudentsList(input: $input) {
      allocated_students {
        ...StudentUserConnectionFragment
      }
      available_students {
        ...StudentUserConnectionFragment
      }
      allocated_student_ids
      available_student_ids
    }
  }
  ${StudentUserConnectionFragmentFragmentDoc}
`;

/**
 * __useGroupStudentListQuery__
 *
 * To run a query within a React component, call `useGroupStudentListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupStudentListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupStudentListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGroupStudentListQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GroupStudentListQuery, GroupStudentListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GroupStudentListQuery, GroupStudentListQueryVariables>(
    GroupStudentListDocument,
    options
  );
}
export function useGroupStudentListLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GroupStudentListQuery, GroupStudentListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GroupStudentListQuery, GroupStudentListQueryVariables>(
    GroupStudentListDocument,
    options
  );
}
export type GroupStudentListQueryHookResult = ReturnType<typeof useGroupStudentListQuery>;
export type GroupStudentListLazyQueryHookResult = ReturnType<typeof useGroupStudentListLazyQuery>;
export type GroupStudentListQueryResult = ApolloReactCommon.QueryResult<
  GroupStudentListQuery,
  GroupStudentListQueryVariables
>;
export const GroupDetailsDocument = gql`
  query GroupDetails($id: String!) {
    groupDetails(id: $id) {
      id
      name
      parent_section_id
      parent_section_name
      student_count
    }
  }
`;

/**
 * __useGroupDetailsQuery__
 *
 * To run a query within a React component, call `useGroupDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGroupDetailsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GroupDetailsQuery, GroupDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GroupDetailsQuery, GroupDetailsQueryVariables>(GroupDetailsDocument, options);
}
export function useGroupDetailsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GroupDetailsQuery, GroupDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GroupDetailsQuery, GroupDetailsQueryVariables>(GroupDetailsDocument, options);
}
export type GroupDetailsQueryHookResult = ReturnType<typeof useGroupDetailsQuery>;
export type GroupDetailsLazyQueryHookResult = ReturnType<typeof useGroupDetailsLazyQuery>;
export type GroupDetailsQueryResult = ApolloReactCommon.QueryResult<GroupDetailsQuery, GroupDetailsQueryVariables>;
export const GroupsDocument = gql`
  query Groups($limit: Int, $page: Int, $section_id: String, $school_id: String, $search: String) {
    groupList(page: $page, limit: $limit, section_id: $section_id, school_id: $school_id, search: $search) {
      totalCount
      nodes {
        ...Groups
      }
    }
  }
  ${GroupsFragmentDoc}
`;

/**
 * __useGroupsQuery__
 *
 * To run a query within a React component, call `useGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      section_id: // value for 'section_id'
 *      school_id: // value for 'school_id'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGroupsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GroupsQuery, GroupsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GroupsQuery, GroupsQueryVariables>(GroupsDocument, options);
}
export function useGroupsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GroupsQuery, GroupsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GroupsQuery, GroupsQueryVariables>(GroupsDocument, options);
}
export type GroupsQueryHookResult = ReturnType<typeof useGroupsQuery>;
export type GroupsLazyQueryHookResult = ReturnType<typeof useGroupsLazyQuery>;
export type GroupsQueryResult = ApolloReactCommon.QueryResult<GroupsQuery, GroupsQueryVariables>;
export const SectionGroupsDocument = gql`
  query SectionGroups(
    $limit: Int
    $page: Int
    $section_id: String
    $search: String
    $sort: GroupsListSortType
    $orderBy: OrderBy
  ) {
    groupsBySection(
      page: $page
      limit: $limit
      section_id: $section_id
      search: $search
      sort: $sort
      order_by: $orderBy
    ) {
      totalCount
      nodes {
        id
        name
        student_count
      }
    }
  }
`;

/**
 * __useSectionGroupsQuery__
 *
 * To run a query within a React component, call `useSectionGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSectionGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSectionGroupsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      section_id: // value for 'section_id'
 *      search: // value for 'search'
 *      sort: // value for 'sort'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useSectionGroupsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<SectionGroupsQuery, SectionGroupsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<SectionGroupsQuery, SectionGroupsQueryVariables>(SectionGroupsDocument, options);
}
export function useSectionGroupsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SectionGroupsQuery, SectionGroupsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<SectionGroupsQuery, SectionGroupsQueryVariables>(SectionGroupsDocument, options);
}
export type SectionGroupsQueryHookResult = ReturnType<typeof useSectionGroupsQuery>;
export type SectionGroupsLazyQueryHookResult = ReturnType<typeof useSectionGroupsLazyQuery>;
export type SectionGroupsQueryResult = ApolloReactCommon.QueryResult<SectionGroupsQuery, SectionGroupsQueryVariables>;
export const GetResourceCountByDistrictDocument = gql`
  query GetResourceCountByDistrict($districtId: ID!) {
    resourceCountByDistrict(districtID: $districtId) {
      school_count
      class_count
      school_admin_count
      district_admin_count
      teacher_count
      student_count
      sync_count
    }
  }
`;

/**
 * __useGetResourceCountByDistrictQuery__
 *
 * To run a query within a React component, call `useGetResourceCountByDistrictQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetResourceCountByDistrictQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetResourceCountByDistrictQuery({
 *   variables: {
 *      districtId: // value for 'districtId'
 *   },
 * });
 */
export function useGetResourceCountByDistrictQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetResourceCountByDistrictQuery,
    GetResourceCountByDistrictQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetResourceCountByDistrictQuery, GetResourceCountByDistrictQueryVariables>(
    GetResourceCountByDistrictDocument,
    options
  );
}
export function useGetResourceCountByDistrictLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetResourceCountByDistrictQuery,
    GetResourceCountByDistrictQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetResourceCountByDistrictQuery, GetResourceCountByDistrictQueryVariables>(
    GetResourceCountByDistrictDocument,
    options
  );
}
export type GetResourceCountByDistrictQueryHookResult = ReturnType<typeof useGetResourceCountByDistrictQuery>;
export type GetResourceCountByDistrictLazyQueryHookResult = ReturnType<typeof useGetResourceCountByDistrictLazyQuery>;
export type GetResourceCountByDistrictQueryResult = ApolloReactCommon.QueryResult<
  GetResourceCountByDistrictQuery,
  GetResourceCountByDistrictQueryVariables
>;
export const GetResourceCountByTeacherDocument = gql`
  query GetResourceCountByTeacher($teacherId: ID!, $schoolId: ID) {
    resourceCountByTeacher(teacherID: $teacherId, schoolID: $schoolId) {
      student_count
      class_count
    }
  }
`;

/**
 * __useGetResourceCountByTeacherQuery__
 *
 * To run a query within a React component, call `useGetResourceCountByTeacherQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetResourceCountByTeacherQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetResourceCountByTeacherQuery({
 *   variables: {
 *      teacherId: // value for 'teacherId'
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useGetResourceCountByTeacherQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetResourceCountByTeacherQuery,
    GetResourceCountByTeacherQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetResourceCountByTeacherQuery, GetResourceCountByTeacherQueryVariables>(
    GetResourceCountByTeacherDocument,
    options
  );
}
export function useGetResourceCountByTeacherLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetResourceCountByTeacherQuery,
    GetResourceCountByTeacherQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetResourceCountByTeacherQuery, GetResourceCountByTeacherQueryVariables>(
    GetResourceCountByTeacherDocument,
    options
  );
}
export type GetResourceCountByTeacherQueryHookResult = ReturnType<typeof useGetResourceCountByTeacherQuery>;
export type GetResourceCountByTeacherLazyQueryHookResult = ReturnType<typeof useGetResourceCountByTeacherLazyQuery>;
export type GetResourceCountByTeacherQueryResult = ApolloReactCommon.QueryResult<
  GetResourceCountByTeacherQuery,
  GetResourceCountByTeacherQueryVariables
>;
export const GetResourceCountByStudentDocument = gql`
  query GetResourceCountByStudent($studentId: ID!) {
    resourceCountByStudent(studentID: $studentId) {
      class_count
    }
  }
`;

/**
 * __useGetResourceCountByStudentQuery__
 *
 * To run a query within a React component, call `useGetResourceCountByStudentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetResourceCountByStudentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetResourceCountByStudentQuery({
 *   variables: {
 *      studentId: // value for 'studentId'
 *   },
 * });
 */
export function useGetResourceCountByStudentQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetResourceCountByStudentQuery,
    GetResourceCountByStudentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetResourceCountByStudentQuery, GetResourceCountByStudentQueryVariables>(
    GetResourceCountByStudentDocument,
    options
  );
}
export function useGetResourceCountByStudentLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetResourceCountByStudentQuery,
    GetResourceCountByStudentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetResourceCountByStudentQuery, GetResourceCountByStudentQueryVariables>(
    GetResourceCountByStudentDocument,
    options
  );
}
export type GetResourceCountByStudentQueryHookResult = ReturnType<typeof useGetResourceCountByStudentQuery>;
export type GetResourceCountByStudentLazyQueryHookResult = ReturnType<typeof useGetResourceCountByStudentLazyQuery>;
export type GetResourceCountByStudentQueryResult = ApolloReactCommon.QueryResult<
  GetResourceCountByStudentQuery,
  GetResourceCountByStudentQueryVariables
>;
export const GetResourceCountBySectionDocument = gql`
  query GetResourceCountBySection($sectionId: ID!) {
    resourceCountByClass(sectionID: $sectionId) {
      student_count
      group_count
    }
  }
`;

/**
 * __useGetResourceCountBySectionQuery__
 *
 * To run a query within a React component, call `useGetResourceCountBySectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetResourceCountBySectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetResourceCountBySectionQuery({
 *   variables: {
 *      sectionId: // value for 'sectionId'
 *   },
 * });
 */
export function useGetResourceCountBySectionQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetResourceCountBySectionQuery,
    GetResourceCountBySectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetResourceCountBySectionQuery, GetResourceCountBySectionQueryVariables>(
    GetResourceCountBySectionDocument,
    options
  );
}
export function useGetResourceCountBySectionLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetResourceCountBySectionQuery,
    GetResourceCountBySectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetResourceCountBySectionQuery, GetResourceCountBySectionQueryVariables>(
    GetResourceCountBySectionDocument,
    options
  );
}
export type GetResourceCountBySectionQueryHookResult = ReturnType<typeof useGetResourceCountBySectionQuery>;
export type GetResourceCountBySectionLazyQueryHookResult = ReturnType<typeof useGetResourceCountBySectionLazyQuery>;
export type GetResourceCountBySectionQueryResult = ApolloReactCommon.QueryResult<
  GetResourceCountBySectionQuery,
  GetResourceCountBySectionQueryVariables
>;
export const GetResourceCountBySchoolDocument = gql`
  query GetResourceCountBySchool($schoolId: ID!) {
    resourceCountBySchool(schoolID: $schoolId) {
      student_count
      class_count
      teacher_count
      school_admin_count
    }
  }
`;

/**
 * __useGetResourceCountBySchoolQuery__
 *
 * To run a query within a React component, call `useGetResourceCountBySchoolQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetResourceCountBySchoolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetResourceCountBySchoolQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useGetResourceCountBySchoolQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetResourceCountBySchoolQuery, GetResourceCountBySchoolQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetResourceCountBySchoolQuery, GetResourceCountBySchoolQueryVariables>(
    GetResourceCountBySchoolDocument,
    options
  );
}
export function useGetResourceCountBySchoolLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetResourceCountBySchoolQuery,
    GetResourceCountBySchoolQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetResourceCountBySchoolQuery, GetResourceCountBySchoolQueryVariables>(
    GetResourceCountBySchoolDocument,
    options
  );
}
export type GetResourceCountBySchoolQueryHookResult = ReturnType<typeof useGetResourceCountBySchoolQuery>;
export type GetResourceCountBySchoolLazyQueryHookResult = ReturnType<typeof useGetResourceCountBySchoolLazyQuery>;
export type GetResourceCountBySchoolQueryResult = ApolloReactCommon.QueryResult<
  GetResourceCountBySchoolQuery,
  GetResourceCountBySchoolQueryVariables
>;
export const GetDistrictByIdDocument = gql`
  query GetDistrictById($id: String!) {
    getDistrictById(id: $id) {
      id
      name
      district_id
      source
      source_id
    }
  }
`;

/**
 * __useGetDistrictByIdQuery__
 *
 * To run a query within a React component, call `useGetDistrictByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDistrictByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDistrictByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDistrictByIdQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetDistrictByIdQuery, GetDistrictByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetDistrictByIdQuery, GetDistrictByIdQueryVariables>(
    GetDistrictByIdDocument,
    options
  );
}
export function useGetDistrictByIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDistrictByIdQuery, GetDistrictByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetDistrictByIdQuery, GetDistrictByIdQueryVariables>(
    GetDistrictByIdDocument,
    options
  );
}
export type GetDistrictByIdQueryHookResult = ReturnType<typeof useGetDistrictByIdQuery>;
export type GetDistrictByIdLazyQueryHookResult = ReturnType<typeof useGetDistrictByIdLazyQuery>;
export type GetDistrictByIdQueryResult = ApolloReactCommon.QueryResult<
  GetDistrictByIdQuery,
  GetDistrictByIdQueryVariables
>;
export const DistrictDocument = gql`
  query District {
    district {
      id
      name
      district_id
    }
  }
`;

/**
 * __useDistrictQuery__
 *
 * To run a query within a React component, call `useDistrictQuery` and pass it any options that fit your needs.
 * When your component renders, `useDistrictQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDistrictQuery({
 *   variables: {
 *   },
 * });
 */
export function useDistrictQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<DistrictQuery, DistrictQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<DistrictQuery, DistrictQueryVariables>(DistrictDocument, options);
}
export function useDistrictLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DistrictQuery, DistrictQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<DistrictQuery, DistrictQueryVariables>(DistrictDocument, options);
}
export type DistrictQueryHookResult = ReturnType<typeof useDistrictQuery>;
export type DistrictLazyQueryHookResult = ReturnType<typeof useDistrictLazyQuery>;
export type DistrictQueryResult = ApolloReactCommon.QueryResult<DistrictQuery, DistrictQueryVariables>;
export const AdminSchoolBenchmarkComparisonDocument = gql`
  query AdminSchoolBenchmarkComparison(
    $districtId: String!
    $page: Int
    $limit: Int
    $sort: AdminBenchmarkReportingWidgetSortType
    $orderBy: OrderBy
  ) {
    adminBenchmarkSchoolsComparison(
      district_id: $districtId
      page: $page
      limit: $limit
      sort: $sort
      orderBy: $orderBy
    ) {
      total_count
      nodes {
        id
        name
        benchmark_data_points {
          ...BenchmarkComparisonCommonNode
        }
      }
    }
  }
  ${BenchmarkComparisonCommonNodeFragmentDoc}
`;

/**
 * __useAdminSchoolBenchmarkComparisonQuery__
 *
 * To run a query within a React component, call `useAdminSchoolBenchmarkComparisonQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminSchoolBenchmarkComparisonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminSchoolBenchmarkComparisonQuery({
 *   variables: {
 *      districtId: // value for 'districtId'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useAdminSchoolBenchmarkComparisonQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    AdminSchoolBenchmarkComparisonQuery,
    AdminSchoolBenchmarkComparisonQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AdminSchoolBenchmarkComparisonQuery, AdminSchoolBenchmarkComparisonQueryVariables>(
    AdminSchoolBenchmarkComparisonDocument,
    options
  );
}
export function useAdminSchoolBenchmarkComparisonLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AdminSchoolBenchmarkComparisonQuery,
    AdminSchoolBenchmarkComparisonQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    AdminSchoolBenchmarkComparisonQuery,
    AdminSchoolBenchmarkComparisonQueryVariables
  >(AdminSchoolBenchmarkComparisonDocument, options);
}
export type AdminSchoolBenchmarkComparisonQueryHookResult = ReturnType<typeof useAdminSchoolBenchmarkComparisonQuery>;
export type AdminSchoolBenchmarkComparisonLazyQueryHookResult = ReturnType<
  typeof useAdminSchoolBenchmarkComparisonLazyQuery
>;
export type AdminSchoolBenchmarkComparisonQueryResult = ApolloReactCommon.QueryResult<
  AdminSchoolBenchmarkComparisonQuery,
  AdminSchoolBenchmarkComparisonQueryVariables
>;
export const AdminTeacherBenchmarkComparisonDocument = gql`
  query AdminTeacherBenchmarkComparison(
    $schoolId: String!
    $page: Int
    $limit: Int
    $sort: AdminBenchmarkReportingWidgetSortType
    $orderBy: OrderBy
  ) {
    adminBenchmarkTeacherComparison(school_id: $schoolId, page: $page, limit: $limit, sort: $sort, orderBy: $orderBy) {
      total_count
      nodes {
        id
        name
        benchmark_data_points {
          ...BenchmarkComparisonCommonNode
        }
      }
    }
  }
  ${BenchmarkComparisonCommonNodeFragmentDoc}
`;

/**
 * __useAdminTeacherBenchmarkComparisonQuery__
 *
 * To run a query within a React component, call `useAdminTeacherBenchmarkComparisonQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminTeacherBenchmarkComparisonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminTeacherBenchmarkComparisonQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useAdminTeacherBenchmarkComparisonQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    AdminTeacherBenchmarkComparisonQuery,
    AdminTeacherBenchmarkComparisonQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AdminTeacherBenchmarkComparisonQuery, AdminTeacherBenchmarkComparisonQueryVariables>(
    AdminTeacherBenchmarkComparisonDocument,
    options
  );
}
export function useAdminTeacherBenchmarkComparisonLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AdminTeacherBenchmarkComparisonQuery,
    AdminTeacherBenchmarkComparisonQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    AdminTeacherBenchmarkComparisonQuery,
    AdminTeacherBenchmarkComparisonQueryVariables
  >(AdminTeacherBenchmarkComparisonDocument, options);
}
export type AdminTeacherBenchmarkComparisonQueryHookResult = ReturnType<typeof useAdminTeacherBenchmarkComparisonQuery>;
export type AdminTeacherBenchmarkComparisonLazyQueryHookResult = ReturnType<
  typeof useAdminTeacherBenchmarkComparisonLazyQuery
>;
export type AdminTeacherBenchmarkComparisonQueryResult = ApolloReactCommon.QueryResult<
  AdminTeacherBenchmarkComparisonQuery,
  AdminTeacherBenchmarkComparisonQueryVariables
>;
export const AdminGradeBenchmarkComparisonDocument = gql`
  query AdminGradeBenchmarkComparison(
    $districtId: String
    $schoolId: String
    $sort: AdminBenchmarkReportingWidgetSortType
    $orderBy: OrderBy
  ) {
    adminBenchmarkGradeComparison(district_id: $districtId, school_id: $schoolId, sort: $sort, orderBy: $orderBy) {
      total_count
      nodes {
        id
        name
        position
        benchmark_data_points {
          ...BenchmarkComparisonCommonNode
        }
      }
    }
  }
  ${BenchmarkComparisonCommonNodeFragmentDoc}
`;

/**
 * __useAdminGradeBenchmarkComparisonQuery__
 *
 * To run a query within a React component, call `useAdminGradeBenchmarkComparisonQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGradeBenchmarkComparisonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGradeBenchmarkComparisonQuery({
 *   variables: {
 *      districtId: // value for 'districtId'
 *      schoolId: // value for 'schoolId'
 *      sort: // value for 'sort'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useAdminGradeBenchmarkComparisonQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AdminGradeBenchmarkComparisonQuery,
    AdminGradeBenchmarkComparisonQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AdminGradeBenchmarkComparisonQuery, AdminGradeBenchmarkComparisonQueryVariables>(
    AdminGradeBenchmarkComparisonDocument,
    options
  );
}
export function useAdminGradeBenchmarkComparisonLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AdminGradeBenchmarkComparisonQuery,
    AdminGradeBenchmarkComparisonQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AdminGradeBenchmarkComparisonQuery, AdminGradeBenchmarkComparisonQueryVariables>(
    AdminGradeBenchmarkComparisonDocument,
    options
  );
}
export type AdminGradeBenchmarkComparisonQueryHookResult = ReturnType<typeof useAdminGradeBenchmarkComparisonQuery>;
export type AdminGradeBenchmarkComparisonLazyQueryHookResult = ReturnType<
  typeof useAdminGradeBenchmarkComparisonLazyQuery
>;
export type AdminGradeBenchmarkComparisonQueryResult = ApolloReactCommon.QueryResult<
  AdminGradeBenchmarkComparisonQuery,
  AdminGradeBenchmarkComparisonQueryVariables
>;
export const AdminBenchmarkIndicatorScoresDocument = gql`
  query AdminBenchmarkIndicatorScores($input: AdminBenchmarkInput!) {
    adminBenchmarkIndicatorScores(input: $input) {
      ...AdminBenchmarkIndicatorScoreResultFragment
    }
  }
  ${AdminBenchmarkIndicatorScoreResultFragmentFragmentDoc}
`;

/**
 * __useAdminBenchmarkIndicatorScoresQuery__
 *
 * To run a query within a React component, call `useAdminBenchmarkIndicatorScoresQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminBenchmarkIndicatorScoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminBenchmarkIndicatorScoresQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminBenchmarkIndicatorScoresQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    AdminBenchmarkIndicatorScoresQuery,
    AdminBenchmarkIndicatorScoresQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AdminBenchmarkIndicatorScoresQuery, AdminBenchmarkIndicatorScoresQueryVariables>(
    AdminBenchmarkIndicatorScoresDocument,
    options
  );
}
export function useAdminBenchmarkIndicatorScoresLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AdminBenchmarkIndicatorScoresQuery,
    AdminBenchmarkIndicatorScoresQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AdminBenchmarkIndicatorScoresQuery, AdminBenchmarkIndicatorScoresQueryVariables>(
    AdminBenchmarkIndicatorScoresDocument,
    options
  );
}
export type AdminBenchmarkIndicatorScoresQueryHookResult = ReturnType<typeof useAdminBenchmarkIndicatorScoresQuery>;
export type AdminBenchmarkIndicatorScoresLazyQueryHookResult = ReturnType<
  typeof useAdminBenchmarkIndicatorScoresLazyQuery
>;
export type AdminBenchmarkIndicatorScoresQueryResult = ApolloReactCommon.QueryResult<
  AdminBenchmarkIndicatorScoresQuery,
  AdminBenchmarkIndicatorScoresQueryVariables
>;
export const AdminBenchmarkSubmissionStatusGrowthDocument = gql`
  query AdminBenchmarkSubmissionStatusGrowth($input: AdminBenchmarkInput!) {
    adminBenchmarkSubmissionStatusGrowth(input: $input) {
      ...AdminBenchmarkSubmissionStatusGrowthResultFragment
    }
  }
  ${AdminBenchmarkSubmissionStatusGrowthResultFragmentFragmentDoc}
`;

/**
 * __useAdminBenchmarkSubmissionStatusGrowthQuery__
 *
 * To run a query within a React component, call `useAdminBenchmarkSubmissionStatusGrowthQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminBenchmarkSubmissionStatusGrowthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminBenchmarkSubmissionStatusGrowthQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminBenchmarkSubmissionStatusGrowthQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    AdminBenchmarkSubmissionStatusGrowthQuery,
    AdminBenchmarkSubmissionStatusGrowthQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    AdminBenchmarkSubmissionStatusGrowthQuery,
    AdminBenchmarkSubmissionStatusGrowthQueryVariables
  >(AdminBenchmarkSubmissionStatusGrowthDocument, options);
}
export function useAdminBenchmarkSubmissionStatusGrowthLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AdminBenchmarkSubmissionStatusGrowthQuery,
    AdminBenchmarkSubmissionStatusGrowthQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    AdminBenchmarkSubmissionStatusGrowthQuery,
    AdminBenchmarkSubmissionStatusGrowthQueryVariables
  >(AdminBenchmarkSubmissionStatusGrowthDocument, options);
}
export type AdminBenchmarkSubmissionStatusGrowthQueryHookResult = ReturnType<
  typeof useAdminBenchmarkSubmissionStatusGrowthQuery
>;
export type AdminBenchmarkSubmissionStatusGrowthLazyQueryHookResult = ReturnType<
  typeof useAdminBenchmarkSubmissionStatusGrowthLazyQuery
>;
export type AdminBenchmarkSubmissionStatusGrowthQueryResult = ApolloReactCommon.QueryResult<
  AdminBenchmarkSubmissionStatusGrowthQuery,
  AdminBenchmarkSubmissionStatusGrowthQueryVariables
>;
export const SchoolDetailsDocument = gql`
  query SchoolDetails($id: ID!) {
    schoolDetails(id: $id) {
      ...SchoolDetailsDatum
    }
  }
  ${SchoolDetailsDatumFragmentDoc}
`;

/**
 * __useSchoolDetailsQuery__
 *
 * To run a query within a React component, call `useSchoolDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSchoolDetailsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<SchoolDetailsQuery, SchoolDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<SchoolDetailsQuery, SchoolDetailsQueryVariables>(SchoolDetailsDocument, options);
}
export function useSchoolDetailsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SchoolDetailsQuery, SchoolDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<SchoolDetailsQuery, SchoolDetailsQueryVariables>(SchoolDetailsDocument, options);
}
export type SchoolDetailsQueryHookResult = ReturnType<typeof useSchoolDetailsQuery>;
export type SchoolDetailsLazyQueryHookResult = ReturnType<typeof useSchoolDetailsLazyQuery>;
export type SchoolDetailsQueryResult = ApolloReactCommon.QueryResult<SchoolDetailsQuery, SchoolDetailsQueryVariables>;
export const MySchoolDocument = gql`
  query MySchool {
    mySchool {
      ...SchoolDetailsDatum
    }
  }
  ${SchoolDetailsDatumFragmentDoc}
`;

/**
 * __useMySchoolQuery__
 *
 * To run a query within a React component, call `useMySchoolQuery` and pass it any options that fit your needs.
 * When your component renders, `useMySchoolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMySchoolQuery({
 *   variables: {
 *   },
 * });
 */
export function useMySchoolQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<MySchoolQuery, MySchoolQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<MySchoolQuery, MySchoolQueryVariables>(MySchoolDocument, options);
}
export function useMySchoolLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MySchoolQuery, MySchoolQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<MySchoolQuery, MySchoolQueryVariables>(MySchoolDocument, options);
}
export type MySchoolQueryHookResult = ReturnType<typeof useMySchoolQuery>;
export type MySchoolLazyQueryHookResult = ReturnType<typeof useMySchoolLazyQuery>;
export type MySchoolQueryResult = ApolloReactCommon.QueryResult<MySchoolQuery, MySchoolQueryVariables>;
export const SchoolPredefinedGradeMappingsDocument = gql`
  query SchoolPredefinedGradeMappings($id: ID!) {
    schoolDetails(id: $id) {
      ...SchoolPredefinedGradeMappings
    }
  }
  ${SchoolPredefinedGradeMappingsFragmentDoc}
`;

/**
 * __useSchoolPredefinedGradeMappingsQuery__
 *
 * To run a query within a React component, call `useSchoolPredefinedGradeMappingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolPredefinedGradeMappingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolPredefinedGradeMappingsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSchoolPredefinedGradeMappingsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    SchoolPredefinedGradeMappingsQuery,
    SchoolPredefinedGradeMappingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<SchoolPredefinedGradeMappingsQuery, SchoolPredefinedGradeMappingsQueryVariables>(
    SchoolPredefinedGradeMappingsDocument,
    options
  );
}
export function useSchoolPredefinedGradeMappingsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SchoolPredefinedGradeMappingsQuery,
    SchoolPredefinedGradeMappingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<SchoolPredefinedGradeMappingsQuery, SchoolPredefinedGradeMappingsQueryVariables>(
    SchoolPredefinedGradeMappingsDocument,
    options
  );
}
export type SchoolPredefinedGradeMappingsQueryHookResult = ReturnType<typeof useSchoolPredefinedGradeMappingsQuery>;
export type SchoolPredefinedGradeMappingsLazyQueryHookResult = ReturnType<
  typeof useSchoolPredefinedGradeMappingsLazyQuery
>;
export type SchoolPredefinedGradeMappingsQueryResult = ApolloReactCommon.QueryResult<
  SchoolPredefinedGradeMappingsQuery,
  SchoolPredefinedGradeMappingsQueryVariables
>;
export const PredefinedGradesListDocument = gql`
  query PredefinedGradesList($stateIDs: [String!], $districtIDs: [String!], $schoolIDs: [String!]) {
    allPredefinedGrades(stateIDs: $stateIDs, districtIDs: $districtIDs, schoolIDs: $schoolIDs) {
      ...PredefinedGradeFragment
    }
  }
  ${PredefinedGradeFragmentFragmentDoc}
`;

/**
 * __usePredefinedGradesListQuery__
 *
 * To run a query within a React component, call `usePredefinedGradesListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePredefinedGradesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePredefinedGradesListQuery({
 *   variables: {
 *      stateIDs: // value for 'stateIDs'
 *      districtIDs: // value for 'districtIDs'
 *      schoolIDs: // value for 'schoolIDs'
 *   },
 * });
 */
export function usePredefinedGradesListQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<PredefinedGradesListQuery, PredefinedGradesListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<PredefinedGradesListQuery, PredefinedGradesListQueryVariables>(
    PredefinedGradesListDocument,
    options
  );
}
export function usePredefinedGradesListLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PredefinedGradesListQuery, PredefinedGradesListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<PredefinedGradesListQuery, PredefinedGradesListQueryVariables>(
    PredefinedGradesListDocument,
    options
  );
}
export type PredefinedGradesListQueryHookResult = ReturnType<typeof usePredefinedGradesListQuery>;
export type PredefinedGradesListLazyQueryHookResult = ReturnType<typeof usePredefinedGradesListLazyQuery>;
export type PredefinedGradesListQueryResult = ApolloReactCommon.QueryResult<
  PredefinedGradesListQuery,
  PredefinedGradesListQueryVariables
>;
export const SchoolFormDocument = gql`
  mutation SchoolForm($input: SchoolInput!) {
    upsertSchool(input: $input)
  }
`;
export type SchoolFormMutationFn = ApolloReactCommon.MutationFunction<SchoolFormMutation, SchoolFormMutationVariables>;

/**
 * __useSchoolFormMutation__
 *
 * To run a mutation, you first call `useSchoolFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSchoolFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [schoolFormMutation, { data, loading, error }] = useSchoolFormMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSchoolFormMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<SchoolFormMutation, SchoolFormMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<SchoolFormMutation, SchoolFormMutationVariables>(SchoolFormDocument, options);
}
export type SchoolFormMutationHookResult = ReturnType<typeof useSchoolFormMutation>;
export type SchoolFormMutationResult = ApolloReactCommon.MutationResult<SchoolFormMutation>;
export type SchoolFormMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SchoolFormMutation,
  SchoolFormMutationVariables
>;
export const SchoolEditDetailsDocument = gql`
  query SchoolEditDetails($id: ID!) {
    schoolDetails(id: $id) {
      ...SchoolFragment
    }
  }
  ${SchoolFragmentFragmentDoc}
`;

/**
 * __useSchoolEditDetailsQuery__
 *
 * To run a query within a React component, call `useSchoolEditDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolEditDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolEditDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSchoolEditDetailsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<SchoolEditDetailsQuery, SchoolEditDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<SchoolEditDetailsQuery, SchoolEditDetailsQueryVariables>(
    SchoolEditDetailsDocument,
    options
  );
}
export function useSchoolEditDetailsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SchoolEditDetailsQuery, SchoolEditDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<SchoolEditDetailsQuery, SchoolEditDetailsQueryVariables>(
    SchoolEditDetailsDocument,
    options
  );
}
export type SchoolEditDetailsQueryHookResult = ReturnType<typeof useSchoolEditDetailsQuery>;
export type SchoolEditDetailsLazyQueryHookResult = ReturnType<typeof useSchoolEditDetailsLazyQuery>;
export type SchoolEditDetailsQueryResult = ApolloReactCommon.QueryResult<
  SchoolEditDetailsQuery,
  SchoolEditDetailsQueryVariables
>;
export const SchoolsDocument = gql`
  query Schools(
    $stateId: String
    $districtId: String
    $agencyId: String
    $page: Int
    $limit: Int
    $sort: SchoolsListSortType
    $searchByName: String
    $orderBy: OrderBy
  ) {
    schoolsList(
      page: $page
      limit: $limit
      sort: $sort
      searchByName: $searchByName
      orderBy: $orderBy
      state_id: $stateId
      district_id: $districtId
      agency_id: $agencyId
    ) {
      totalCount
      nodes {
        ...SchoolListDetails
      }
    }
  }
  ${SchoolListDetailsFragmentDoc}
`;

/**
 * __useSchoolsQuery__
 *
 * To run a query within a React component, call `useSchoolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolsQuery({
 *   variables: {
 *      stateId: // value for 'stateId'
 *      districtId: // value for 'districtId'
 *      agencyId: // value for 'agencyId'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *      searchByName: // value for 'searchByName'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useSchoolsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SchoolsQuery, SchoolsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<SchoolsQuery, SchoolsQueryVariables>(SchoolsDocument, options);
}
export function useSchoolsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SchoolsQuery, SchoolsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<SchoolsQuery, SchoolsQueryVariables>(SchoolsDocument, options);
}
export type SchoolsQueryHookResult = ReturnType<typeof useSchoolsQuery>;
export type SchoolsLazyQueryHookResult = ReturnType<typeof useSchoolsLazyQuery>;
export type SchoolsQueryResult = ApolloReactCommon.QueryResult<SchoolsQuery, SchoolsQueryVariables>;
export const AdditionalFeedbackDocument = gql`
  mutation AdditionalFeedback($input: AdditionalFeedbackInput!) {
    updateAdditionalFeedBack(input: $input) {
      id
    }
  }
`;
export type AdditionalFeedbackMutationFn = ApolloReactCommon.MutationFunction<
  AdditionalFeedbackMutation,
  AdditionalFeedbackMutationVariables
>;

/**
 * __useAdditionalFeedbackMutation__
 *
 * To run a mutation, you first call `useAdditionalFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdditionalFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [additionalFeedbackMutation, { data, loading, error }] = useAdditionalFeedbackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdditionalFeedbackMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<AdditionalFeedbackMutation, AdditionalFeedbackMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<AdditionalFeedbackMutation, AdditionalFeedbackMutationVariables>(
    AdditionalFeedbackDocument,
    options
  );
}
export type AdditionalFeedbackMutationHookResult = ReturnType<typeof useAdditionalFeedbackMutation>;
export type AdditionalFeedbackMutationResult = ApolloReactCommon.MutationResult<AdditionalFeedbackMutation>;
export type AdditionalFeedbackMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AdditionalFeedbackMutation,
  AdditionalFeedbackMutationVariables
>;
export const DeleteFeedbackAudioDocument = gql`
  mutation DeleteFeedbackAudio($id: ID!, $submission_id: String!) {
    deleteFeedbackAudio(id: $id, submission_id: $submission_id)
  }
`;
export type DeleteFeedbackAudioMutationFn = ApolloReactCommon.MutationFunction<
  DeleteFeedbackAudioMutation,
  DeleteFeedbackAudioMutationVariables
>;

/**
 * __useDeleteFeedbackAudioMutation__
 *
 * To run a mutation, you first call `useDeleteFeedbackAudioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFeedbackAudioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFeedbackAudioMutation, { data, loading, error }] = useDeleteFeedbackAudioMutation({
 *   variables: {
 *      id: // value for 'id'
 *      submission_id: // value for 'submission_id'
 *   },
 * });
 */
export function useDeleteFeedbackAudioMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteFeedbackAudioMutation, DeleteFeedbackAudioMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeleteFeedbackAudioMutation, DeleteFeedbackAudioMutationVariables>(
    DeleteFeedbackAudioDocument,
    options
  );
}
export type DeleteFeedbackAudioMutationHookResult = ReturnType<typeof useDeleteFeedbackAudioMutation>;
export type DeleteFeedbackAudioMutationResult = ApolloReactCommon.MutationResult<DeleteFeedbackAudioMutation>;
export type DeleteFeedbackAudioMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteFeedbackAudioMutation,
  DeleteFeedbackAudioMutationVariables
>;
export const SubmissionDocument = gql`
  query Submission($id: ID!) {
    submission(id: $id) {
      ...SubmissionScoreFragment
    }
  }
  ${SubmissionScoreFragmentFragmentDoc}
`;

/**
 * __useSubmissionQuery__
 *
 * To run a query within a React component, call `useSubmissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubmissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubmissionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSubmissionQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<SubmissionQuery, SubmissionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<SubmissionQuery, SubmissionQueryVariables>(SubmissionDocument, options);
}
export function useSubmissionLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SubmissionQuery, SubmissionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<SubmissionQuery, SubmissionQueryVariables>(SubmissionDocument, options);
}
export type SubmissionQueryHookResult = ReturnType<typeof useSubmissionQuery>;
export type SubmissionLazyQueryHookResult = ReturnType<typeof useSubmissionLazyQuery>;
export type SubmissionQueryResult = ApolloReactCommon.QueryResult<SubmissionQuery, SubmissionQueryVariables>;
export const NewSubmissionDocument = gql`
  query NewSubmission($id: ID!) {
    newSubmission(id: $id) {
      ...NewSubmissionScoreFragment
    }
  }
  ${NewSubmissionScoreFragmentFragmentDoc}
`;

/**
 * __useNewSubmissionQuery__
 *
 * To run a query within a React component, call `useNewSubmissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewSubmissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewSubmissionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNewSubmissionQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<NewSubmissionQuery, NewSubmissionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<NewSubmissionQuery, NewSubmissionQueryVariables>(NewSubmissionDocument, options);
}
export function useNewSubmissionLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NewSubmissionQuery, NewSubmissionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<NewSubmissionQuery, NewSubmissionQueryVariables>(NewSubmissionDocument, options);
}
export type NewSubmissionQueryHookResult = ReturnType<typeof useNewSubmissionQuery>;
export type NewSubmissionLazyQueryHookResult = ReturnType<typeof useNewSubmissionLazyQuery>;
export type NewSubmissionQueryResult = ApolloReactCommon.QueryResult<NewSubmissionQuery, NewSubmissionQueryVariables>;
export const SubmissionGoalInfoDocument = gql`
  query SubmissionGoalInfo($submissionID: ID!) {
    getSubmissionGoalInfo(submissionID: $submissionID) {
      ...SubmissionGoalInfoFragment
    }
  }
  ${SubmissionGoalInfoFragmentFragmentDoc}
`;

/**
 * __useSubmissionGoalInfoQuery__
 *
 * To run a query within a React component, call `useSubmissionGoalInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubmissionGoalInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubmissionGoalInfoQuery({
 *   variables: {
 *      submissionID: // value for 'submissionID'
 *   },
 * });
 */
export function useSubmissionGoalInfoQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<SubmissionGoalInfoQuery, SubmissionGoalInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<SubmissionGoalInfoQuery, SubmissionGoalInfoQueryVariables>(
    SubmissionGoalInfoDocument,
    options
  );
}
export function useSubmissionGoalInfoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SubmissionGoalInfoQuery, SubmissionGoalInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<SubmissionGoalInfoQuery, SubmissionGoalInfoQueryVariables>(
    SubmissionGoalInfoDocument,
    options
  );
}
export type SubmissionGoalInfoQueryHookResult = ReturnType<typeof useSubmissionGoalInfoQuery>;
export type SubmissionGoalInfoLazyQueryHookResult = ReturnType<typeof useSubmissionGoalInfoLazyQuery>;
export type SubmissionGoalInfoQueryResult = ApolloReactCommon.QueryResult<
  SubmissionGoalInfoQuery,
  SubmissionGoalInfoQueryVariables
>;
export const SubmissionsIdQueueDocument = gql`
  query submissionsIDQueue(
    $sort: String
    $orderBy: OrderBy
    $search: String
    $status: [SubmissionStatus]
    $section_id: String
    $school_id: String
  ) {
    submissionQueue(
      sort: $sort
      orderBy: $orderBy
      search: $search
      status: $status
      sectionID: $section_id
      schoolID: $school_id
    ) {
      IDs
    }
  }
`;

/**
 * __useSubmissionsIdQueueQuery__
 *
 * To run a query within a React component, call `useSubmissionsIdQueueQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubmissionsIdQueueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubmissionsIdQueueQuery({
 *   variables: {
 *      sort: // value for 'sort'
 *      orderBy: // value for 'orderBy'
 *      search: // value for 'search'
 *      status: // value for 'status'
 *      section_id: // value for 'section_id'
 *      school_id: // value for 'school_id'
 *   },
 * });
 */
export function useSubmissionsIdQueueQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<SubmissionsIdQueueQuery, SubmissionsIdQueueQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<SubmissionsIdQueueQuery, SubmissionsIdQueueQueryVariables>(
    SubmissionsIdQueueDocument,
    options
  );
}
export function useSubmissionsIdQueueLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SubmissionsIdQueueQuery, SubmissionsIdQueueQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<SubmissionsIdQueueQuery, SubmissionsIdQueueQueryVariables>(
    SubmissionsIdQueueDocument,
    options
  );
}
export type SubmissionsIdQueueQueryHookResult = ReturnType<typeof useSubmissionsIdQueueQuery>;
export type SubmissionsIdQueueLazyQueryHookResult = ReturnType<typeof useSubmissionsIdQueueLazyQuery>;
export type SubmissionsIdQueueQueryResult = ApolloReactCommon.QueryResult<
  SubmissionsIdQueueQuery,
  SubmissionsIdQueueQueryVariables
>;
export const MarkReviewCompleteDocument = gql`
  mutation MarkReviewComplete($submissionId: String!) {
    markReviewComplete(submission_id: $submissionId) {
      status
    }
  }
`;
export type MarkReviewCompleteMutationFn = ApolloReactCommon.MutationFunction<
  MarkReviewCompleteMutation,
  MarkReviewCompleteMutationVariables
>;

/**
 * __useMarkReviewCompleteMutation__
 *
 * To run a mutation, you first call `useMarkReviewCompleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkReviewCompleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markReviewCompleteMutation, { data, loading, error }] = useMarkReviewCompleteMutation({
 *   variables: {
 *      submissionId: // value for 'submissionId'
 *   },
 * });
 */
export function useMarkReviewCompleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<MarkReviewCompleteMutation, MarkReviewCompleteMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<MarkReviewCompleteMutation, MarkReviewCompleteMutationVariables>(
    MarkReviewCompleteDocument,
    options
  );
}
export type MarkReviewCompleteMutationHookResult = ReturnType<typeof useMarkReviewCompleteMutation>;
export type MarkReviewCompleteMutationResult = ApolloReactCommon.MutationResult<MarkReviewCompleteMutation>;
export type MarkReviewCompleteMutationOptions = ApolloReactCommon.BaseMutationOptions<
  MarkReviewCompleteMutation,
  MarkReviewCompleteMutationVariables
>;
export const UpdateFeedbackNotesDocument = gql`
  mutation UpdateFeedbackNotes($id: ID!, $notes: String) {
    updateFeedbackNotes(id: $id, notes: $notes) {
      id
      feedback_notes
    }
  }
`;
export type UpdateFeedbackNotesMutationFn = ApolloReactCommon.MutationFunction<
  UpdateFeedbackNotesMutation,
  UpdateFeedbackNotesMutationVariables
>;

/**
 * __useUpdateFeedbackNotesMutation__
 *
 * To run a mutation, you first call `useUpdateFeedbackNotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFeedbackNotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFeedbackNotesMutation, { data, loading, error }] = useUpdateFeedbackNotesMutation({
 *   variables: {
 *      id: // value for 'id'
 *      notes: // value for 'notes'
 *   },
 * });
 */
export function useUpdateFeedbackNotesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateFeedbackNotesMutation, UpdateFeedbackNotesMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateFeedbackNotesMutation, UpdateFeedbackNotesMutationVariables>(
    UpdateFeedbackNotesDocument,
    options
  );
}
export type UpdateFeedbackNotesMutationHookResult = ReturnType<typeof useUpdateFeedbackNotesMutation>;
export type UpdateFeedbackNotesMutationResult = ApolloReactCommon.MutationResult<UpdateFeedbackNotesMutation>;
export type UpdateFeedbackNotesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateFeedbackNotesMutation,
  UpdateFeedbackNotesMutationVariables
>;
export const UpdateScoreDocument = gql`
  mutation UpdateScore($gradeId: String!, $scoreDetailID: String!) {
    updateScore(input: { grade_id: $gradeId, score_detail_id: $scoreDetailID })
  }
`;
export type UpdateScoreMutationFn = ApolloReactCommon.MutationFunction<
  UpdateScoreMutation,
  UpdateScoreMutationVariables
>;

/**
 * __useUpdateScoreMutation__
 *
 * To run a mutation, you first call `useUpdateScoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScoreMutation, { data, loading, error }] = useUpdateScoreMutation({
 *   variables: {
 *      gradeId: // value for 'gradeId'
 *      scoreDetailID: // value for 'scoreDetailID'
 *   },
 * });
 */
export function useUpdateScoreMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateScoreMutation, UpdateScoreMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateScoreMutation, UpdateScoreMutationVariables>(UpdateScoreDocument, options);
}
export type UpdateScoreMutationHookResult = ReturnType<typeof useUpdateScoreMutation>;
export type UpdateScoreMutationResult = ApolloReactCommon.MutationResult<UpdateScoreMutation>;
export type UpdateScoreMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateScoreMutation,
  UpdateScoreMutationVariables
>;
export const UpdateBenchmarkScoreDocument = gql`
  mutation UpdateBenchmarkScore($submissionId: ID, $gradeId: String!, $scoreDetailID: String!) {
    updateBenchmarkScore(input: { submission_id: $submissionId, grade_id: $gradeId, score_detail_id: $scoreDetailID })
  }
`;
export type UpdateBenchmarkScoreMutationFn = ApolloReactCommon.MutationFunction<
  UpdateBenchmarkScoreMutation,
  UpdateBenchmarkScoreMutationVariables
>;

/**
 * __useUpdateBenchmarkScoreMutation__
 *
 * To run a mutation, you first call `useUpdateBenchmarkScoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBenchmarkScoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBenchmarkScoreMutation, { data, loading, error }] = useUpdateBenchmarkScoreMutation({
 *   variables: {
 *      submissionId: // value for 'submissionId'
 *      gradeId: // value for 'gradeId'
 *      scoreDetailID: // value for 'scoreDetailID'
 *   },
 * });
 */
export function useUpdateBenchmarkScoreMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateBenchmarkScoreMutation,
    UpdateBenchmarkScoreMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateBenchmarkScoreMutation, UpdateBenchmarkScoreMutationVariables>(
    UpdateBenchmarkScoreDocument,
    options
  );
}
export type UpdateBenchmarkScoreMutationHookResult = ReturnType<typeof useUpdateBenchmarkScoreMutation>;
export type UpdateBenchmarkScoreMutationResult = ApolloReactCommon.MutationResult<UpdateBenchmarkScoreMutation>;
export type UpdateBenchmarkScoreMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateBenchmarkScoreMutation,
  UpdateBenchmarkScoreMutationVariables
>;
export const UpdateBenchmarkFeedbackNotesDocument = gql`
  mutation UpdateBenchmarkFeedbackNotes($id: ID!, $notes: String) {
    updateBenchmarkFeedbackNotes(id: $id, notes: $notes) {
      id
      feedback_notes
    }
  }
`;
export type UpdateBenchmarkFeedbackNotesMutationFn = ApolloReactCommon.MutationFunction<
  UpdateBenchmarkFeedbackNotesMutation,
  UpdateBenchmarkFeedbackNotesMutationVariables
>;

/**
 * __useUpdateBenchmarkFeedbackNotesMutation__
 *
 * To run a mutation, you first call `useUpdateBenchmarkFeedbackNotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBenchmarkFeedbackNotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBenchmarkFeedbackNotesMutation, { data, loading, error }] = useUpdateBenchmarkFeedbackNotesMutation({
 *   variables: {
 *      id: // value for 'id'
 *      notes: // value for 'notes'
 *   },
 * });
 */
export function useUpdateBenchmarkFeedbackNotesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateBenchmarkFeedbackNotesMutation,
    UpdateBenchmarkFeedbackNotesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateBenchmarkFeedbackNotesMutation,
    UpdateBenchmarkFeedbackNotesMutationVariables
  >(UpdateBenchmarkFeedbackNotesDocument, options);
}
export type UpdateBenchmarkFeedbackNotesMutationHookResult = ReturnType<typeof useUpdateBenchmarkFeedbackNotesMutation>;
export type UpdateBenchmarkFeedbackNotesMutationResult =
  ApolloReactCommon.MutationResult<UpdateBenchmarkFeedbackNotesMutation>;
export type UpdateBenchmarkFeedbackNotesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateBenchmarkFeedbackNotesMutation,
  UpdateBenchmarkFeedbackNotesMutationVariables
>;
export const UpdateBenchmarkAdditionalFeedBackDocument = gql`
  mutation UpdateBenchmarkAdditionalFeedBack($input: AdditionalFeedbackInput!) {
    updateBenchmarkAdditionalFeedBack(input: $input) {
      id
    }
  }
`;
export type UpdateBenchmarkAdditionalFeedBackMutationFn = ApolloReactCommon.MutationFunction<
  UpdateBenchmarkAdditionalFeedBackMutation,
  UpdateBenchmarkAdditionalFeedBackMutationVariables
>;

/**
 * __useUpdateBenchmarkAdditionalFeedBackMutation__
 *
 * To run a mutation, you first call `useUpdateBenchmarkAdditionalFeedBackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBenchmarkAdditionalFeedBackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBenchmarkAdditionalFeedBackMutation, { data, loading, error }] = useUpdateBenchmarkAdditionalFeedBackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBenchmarkAdditionalFeedBackMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateBenchmarkAdditionalFeedBackMutation,
    UpdateBenchmarkAdditionalFeedBackMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateBenchmarkAdditionalFeedBackMutation,
    UpdateBenchmarkAdditionalFeedBackMutationVariables
  >(UpdateBenchmarkAdditionalFeedBackDocument, options);
}
export type UpdateBenchmarkAdditionalFeedBackMutationHookResult = ReturnType<
  typeof useUpdateBenchmarkAdditionalFeedBackMutation
>;
export type UpdateBenchmarkAdditionalFeedBackMutationResult =
  ApolloReactCommon.MutationResult<UpdateBenchmarkAdditionalFeedBackMutation>;
export type UpdateBenchmarkAdditionalFeedBackMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateBenchmarkAdditionalFeedBackMutation,
  UpdateBenchmarkAdditionalFeedBackMutationVariables
>;
export const DeleteBenchmarkFeedbackAudioDocument = gql`
  mutation DeleteBenchmarkFeedbackAudio($id: ID!, $submission_id: String!) {
    benchmarkDeleteFeedbackAudio(id: $id, submission_id: $submission_id)
  }
`;
export type DeleteBenchmarkFeedbackAudioMutationFn = ApolloReactCommon.MutationFunction<
  DeleteBenchmarkFeedbackAudioMutation,
  DeleteBenchmarkFeedbackAudioMutationVariables
>;

/**
 * __useDeleteBenchmarkFeedbackAudioMutation__
 *
 * To run a mutation, you first call `useDeleteBenchmarkFeedbackAudioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBenchmarkFeedbackAudioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBenchmarkFeedbackAudioMutation, { data, loading, error }] = useDeleteBenchmarkFeedbackAudioMutation({
 *   variables: {
 *      id: // value for 'id'
 *      submission_id: // value for 'submission_id'
 *   },
 * });
 */
export function useDeleteBenchmarkFeedbackAudioMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteBenchmarkFeedbackAudioMutation,
    DeleteBenchmarkFeedbackAudioMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeleteBenchmarkFeedbackAudioMutation,
    DeleteBenchmarkFeedbackAudioMutationVariables
  >(DeleteBenchmarkFeedbackAudioDocument, options);
}
export type DeleteBenchmarkFeedbackAudioMutationHookResult = ReturnType<typeof useDeleteBenchmarkFeedbackAudioMutation>;
export type DeleteBenchmarkFeedbackAudioMutationResult =
  ApolloReactCommon.MutationResult<DeleteBenchmarkFeedbackAudioMutation>;
export type DeleteBenchmarkFeedbackAudioMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteBenchmarkFeedbackAudioMutation,
  DeleteBenchmarkFeedbackAudioMutationVariables
>;
export const MarkBenchmarkReviewCompleteDocument = gql`
  mutation MarkBenchmarkReviewComplete($id: ID!) {
    markBenchmarkReviewComplete(id: $id) {
      status
    }
  }
`;
export type MarkBenchmarkReviewCompleteMutationFn = ApolloReactCommon.MutationFunction<
  MarkBenchmarkReviewCompleteMutation,
  MarkBenchmarkReviewCompleteMutationVariables
>;

/**
 * __useMarkBenchmarkReviewCompleteMutation__
 *
 * To run a mutation, you first call `useMarkBenchmarkReviewCompleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkBenchmarkReviewCompleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markBenchmarkReviewCompleteMutation, { data, loading, error }] = useMarkBenchmarkReviewCompleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMarkBenchmarkReviewCompleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    MarkBenchmarkReviewCompleteMutation,
    MarkBenchmarkReviewCompleteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    MarkBenchmarkReviewCompleteMutation,
    MarkBenchmarkReviewCompleteMutationVariables
  >(MarkBenchmarkReviewCompleteDocument, options);
}
export type MarkBenchmarkReviewCompleteMutationHookResult = ReturnType<typeof useMarkBenchmarkReviewCompleteMutation>;
export type MarkBenchmarkReviewCompleteMutationResult =
  ApolloReactCommon.MutationResult<MarkBenchmarkReviewCompleteMutation>;
export type MarkBenchmarkReviewCompleteMutationOptions = ApolloReactCommon.BaseMutationOptions<
  MarkBenchmarkReviewCompleteMutation,
  MarkBenchmarkReviewCompleteMutationVariables
>;
export const BenchmarkSubmissionDocument = gql`
  query BenchmarkSubmission($id: ID!) {
    benchmarkSubmission(id: $id) {
      ...BenchmarkSubmissionScoreFragment
    }
  }
  ${BenchmarkSubmissionScoreFragmentFragmentDoc}
`;

/**
 * __useBenchmarkSubmissionQuery__
 *
 * To run a query within a React component, call `useBenchmarkSubmissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useBenchmarkSubmissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBenchmarkSubmissionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBenchmarkSubmissionQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<BenchmarkSubmissionQuery, BenchmarkSubmissionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<BenchmarkSubmissionQuery, BenchmarkSubmissionQueryVariables>(
    BenchmarkSubmissionDocument,
    options
  );
}
export function useBenchmarkSubmissionLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BenchmarkSubmissionQuery, BenchmarkSubmissionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<BenchmarkSubmissionQuery, BenchmarkSubmissionQueryVariables>(
    BenchmarkSubmissionDocument,
    options
  );
}
export type BenchmarkSubmissionQueryHookResult = ReturnType<typeof useBenchmarkSubmissionQuery>;
export type BenchmarkSubmissionLazyQueryHookResult = ReturnType<typeof useBenchmarkSubmissionLazyQuery>;
export type BenchmarkSubmissionQueryResult = ApolloReactCommon.QueryResult<
  BenchmarkSubmissionQuery,
  BenchmarkSubmissionQueryVariables
>;
export const TeacherBenchmarkGradingQueueDocument = gql`
  query TeacherBenchmarkGradingQueue(
    $grade_statuses: [SubmissionStatus!]
    $benchmark_types: [BenchmarkType!]
    $page: Int
    $limit: Int
    $sort: TeacherBenchmarkSubmissionListSortType
    $order_by: OrderBy
    $graded_by: [BenchmarkGradedBy!]
  ) {
    teacherBenchmarkGradingQueue(
      input: {
        submission_statuses: $grade_statuses
        benchmark_types: $benchmark_types
        page: $page
        limit: $limit
        sort: $sort
        order_by: $order_by
        graded_by: $graded_by
      }
    )
  }
`;

/**
 * __useTeacherBenchmarkGradingQueueQuery__
 *
 * To run a query within a React component, call `useTeacherBenchmarkGradingQueueQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeacherBenchmarkGradingQueueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeacherBenchmarkGradingQueueQuery({
 *   variables: {
 *      grade_statuses: // value for 'grade_statuses'
 *      benchmark_types: // value for 'benchmark_types'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *      order_by: // value for 'order_by'
 *      graded_by: // value for 'graded_by'
 *   },
 * });
 */
export function useTeacherBenchmarkGradingQueueQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    TeacherBenchmarkGradingQueueQuery,
    TeacherBenchmarkGradingQueueQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<TeacherBenchmarkGradingQueueQuery, TeacherBenchmarkGradingQueueQueryVariables>(
    TeacherBenchmarkGradingQueueDocument,
    options
  );
}
export function useTeacherBenchmarkGradingQueueLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    TeacherBenchmarkGradingQueueQuery,
    TeacherBenchmarkGradingQueueQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<TeacherBenchmarkGradingQueueQuery, TeacherBenchmarkGradingQueueQueryVariables>(
    TeacherBenchmarkGradingQueueDocument,
    options
  );
}
export type TeacherBenchmarkGradingQueueQueryHookResult = ReturnType<typeof useTeacherBenchmarkGradingQueueQuery>;
export type TeacherBenchmarkGradingQueueLazyQueryHookResult = ReturnType<
  typeof useTeacherBenchmarkGradingQueueLazyQuery
>;
export type TeacherBenchmarkGradingQueueQueryResult = ApolloReactCommon.QueryResult<
  TeacherBenchmarkGradingQueueQuery,
  TeacherBenchmarkGradingQueueQueryVariables
>;
export const EditAdminResourceDocument = gql`
  mutation EditAdminResource($input: UpdateAdminResourceInput!) {
    updateAdminImageResource(input: $input)
  }
`;
export type EditAdminResourceMutationFn = ApolloReactCommon.MutationFunction<
  EditAdminResourceMutation,
  EditAdminResourceMutationVariables
>;

/**
 * __useEditAdminResourceMutation__
 *
 * To run a mutation, you first call `useEditAdminResourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAdminResourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAdminResourceMutation, { data, loading, error }] = useEditAdminResourceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditAdminResourceMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<EditAdminResourceMutation, EditAdminResourceMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<EditAdminResourceMutation, EditAdminResourceMutationVariables>(
    EditAdminResourceDocument,
    options
  );
}
export type EditAdminResourceMutationHookResult = ReturnType<typeof useEditAdminResourceMutation>;
export type EditAdminResourceMutationResult = ApolloReactCommon.MutationResult<EditAdminResourceMutation>;
export type EditAdminResourceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  EditAdminResourceMutation,
  EditAdminResourceMutationVariables
>;
export const DeleteAdminResourceDocument = gql`
  mutation DeleteAdminResource($input: String!) {
    archiveStaticResource(id: $input)
  }
`;
export type DeleteAdminResourceMutationFn = ApolloReactCommon.MutationFunction<
  DeleteAdminResourceMutation,
  DeleteAdminResourceMutationVariables
>;

/**
 * __useDeleteAdminResourceMutation__
 *
 * To run a mutation, you first call `useDeleteAdminResourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAdminResourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAdminResourceMutation, { data, loading, error }] = useDeleteAdminResourceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAdminResourceMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteAdminResourceMutation, DeleteAdminResourceMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeleteAdminResourceMutation, DeleteAdminResourceMutationVariables>(
    DeleteAdminResourceDocument,
    options
  );
}
export type DeleteAdminResourceMutationHookResult = ReturnType<typeof useDeleteAdminResourceMutation>;
export type DeleteAdminResourceMutationResult = ApolloReactCommon.MutationResult<DeleteAdminResourceMutation>;
export type DeleteAdminResourceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteAdminResourceMutation,
  DeleteAdminResourceMutationVariables
>;
export const StaticResourceDetailsDocument = gql`
  query StaticResourceDetails($id: ID!) {
    staticResource(id: $id) {
      id
      title
      grade
      thumbnail_height
      thumbnail_width
      file {
        id
        url
        file_type
        file_key
        thumbnail_url
        thumbnail_file_type
        thumbnail_file_key
      }
      subject_areas
      task_types
      keywords
    }
  }
`;

/**
 * __useStaticResourceDetailsQuery__
 *
 * To run a query within a React component, call `useStaticResourceDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaticResourceDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaticResourceDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStaticResourceDetailsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<StaticResourceDetailsQuery, StaticResourceDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<StaticResourceDetailsQuery, StaticResourceDetailsQueryVariables>(
    StaticResourceDetailsDocument,
    options
  );
}
export function useStaticResourceDetailsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StaticResourceDetailsQuery, StaticResourceDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<StaticResourceDetailsQuery, StaticResourceDetailsQueryVariables>(
    StaticResourceDetailsDocument,
    options
  );
}
export type StaticResourceDetailsQueryHookResult = ReturnType<typeof useStaticResourceDetailsQuery>;
export type StaticResourceDetailsLazyQueryHookResult = ReturnType<typeof useStaticResourceDetailsLazyQuery>;
export type StaticResourceDetailsQueryResult = ApolloReactCommon.QueryResult<
  StaticResourceDetailsQuery,
  StaticResourceDetailsQueryVariables
>;
export const CreateSuperAdminDocument = gql`
  mutation CreateSuperAdmin(
    $ID: String
    $firstName: String!
    $middleName: String!
    $lastName: String!
    $adminID: String!
    $title: String!
    $email: String!
    $phoneNumber: String!
    $password: String
  ) {
    upsertSuperAdmin(
      input: {
        id: $ID
        first_name: $firstName
        middle_name: $middleName
        last_name: $lastName
        admin_id: $adminID
        title: $title
        email: $email
        phone_number: $phoneNumber
        password: $password
      }
    )
  }
`;
export type CreateSuperAdminMutationFn = ApolloReactCommon.MutationFunction<
  CreateSuperAdminMutation,
  CreateSuperAdminMutationVariables
>;

/**
 * __useCreateSuperAdminMutation__
 *
 * To run a mutation, you first call `useCreateSuperAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSuperAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSuperAdminMutation, { data, loading, error }] = useCreateSuperAdminMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      firstName: // value for 'firstName'
 *      middleName: // value for 'middleName'
 *      lastName: // value for 'lastName'
 *      adminID: // value for 'adminID'
 *      title: // value for 'title'
 *      email: // value for 'email'
 *      phoneNumber: // value for 'phoneNumber'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useCreateSuperAdminMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSuperAdminMutation, CreateSuperAdminMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateSuperAdminMutation, CreateSuperAdminMutationVariables>(
    CreateSuperAdminDocument,
    options
  );
}
export type CreateSuperAdminMutationHookResult = ReturnType<typeof useCreateSuperAdminMutation>;
export type CreateSuperAdminMutationResult = ApolloReactCommon.MutationResult<CreateSuperAdminMutation>;
export type CreateSuperAdminMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateSuperAdminMutation,
  CreateSuperAdminMutationVariables
>;
export const GetSuperAdminDetailsDocument = gql`
  query getSuperAdminDetails($Id: ID!) {
    superAdminDetails(id: $Id) {
      id
      title
      user_id
      first_name
      admin_id
      email
      last_name
      middle_name
      title
      phone_number
    }
  }
`;

/**
 * __useGetSuperAdminDetailsQuery__
 *
 * To run a query within a React component, call `useGetSuperAdminDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSuperAdminDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSuperAdminDetailsQuery({
 *   variables: {
 *      Id: // value for 'Id'
 *   },
 * });
 */
export function useGetSuperAdminDetailsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetSuperAdminDetailsQuery, GetSuperAdminDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetSuperAdminDetailsQuery, GetSuperAdminDetailsQueryVariables>(
    GetSuperAdminDetailsDocument,
    options
  );
}
export function useGetSuperAdminDetailsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSuperAdminDetailsQuery, GetSuperAdminDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetSuperAdminDetailsQuery, GetSuperAdminDetailsQueryVariables>(
    GetSuperAdminDetailsDocument,
    options
  );
}
export type GetSuperAdminDetailsQueryHookResult = ReturnType<typeof useGetSuperAdminDetailsQuery>;
export type GetSuperAdminDetailsLazyQueryHookResult = ReturnType<typeof useGetSuperAdminDetailsLazyQuery>;
export type GetSuperAdminDetailsQueryResult = ApolloReactCommon.QueryResult<
  GetSuperAdminDetailsQuery,
  GetSuperAdminDetailsQueryVariables
>;
export const ListSuperAdminDocument = gql`
  query listSuperAdmin($page: Int, $sort: AdminListSortType, $orderBy: OrderBy, $limit: Int, $searchByName: String) {
    superAdminList(page: $page, sort: $sort, orderBy: $orderBy, limit: $limit, searchByName: $searchByName) {
      totalCount
      nodes {
        id
        user_id
        first_name
        middle_name
        last_name
        job_title
        updated_by_first_name
        updated_by_last_name
        updated_at
        email
      }
    }
  }
`;

/**
 * __useListSuperAdminQuery__
 *
 * To run a query within a React component, call `useListSuperAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSuperAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSuperAdminQuery({
 *   variables: {
 *      page: // value for 'page'
 *      sort: // value for 'sort'
 *      orderBy: // value for 'orderBy'
 *      limit: // value for 'limit'
 *      searchByName: // value for 'searchByName'
 *   },
 * });
 */
export function useListSuperAdminQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<ListSuperAdminQuery, ListSuperAdminQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ListSuperAdminQuery, ListSuperAdminQueryVariables>(ListSuperAdminDocument, options);
}
export function useListSuperAdminLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListSuperAdminQuery, ListSuperAdminQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ListSuperAdminQuery, ListSuperAdminQueryVariables>(
    ListSuperAdminDocument,
    options
  );
}
export type ListSuperAdminQueryHookResult = ReturnType<typeof useListSuperAdminQuery>;
export type ListSuperAdminLazyQueryHookResult = ReturnType<typeof useListSuperAdminLazyQuery>;
export type ListSuperAdminQueryResult = ApolloReactCommon.QueryResult<
  ListSuperAdminQuery,
  ListSuperAdminQueryVariables
>;
export const AdminResourceDocument = gql`
  mutation AdminResource($input: CreateAdminResourceInput!) {
    createAdminImageResource(input: $input)
  }
`;
export type AdminResourceMutationFn = ApolloReactCommon.MutationFunction<
  AdminResourceMutation,
  AdminResourceMutationVariables
>;

/**
 * __useAdminResourceMutation__
 *
 * To run a mutation, you first call `useAdminResourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminResourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminResourceMutation, { data, loading, error }] = useAdminResourceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminResourceMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<AdminResourceMutation, AdminResourceMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<AdminResourceMutation, AdminResourceMutationVariables>(
    AdminResourceDocument,
    options
  );
}
export type AdminResourceMutationHookResult = ReturnType<typeof useAdminResourceMutation>;
export type AdminResourceMutationResult = ApolloReactCommon.MutationResult<AdminResourceMutation>;
export type AdminResourceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AdminResourceMutation,
  AdminResourceMutationVariables
>;
export const SubjectAreasDocument = gql`
  query SubjectAreas {
    subjectArea {
      id
      title
    }
  }
`;

/**
 * __useSubjectAreasQuery__
 *
 * To run a query within a React component, call `useSubjectAreasQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubjectAreasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubjectAreasQuery({
 *   variables: {
 *   },
 * });
 */
export function useSubjectAreasQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<SubjectAreasQuery, SubjectAreasQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<SubjectAreasQuery, SubjectAreasQueryVariables>(SubjectAreasDocument, options);
}
export function useSubjectAreasLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SubjectAreasQuery, SubjectAreasQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<SubjectAreasQuery, SubjectAreasQueryVariables>(SubjectAreasDocument, options);
}
export type SubjectAreasQueryHookResult = ReturnType<typeof useSubjectAreasQuery>;
export type SubjectAreasLazyQueryHookResult = ReturnType<typeof useSubjectAreasLazyQuery>;
export type SubjectAreasQueryResult = ApolloReactCommon.QueryResult<SubjectAreasQuery, SubjectAreasQueryVariables>;
export const GetBackPackSubmissionDocument = gql`
  query GetBackPackSubmission($id: ID!) {
    submission(id: $id) {
      ...BackPackSubmission
    }
  }
  ${BackPackSubmissionFragmentDoc}
`;

/**
 * __useGetBackPackSubmissionQuery__
 *
 * To run a query within a React component, call `useGetBackPackSubmissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBackPackSubmissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBackPackSubmissionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBackPackSubmissionQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetBackPackSubmissionQuery, GetBackPackSubmissionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetBackPackSubmissionQuery, GetBackPackSubmissionQueryVariables>(
    GetBackPackSubmissionDocument,
    options
  );
}
export function useGetBackPackSubmissionLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBackPackSubmissionQuery, GetBackPackSubmissionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetBackPackSubmissionQuery, GetBackPackSubmissionQueryVariables>(
    GetBackPackSubmissionDocument,
    options
  );
}
export type GetBackPackSubmissionQueryHookResult = ReturnType<typeof useGetBackPackSubmissionQuery>;
export type GetBackPackSubmissionLazyQueryHookResult = ReturnType<typeof useGetBackPackSubmissionLazyQuery>;
export type GetBackPackSubmissionQueryResult = ApolloReactCommon.QueryResult<
  GetBackPackSubmissionQuery,
  GetBackPackSubmissionQueryVariables
>;
export const GetScoreSummaryDocument = gql`
  query GetScoreSummary($assignmentIDs: [ID!], $sectionIDs: [ID!], $submissionID: ID!, $teacherID: ID) {
    scoreSummaryForStudent(
      assignmentIDs: $assignmentIDs
      sectionIDs: $sectionIDs
      submissionID: $submissionID
      teacherID: $teacherID
    ) {
      ...StudentViewScoreSummary
    }
  }
  ${StudentViewScoreSummaryFragmentDoc}
`;

/**
 * __useGetScoreSummaryQuery__
 *
 * To run a query within a React component, call `useGetScoreSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScoreSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScoreSummaryQuery({
 *   variables: {
 *      assignmentIDs: // value for 'assignmentIDs'
 *      sectionIDs: // value for 'sectionIDs'
 *      submissionID: // value for 'submissionID'
 *      teacherID: // value for 'teacherID'
 *   },
 * });
 */
export function useGetScoreSummaryQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetScoreSummaryQuery, GetScoreSummaryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetScoreSummaryQuery, GetScoreSummaryQueryVariables>(
    GetScoreSummaryDocument,
    options
  );
}
export function useGetScoreSummaryLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetScoreSummaryQuery, GetScoreSummaryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetScoreSummaryQuery, GetScoreSummaryQueryVariables>(
    GetScoreSummaryDocument,
    options
  );
}
export type GetScoreSummaryQueryHookResult = ReturnType<typeof useGetScoreSummaryQuery>;
export type GetScoreSummaryLazyQueryHookResult = ReturnType<typeof useGetScoreSummaryLazyQuery>;
export type GetScoreSummaryQueryResult = ApolloReactCommon.QueryResult<
  GetScoreSummaryQuery,
  GetScoreSummaryQueryVariables
>;
export const StudentAssignmentsDocument = gql`
  query StudentAssignments($input: StudentAssignmentAndBenchmarkInput) {
    getAllAssignmentsAndBenchmarksForStudent(input: $input) {
      score_details {
        score_value
        color_code
      }
      total_count
      assignments {
        ...AssignmentForStudent
      }
      benchmarks {
        ...BenchmarkForStudent
      }
    }
  }
  ${AssignmentForStudentFragmentDoc}
  ${BenchmarkForStudentFragmentDoc}
`;

/**
 * __useStudentAssignmentsQuery__
 *
 * To run a query within a React component, call `useStudentAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentAssignmentsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStudentAssignmentsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<StudentAssignmentsQuery, StudentAssignmentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<StudentAssignmentsQuery, StudentAssignmentsQueryVariables>(
    StudentAssignmentsDocument,
    options
  );
}
export function useStudentAssignmentsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StudentAssignmentsQuery, StudentAssignmentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<StudentAssignmentsQuery, StudentAssignmentsQueryVariables>(
    StudentAssignmentsDocument,
    options
  );
}
export type StudentAssignmentsQueryHookResult = ReturnType<typeof useStudentAssignmentsQuery>;
export type StudentAssignmentsLazyQueryHookResult = ReturnType<typeof useStudentAssignmentsLazyQuery>;
export type StudentAssignmentsQueryResult = ApolloReactCommon.QueryResult<
  StudentAssignmentsQuery,
  StudentAssignmentsQueryVariables
>;
export const GetAllAssignmentsForStudentDocument = gql`
  query GetAllAssignmentsForStudent($input: StudentAssignmentAndBenchmarkInput) {
    getAllAssignmentsAndBenchmarksForStudent(input: $input) {
      total_count
      assignments {
        ...AssignmentForStudent
      }
      benchmarks {
        ...BenchmarkForStudent
      }
    }
  }
  ${AssignmentForStudentFragmentDoc}
  ${BenchmarkForStudentFragmentDoc}
`;

/**
 * __useGetAllAssignmentsForStudentQuery__
 *
 * To run a query within a React component, call `useGetAllAssignmentsForStudentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAssignmentsForStudentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAssignmentsForStudentQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAllAssignmentsForStudentQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetAllAssignmentsForStudentQuery,
    GetAllAssignmentsForStudentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetAllAssignmentsForStudentQuery, GetAllAssignmentsForStudentQueryVariables>(
    GetAllAssignmentsForStudentDocument,
    options
  );
}
export function useGetAllAssignmentsForStudentLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetAllAssignmentsForStudentQuery,
    GetAllAssignmentsForStudentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetAllAssignmentsForStudentQuery, GetAllAssignmentsForStudentQueryVariables>(
    GetAllAssignmentsForStudentDocument,
    options
  );
}
export type GetAllAssignmentsForStudentQueryHookResult = ReturnType<typeof useGetAllAssignmentsForStudentQuery>;
export type GetAllAssignmentsForStudentLazyQueryHookResult = ReturnType<typeof useGetAllAssignmentsForStudentLazyQuery>;
export type GetAllAssignmentsForStudentQueryResult = ApolloReactCommon.QueryResult<
  GetAllAssignmentsForStudentQuery,
  GetAllAssignmentsForStudentQueryVariables
>;
export const GetTeachersAssociatedWithSectionsDocument = gql`
  query GetTeachersAssociatedWithSections($sectionIDs: [ID!]) {
    teachersOfSection(section_ids: $sectionIDs) {
      id
      teachers {
        id
        first_name
        last_name
        primary_teacher
      }
    }
  }
`;

/**
 * __useGetTeachersAssociatedWithSectionsQuery__
 *
 * To run a query within a React component, call `useGetTeachersAssociatedWithSectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeachersAssociatedWithSectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeachersAssociatedWithSectionsQuery({
 *   variables: {
 *      sectionIDs: // value for 'sectionIDs'
 *   },
 * });
 */
export function useGetTeachersAssociatedWithSectionsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetTeachersAssociatedWithSectionsQuery,
    GetTeachersAssociatedWithSectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetTeachersAssociatedWithSectionsQuery,
    GetTeachersAssociatedWithSectionsQueryVariables
  >(GetTeachersAssociatedWithSectionsDocument, options);
}
export function useGetTeachersAssociatedWithSectionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetTeachersAssociatedWithSectionsQuery,
    GetTeachersAssociatedWithSectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetTeachersAssociatedWithSectionsQuery,
    GetTeachersAssociatedWithSectionsQueryVariables
  >(GetTeachersAssociatedWithSectionsDocument, options);
}
export type GetTeachersAssociatedWithSectionsQueryHookResult = ReturnType<
  typeof useGetTeachersAssociatedWithSectionsQuery
>;
export type GetTeachersAssociatedWithSectionsLazyQueryHookResult = ReturnType<
  typeof useGetTeachersAssociatedWithSectionsLazyQuery
>;
export type GetTeachersAssociatedWithSectionsQueryResult = ApolloReactCommon.QueryResult<
  GetTeachersAssociatedWithSectionsQuery,
  GetTeachersAssociatedWithSectionsQueryVariables
>;
export const GetStudentDashboardAssignBenchmarksDocument = gql`
  query GetStudentDashboardAssignBenchmarks($input: StudentAssignmentAndBenchmarkInput) {
    getPTDetailsForStudent(input: $input) {
      total_count
      assignments {
        ...StudentDashboardAssignment
      }
      benchmarks {
        ...StudentDashboardBenchmark
      }
    }
  }
  ${StudentDashboardAssignmentFragmentDoc}
  ${StudentDashboardBenchmarkFragmentDoc}
`;

/**
 * __useGetStudentDashboardAssignBenchmarksQuery__
 *
 * To run a query within a React component, call `useGetStudentDashboardAssignBenchmarksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentDashboardAssignBenchmarksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentDashboardAssignBenchmarksQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetStudentDashboardAssignBenchmarksQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetStudentDashboardAssignBenchmarksQuery,
    GetStudentDashboardAssignBenchmarksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetStudentDashboardAssignBenchmarksQuery,
    GetStudentDashboardAssignBenchmarksQueryVariables
  >(GetStudentDashboardAssignBenchmarksDocument, options);
}
export function useGetStudentDashboardAssignBenchmarksLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetStudentDashboardAssignBenchmarksQuery,
    GetStudentDashboardAssignBenchmarksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetStudentDashboardAssignBenchmarksQuery,
    GetStudentDashboardAssignBenchmarksQueryVariables
  >(GetStudentDashboardAssignBenchmarksDocument, options);
}
export type GetStudentDashboardAssignBenchmarksQueryHookResult = ReturnType<
  typeof useGetStudentDashboardAssignBenchmarksQuery
>;
export type GetStudentDashboardAssignBenchmarksLazyQueryHookResult = ReturnType<
  typeof useGetStudentDashboardAssignBenchmarksLazyQuery
>;
export type GetStudentDashboardAssignBenchmarksQueryResult = ApolloReactCommon.QueryResult<
  GetStudentDashboardAssignBenchmarksQuery,
  GetStudentDashboardAssignBenchmarksQueryVariables
>;
export const CreateSubmissionDocument = gql`
  mutation CreateSubmission($assignment_id: String!) {
    createSubmission(assignment_id: $assignment_id)
  }
`;
export type CreateSubmissionMutationFn = ApolloReactCommon.MutationFunction<
  CreateSubmissionMutation,
  CreateSubmissionMutationVariables
>;

/**
 * __useCreateSubmissionMutation__
 *
 * To run a mutation, you first call `useCreateSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubmissionMutation, { data, loading, error }] = useCreateSubmissionMutation({
 *   variables: {
 *      assignment_id: // value for 'assignment_id'
 *   },
 * });
 */
export function useCreateSubmissionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSubmissionMutation, CreateSubmissionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateSubmissionMutation, CreateSubmissionMutationVariables>(
    CreateSubmissionDocument,
    options
  );
}
export type CreateSubmissionMutationHookResult = ReturnType<typeof useCreateSubmissionMutation>;
export type CreateSubmissionMutationResult = ApolloReactCommon.MutationResult<CreateSubmissionMutation>;
export type CreateSubmissionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateSubmissionMutation,
  CreateSubmissionMutationVariables
>;
export const CreateBenchmarkSubmissionDocument = gql`
  mutation CreateBenchmarkSubmission($benchmark_id: String!) {
    createBenchmarkSubmission(benchmark_id: $benchmark_id)
  }
`;
export type CreateBenchmarkSubmissionMutationFn = ApolloReactCommon.MutationFunction<
  CreateBenchmarkSubmissionMutation,
  CreateBenchmarkSubmissionMutationVariables
>;

/**
 * __useCreateBenchmarkSubmissionMutation__
 *
 * To run a mutation, you first call `useCreateBenchmarkSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBenchmarkSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBenchmarkSubmissionMutation, { data, loading, error }] = useCreateBenchmarkSubmissionMutation({
 *   variables: {
 *      benchmark_id: // value for 'benchmark_id'
 *   },
 * });
 */
export function useCreateBenchmarkSubmissionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateBenchmarkSubmissionMutation,
    CreateBenchmarkSubmissionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateBenchmarkSubmissionMutation, CreateBenchmarkSubmissionMutationVariables>(
    CreateBenchmarkSubmissionDocument,
    options
  );
}
export type CreateBenchmarkSubmissionMutationHookResult = ReturnType<typeof useCreateBenchmarkSubmissionMutation>;
export type CreateBenchmarkSubmissionMutationResult =
  ApolloReactCommon.MutationResult<CreateBenchmarkSubmissionMutation>;
export type CreateBenchmarkSubmissionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateBenchmarkSubmissionMutation,
  CreateBenchmarkSubmissionMutationVariables
>;
export const MarkFeedbackViewedDocument = gql`
  mutation MarkFeedbackViewed($submissionIds: [String!]!) {
    markFeedbackViewed(submission_ids: $submissionIds)
  }
`;
export type MarkFeedbackViewedMutationFn = ApolloReactCommon.MutationFunction<
  MarkFeedbackViewedMutation,
  MarkFeedbackViewedMutationVariables
>;

/**
 * __useMarkFeedbackViewedMutation__
 *
 * To run a mutation, you first call `useMarkFeedbackViewedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkFeedbackViewedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markFeedbackViewedMutation, { data, loading, error }] = useMarkFeedbackViewedMutation({
 *   variables: {
 *      submissionIds: // value for 'submissionIds'
 *   },
 * });
 */
export function useMarkFeedbackViewedMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<MarkFeedbackViewedMutation, MarkFeedbackViewedMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<MarkFeedbackViewedMutation, MarkFeedbackViewedMutationVariables>(
    MarkFeedbackViewedDocument,
    options
  );
}
export type MarkFeedbackViewedMutationHookResult = ReturnType<typeof useMarkFeedbackViewedMutation>;
export type MarkFeedbackViewedMutationResult = ApolloReactCommon.MutationResult<MarkFeedbackViewedMutation>;
export type MarkFeedbackViewedMutationOptions = ApolloReactCommon.BaseMutationOptions<
  MarkFeedbackViewedMutation,
  MarkFeedbackViewedMutationVariables
>;
export const GetUnreadFeedbackOfStudentDocument = gql`
  query GetUnreadFeedbackOfStudent($sectionID: ID, $teacherID: ID) {
    unreadFeedbackOfStudent(section_id: $sectionID, teacher_id: $teacherID) {
      ...UnReadFeedbacks
    }
  }
  ${UnReadFeedbacksFragmentDoc}
`;

/**
 * __useGetUnreadFeedbackOfStudentQuery__
 *
 * To run a query within a React component, call `useGetUnreadFeedbackOfStudentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnreadFeedbackOfStudentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnreadFeedbackOfStudentQuery({
 *   variables: {
 *      sectionID: // value for 'sectionID'
 *      teacherID: // value for 'teacherID'
 *   },
 * });
 */
export function useGetUnreadFeedbackOfStudentQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetUnreadFeedbackOfStudentQuery,
    GetUnreadFeedbackOfStudentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetUnreadFeedbackOfStudentQuery, GetUnreadFeedbackOfStudentQueryVariables>(
    GetUnreadFeedbackOfStudentDocument,
    options
  );
}
export function useGetUnreadFeedbackOfStudentLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetUnreadFeedbackOfStudentQuery,
    GetUnreadFeedbackOfStudentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetUnreadFeedbackOfStudentQuery, GetUnreadFeedbackOfStudentQueryVariables>(
    GetUnreadFeedbackOfStudentDocument,
    options
  );
}
export type GetUnreadFeedbackOfStudentQueryHookResult = ReturnType<typeof useGetUnreadFeedbackOfStudentQuery>;
export type GetUnreadFeedbackOfStudentLazyQueryHookResult = ReturnType<typeof useGetUnreadFeedbackOfStudentLazyQuery>;
export type GetUnreadFeedbackOfStudentQueryResult = ApolloReactCommon.QueryResult<
  GetUnreadFeedbackOfStudentQuery,
  GetUnreadFeedbackOfStudentQueryVariables
>;
export const RemoveStudentsFromClassesDocument = gql`
  mutation removeStudentsFromClasses($input: RemoveStudentsFromClassInput!) {
    removeStudentsFromClasses(input: $input)
  }
`;
export type RemoveStudentsFromClassesMutationFn = ApolloReactCommon.MutationFunction<
  RemoveStudentsFromClassesMutation,
  RemoveStudentsFromClassesMutationVariables
>;

/**
 * __useRemoveStudentsFromClassesMutation__
 *
 * To run a mutation, you first call `useRemoveStudentsFromClassesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveStudentsFromClassesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeStudentsFromClassesMutation, { data, loading, error }] = useRemoveStudentsFromClassesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveStudentsFromClassesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RemoveStudentsFromClassesMutation,
    RemoveStudentsFromClassesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<RemoveStudentsFromClassesMutation, RemoveStudentsFromClassesMutationVariables>(
    RemoveStudentsFromClassesDocument,
    options
  );
}
export type RemoveStudentsFromClassesMutationHookResult = ReturnType<typeof useRemoveStudentsFromClassesMutation>;
export type RemoveStudentsFromClassesMutationResult =
  ApolloReactCommon.MutationResult<RemoveStudentsFromClassesMutation>;
export type RemoveStudentsFromClassesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RemoveStudentsFromClassesMutation,
  RemoveStudentsFromClassesMutationVariables
>;
export const GetStudentsWithClassesDocument = gql`
  query getStudentsWithClasses($studentIDs: [ID]) {
    getStudentsWithClasses(studentIDs: $studentIDs) {
      ...StudentsSectionFrag
    }
  }
  ${StudentsSectionFragFragmentDoc}
`;

/**
 * __useGetStudentsWithClassesQuery__
 *
 * To run a query within a React component, call `useGetStudentsWithClassesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentsWithClassesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentsWithClassesQuery({
 *   variables: {
 *      studentIDs: // value for 'studentIDs'
 *   },
 * });
 */
export function useGetStudentsWithClassesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetStudentsWithClassesQuery, GetStudentsWithClassesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetStudentsWithClassesQuery, GetStudentsWithClassesQueryVariables>(
    GetStudentsWithClassesDocument,
    options
  );
}
export function useGetStudentsWithClassesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStudentsWithClassesQuery, GetStudentsWithClassesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetStudentsWithClassesQuery, GetStudentsWithClassesQueryVariables>(
    GetStudentsWithClassesDocument,
    options
  );
}
export type GetStudentsWithClassesQueryHookResult = ReturnType<typeof useGetStudentsWithClassesQuery>;
export type GetStudentsWithClassesLazyQueryHookResult = ReturnType<typeof useGetStudentsWithClassesLazyQuery>;
export type GetStudentsWithClassesQueryResult = ApolloReactCommon.QueryResult<
  GetStudentsWithClassesQuery,
  GetStudentsWithClassesQueryVariables
>;
export const ViewStudentDocument = gql`
  query ViewStudent($id: String!) {
    getAllAssignmentsAndBenchmarksForStudent {
      score_details {
        score_value
        color_code
      }
      total_count
      assignments {
        start_at
        close_at
        id
        title
        section_id
        section_name
        submission_id
        submission_status
        is_common_prompt
        resource_id
        resource_url
        thumbnail_url
        section_id
        section_name
        submission_id
        submission_status
        teacher_user_id
        teachers
        speaking_prompt_url
        writing_prompt_title
        writing_prompt_url
        is_submissions_started
        back_navigation_allowed
        speaking_prompt_title
        submission_speaking_average_score
        submission_writing_average_score
        submitted_at
      }
    }
  }
`;

/**
 * __useViewStudentQuery__
 *
 * To run a query within a React component, call `useViewStudentQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewStudentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewStudentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useViewStudentQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<ViewStudentQuery, ViewStudentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ViewStudentQuery, ViewStudentQueryVariables>(ViewStudentDocument, options);
}
export function useViewStudentLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ViewStudentQuery, ViewStudentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ViewStudentQuery, ViewStudentQueryVariables>(ViewStudentDocument, options);
}
export type ViewStudentQueryHookResult = ReturnType<typeof useViewStudentQuery>;
export type ViewStudentLazyQueryHookResult = ReturnType<typeof useViewStudentLazyQuery>;
export type ViewStudentQueryResult = ApolloReactCommon.QueryResult<ViewStudentQuery, ViewStudentQueryVariables>;
export const StudentDetailDocument = gql`
  query StudentDetail($id: ID!) {
    student(id: $id) {
      id
      user {
        first_name
        middle_name
        last_name
      }
      student_number
      grade
      ell_status
    }
  }
`;

/**
 * __useStudentDetailQuery__
 *
 * To run a query within a React component, call `useStudentDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStudentDetailQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<StudentDetailQuery, StudentDetailQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<StudentDetailQuery, StudentDetailQueryVariables>(StudentDetailDocument, options);
}
export function useStudentDetailLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StudentDetailQuery, StudentDetailQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<StudentDetailQuery, StudentDetailQueryVariables>(StudentDetailDocument, options);
}
export type StudentDetailQueryHookResult = ReturnType<typeof useStudentDetailQuery>;
export type StudentDetailLazyQueryHookResult = ReturnType<typeof useStudentDetailLazyQuery>;
export type StudentDetailQueryResult = ApolloReactCommon.QueryResult<StudentDetailQuery, StudentDetailQueryVariables>;
export const GetGoalsDocument = gql`
  query GetGoals($goalType: GoalType, $submissionID: ID, $studentID: ID, $sectionID: ID, $assignmentID: ID) {
    getGoalsByStudentID(
      target_submission_id: $goalType
      submission_id: $submissionID
      assignment_id: $assignmentID
      section_id: $sectionID
      student_id: $studentID
    ) {
      ...StudentGoals
    }
  }
  ${StudentGoalsFragmentDoc}
`;

/**
 * __useGetGoalsQuery__
 *
 * To run a query within a React component, call `useGetGoalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGoalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGoalsQuery({
 *   variables: {
 *      goalType: // value for 'goalType'
 *      submissionID: // value for 'submissionID'
 *      studentID: // value for 'studentID'
 *      sectionID: // value for 'sectionID'
 *      assignmentID: // value for 'assignmentID'
 *   },
 * });
 */
export function useGetGoalsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetGoalsQuery, GetGoalsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetGoalsQuery, GetGoalsQueryVariables>(GetGoalsDocument, options);
}
export function useGetGoalsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGoalsQuery, GetGoalsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetGoalsQuery, GetGoalsQueryVariables>(GetGoalsDocument, options);
}
export type GetGoalsQueryHookResult = ReturnType<typeof useGetGoalsQuery>;
export type GetGoalsLazyQueryHookResult = ReturnType<typeof useGetGoalsLazyQuery>;
export type GetGoalsQueryResult = ApolloReactCommon.QueryResult<GetGoalsQuery, GetGoalsQueryVariables>;
export const GetStudentScoreSummaryDocument = gql`
  query GetStudentScoreSummary($assignmentIDs: [ID!], $studentID: ID!, $sectionIDs: [ID!]) {
    studentScoreSummary(assignmentIDs: $assignmentIDs, studentID: $studentID, sectionIDs: $sectionIDs) {
      ...StudentScoreSummary
    }
  }
  ${StudentScoreSummaryFragmentDoc}
`;

/**
 * __useGetStudentScoreSummaryQuery__
 *
 * To run a query within a React component, call `useGetStudentScoreSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentScoreSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentScoreSummaryQuery({
 *   variables: {
 *      assignmentIDs: // value for 'assignmentIDs'
 *      studentID: // value for 'studentID'
 *      sectionIDs: // value for 'sectionIDs'
 *   },
 * });
 */
export function useGetStudentScoreSummaryQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetStudentScoreSummaryQuery, GetStudentScoreSummaryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetStudentScoreSummaryQuery, GetStudentScoreSummaryQueryVariables>(
    GetStudentScoreSummaryDocument,
    options
  );
}
export function useGetStudentScoreSummaryLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStudentScoreSummaryQuery, GetStudentScoreSummaryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetStudentScoreSummaryQuery, GetStudentScoreSummaryQueryVariables>(
    GetStudentScoreSummaryDocument,
    options
  );
}
export type GetStudentScoreSummaryQueryHookResult = ReturnType<typeof useGetStudentScoreSummaryQuery>;
export type GetStudentScoreSummaryLazyQueryHookResult = ReturnType<typeof useGetStudentScoreSummaryLazyQuery>;
export type GetStudentScoreSummaryQueryResult = ApolloReactCommon.QueryResult<
  GetStudentScoreSummaryQuery,
  GetStudentScoreSummaryQueryVariables
>;
export const GetAllPtStudentSubmissionsForTeacherDocument = gql`
  query GetAllPTStudentSubmissionsForTeacher(
    $student_id: String
    $section_id: String
    $assignment_id: String
    $sort: AssignmentSubmissionsForTeacherSortType
    $order_by: OrderBy
  ) {
    getAllAssignmentSubmissionsForTeacher(
      input: {
        student_id: $student_id
        section_id: $section_id
        assignment_id: $assignment_id
        sort: $sort
        order_by: $order_by
      }
    ) {
      total_count
      assignments {
        assignment_id
        assignment_title
        assignment_assigned_at
        submission_id
        submission_status
        submission_speaking_average_score
        submission_writing_average_score
        submission_submitted_at
      }
    }
  }
`;

/**
 * __useGetAllPtStudentSubmissionsForTeacherQuery__
 *
 * To run a query within a React component, call `useGetAllPtStudentSubmissionsForTeacherQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPtStudentSubmissionsForTeacherQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPtStudentSubmissionsForTeacherQuery({
 *   variables: {
 *      student_id: // value for 'student_id'
 *      section_id: // value for 'section_id'
 *      assignment_id: // value for 'assignment_id'
 *      sort: // value for 'sort'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useGetAllPtStudentSubmissionsForTeacherQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetAllPtStudentSubmissionsForTeacherQuery,
    GetAllPtStudentSubmissionsForTeacherQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetAllPtStudentSubmissionsForTeacherQuery,
    GetAllPtStudentSubmissionsForTeacherQueryVariables
  >(GetAllPtStudentSubmissionsForTeacherDocument, options);
}
export function useGetAllPtStudentSubmissionsForTeacherLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetAllPtStudentSubmissionsForTeacherQuery,
    GetAllPtStudentSubmissionsForTeacherQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetAllPtStudentSubmissionsForTeacherQuery,
    GetAllPtStudentSubmissionsForTeacherQueryVariables
  >(GetAllPtStudentSubmissionsForTeacherDocument, options);
}
export type GetAllPtStudentSubmissionsForTeacherQueryHookResult = ReturnType<
  typeof useGetAllPtStudentSubmissionsForTeacherQuery
>;
export type GetAllPtStudentSubmissionsForTeacherLazyQueryHookResult = ReturnType<
  typeof useGetAllPtStudentSubmissionsForTeacherLazyQuery
>;
export type GetAllPtStudentSubmissionsForTeacherQueryResult = ApolloReactCommon.QueryResult<
  GetAllPtStudentSubmissionsForTeacherQuery,
  GetAllPtStudentSubmissionsForTeacherQueryVariables
>;
export const PrimarySectionsOfTeacherDocument = gql`
  query PrimarySectionsOfTeacher($schoolID: String, $classTypes: [SectionCategory!]) {
    primarySectionsOfTeacher(school_id: $schoolID, class_types: $classTypes) {
      ...PrimarySectionsFilter
    }
  }
  ${PrimarySectionsFilterFragmentDoc}
`;

/**
 * __usePrimarySectionsOfTeacherQuery__
 *
 * To run a query within a React component, call `usePrimarySectionsOfTeacherQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrimarySectionsOfTeacherQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrimarySectionsOfTeacherQuery({
 *   variables: {
 *      schoolID: // value for 'schoolID'
 *      classTypes: // value for 'classTypes'
 *   },
 * });
 */
export function usePrimarySectionsOfTeacherQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<PrimarySectionsOfTeacherQuery, PrimarySectionsOfTeacherQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<PrimarySectionsOfTeacherQuery, PrimarySectionsOfTeacherQueryVariables>(
    PrimarySectionsOfTeacherDocument,
    options
  );
}
export function usePrimarySectionsOfTeacherLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PrimarySectionsOfTeacherQuery,
    PrimarySectionsOfTeacherQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<PrimarySectionsOfTeacherQuery, PrimarySectionsOfTeacherQueryVariables>(
    PrimarySectionsOfTeacherDocument,
    options
  );
}
export type PrimarySectionsOfTeacherQueryHookResult = ReturnType<typeof usePrimarySectionsOfTeacherQuery>;
export type PrimarySectionsOfTeacherLazyQueryHookResult = ReturnType<typeof usePrimarySectionsOfTeacherLazyQuery>;
export type PrimarySectionsOfTeacherQueryResult = ApolloReactCommon.QueryResult<
  PrimarySectionsOfTeacherQuery,
  PrimarySectionsOfTeacherQueryVariables
>;
export const SearchStudentDocument = gql`
  query SearchStudent($limit: Int, $orderBy: OrderBy, $sort: StudentListOfTeacherSortType, $search: String) {
    getAllStudentsOfTeacher(limit: $limit, orderBy: $orderBy, sort: $sort, search: $search) {
      ...SearchStudentFragment
    }
  }
  ${SearchStudentFragmentFragmentDoc}
`;

/**
 * __useSearchStudentQuery__
 *
 * To run a query within a React component, call `useSearchStudentQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchStudentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchStudentQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      orderBy: // value for 'orderBy'
 *      sort: // value for 'sort'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSearchStudentQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<SearchStudentQuery, SearchStudentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<SearchStudentQuery, SearchStudentQueryVariables>(SearchStudentDocument, options);
}
export function useSearchStudentLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchStudentQuery, SearchStudentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<SearchStudentQuery, SearchStudentQueryVariables>(SearchStudentDocument, options);
}
export type SearchStudentQueryHookResult = ReturnType<typeof useSearchStudentQuery>;
export type SearchStudentLazyQueryHookResult = ReturnType<typeof useSearchStudentLazyQuery>;
export type SearchStudentQueryResult = ApolloReactCommon.QueryResult<SearchStudentQuery, SearchStudentQueryVariables>;
export const SchoolPredefinedGradesMappingDocument = gql`
  query SchoolPredefinedGradesMapping($schoolID: String!) {
    schoolPredefinedGrades(school_id: $schoolID) {
      id
      school_id
      predefined_grade_id
      predefined_grades {
        id
        grade
        position
      }
    }
  }
`;

/**
 * __useSchoolPredefinedGradesMappingQuery__
 *
 * To run a query within a React component, call `useSchoolPredefinedGradesMappingQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolPredefinedGradesMappingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolPredefinedGradesMappingQuery({
 *   variables: {
 *      schoolID: // value for 'schoolID'
 *   },
 * });
 */
export function useSchoolPredefinedGradesMappingQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    SchoolPredefinedGradesMappingQuery,
    SchoolPredefinedGradesMappingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<SchoolPredefinedGradesMappingQuery, SchoolPredefinedGradesMappingQueryVariables>(
    SchoolPredefinedGradesMappingDocument,
    options
  );
}
export function useSchoolPredefinedGradesMappingLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SchoolPredefinedGradesMappingQuery,
    SchoolPredefinedGradesMappingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<SchoolPredefinedGradesMappingQuery, SchoolPredefinedGradesMappingQueryVariables>(
    SchoolPredefinedGradesMappingDocument,
    options
  );
}
export type SchoolPredefinedGradesMappingQueryHookResult = ReturnType<typeof useSchoolPredefinedGradesMappingQuery>;
export type SchoolPredefinedGradesMappingLazyQueryHookResult = ReturnType<
  typeof useSchoolPredefinedGradesMappingLazyQuery
>;
export type SchoolPredefinedGradesMappingQueryResult = ApolloReactCommon.QueryResult<
  SchoolPredefinedGradesMappingQuery,
  SchoolPredefinedGradesMappingQueryVariables
>;
export const StudentsDocument = gql`
  query Students(
    $sectionID: ID
    $page: Int
    $limit: Int
    $sort: String
    $orderBy: OrderBy
    $search: String
    $schoolID: ID
  ) {
    students(
      section_id: $sectionID
      page: $page
      limit: $limit
      sort: $sort
      orderBy: $orderBy
      search: $search
      school_id: $schoolID
    ) {
      totalCount
      nodes {
        ...StudentsFrag
      }
    }
  }
  ${StudentsFragFragmentDoc}
`;

/**
 * __useStudentsQuery__
 *
 * To run a query within a React component, call `useStudentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentsQuery({
 *   variables: {
 *      sectionID: // value for 'sectionID'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *      orderBy: // value for 'orderBy'
 *      search: // value for 'search'
 *      schoolID: // value for 'schoolID'
 *   },
 * });
 */
export function useStudentsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<StudentsQuery, StudentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<StudentsQuery, StudentsQueryVariables>(StudentsDocument, options);
}
export function useStudentsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StudentsQuery, StudentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<StudentsQuery, StudentsQueryVariables>(StudentsDocument, options);
}
export type StudentsQueryHookResult = ReturnType<typeof useStudentsQuery>;
export type StudentsLazyQueryHookResult = ReturnType<typeof useStudentsLazyQuery>;
export type StudentsQueryResult = ApolloReactCommon.QueryResult<StudentsQuery, StudentsQueryVariables>;
export const GetTeacherByUserDocument = gql`
  query GetTeacherByUser($userID: ID!) {
    teacherByUser(userID: $userID) {
      ...TeacherByUserIDFragment
    }
  }
  ${TeacherByUserIdFragmentFragmentDoc}
`;

/**
 * __useGetTeacherByUserQuery__
 *
 * To run a query within a React component, call `useGetTeacherByUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeacherByUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeacherByUserQuery({
 *   variables: {
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useGetTeacherByUserQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetTeacherByUserQuery, GetTeacherByUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetTeacherByUserQuery, GetTeacherByUserQueryVariables>(
    GetTeacherByUserDocument,
    options
  );
}
export function useGetTeacherByUserLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTeacherByUserQuery, GetTeacherByUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetTeacherByUserQuery, GetTeacherByUserQueryVariables>(
    GetTeacherByUserDocument,
    options
  );
}
export type GetTeacherByUserQueryHookResult = ReturnType<typeof useGetTeacherByUserQuery>;
export type GetTeacherByUserLazyQueryHookResult = ReturnType<typeof useGetTeacherByUserLazyQuery>;
export type GetTeacherByUserQueryResult = ApolloReactCommon.QueryResult<
  GetTeacherByUserQuery,
  GetTeacherByUserQueryVariables
>;
export const UpdateSpeakingDocument = gql`
  mutation UpdateSpeaking($input: UpdateSpeakingInput!) {
    updateSpeaking(input: $input) {
      status
      speakings {
        url
        file_type
      }
    }
  }
`;
export type UpdateSpeakingMutationFn = ApolloReactCommon.MutationFunction<
  UpdateSpeakingMutation,
  UpdateSpeakingMutationVariables
>;

/**
 * __useUpdateSpeakingMutation__
 *
 * To run a mutation, you first call `useUpdateSpeakingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSpeakingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSpeakingMutation, { data, loading, error }] = useUpdateSpeakingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSpeakingMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSpeakingMutation, UpdateSpeakingMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateSpeakingMutation, UpdateSpeakingMutationVariables>(
    UpdateSpeakingDocument,
    options
  );
}
export type UpdateSpeakingMutationHookResult = ReturnType<typeof useUpdateSpeakingMutation>;
export type UpdateSpeakingMutationResult = ApolloReactCommon.MutationResult<UpdateSpeakingMutation>;
export type UpdateSpeakingMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateSpeakingMutation,
  UpdateSpeakingMutationVariables
>;
export const UpdateSpeakingCompleteDocument = gql`
  mutation UpdateSpeakingComplete($id: ID!) {
    updateSpeakingComplete(id: $id) {
      status
    }
  }
`;
export type UpdateSpeakingCompleteMutationFn = ApolloReactCommon.MutationFunction<
  UpdateSpeakingCompleteMutation,
  UpdateSpeakingCompleteMutationVariables
>;

/**
 * __useUpdateSpeakingCompleteMutation__
 *
 * To run a mutation, you first call `useUpdateSpeakingCompleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSpeakingCompleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSpeakingCompleteMutation, { data, loading, error }] = useUpdateSpeakingCompleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateSpeakingCompleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateSpeakingCompleteMutation,
    UpdateSpeakingCompleteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateSpeakingCompleteMutation, UpdateSpeakingCompleteMutationVariables>(
    UpdateSpeakingCompleteDocument,
    options
  );
}
export type UpdateSpeakingCompleteMutationHookResult = ReturnType<typeof useUpdateSpeakingCompleteMutation>;
export type UpdateSpeakingCompleteMutationResult = ApolloReactCommon.MutationResult<UpdateSpeakingCompleteMutation>;
export type UpdateSpeakingCompleteMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateSpeakingCompleteMutation,
  UpdateSpeakingCompleteMutationVariables
>;
export const CreateOrSkipAnnotationsDocument = gql`
  mutation CreateOrSkipAnnotations($annotations: [CreateAnnotationsInput!], $submission_id: String!) {
    createOrSkipAnnotations(annotations: $annotations, submission_id: $submission_id) {
      id
    }
  }
`;
export type CreateOrSkipAnnotationsMutationFn = ApolloReactCommon.MutationFunction<
  CreateOrSkipAnnotationsMutation,
  CreateOrSkipAnnotationsMutationVariables
>;

/**
 * __useCreateOrSkipAnnotationsMutation__
 *
 * To run a mutation, you first call `useCreateOrSkipAnnotationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrSkipAnnotationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrSkipAnnotationsMutation, { data, loading, error }] = useCreateOrSkipAnnotationsMutation({
 *   variables: {
 *      annotations: // value for 'annotations'
 *      submission_id: // value for 'submission_id'
 *   },
 * });
 */
export function useCreateOrSkipAnnotationsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateOrSkipAnnotationsMutation,
    CreateOrSkipAnnotationsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateOrSkipAnnotationsMutation, CreateOrSkipAnnotationsMutationVariables>(
    CreateOrSkipAnnotationsDocument,
    options
  );
}
export type CreateOrSkipAnnotationsMutationHookResult = ReturnType<typeof useCreateOrSkipAnnotationsMutation>;
export type CreateOrSkipAnnotationsMutationResult = ApolloReactCommon.MutationResult<CreateOrSkipAnnotationsMutation>;
export type CreateOrSkipAnnotationsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateOrSkipAnnotationsMutation,
  CreateOrSkipAnnotationsMutationVariables
>;
export const UpdateWritingDocument = gql`
  mutation UpdateWriting($id: String!, $writing: String) {
    updateWriting(id: $id, writing: $writing) {
      id
      status
    }
  }
`;
export type UpdateWritingMutationFn = ApolloReactCommon.MutationFunction<
  UpdateWritingMutation,
  UpdateWritingMutationVariables
>;

/**
 * __useUpdateWritingMutation__
 *
 * To run a mutation, you first call `useUpdateWritingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWritingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWritingMutation, { data, loading, error }] = useUpdateWritingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      writing: // value for 'writing'
 *   },
 * });
 */
export function useUpdateWritingMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateWritingMutation, UpdateWritingMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateWritingMutation, UpdateWritingMutationVariables>(
    UpdateWritingDocument,
    options
  );
}
export type UpdateWritingMutationHookResult = ReturnType<typeof useUpdateWritingMutation>;
export type UpdateWritingMutationResult = ApolloReactCommon.MutationResult<UpdateWritingMutation>;
export type UpdateWritingMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateWritingMutation,
  UpdateWritingMutationVariables
>;
export const UpdateSubmissionDocument = gql`
  mutation UpdateSubmission($id: ID!, $input: SubmissionUpdateInput!) {
    updateSubmission(id: $id, input: $input) {
      id
    }
  }
`;
export type UpdateSubmissionMutationFn = ApolloReactCommon.MutationFunction<
  UpdateSubmissionMutation,
  UpdateSubmissionMutationVariables
>;

/**
 * __useUpdateSubmissionMutation__
 *
 * To run a mutation, you first call `useUpdateSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubmissionMutation, { data, loading, error }] = useUpdateSubmissionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSubmissionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSubmissionMutation, UpdateSubmissionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateSubmissionMutation, UpdateSubmissionMutationVariables>(
    UpdateSubmissionDocument,
    options
  );
}
export type UpdateSubmissionMutationHookResult = ReturnType<typeof useUpdateSubmissionMutation>;
export type UpdateSubmissionMutationResult = ApolloReactCommon.MutationResult<UpdateSubmissionMutation>;
export type UpdateSubmissionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateSubmissionMutation,
  UpdateSubmissionMutationVariables
>;
export const GetSubmissionDocument = gql`
  query GetSubmission($id: ID!) {
    submission(id: $id) {
      ...StudentSubmissionFragment
    }
  }
  ${StudentSubmissionFragmentFragmentDoc}
`;

/**
 * __useGetSubmissionQuery__
 *
 * To run a query within a React component, call `useGetSubmissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubmissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubmissionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSubmissionQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetSubmissionQuery, GetSubmissionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetSubmissionQuery, GetSubmissionQueryVariables>(GetSubmissionDocument, options);
}
export function useGetSubmissionLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubmissionQuery, GetSubmissionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetSubmissionQuery, GetSubmissionQueryVariables>(GetSubmissionDocument, options);
}
export type GetSubmissionQueryHookResult = ReturnType<typeof useGetSubmissionQuery>;
export type GetSubmissionLazyQueryHookResult = ReturnType<typeof useGetSubmissionLazyQuery>;
export type GetSubmissionQueryResult = ApolloReactCommon.QueryResult<GetSubmissionQuery, GetSubmissionQueryVariables>;
export const TeacherBenchmarkGradesListDocument = gql`
  query TeacherBenchmarkGradesList(
    $grade_statuses: [SubmissionStatus!]
    $benchmark_types: [BenchmarkType!]
    $page: Int
    $limit: Int
    $sort: TeacherBenchmarkSubmissionListSortType
    $order_by: OrderBy
    $graded_by: [BenchmarkGradedBy!]
  ) {
    teacherBenchmarkSubmissionList(
      input: {
        submission_statuses: $grade_statuses
        benchmark_types: $benchmark_types
        page: $page
        limit: $limit
        sort: $sort
        order_by: $order_by
        graded_by: $graded_by
      }
    ) {
      node_count
      nodes {
        id
        status
        user_first_name
        user_last_name
        benchmark_id
        benchmark_title
        benchmark_type
        benchmark_image_url
        benchmark_thumbnail_image_url
        submitted_at
        student_school_id
        student_school_title
        graded_by
      }
    }
  }
`;

/**
 * __useTeacherBenchmarkGradesListQuery__
 *
 * To run a query within a React component, call `useTeacherBenchmarkGradesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeacherBenchmarkGradesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeacherBenchmarkGradesListQuery({
 *   variables: {
 *      grade_statuses: // value for 'grade_statuses'
 *      benchmark_types: // value for 'benchmark_types'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *      order_by: // value for 'order_by'
 *      graded_by: // value for 'graded_by'
 *   },
 * });
 */
export function useTeacherBenchmarkGradesListQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    TeacherBenchmarkGradesListQuery,
    TeacherBenchmarkGradesListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<TeacherBenchmarkGradesListQuery, TeacherBenchmarkGradesListQueryVariables>(
    TeacherBenchmarkGradesListDocument,
    options
  );
}
export function useTeacherBenchmarkGradesListLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    TeacherBenchmarkGradesListQuery,
    TeacherBenchmarkGradesListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<TeacherBenchmarkGradesListQuery, TeacherBenchmarkGradesListQueryVariables>(
    TeacherBenchmarkGradesListDocument,
    options
  );
}
export type TeacherBenchmarkGradesListQueryHookResult = ReturnType<typeof useTeacherBenchmarkGradesListQuery>;
export type TeacherBenchmarkGradesListLazyQueryHookResult = ReturnType<typeof useTeacherBenchmarkGradesListLazyQuery>;
export type TeacherBenchmarkGradesListQueryResult = ApolloReactCommon.QueryResult<
  TeacherBenchmarkGradesListQuery,
  TeacherBenchmarkGradesListQueryVariables
>;
export const TeacherBmStudentsSlideoutDocument = gql`
  query teacherBMStudentsSlideout($benchmarkId: String!, $status: [SubmissionStatus!]) {
    teacherBenchmarkSlideOut(input: { benchmark_id: $benchmarkId, status_list: $status }) {
      total_count
      nodes {
        first_name
        last_name
        submission_id
        submission_status
        student_number
      }
    }
  }
`;

/**
 * __useTeacherBmStudentsSlideoutQuery__
 *
 * To run a query within a React component, call `useTeacherBmStudentsSlideoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeacherBmStudentsSlideoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeacherBmStudentsSlideoutQuery({
 *   variables: {
 *      benchmarkId: // value for 'benchmarkId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useTeacherBmStudentsSlideoutQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    TeacherBmStudentsSlideoutQuery,
    TeacherBmStudentsSlideoutQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<TeacherBmStudentsSlideoutQuery, TeacherBmStudentsSlideoutQueryVariables>(
    TeacherBmStudentsSlideoutDocument,
    options
  );
}
export function useTeacherBmStudentsSlideoutLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    TeacherBmStudentsSlideoutQuery,
    TeacherBmStudentsSlideoutQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<TeacherBmStudentsSlideoutQuery, TeacherBmStudentsSlideoutQueryVariables>(
    TeacherBmStudentsSlideoutDocument,
    options
  );
}
export type TeacherBmStudentsSlideoutQueryHookResult = ReturnType<typeof useTeacherBmStudentsSlideoutQuery>;
export type TeacherBmStudentsSlideoutLazyQueryHookResult = ReturnType<typeof useTeacherBmStudentsSlideoutLazyQuery>;
export type TeacherBmStudentsSlideoutQueryResult = ApolloReactCommon.QueryResult<
  TeacherBmStudentsSlideoutQuery,
  TeacherBmStudentsSlideoutQueryVariables
>;
export const TeacherBenchmarksListDocument = gql`
  query TeacherBenchmarksList(
    $startAt: DateQuery
    $closeAt: DateQuery
    $page: Int
    $limit: Int
    $sort: BenchmarkTeacherDTOSortType
    $orderBy: OrderBy
  ) {
    teacherBenchmarks(
      start_at: $startAt
      close_at: $closeAt
      page: $page
      limit: $limit
      sort: $sort
      order_by: $orderBy
    ) {
      total_count
      nodes {
        benchmark_id
        resource {
          id
          file {
            url
            thumbnail_url
          }
        }
        benchmark_title
        start_date
        close_date
        benchmark_type
        graded_by
        status_aggregation {
          Submitted
          InProgress
          NotStarted
        }
      }
    }
  }
`;

/**
 * __useTeacherBenchmarksListQuery__
 *
 * To run a query within a React component, call `useTeacherBenchmarksListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeacherBenchmarksListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeacherBenchmarksListQuery({
 *   variables: {
 *      startAt: // value for 'startAt'
 *      closeAt: // value for 'closeAt'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useTeacherBenchmarksListQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<TeacherBenchmarksListQuery, TeacherBenchmarksListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<TeacherBenchmarksListQuery, TeacherBenchmarksListQueryVariables>(
    TeacherBenchmarksListDocument,
    options
  );
}
export function useTeacherBenchmarksListLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeacherBenchmarksListQuery, TeacherBenchmarksListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<TeacherBenchmarksListQuery, TeacherBenchmarksListQueryVariables>(
    TeacherBenchmarksListDocument,
    options
  );
}
export type TeacherBenchmarksListQueryHookResult = ReturnType<typeof useTeacherBenchmarksListQuery>;
export type TeacherBenchmarksListLazyQueryHookResult = ReturnType<typeof useTeacherBenchmarksListLazyQuery>;
export type TeacherBenchmarksListQueryResult = ApolloReactCommon.QueryResult<
  TeacherBenchmarksListQuery,
  TeacherBenchmarksListQueryVariables
>;
export const OverallBenchmarkIndicatorScoresDocument = gql`
  query OverallBenchmarkIndicatorScores($input: OverallBenchmarkIndicatorInput!) {
    overallBenchmarkIndicatorScores(input: $input) {
      submission_type
      rubric_line_items {
        rubric_id
        rubric_title
        rubric_icon
        rubric_score_color
        rubric_score_average
      }
    }
  }
`;

/**
 * __useOverallBenchmarkIndicatorScoresQuery__
 *
 * To run a query within a React component, call `useOverallBenchmarkIndicatorScoresQuery` and pass it any options that fit your needs.
 * When your component renders, `useOverallBenchmarkIndicatorScoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOverallBenchmarkIndicatorScoresQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOverallBenchmarkIndicatorScoresQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    OverallBenchmarkIndicatorScoresQuery,
    OverallBenchmarkIndicatorScoresQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<OverallBenchmarkIndicatorScoresQuery, OverallBenchmarkIndicatorScoresQueryVariables>(
    OverallBenchmarkIndicatorScoresDocument,
    options
  );
}
export function useOverallBenchmarkIndicatorScoresLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OverallBenchmarkIndicatorScoresQuery,
    OverallBenchmarkIndicatorScoresQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OverallBenchmarkIndicatorScoresQuery,
    OverallBenchmarkIndicatorScoresQueryVariables
  >(OverallBenchmarkIndicatorScoresDocument, options);
}
export type OverallBenchmarkIndicatorScoresQueryHookResult = ReturnType<typeof useOverallBenchmarkIndicatorScoresQuery>;
export type OverallBenchmarkIndicatorScoresLazyQueryHookResult = ReturnType<
  typeof useOverallBenchmarkIndicatorScoresLazyQuery
>;
export type OverallBenchmarkIndicatorScoresQueryResult = ApolloReactCommon.QueryResult<
  OverallBenchmarkIndicatorScoresQuery,
  OverallBenchmarkIndicatorScoresQueryVariables
>;
export const OverallPerformanceLevelSlideOutDocument = gql`
  query overallPerformanceLevelSlideOut($input: OverallPerformanceLevelSlideOutInput!) {
    overallPerformanceLevelSlideOut(input: $input) {
      total_count
      slideout_line_items {
        student_id
        student_user_id
        student_first_name
        student_last_name
        student_average_score
        score_level_color
      }
    }
  }
`;

/**
 * __useOverallPerformanceLevelSlideOutQuery__
 *
 * To run a query within a React component, call `useOverallPerformanceLevelSlideOutQuery` and pass it any options that fit your needs.
 * When your component renders, `useOverallPerformanceLevelSlideOutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOverallPerformanceLevelSlideOutQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOverallPerformanceLevelSlideOutQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    OverallPerformanceLevelSlideOutQuery,
    OverallPerformanceLevelSlideOutQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<OverallPerformanceLevelSlideOutQuery, OverallPerformanceLevelSlideOutQueryVariables>(
    OverallPerformanceLevelSlideOutDocument,
    options
  );
}
export function useOverallPerformanceLevelSlideOutLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OverallPerformanceLevelSlideOutQuery,
    OverallPerformanceLevelSlideOutQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OverallPerformanceLevelSlideOutQuery,
    OverallPerformanceLevelSlideOutQueryVariables
  >(OverallPerformanceLevelSlideOutDocument, options);
}
export type OverallPerformanceLevelSlideOutQueryHookResult = ReturnType<typeof useOverallPerformanceLevelSlideOutQuery>;
export type OverallPerformanceLevelSlideOutLazyQueryHookResult = ReturnType<
  typeof useOverallPerformanceLevelSlideOutLazyQuery
>;
export type OverallPerformanceLevelSlideOutQueryResult = ApolloReactCommon.QueryResult<
  OverallPerformanceLevelSlideOutQuery,
  OverallPerformanceLevelSlideOutQueryVariables
>;
export const OverallPerformanceLevelDocument = gql`
  query overallPerformanceLevel($input: OverallPerformanceLevelInput!) {
    overallPerformanceLevel(input: $input) {
      submission_type
      average_score
      score_line_items {
        score_level_id
        score_level_title
        score_level_abbr
        score_level_color
        student_count
      }
    }
  }
`;

/**
 * __useOverallPerformanceLevelQuery__
 *
 * To run a query within a React component, call `useOverallPerformanceLevelQuery` and pass it any options that fit your needs.
 * When your component renders, `useOverallPerformanceLevelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOverallPerformanceLevelQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOverallPerformanceLevelQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<OverallPerformanceLevelQuery, OverallPerformanceLevelQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<OverallPerformanceLevelQuery, OverallPerformanceLevelQueryVariables>(
    OverallPerformanceLevelDocument,
    options
  );
}
export function useOverallPerformanceLevelLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OverallPerformanceLevelQuery,
    OverallPerformanceLevelQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<OverallPerformanceLevelQuery, OverallPerformanceLevelQueryVariables>(
    OverallPerformanceLevelDocument,
    options
  );
}
export type OverallPerformanceLevelQueryHookResult = ReturnType<typeof useOverallPerformanceLevelQuery>;
export type OverallPerformanceLevelLazyQueryHookResult = ReturnType<typeof useOverallPerformanceLevelLazyQuery>;
export type OverallPerformanceLevelQueryResult = ApolloReactCommon.QueryResult<
  OverallPerformanceLevelQuery,
  OverallPerformanceLevelQueryVariables
>;
export const SubmissionStatusSlideoutDocument = gql`
  query submissionStatusSlideout($input: SubmissionStatusSlideoutDataInput!) {
    submissionStatusSlideout(input: $input) {
      total_count
      slideout_line_items {
        student_id
        student_user_id
        student_first_name
        student_last_name
        submission_status
      }
    }
  }
`;

/**
 * __useSubmissionStatusSlideoutQuery__
 *
 * To run a query within a React component, call `useSubmissionStatusSlideoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubmissionStatusSlideoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubmissionStatusSlideoutQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmissionStatusSlideoutQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<SubmissionStatusSlideoutQuery, SubmissionStatusSlideoutQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<SubmissionStatusSlideoutQuery, SubmissionStatusSlideoutQueryVariables>(
    SubmissionStatusSlideoutDocument,
    options
  );
}
export function useSubmissionStatusSlideoutLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SubmissionStatusSlideoutQuery,
    SubmissionStatusSlideoutQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<SubmissionStatusSlideoutQuery, SubmissionStatusSlideoutQueryVariables>(
    SubmissionStatusSlideoutDocument,
    options
  );
}
export type SubmissionStatusSlideoutQueryHookResult = ReturnType<typeof useSubmissionStatusSlideoutQuery>;
export type SubmissionStatusSlideoutLazyQueryHookResult = ReturnType<typeof useSubmissionStatusSlideoutLazyQuery>;
export type SubmissionStatusSlideoutQueryResult = ApolloReactCommon.QueryResult<
  SubmissionStatusSlideoutQuery,
  SubmissionStatusSlideoutQueryVariables
>;
export const BenchmarkSubmissionStatusDocument = gql`
  query BenchmarkSubmissionStatus($input: SubmissionStatusDataInput!) {
    submissionStatus(input: $input) {
      total_submissions
      submission_line_items {
        submission_status
        student_count
      }
    }
  }
`;

/**
 * __useBenchmarkSubmissionStatusQuery__
 *
 * To run a query within a React component, call `useBenchmarkSubmissionStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useBenchmarkSubmissionStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBenchmarkSubmissionStatusQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBenchmarkSubmissionStatusQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    BenchmarkSubmissionStatusQuery,
    BenchmarkSubmissionStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<BenchmarkSubmissionStatusQuery, BenchmarkSubmissionStatusQueryVariables>(
    BenchmarkSubmissionStatusDocument,
    options
  );
}
export function useBenchmarkSubmissionStatusLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    BenchmarkSubmissionStatusQuery,
    BenchmarkSubmissionStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<BenchmarkSubmissionStatusQuery, BenchmarkSubmissionStatusQueryVariables>(
    BenchmarkSubmissionStatusDocument,
    options
  );
}
export type BenchmarkSubmissionStatusQueryHookResult = ReturnType<typeof useBenchmarkSubmissionStatusQuery>;
export type BenchmarkSubmissionStatusLazyQueryHookResult = ReturnType<typeof useBenchmarkSubmissionStatusLazyQuery>;
export type BenchmarkSubmissionStatusQueryResult = ApolloReactCommon.QueryResult<
  BenchmarkSubmissionStatusQuery,
  BenchmarkSubmissionStatusQueryVariables
>;
export const BenchmarkIndividualVsGradeLevelInfoDocument = gql`
  query BenchmarkIndividualVsGradeLevelInfo($input: BenchmarkIndividualVsGradeLevelInput!) {
    benchmarkIndividualVsGradeLevelInfo(input: $input) {
      submission_type
      rubric_line_items {
        ...BenchmarkIndividualVsGradeLevelRubricLineItemFragment
      }
    }
  }
  ${BenchmarkIndividualVsGradeLevelRubricLineItemFragmentFragmentDoc}
`;

/**
 * __useBenchmarkIndividualVsGradeLevelInfoQuery__
 *
 * To run a query within a React component, call `useBenchmarkIndividualVsGradeLevelInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useBenchmarkIndividualVsGradeLevelInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBenchmarkIndividualVsGradeLevelInfoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBenchmarkIndividualVsGradeLevelInfoQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    BenchmarkIndividualVsGradeLevelInfoQuery,
    BenchmarkIndividualVsGradeLevelInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    BenchmarkIndividualVsGradeLevelInfoQuery,
    BenchmarkIndividualVsGradeLevelInfoQueryVariables
  >(BenchmarkIndividualVsGradeLevelInfoDocument, options);
}
export function useBenchmarkIndividualVsGradeLevelInfoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    BenchmarkIndividualVsGradeLevelInfoQuery,
    BenchmarkIndividualVsGradeLevelInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    BenchmarkIndividualVsGradeLevelInfoQuery,
    BenchmarkIndividualVsGradeLevelInfoQueryVariables
  >(BenchmarkIndividualVsGradeLevelInfoDocument, options);
}
export type BenchmarkIndividualVsGradeLevelInfoQueryHookResult = ReturnType<
  typeof useBenchmarkIndividualVsGradeLevelInfoQuery
>;
export type BenchmarkIndividualVsGradeLevelInfoLazyQueryHookResult = ReturnType<
  typeof useBenchmarkIndividualVsGradeLevelInfoLazyQuery
>;
export type BenchmarkIndividualVsGradeLevelInfoQueryResult = ApolloReactCommon.QueryResult<
  BenchmarkIndividualVsGradeLevelInfoQuery,
  BenchmarkIndividualVsGradeLevelInfoQueryVariables
>;
export const BenchmarkScoresDocument = gql`
  query BenchmarkScores($input: BenchmarkScoresInput!) {
    benchmarkScores(input: $input) {
      current_page
      total_count
      submission_line_items {
        ...BenchmarkScoresLineItemFragment
      }
    }
  }
  ${BenchmarkScoresLineItemFragmentFragmentDoc}
`;

/**
 * __useBenchmarkScoresQuery__
 *
 * To run a query within a React component, call `useBenchmarkScoresQuery` and pass it any options that fit your needs.
 * When your component renders, `useBenchmarkScoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBenchmarkScoresQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBenchmarkScoresQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<BenchmarkScoresQuery, BenchmarkScoresQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<BenchmarkScoresQuery, BenchmarkScoresQueryVariables>(
    BenchmarkScoresDocument,
    options
  );
}
export function useBenchmarkScoresLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BenchmarkScoresQuery, BenchmarkScoresQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<BenchmarkScoresQuery, BenchmarkScoresQueryVariables>(
    BenchmarkScoresDocument,
    options
  );
}
export type BenchmarkScoresQueryHookResult = ReturnType<typeof useBenchmarkScoresQuery>;
export type BenchmarkScoresLazyQueryHookResult = ReturnType<typeof useBenchmarkScoresLazyQuery>;
export type BenchmarkScoresQueryResult = ApolloReactCommon.QueryResult<
  BenchmarkScoresQuery,
  BenchmarkScoresQueryVariables
>;
export const BenchmarkStudentGrowthReportDocument = gql`
  query benchmarkStudentGrowthReport($input: BenchmarkStudentGrowthReportInput!) {
    benchmarkStudentGrowthReport(input: $input) {
      submission_speaking_score_average
      submission_writing_score_average
      submission_submitted_at_timestamp
      benchmark_type
    }
  }
`;

/**
 * __useBenchmarkStudentGrowthReportQuery__
 *
 * To run a query within a React component, call `useBenchmarkStudentGrowthReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useBenchmarkStudentGrowthReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBenchmarkStudentGrowthReportQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBenchmarkStudentGrowthReportQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    BenchmarkStudentGrowthReportQuery,
    BenchmarkStudentGrowthReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<BenchmarkStudentGrowthReportQuery, BenchmarkStudentGrowthReportQueryVariables>(
    BenchmarkStudentGrowthReportDocument,
    options
  );
}
export function useBenchmarkStudentGrowthReportLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    BenchmarkStudentGrowthReportQuery,
    BenchmarkStudentGrowthReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<BenchmarkStudentGrowthReportQuery, BenchmarkStudentGrowthReportQueryVariables>(
    BenchmarkStudentGrowthReportDocument,
    options
  );
}
export type BenchmarkStudentGrowthReportQueryHookResult = ReturnType<typeof useBenchmarkStudentGrowthReportQuery>;
export type BenchmarkStudentGrowthReportLazyQueryHookResult = ReturnType<
  typeof useBenchmarkStudentGrowthReportLazyQuery
>;
export type BenchmarkStudentGrowthReportQueryResult = ApolloReactCommon.QueryResult<
  BenchmarkStudentGrowthReportQuery,
  BenchmarkStudentGrowthReportQueryVariables
>;
export const TeacherBenchmarkStudentListDocument = gql`
  query TeacherBenchmarkStudentList($input: TeacherBenchmarkStudentListInput!) {
    teacherBenchmarkStudentList(input: $input) {
      student_first_name
      student_last_name
      student_user_id
    }
  }
`;

/**
 * __useTeacherBenchmarkStudentListQuery__
 *
 * To run a query within a React component, call `useTeacherBenchmarkStudentListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeacherBenchmarkStudentListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeacherBenchmarkStudentListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTeacherBenchmarkStudentListQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    TeacherBenchmarkStudentListQuery,
    TeacherBenchmarkStudentListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<TeacherBenchmarkStudentListQuery, TeacherBenchmarkStudentListQueryVariables>(
    TeacherBenchmarkStudentListDocument,
    options
  );
}
export function useTeacherBenchmarkStudentListLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    TeacherBenchmarkStudentListQuery,
    TeacherBenchmarkStudentListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<TeacherBenchmarkStudentListQuery, TeacherBenchmarkStudentListQueryVariables>(
    TeacherBenchmarkStudentListDocument,
    options
  );
}
export type TeacherBenchmarkStudentListQueryHookResult = ReturnType<typeof useTeacherBenchmarkStudentListQuery>;
export type TeacherBenchmarkStudentListLazyQueryHookResult = ReturnType<typeof useTeacherBenchmarkStudentListLazyQuery>;
export type TeacherBenchmarkStudentListQueryResult = ApolloReactCommon.QueryResult<
  TeacherBenchmarkStudentListQuery,
  TeacherBenchmarkStudentListQueryVariables
>;
export const BenchmarkProgressScoreDocument = gql`
  query benchmarkProgressScore($input: BenchmarkProgressScoreInput!) {
    benchmarkProgressScore(input: $input) {
      total_count
      nodes {
        ...BenchmarkProgressScoreFragment
      }
    }
  }
  ${BenchmarkProgressScoreFragmentFragmentDoc}
`;

/**
 * __useBenchmarkProgressScoreQuery__
 *
 * To run a query within a React component, call `useBenchmarkProgressScoreQuery` and pass it any options that fit your needs.
 * When your component renders, `useBenchmarkProgressScoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBenchmarkProgressScoreQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBenchmarkProgressScoreQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<BenchmarkProgressScoreQuery, BenchmarkProgressScoreQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<BenchmarkProgressScoreQuery, BenchmarkProgressScoreQueryVariables>(
    BenchmarkProgressScoreDocument,
    options
  );
}
export function useBenchmarkProgressScoreLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BenchmarkProgressScoreQuery, BenchmarkProgressScoreQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<BenchmarkProgressScoreQuery, BenchmarkProgressScoreQueryVariables>(
    BenchmarkProgressScoreDocument,
    options
  );
}
export type BenchmarkProgressScoreQueryHookResult = ReturnType<typeof useBenchmarkProgressScoreQuery>;
export type BenchmarkProgressScoreLazyQueryHookResult = ReturnType<typeof useBenchmarkProgressScoreLazyQuery>;
export type BenchmarkProgressScoreQueryResult = ApolloReactCommon.QueryResult<
  BenchmarkProgressScoreQuery,
  BenchmarkProgressScoreQueryVariables
>;
export const AddTypeformUserMappingDocument = gql`
  mutation AddTypeformUserMapping($typeformId: String!, $submitted: Boolean) {
    AddTypeformUserMapping(input: { typeform_id: $typeformId, submitted: $submitted })
  }
`;
export type AddTypeformUserMappingMutationFn = ApolloReactCommon.MutationFunction<
  AddTypeformUserMappingMutation,
  AddTypeformUserMappingMutationVariables
>;

/**
 * __useAddTypeformUserMappingMutation__
 *
 * To run a mutation, you first call `useAddTypeformUserMappingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTypeformUserMappingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTypeformUserMappingMutation, { data, loading, error }] = useAddTypeformUserMappingMutation({
 *   variables: {
 *      typeformId: // value for 'typeformId'
 *      submitted: // value for 'submitted'
 *   },
 * });
 */
export function useAddTypeformUserMappingMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddTypeformUserMappingMutation,
    AddTypeformUserMappingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<AddTypeformUserMappingMutation, AddTypeformUserMappingMutationVariables>(
    AddTypeformUserMappingDocument,
    options
  );
}
export type AddTypeformUserMappingMutationHookResult = ReturnType<typeof useAddTypeformUserMappingMutation>;
export type AddTypeformUserMappingMutationResult = ApolloReactCommon.MutationResult<AddTypeformUserMappingMutation>;
export type AddTypeformUserMappingMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddTypeformUserMappingMutation,
  AddTypeformUserMappingMutationVariables
>;
export const SubmissionsDocument = gql`
  query Submissions(
    $limit: Int
    $page: Int
    $sort: String
    $orderBy: OrderBy
    $search: String
    $statusList: [SubmissionStatus]
    $section_id: String
    $school_id: String
  ) {
    submissions(
      page: $page
      limit: $limit
      sort: $sort
      orderBy: $orderBy
      search: $search
      status: $statusList
      section_id: $section_id
      school_id: $school_id
    ) {
      totalCount
      nodes {
        ...AssignmentSubmission
      }
    }
  }
  ${AssignmentSubmissionFragmentDoc}
`;

/**
 * __useSubmissionsQuery__
 *
 * To run a query within a React component, call `useSubmissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubmissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubmissionsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      sort: // value for 'sort'
 *      orderBy: // value for 'orderBy'
 *      search: // value for 'search'
 *      statusList: // value for 'statusList'
 *      section_id: // value for 'section_id'
 *      school_id: // value for 'school_id'
 *   },
 * });
 */
export function useSubmissionsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<SubmissionsQuery, SubmissionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<SubmissionsQuery, SubmissionsQueryVariables>(SubmissionsDocument, options);
}
export function useSubmissionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SubmissionsQuery, SubmissionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<SubmissionsQuery, SubmissionsQueryVariables>(SubmissionsDocument, options);
}
export type SubmissionsQueryHookResult = ReturnType<typeof useSubmissionsQuery>;
export type SubmissionsLazyQueryHookResult = ReturnType<typeof useSubmissionsLazyQuery>;
export type SubmissionsQueryResult = ApolloReactCommon.QueryResult<SubmissionsQuery, SubmissionsQueryVariables>;
export const GetBenchmarkV2TypeformDocument = gql`
  query GetBenchmarkV2Typeform {
    getBenchmarkV2Typeform {
      id
      typeform_form_id
      typeform_name
      show_typeform
    }
  }
`;

/**
 * __useGetBenchmarkV2TypeformQuery__
 *
 * To run a query within a React component, call `useGetBenchmarkV2TypeformQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBenchmarkV2TypeformQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBenchmarkV2TypeformQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBenchmarkV2TypeformQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetBenchmarkV2TypeformQuery, GetBenchmarkV2TypeformQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetBenchmarkV2TypeformQuery, GetBenchmarkV2TypeformQueryVariables>(
    GetBenchmarkV2TypeformDocument,
    options
  );
}
export function useGetBenchmarkV2TypeformLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBenchmarkV2TypeformQuery, GetBenchmarkV2TypeformQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetBenchmarkV2TypeformQuery, GetBenchmarkV2TypeformQueryVariables>(
    GetBenchmarkV2TypeformDocument,
    options
  );
}
export type GetBenchmarkV2TypeformQueryHookResult = ReturnType<typeof useGetBenchmarkV2TypeformQuery>;
export type GetBenchmarkV2TypeformLazyQueryHookResult = ReturnType<typeof useGetBenchmarkV2TypeformLazyQuery>;
export type GetBenchmarkV2TypeformQueryResult = ApolloReactCommon.QueryResult<
  GetBenchmarkV2TypeformQuery,
  GetBenchmarkV2TypeformQueryVariables
>;
export const GetTeacherDashboardSurveyTypeformDocument = gql`
  query GetTeacherDashboardSurveyTypeform {
    getTeacherDashboardSurveyTypeform {
      id
      typeform_form_id
      typeform_name
      show_typeform
    }
  }
`;

/**
 * __useGetTeacherDashboardSurveyTypeformQuery__
 *
 * To run a query within a React component, call `useGetTeacherDashboardSurveyTypeformQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeacherDashboardSurveyTypeformQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeacherDashboardSurveyTypeformQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTeacherDashboardSurveyTypeformQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetTeacherDashboardSurveyTypeformQuery,
    GetTeacherDashboardSurveyTypeformQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetTeacherDashboardSurveyTypeformQuery,
    GetTeacherDashboardSurveyTypeformQueryVariables
  >(GetTeacherDashboardSurveyTypeformDocument, options);
}
export function useGetTeacherDashboardSurveyTypeformLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetTeacherDashboardSurveyTypeformQuery,
    GetTeacherDashboardSurveyTypeformQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetTeacherDashboardSurveyTypeformQuery,
    GetTeacherDashboardSurveyTypeformQueryVariables
  >(GetTeacherDashboardSurveyTypeformDocument, options);
}
export type GetTeacherDashboardSurveyTypeformQueryHookResult = ReturnType<
  typeof useGetTeacherDashboardSurveyTypeformQuery
>;
export type GetTeacherDashboardSurveyTypeformLazyQueryHookResult = ReturnType<
  typeof useGetTeacherDashboardSurveyTypeformLazyQuery
>;
export type GetTeacherDashboardSurveyTypeformQueryResult = ApolloReactCommon.QueryResult<
  GetTeacherDashboardSurveyTypeformQuery,
  GetTeacherDashboardSurveyTypeformQueryVariables
>;
export const GetCsvDownloadSurveyTypeformDocument = gql`
  query GetCSVDownloadSurveyTypeform {
    getCSVDownloadSurveyTypeform {
      id
      typeform_form_id
      typeform_name
      show_typeform
    }
  }
`;

/**
 * __useGetCsvDownloadSurveyTypeformQuery__
 *
 * To run a query within a React component, call `useGetCsvDownloadSurveyTypeformQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCsvDownloadSurveyTypeformQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCsvDownloadSurveyTypeformQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCsvDownloadSurveyTypeformQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetCsvDownloadSurveyTypeformQuery,
    GetCsvDownloadSurveyTypeformQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetCsvDownloadSurveyTypeformQuery, GetCsvDownloadSurveyTypeformQueryVariables>(
    GetCsvDownloadSurveyTypeformDocument,
    options
  );
}
export function useGetCsvDownloadSurveyTypeformLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetCsvDownloadSurveyTypeformQuery,
    GetCsvDownloadSurveyTypeformQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetCsvDownloadSurveyTypeformQuery, GetCsvDownloadSurveyTypeformQueryVariables>(
    GetCsvDownloadSurveyTypeformDocument,
    options
  );
}
export type GetCsvDownloadSurveyTypeformQueryHookResult = ReturnType<typeof useGetCsvDownloadSurveyTypeformQuery>;
export type GetCsvDownloadSurveyTypeformLazyQueryHookResult = ReturnType<
  typeof useGetCsvDownloadSurveyTypeformLazyQuery
>;
export type GetCsvDownloadSurveyTypeformQueryResult = ApolloReactCommon.QueryResult<
  GetCsvDownloadSurveyTypeformQuery,
  GetCsvDownloadSurveyTypeformQueryVariables
>;
export const GetCsvSurveyTypeformDocument = gql`
  query GetCSVSurveyTypeform {
    getCSVSurveyTypeform {
      id
      typeform_form_id
      typeform_name
      show_typeform
    }
  }
`;

/**
 * __useGetCsvSurveyTypeformQuery__
 *
 * To run a query within a React component, call `useGetCsvSurveyTypeformQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCsvSurveyTypeformQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCsvSurveyTypeformQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCsvSurveyTypeformQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetCsvSurveyTypeformQuery, GetCsvSurveyTypeformQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetCsvSurveyTypeformQuery, GetCsvSurveyTypeformQueryVariables>(
    GetCsvSurveyTypeformDocument,
    options
  );
}
export function useGetCsvSurveyTypeformLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCsvSurveyTypeformQuery, GetCsvSurveyTypeformQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetCsvSurveyTypeformQuery, GetCsvSurveyTypeformQueryVariables>(
    GetCsvSurveyTypeformDocument,
    options
  );
}
export type GetCsvSurveyTypeformQueryHookResult = ReturnType<typeof useGetCsvSurveyTypeformQuery>;
export type GetCsvSurveyTypeformLazyQueryHookResult = ReturnType<typeof useGetCsvSurveyTypeformLazyQuery>;
export type GetCsvSurveyTypeformQueryResult = ApolloReactCommon.QueryResult<
  GetCsvSurveyTypeformQuery,
  GetCsvSurveyTypeformQueryVariables
>;
export const GetTeacherBenchmarkDashboardWalkthroughTypeformDocument = gql`
  query GetTeacherBenchmarkDashboardWalkthroughTypeform {
    getTeacherBenchmarkDashboardWalkthrough {
      id
      typeform_form_id
      typeform_name
      show_typeform
    }
  }
`;

/**
 * __useGetTeacherBenchmarkDashboardWalkthroughTypeformQuery__
 *
 * To run a query within a React component, call `useGetTeacherBenchmarkDashboardWalkthroughTypeformQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeacherBenchmarkDashboardWalkthroughTypeformQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeacherBenchmarkDashboardWalkthroughTypeformQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTeacherBenchmarkDashboardWalkthroughTypeformQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetTeacherBenchmarkDashboardWalkthroughTypeformQuery,
    GetTeacherBenchmarkDashboardWalkthroughTypeformQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetTeacherBenchmarkDashboardWalkthroughTypeformQuery,
    GetTeacherBenchmarkDashboardWalkthroughTypeformQueryVariables
  >(GetTeacherBenchmarkDashboardWalkthroughTypeformDocument, options);
}
export function useGetTeacherBenchmarkDashboardWalkthroughTypeformLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetTeacherBenchmarkDashboardWalkthroughTypeformQuery,
    GetTeacherBenchmarkDashboardWalkthroughTypeformQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetTeacherBenchmarkDashboardWalkthroughTypeformQuery,
    GetTeacherBenchmarkDashboardWalkthroughTypeformQueryVariables
  >(GetTeacherBenchmarkDashboardWalkthroughTypeformDocument, options);
}
export type GetTeacherBenchmarkDashboardWalkthroughTypeformQueryHookResult = ReturnType<
  typeof useGetTeacherBenchmarkDashboardWalkthroughTypeformQuery
>;
export type GetTeacherBenchmarkDashboardWalkthroughTypeformLazyQueryHookResult = ReturnType<
  typeof useGetTeacherBenchmarkDashboardWalkthroughTypeformLazyQuery
>;
export type GetTeacherBenchmarkDashboardWalkthroughTypeformQueryResult = ApolloReactCommon.QueryResult<
  GetTeacherBenchmarkDashboardWalkthroughTypeformQuery,
  GetTeacherBenchmarkDashboardWalkthroughTypeformQueryVariables
>;
export const GetTeacherBenchmarkDashboardSurveyTypeformDocument = gql`
  query GetTeacherBenchmarkDashboardSurveyTypeform {
    getTeacherBenchmarkDashboardSurvey {
      id
      typeform_form_id
      typeform_name
      show_typeform
    }
  }
`;

/**
 * __useGetTeacherBenchmarkDashboardSurveyTypeformQuery__
 *
 * To run a query within a React component, call `useGetTeacherBenchmarkDashboardSurveyTypeformQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeacherBenchmarkDashboardSurveyTypeformQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeacherBenchmarkDashboardSurveyTypeformQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTeacherBenchmarkDashboardSurveyTypeformQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetTeacherBenchmarkDashboardSurveyTypeformQuery,
    GetTeacherBenchmarkDashboardSurveyTypeformQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetTeacherBenchmarkDashboardSurveyTypeformQuery,
    GetTeacherBenchmarkDashboardSurveyTypeformQueryVariables
  >(GetTeacherBenchmarkDashboardSurveyTypeformDocument, options);
}
export function useGetTeacherBenchmarkDashboardSurveyTypeformLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetTeacherBenchmarkDashboardSurveyTypeformQuery,
    GetTeacherBenchmarkDashboardSurveyTypeformQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetTeacherBenchmarkDashboardSurveyTypeformQuery,
    GetTeacherBenchmarkDashboardSurveyTypeformQueryVariables
  >(GetTeacherBenchmarkDashboardSurveyTypeformDocument, options);
}
export type GetTeacherBenchmarkDashboardSurveyTypeformQueryHookResult = ReturnType<
  typeof useGetTeacherBenchmarkDashboardSurveyTypeformQuery
>;
export type GetTeacherBenchmarkDashboardSurveyTypeformLazyQueryHookResult = ReturnType<
  typeof useGetTeacherBenchmarkDashboardSurveyTypeformLazyQuery
>;
export type GetTeacherBenchmarkDashboardSurveyTypeformQueryResult = ApolloReactCommon.QueryResult<
  GetTeacherBenchmarkDashboardSurveyTypeformQuery,
  GetTeacherBenchmarkDashboardSurveyTypeformQueryVariables
>;
export const GetBtsTypeformDocument = gql`
  query GetBTSTypeform {
    getBTSTypeform {
      id
      typeform_form_id
      typeform_name
      show_typeform
    }
  }
`;

/**
 * __useGetBtsTypeformQuery__
 *
 * To run a query within a React component, call `useGetBtsTypeformQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBtsTypeformQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBtsTypeformQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBtsTypeformQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetBtsTypeformQuery, GetBtsTypeformQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetBtsTypeformQuery, GetBtsTypeformQueryVariables>(GetBtsTypeformDocument, options);
}
export function useGetBtsTypeformLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBtsTypeformQuery, GetBtsTypeformQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetBtsTypeformQuery, GetBtsTypeformQueryVariables>(
    GetBtsTypeformDocument,
    options
  );
}
export type GetBtsTypeformQueryHookResult = ReturnType<typeof useGetBtsTypeformQuery>;
export type GetBtsTypeformLazyQueryHookResult = ReturnType<typeof useGetBtsTypeformLazyQuery>;
export type GetBtsTypeformQueryResult = ApolloReactCommon.QueryResult<
  GetBtsTypeformQuery,
  GetBtsTypeformQueryVariables
>;
export const GetCreateClassesSurveyTypeformDocument = gql`
  query GetCreateClassesSurveyTypeform {
    getCreateClassesSurveyTypeform {
      id
      typeform_form_id
      typeform_name
      show_typeform
    }
  }
`;

/**
 * __useGetCreateClassesSurveyTypeformQuery__
 *
 * To run a query within a React component, call `useGetCreateClassesSurveyTypeformQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreateClassesSurveyTypeformQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreateClassesSurveyTypeformQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCreateClassesSurveyTypeformQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetCreateClassesSurveyTypeformQuery,
    GetCreateClassesSurveyTypeformQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetCreateClassesSurveyTypeformQuery, GetCreateClassesSurveyTypeformQueryVariables>(
    GetCreateClassesSurveyTypeformDocument,
    options
  );
}
export function useGetCreateClassesSurveyTypeformLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetCreateClassesSurveyTypeformQuery,
    GetCreateClassesSurveyTypeformQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetCreateClassesSurveyTypeformQuery,
    GetCreateClassesSurveyTypeformQueryVariables
  >(GetCreateClassesSurveyTypeformDocument, options);
}
export type GetCreateClassesSurveyTypeformQueryHookResult = ReturnType<typeof useGetCreateClassesSurveyTypeformQuery>;
export type GetCreateClassesSurveyTypeformLazyQueryHookResult = ReturnType<
  typeof useGetCreateClassesSurveyTypeformLazyQuery
>;
export type GetCreateClassesSurveyTypeformQueryResult = ApolloReactCommon.QueryResult<
  GetCreateClassesSurveyTypeformQuery,
  GetCreateClassesSurveyTypeformQueryVariables
>;
export const GetBtsSideTabTypeformDocument = gql`
  query GetBTSSideTabTypeform {
    getBTSSideTabTypeform {
      id
      typeform_form_id
      typeform_name
      show_typeform
    }
  }
`;

/**
 * __useGetBtsSideTabTypeformQuery__
 *
 * To run a query within a React component, call `useGetBtsSideTabTypeformQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBtsSideTabTypeformQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBtsSideTabTypeformQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBtsSideTabTypeformQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetBtsSideTabTypeformQuery, GetBtsSideTabTypeformQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetBtsSideTabTypeformQuery, GetBtsSideTabTypeformQueryVariables>(
    GetBtsSideTabTypeformDocument,
    options
  );
}
export function useGetBtsSideTabTypeformLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBtsSideTabTypeformQuery, GetBtsSideTabTypeformQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetBtsSideTabTypeformQuery, GetBtsSideTabTypeformQueryVariables>(
    GetBtsSideTabTypeformDocument,
    options
  );
}
export type GetBtsSideTabTypeformQueryHookResult = ReturnType<typeof useGetBtsSideTabTypeformQuery>;
export type GetBtsSideTabTypeformLazyQueryHookResult = ReturnType<typeof useGetBtsSideTabTypeformLazyQuery>;
export type GetBtsSideTabTypeformQueryResult = ApolloReactCommon.QueryResult<
  GetBtsSideTabTypeformQuery,
  GetBtsSideTabTypeformQueryVariables
>;
export const GetAdminDashboardTourTypeformDocument = gql`
  query GetAdminDashboardTourTypeform {
    getAdminDashboardTourTypeform {
      id
      typeform_form_id
      typeform_name
      show_typeform
    }
  }
`;

/**
 * __useGetAdminDashboardTourTypeformQuery__
 *
 * To run a query within a React component, call `useGetAdminDashboardTourTypeformQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminDashboardTourTypeformQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminDashboardTourTypeformQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAdminDashboardTourTypeformQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetAdminDashboardTourTypeformQuery,
    GetAdminDashboardTourTypeformQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetAdminDashboardTourTypeformQuery, GetAdminDashboardTourTypeformQueryVariables>(
    GetAdminDashboardTourTypeformDocument,
    options
  );
}
export function useGetAdminDashboardTourTypeformLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetAdminDashboardTourTypeformQuery,
    GetAdminDashboardTourTypeformQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetAdminDashboardTourTypeformQuery, GetAdminDashboardTourTypeformQueryVariables>(
    GetAdminDashboardTourTypeformDocument,
    options
  );
}
export type GetAdminDashboardTourTypeformQueryHookResult = ReturnType<typeof useGetAdminDashboardTourTypeformQuery>;
export type GetAdminDashboardTourTypeformLazyQueryHookResult = ReturnType<
  typeof useGetAdminDashboardTourTypeformLazyQuery
>;
export type GetAdminDashboardTourTypeformQueryResult = ApolloReactCommon.QueryResult<
  GetAdminDashboardTourTypeformQuery,
  GetAdminDashboardTourTypeformQueryVariables
>;
export const GetAdminDashboardSurveyTypeformDocument = gql`
  query GetAdminDashboardSurveyTypeform {
    getAdminDashboardSurveyTypeform {
      id
      typeform_form_id
      typeform_name
      show_typeform
    }
  }
`;

/**
 * __useGetAdminDashboardSurveyTypeformQuery__
 *
 * To run a query within a React component, call `useGetAdminDashboardSurveyTypeformQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminDashboardSurveyTypeformQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminDashboardSurveyTypeformQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAdminDashboardSurveyTypeformQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetAdminDashboardSurveyTypeformQuery,
    GetAdminDashboardSurveyTypeformQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetAdminDashboardSurveyTypeformQuery, GetAdminDashboardSurveyTypeformQueryVariables>(
    GetAdminDashboardSurveyTypeformDocument,
    options
  );
}
export function useGetAdminDashboardSurveyTypeformLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetAdminDashboardSurveyTypeformQuery,
    GetAdminDashboardSurveyTypeformQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetAdminDashboardSurveyTypeformQuery,
    GetAdminDashboardSurveyTypeformQueryVariables
  >(GetAdminDashboardSurveyTypeformDocument, options);
}
export type GetAdminDashboardSurveyTypeformQueryHookResult = ReturnType<typeof useGetAdminDashboardSurveyTypeformQuery>;
export type GetAdminDashboardSurveyTypeformLazyQueryHookResult = ReturnType<
  typeof useGetAdminDashboardSurveyTypeformLazyQuery
>;
export type GetAdminDashboardSurveyTypeformQueryResult = ApolloReactCommon.QueryResult<
  GetAdminDashboardSurveyTypeformQuery,
  GetAdminDashboardSurveyTypeformQueryVariables
>;
export const GetTeacherDashboardIndividualStudentTourTypeformDocument = gql`
  query GetTeacherDashboardIndividualStudentTourTypeform {
    getTeacherDashboardIndividualStudentTourTypeform {
      id
      typeform_form_id
      typeform_name
      show_typeform
    }
  }
`;

/**
 * __useGetTeacherDashboardIndividualStudentTourTypeformQuery__
 *
 * To run a query within a React component, call `useGetTeacherDashboardIndividualStudentTourTypeformQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeacherDashboardIndividualStudentTourTypeformQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeacherDashboardIndividualStudentTourTypeformQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTeacherDashboardIndividualStudentTourTypeformQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetTeacherDashboardIndividualStudentTourTypeformQuery,
    GetTeacherDashboardIndividualStudentTourTypeformQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetTeacherDashboardIndividualStudentTourTypeformQuery,
    GetTeacherDashboardIndividualStudentTourTypeformQueryVariables
  >(GetTeacherDashboardIndividualStudentTourTypeformDocument, options);
}
export function useGetTeacherDashboardIndividualStudentTourTypeformLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetTeacherDashboardIndividualStudentTourTypeformQuery,
    GetTeacherDashboardIndividualStudentTourTypeformQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetTeacherDashboardIndividualStudentTourTypeformQuery,
    GetTeacherDashboardIndividualStudentTourTypeformQueryVariables
  >(GetTeacherDashboardIndividualStudentTourTypeformDocument, options);
}
export type GetTeacherDashboardIndividualStudentTourTypeformQueryHookResult = ReturnType<
  typeof useGetTeacherDashboardIndividualStudentTourTypeformQuery
>;
export type GetTeacherDashboardIndividualStudentTourTypeformLazyQueryHookResult = ReturnType<
  typeof useGetTeacherDashboardIndividualStudentTourTypeformLazyQuery
>;
export type GetTeacherDashboardIndividualStudentTourTypeformQueryResult = ApolloReactCommon.QueryResult<
  GetTeacherDashboardIndividualStudentTourTypeformQuery,
  GetTeacherDashboardIndividualStudentTourTypeformQueryVariables
>;
export const GetTeacherDashboardIndividualStudentSurveyTypeformDocument = gql`
  query GetTeacherDashboardIndividualStudentSurveyTypeform {
    getTeacherDashboardIndividualStudentSurveyTypeform {
      id
      typeform_form_id
      typeform_name
      show_typeform
    }
  }
`;

/**
 * __useGetTeacherDashboardIndividualStudentSurveyTypeformQuery__
 *
 * To run a query within a React component, call `useGetTeacherDashboardIndividualStudentSurveyTypeformQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeacherDashboardIndividualStudentSurveyTypeformQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeacherDashboardIndividualStudentSurveyTypeformQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTeacherDashboardIndividualStudentSurveyTypeformQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetTeacherDashboardIndividualStudentSurveyTypeformQuery,
    GetTeacherDashboardIndividualStudentSurveyTypeformQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetTeacherDashboardIndividualStudentSurveyTypeformQuery,
    GetTeacherDashboardIndividualStudentSurveyTypeformQueryVariables
  >(GetTeacherDashboardIndividualStudentSurveyTypeformDocument, options);
}
export function useGetTeacherDashboardIndividualStudentSurveyTypeformLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetTeacherDashboardIndividualStudentSurveyTypeformQuery,
    GetTeacherDashboardIndividualStudentSurveyTypeformQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetTeacherDashboardIndividualStudentSurveyTypeformQuery,
    GetTeacherDashboardIndividualStudentSurveyTypeformQueryVariables
  >(GetTeacherDashboardIndividualStudentSurveyTypeformDocument, options);
}
export type GetTeacherDashboardIndividualStudentSurveyTypeformQueryHookResult = ReturnType<
  typeof useGetTeacherDashboardIndividualStudentSurveyTypeformQuery
>;
export type GetTeacherDashboardIndividualStudentSurveyTypeformLazyQueryHookResult = ReturnType<
  typeof useGetTeacherDashboardIndividualStudentSurveyTypeformLazyQuery
>;
export type GetTeacherDashboardIndividualStudentSurveyTypeformQueryResult = ApolloReactCommon.QueryResult<
  GetTeacherDashboardIndividualStudentSurveyTypeformQuery,
  GetTeacherDashboardIndividualStudentSurveyTypeformQueryVariables
>;
export const GetAdminDashboardTourTypeformPhase2Document = gql`
  query GetAdminDashboardTourTypeformPhase2 {
    getAdminDashboardTourTypeformPhase2 {
      id
      typeform_form_id
      typeform_name
      show_typeform
    }
  }
`;

/**
 * __useGetAdminDashboardTourTypeformPhase2Query__
 *
 * To run a query within a React component, call `useGetAdminDashboardTourTypeformPhase2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminDashboardTourTypeformPhase2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminDashboardTourTypeformPhase2Query({
 *   variables: {
 *   },
 * });
 */
export function useGetAdminDashboardTourTypeformPhase2Query(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetAdminDashboardTourTypeformPhase2Query,
    GetAdminDashboardTourTypeformPhase2QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetAdminDashboardTourTypeformPhase2Query,
    GetAdminDashboardTourTypeformPhase2QueryVariables
  >(GetAdminDashboardTourTypeformPhase2Document, options);
}
export function useGetAdminDashboardTourTypeformPhase2LazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetAdminDashboardTourTypeformPhase2Query,
    GetAdminDashboardTourTypeformPhase2QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetAdminDashboardTourTypeformPhase2Query,
    GetAdminDashboardTourTypeformPhase2QueryVariables
  >(GetAdminDashboardTourTypeformPhase2Document, options);
}
export type GetAdminDashboardTourTypeformPhase2QueryHookResult = ReturnType<
  typeof useGetAdminDashboardTourTypeformPhase2Query
>;
export type GetAdminDashboardTourTypeformPhase2LazyQueryHookResult = ReturnType<
  typeof useGetAdminDashboardTourTypeformPhase2LazyQuery
>;
export type GetAdminDashboardTourTypeformPhase2QueryResult = ApolloReactCommon.QueryResult<
  GetAdminDashboardTourTypeformPhase2Query,
  GetAdminDashboardTourTypeformPhase2QueryVariables
>;
export const GetAdminDashboardSurveyTypeformPhase2Document = gql`
  query GetAdminDashboardSurveyTypeformPhase2 {
    getAdminDashboardSurveyTypeformPhase2 {
      id
      typeform_form_id
      typeform_name
      show_typeform
    }
  }
`;

/**
 * __useGetAdminDashboardSurveyTypeformPhase2Query__
 *
 * To run a query within a React component, call `useGetAdminDashboardSurveyTypeformPhase2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminDashboardSurveyTypeformPhase2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminDashboardSurveyTypeformPhase2Query({
 *   variables: {
 *   },
 * });
 */
export function useGetAdminDashboardSurveyTypeformPhase2Query(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetAdminDashboardSurveyTypeformPhase2Query,
    GetAdminDashboardSurveyTypeformPhase2QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetAdminDashboardSurveyTypeformPhase2Query,
    GetAdminDashboardSurveyTypeformPhase2QueryVariables
  >(GetAdminDashboardSurveyTypeformPhase2Document, options);
}
export function useGetAdminDashboardSurveyTypeformPhase2LazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetAdminDashboardSurveyTypeformPhase2Query,
    GetAdminDashboardSurveyTypeformPhase2QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetAdminDashboardSurveyTypeformPhase2Query,
    GetAdminDashboardSurveyTypeformPhase2QueryVariables
  >(GetAdminDashboardSurveyTypeformPhase2Document, options);
}
export type GetAdminDashboardSurveyTypeformPhase2QueryHookResult = ReturnType<
  typeof useGetAdminDashboardSurveyTypeformPhase2Query
>;
export type GetAdminDashboardSurveyTypeformPhase2LazyQueryHookResult = ReturnType<
  typeof useGetAdminDashboardSurveyTypeformPhase2LazyQuery
>;
export type GetAdminDashboardSurveyTypeformPhase2QueryResult = ApolloReactCommon.QueryResult<
  GetAdminDashboardSurveyTypeformPhase2Query,
  GetAdminDashboardSurveyTypeformPhase2QueryVariables
>;
export const TeacherDetailsDocument = gql`
  query TeacherDetails($id: String!) {
    teacher(id: $id) {
      id
      first_name
      last_name
      teacher_id
      email
      job_title
      user_id
      district
      district_id
      state_id
      state_code
      source
      schools {
        id
        name
      }
    }
  }
`;

/**
 * __useTeacherDetailsQuery__
 *
 * To run a query within a React component, call `useTeacherDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeacherDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeacherDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTeacherDetailsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<TeacherDetailsQuery, TeacherDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<TeacherDetailsQuery, TeacherDetailsQueryVariables>(TeacherDetailsDocument, options);
}
export function useTeacherDetailsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeacherDetailsQuery, TeacherDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<TeacherDetailsQuery, TeacherDetailsQueryVariables>(
    TeacherDetailsDocument,
    options
  );
}
export type TeacherDetailsQueryHookResult = ReturnType<typeof useTeacherDetailsQuery>;
export type TeacherDetailsLazyQueryHookResult = ReturnType<typeof useTeacherDetailsLazyQuery>;
export type TeacherDetailsQueryResult = ApolloReactCommon.QueryResult<
  TeacherDetailsQuery,
  TeacherDetailsQueryVariables
>;
export const TeacherEditFormDocument = gql`
  query TeacherEditForm($id: String!, $classTypes: [SectionCategory!]) {
    teacher(id: $id, class_types: $classTypes) {
      id
      first_name
      last_name
      teacher_id
      email
      job_title
      user_id
      district
      state_code
      schools {
        id
      }
      school_sections {
        school_id
        section_id
        primary_teacher
        school_name
        section_name
        source
      }
      district_id
      state_id
      title
      phone_number
    }
  }
`;

/**
 * __useTeacherEditFormQuery__
 *
 * To run a query within a React component, call `useTeacherEditFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeacherEditFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeacherEditFormQuery({
 *   variables: {
 *      id: // value for 'id'
 *      classTypes: // value for 'classTypes'
 *   },
 * });
 */
export function useTeacherEditFormQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<TeacherEditFormQuery, TeacherEditFormQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<TeacherEditFormQuery, TeacherEditFormQueryVariables>(
    TeacherEditFormDocument,
    options
  );
}
export function useTeacherEditFormLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeacherEditFormQuery, TeacherEditFormQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<TeacherEditFormQuery, TeacherEditFormQueryVariables>(
    TeacherEditFormDocument,
    options
  );
}
export type TeacherEditFormQueryHookResult = ReturnType<typeof useTeacherEditFormQuery>;
export type TeacherEditFormLazyQueryHookResult = ReturnType<typeof useTeacherEditFormLazyQuery>;
export type TeacherEditFormQueryResult = ApolloReactCommon.QueryResult<
  TeacherEditFormQuery,
  TeacherEditFormQueryVariables
>;
export const CreateTeacherFormDocument = gql`
  mutation CreateTeacherForm($input: CreateTeacherInput!) {
    createTeacher(input: $input)
  }
`;
export type CreateTeacherFormMutationFn = ApolloReactCommon.MutationFunction<
  CreateTeacherFormMutation,
  CreateTeacherFormMutationVariables
>;

/**
 * __useCreateTeacherFormMutation__
 *
 * To run a mutation, you first call `useCreateTeacherFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTeacherFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTeacherFormMutation, { data, loading, error }] = useCreateTeacherFormMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTeacherFormMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateTeacherFormMutation, CreateTeacherFormMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateTeacherFormMutation, CreateTeacherFormMutationVariables>(
    CreateTeacherFormDocument,
    options
  );
}
export type CreateTeacherFormMutationHookResult = ReturnType<typeof useCreateTeacherFormMutation>;
export type CreateTeacherFormMutationResult = ApolloReactCommon.MutationResult<CreateTeacherFormMutation>;
export type CreateTeacherFormMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateTeacherFormMutation,
  CreateTeacherFormMutationVariables
>;
export const UpdateTeacherFormDocument = gql`
  mutation UpdateTeacherForm($input: UpdateTeacherInput!) {
    updateTeacher(input: $input)
  }
`;
export type UpdateTeacherFormMutationFn = ApolloReactCommon.MutationFunction<
  UpdateTeacherFormMutation,
  UpdateTeacherFormMutationVariables
>;

/**
 * __useUpdateTeacherFormMutation__
 *
 * To run a mutation, you first call `useUpdateTeacherFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeacherFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeacherFormMutation, { data, loading, error }] = useUpdateTeacherFormMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTeacherFormMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTeacherFormMutation, UpdateTeacherFormMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateTeacherFormMutation, UpdateTeacherFormMutationVariables>(
    UpdateTeacherFormDocument,
    options
  );
}
export type UpdateTeacherFormMutationHookResult = ReturnType<typeof useUpdateTeacherFormMutation>;
export type UpdateTeacherFormMutationResult = ApolloReactCommon.MutationResult<UpdateTeacherFormMutation>;
export type UpdateTeacherFormMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateTeacherFormMutation,
  UpdateTeacherFormMutationVariables
>;
export const TeachersListDocument = gql`
  query TeachersList(
    $stateId: String
    $districtId: String
    $schoolID: String
    $agencyId: String
    $page: Int
    $limit: Int
    $sort: TeachersListSortType
    $searchByName: String
    $orderBy: OrderBy
  ) {
    teacherList(
      page: $page
      limit: $limit
      sort: $sort
      searchByName: $searchByName
      orderBy: $orderBy
      state_id: $stateId
      district_id: $districtId
      agency_id: $agencyId
      school_id: $schoolID
    ) {
      totalCount
      nodes {
        ...TeacherDetailsFragment
      }
    }
  }
  ${TeacherDetailsFragmentFragmentDoc}
`;

/**
 * __useTeachersListQuery__
 *
 * To run a query within a React component, call `useTeachersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeachersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeachersListQuery({
 *   variables: {
 *      stateId: // value for 'stateId'
 *      districtId: // value for 'districtId'
 *      schoolID: // value for 'schoolID'
 *      agencyId: // value for 'agencyId'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *      searchByName: // value for 'searchByName'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useTeachersListQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<TeachersListQuery, TeachersListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<TeachersListQuery, TeachersListQueryVariables>(TeachersListDocument, options);
}
export function useTeachersListLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeachersListQuery, TeachersListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<TeachersListQuery, TeachersListQueryVariables>(TeachersListDocument, options);
}
export type TeachersListQueryHookResult = ReturnType<typeof useTeachersListQuery>;
export type TeachersListLazyQueryHookResult = ReturnType<typeof useTeachersListLazyQuery>;
export type TeachersListQueryResult = ApolloReactCommon.QueryResult<TeachersListQuery, TeachersListQueryVariables>;
export const MeDocument = gql`
  query Me {
    me {
      id
      first_name
      last_name
      email
      role
      showHelpVideo
      permissions {
        id
        permission
      }
      score_scheme {
        id
        title_key
        title
        scoreDetail {
          id
          title_key
          title
          score_value
          description
          color_code
          range_start
          range_end
        }
      }
    }
  }
`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = ApolloReactCommon.QueryResult<MeQuery, MeQueryVariables>;
